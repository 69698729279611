import { getDownloadURL, uploadBytes } from "@firebase/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging from "@react-native-firebase/messaging";
import * as ImagePicker from "expo-image-picker";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { Platform } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { posthog } from "../../api/analytics/posthog";
import staticColors from "../../constants/static-colors";
import { setSelectedEnterprise } from "../enterprise/actions";
import { store } from "../store";
import {
  removeCurrentUser,
  setProfilePicture,
  setSessionToken,
} from "../user/actions";
import { setLogged, updateUserPropertyCall } from "../user/functions";
import { User } from "../user/types";

export async function getImageFromLibrary() {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 4],
    quality: 0,
  });

  if (!result.canceled && result.assets?.[0]?.uri) {
    return result.assets?.[0]?.uri;
  } else {
    return null;
  }
}

// Function to delete a user's profile image
export async function deleteProfileImageFromStore(currentUser) {
  try {
    // Create a reference to the file to delete
    const fileRef = ref(getStorage(), currentUser.email);

    // Delete the file
    await deleteObject(fileRef);

    return true;
  } catch (error) {
    return false;
  }
}

export async function uploadProfileImage(imageURI: string, currentUser: User) {
  console.log("Starting uploadProfileImage...");
  console.log("Image URI:", imageURI);
  console.log("Current user:", currentUser);

  try {
    const blob: any = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        console.log("XHR onload triggered");
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        console.error("XHR onerror triggered");
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", imageURI, true);
      console.log("Sending XHR request...");
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), currentUser.email);
    console.log("File reference created:", fileRef);

    await uploadBytes(fileRef, blob);
    console.log("File uploaded successfully");

    blob.close && blob.close();
    console.log("Blob closed");

    const url = await getDownloadURL(fileRef);
    console.log("Download URL retrieved:", url);

    const updateResult = await updateUserPropertyCall(
      currentUser,
      "profile_picture",
      url
    );
    if (updateResult) {
      console.log("User profile picture updated successfully");
      store.dispatch(setProfilePicture(url));
    } else {
      console.warn("Failed to update user profile picture");
    }

    return url;
  } catch (error) {
    console.error("Error in uploadProfileImage:", error);
    throw error; // Rethrow to handle this further up if needed
  }
}

export async function deleteProfileImage() {
  const currentUser = store.getState().userReducer.user;
  try {
    if (await updateUserPropertyCall(currentUser, "profile_picture", "")) {
      store.dispatch(setProfilePicture(""));
      await deleteProfileImageFromStore(currentUser);
    }
    return true;
  } catch {
    return false;
  }
}

export async function logout(currentUser, navigation) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "user_logged_out",
    "ProfileScreen",
    "UserLoggedOut"
  );
  store.dispatch(setSelectedEnterprise(null));

  staticColors["light"].accentLight = "#FFC371";
  staticColors["light"].accent = "#f4c430";
  staticColors["light"].accentDark = "#c9a227";
  staticColors["dark"].accentLight = "#FFC371";
  staticColors["dark"].accent = "#f4c430";
  staticColors["dark"].accentDark = "#c9a227";

  posthog?.reset();
  navigation?.reset({
    index: 0,
    routes: [{ name: "LandingScreen" }],
  });

  if (Platform.OS !== "web") {
    await messaging().unsubscribeFromTopic(currentUser?.user_id ?? "");
    await messaging().unsubscribeFromTopic("updates");
    await messaging().unsubscribeFromTopic("motivational");
  }

  store.dispatch(removeCurrentUser());
  store.dispatch(setSessionToken(undefined));

  await AsyncStorage.setItem("user", "null");
  await AsyncStorage.setItem("selectedEnterprise", "null");
  await setLogged(false, "", "", "");
}
