export const NURSE_JOBS = [
  "Atemtherapeut (Respiratory Therapist)",
  "Altenpfleger/in (Geriatric Nurse)",
  "Physician Assistant",
  "Gesundheits- und Kinderkrankenpfleger/in (Pediatric Nurse)",
  "Gesundheits- und Krankenpfleger/in (Nurse)",
  "Hebamme (Midwife)",
  "MFA (Medizinische/r Fachangestellte/r - Medical Assistant)",
  "MTA für Laboratoriumsanalytik (Medical Laboratory Technician)",
  "MTA für Funktionsdiagnostik (Medical Technical Assistant for Functional Diagnostics)",
  "MTA Radiologie (Radiology Technologist)",
  "Notfallsanitäter (Paramedic)",
  "OTA (Operationstechnische/r Assistent/in - Surgical Technologist)",
  "ATA (Anästhesietechnische/r Assistent/in - Anesthesia Technologist)",
  "Pflegefachkraft (generalisierte Pflege) (Registered Nurse - Generalized Nursing)",
  "Rettungssanitäter (Emergency Medical Technician)",
  "Logopäde/Logopädin (Speech Therapist)",
  "Physiotherapeut/in (Physiotherapist)",
  "Ergotherapeut/in (Occupational Therapist)",
  "Diätassistent/in (Dietitian)",
  "Pharmazeutisch-technische/r Assistent/in (PTA - Pharmaceutical Technician)",
  "Sozialarbeiter/in im Gesundheitswesen (Healthcare Social Worker)",
  "Psychologische/r Psychotherapeut/in (Psychological Psychotherapist)",
  "Orthoptist/in (Orthoptist)",
  "Podologe/Podologin (Podiatrist)",
  "Krankenhausmanager/in (Hospital Manager)",
  "Zahntechniker/in (Dental Technician)",
  "Gesundheitsökonom/in (Health Economist)",
  "Audiologe/Audiologin (Audiologist)",
  "Heilpädagoge/Heilpädagogin (Remedial Educator)",
  "Rehabilitationsberater/in (Rehabilitation Counselor)",
  "Diabetesberater/in (Diabetes Counselor)",
  "Gesundheitswissenschaftler/in (Health Scientist)",
  "Medizinische Dokumentationsassistent/in (Medical Documentation Assistant)",
  "Apothekenhelfer/in (Pharmacy Assistant)",
  "Kardiotechniker/in (Cardiovascular Technician)",
  "Radiologietechnologe/in (Radiology Technologist)",
  "Krankenpflegehelfer/in (Nursing Assistant)",
  "Medizinisch-technische/r Assistent/in für Radiologie (MTRA - Medical Radiologic Technologist)",
  "Assistenz im OP (Operating Room Assistant)",
  "Pflegeassistent/in (Nursing Assistant)",
  "Medizinischer Schreibdienst (Medical Transcriptionist)",
  "Hygienefachkraft (Infection Control Nurse)",
  "Versorgungsassistent/in im Krankenhaus (Hospital Supply Assistant)",
];
