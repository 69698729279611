import Ionicons from "@expo/vector-icons/Ionicons";
import { Text, TouchableOpacity, View } from "react-native";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import { Feather } from "@expo/vector-icons";
import { Sizes } from "../../constants/static-sizes";

export const SearchHistoryItem = ({
  title,
  issueSearchWithQuery,
  deleteMode,
  isHistory,
  deleteItem,
  index,
}) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        flexDirection: "row",
        flex: 1,
        marginHorizontal: 0,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
      }}
    >
      {deleteMode && (
        <TouchableOpacity
          style={{ marginRight: 12 }}
          onPress={() => deleteItem(index)}
        >
          <Ionicons name="close" size={24} color={Colors[colorScheme].red} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        onPress={() => {
          issueSearchWithQuery(title);
        }}
        style={{
          flexDirection: "row",
          paddingVertical: 8,
          alignItems: "center",
          flex: 1,
          opacity: 0.75,
        }}
      >
        {isHistory ? (
          <Ionicons
            name="time-outline"
            size={24}
            style={{ marginRight: 20 }}
            color={Colors[colorScheme].text}
          />
        ) : (
          <Ionicons
            name="trending-up-outline"
            size={24}
            style={{ marginRight: 20 }}
            color={Colors[colorScheme].text}
          />
        )}
        <Text
          style={{
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText + 1,
            marginRight: 12,
            flex: 1,
          }}
        >
          {title}
        </Text>
        <Feather
          name="chevron-right"
          size={24}
          color={Colors[colorScheme].text}
        />
      </TouchableOpacity>
    </View>
  );
};
