import { Text } from "react-native";
import { Sizes } from "../constants/static-sizes";
import staticColors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

export default function SectionHeaderText({ children, style = {} }) {
  const colorScheme = getColorScheme();
  return (
    <Text
      style={{
        fontSize: Sizes.sectionHeader,
        fontWeight: Sizes.sectionHeaderFont as any,
        color: staticColors[colorScheme].text,
        ...style,
      }}
    >
      {children}
    </Text>
  );
}
