export const contentStudent = () => [
  {
    title: "Wie ist dein Vorname?",
    textInput: {
      placeholder: "Vorname",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "firstname",
  },
  {
    title: "Wie ist dein Nachname?",
    textInput: {
      placeholder: "Nachname",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "lastname",
  },
  {
    title: "",
    textInput: {
      placeholder: "",
      type: "",
      contentType: "",
    },
    buttonForward: "",
    buttonBack: "",
    key: "additional_info",
  },
];

export const contentDoc = () => [
  {
    title: "Hast du einen Titel?",
    textInput: {
      placeholder: "Dr. / Prof. / PD Dr. / ...",
      type: "default",
      contentType: "title",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "title",
  },
  {
    title: "Wie ist dein Vorname?",
    textInput: {
      placeholder: "Vorname",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "firstname",
  },
  {
    title: "Wie dein Nachname?",
    textInput: {
      placeholder: "Nachname",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "lastname",
  },
  {
    title: "",
    textInput: {
      placeholder: "",
      type: "",
      contentType: "",
    },
    buttonForward: "",
    buttonBack: "",
    key: "additional_info",
  },
];

export const contentNurse = () => [
  {
    title: "Wie ist dein Vorname?",
    textInput: {
      placeholder: "Vorname",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "firstname",
  },
  {
    title: "Wie ist dein Nachname?",
    textInput: {
      placeholder: "Nachname",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Weiter",
    buttonBack: "Zurück",
    key: "lastname",
  },
  {
    title: "",
    textInput: {
      placeholder: "",
      type: "",
      contentType: "",
    },
    buttonForward: "",
    buttonBack: "",
    key: "additional_info",
  },
];
