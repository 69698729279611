import { Text, TouchableOpacity, View } from "react-native";

import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";

export default function ContraindicationHintModal({ open, onClose }) {
  const colorScheme = getColorScheme();

  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "contraindication_hint_modal",
        "ContraindicationHintModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></View>
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Hinweis: Kontraindikationen
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          Hier sind ausschließlich besondere Kontraindikationen für diesen
          Inhalt aufgeführt. Weitere Kontraindikationen des jeweils verwendeten
          Arzneimittels müssen anhand der Fachinformation geprüft werden.
        </Text>
        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text="Verstanden!"
          icon={null}
        />
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
