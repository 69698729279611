import { useEffect, useState } from "react";
import { Text, View } from "react-native";

import ScoreElementMultipleComponent from "./ScoreElementMultipleComponent";

import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import ScoreElementToContent from "./ScoreElementToContent";

export default function ScoreElementConditionalComponent({
  data,
  isSelecting,
  scrollViewRef,
  setCurrentSelectionIndex,
  setCurrentScore,
  setShouldChange,
  shouldChange,
  index,
  setAnswers,
  informationHeight,
  answers,
}) {
  const [answerIndex, setAnswerIndex] = useState<number | null>(null); // 0 NEGATIVE, 1 POSITIVE, null NOT SET
  const itemData = getSelectedAnswer(data, answerIndex);
  const colorScheme = getColorScheme();

  const mappedData: any = {
    title: data.conditionTitle,
    scoreSelectionItems: [
      {
        value: null,
        title: data.positiveAnswerTitle,
      },
      {
        value: null,
        title: data.negativeAnswerTitle,
      },
    ],
  };

  useEffect(() => {
    if (answers.find((item) => item.value === data.negativeAnswerTitle))
      setAnswerIndex(0);
    else if (answers.find((item) => item.value === data.positiveAnswerTitle))
      setAnswerIndex(1);
  }, [answers]);

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: staticColors[colorScheme].text,
        borderRadius: 12,
        margin: 2,
        padding: 6,
        paddingBottom: answerIndex !== null ? 0 : 6,
        marginBottom: 20,
      }}
    >
      <ScoreElementMultipleComponent
        key={index}
        answers={answers}
        informationHeight={informationHeight}
        setAnswers={setAnswers}
        index={index}
        setShouldChange={setShouldChange}
        shouldChange={shouldChange}
        setCurrentScore={setCurrentScore}
        scrollViewRef={scrollViewRef}
        isSelecting={isSelecting}
        setCurrentSelectionIndex={setCurrentSelectionIndex}
        data={mappedData}
        stopTransmission
      />
      {answerIndex !== null ? (
        <ScoreElementToContent
          scrollViewRef={scrollViewRef}
          setScore={setCurrentScore}
          answers={answers}
          setAnswers={setAnswers}
          index={index}
          d={itemData}
          setShouldChange={setShouldChange}
          shouldChange={shouldChange}
          setCurrentSelectionIndex={setCurrentSelectionIndex}
          isEuroscore={false}
          currentSelectionIndex={isSelecting ? index : -1}
          informationHeight={informationHeight}
          style={{
            marginBottom: 6,
          }}
        />
      ) : (
        <View
          style={{
            backgroundColor: staticColors[colorScheme].foreground,
            padding: 16,
            borderWidth: 1,
            borderRadius: 12,
            borderColor: staticColors[colorScheme].text,
          }}
        >
          <Text
            style={{
              color: staticColors[colorScheme].text,
              fontSize: Sizes.boxText,
            }}
          >
            Das Element ist abhängig von der vorherigen Auswahl.
          </Text>
        </View>
      )}
    </View>
  );
}

function getSelectedAnswer(data, answerIndex) {
  if (answerIndex === 0) {
    return data.negativeConditionScoreElement[0];
  } else {
    return data.positiveConditionScoreElement[0];
  }
}
