import { useEffect } from "react";
import { Linking } from "react-native";

export function WebBrowserScreen({ route, navigation }) {
  const { url } = route.params;

  useEffect(() => {
    Linking.openURL(url);
    navigation.goBack();
  }, []);

  return null;
}
