import { Ionicons } from "@expo/vector-icons";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import { useEffect } from "react";
import { posthog } from "../../../api/analytics/posthog";
import loadAllData from "../../../api/content/load-all/load-all-content";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import ProgressBar from "../../../components/registry/ProgressBar";
import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";

const fontSize = Dimensions.get("window").height < 700 ? 12 : 15;

const StarRating = () => (
  <View style={styles.starRating}>
    {[1, 2, 3, 4, 5].map((item) => (
      <Ionicons key={item} name="star" size={24} color="gold" />
    ))}
  </View>
);

const TestimonialCard = ({ testimonial, author, style = {}, opacity }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={[
        styles.card,
        style,
        { opacity: 1, backgroundColor: staticColors[colorScheme].background },
      ]}
    >
      <View
        style={{
          backgroundColor: staticColors[colorScheme].foreground,
          opacity: opacity,
          padding: fontSize,
          shadowColor: staticColors[colorScheme].text,
          elevation: 2,
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 4,
          borderRadius: 8,
        }}
      >
        <StarRating />
        <Text
          style={[
            styles.testimonial,
            { color: staticColors[colorScheme].text },
          ]}
        >
          {testimonial}
        </Text>
        <Text
          style={{
            fontSize: fontSize - 2,
            color: staticColors[colorScheme].text,
            opacity: 0.75,
          }}
        >
          - {author}
        </Text>
      </View>
    </View>
  );
};

const BackgroundReviews = () => {
  const backgroundReviews = [
    {
      top: 270,
      left: -150,
      opacity: 0.6,
      testimonial:
        "Die App ist super hilfreich und klasse aufgebaut, besonders im Klinikalltag sehr komfortabel (...)",
      author: "Oberarzt, Bremen",
    },
    {
      top: 190,
      right: -100,
      opacity: 0.4,
      testimonial:
        "Endlich eine Applikation, wo alle wichtigen Informationen vor allem der Spezialfälle auf einen Blick sichtbar sind.",
      author: "Pflegerin, Münster",
    },
    {
      top: 70,
      opacity: 0.5,
      testimonial:
        "Die App hilft mir bei meinen Famulaturen als Medizinstudentin in der Klinik sehr. (...)",
      author: "Studentin, Frankfurt",
    },
    {
      top: 40,
      left: -50,
      opacity: 0.6,
      testimonial:
        "Für die Notfallmedizin finde ich die Anästhesiologie Quick Tools super interessant und wichtig! ",
      author: "Notarzt, Berlin",
    },
    {
      top: 20,
      right: -50,
      opacity: 0.7,
      testimonial:
        "Generell eine sehr einfache und intuitive Benutzeroberfläche. Ich bin begeistert!",
      author: "Arzt, Freiburg",
    },
    {
      top: 220,
      left: 40,
      scale: 1.25,
      maxWidth: Platform.OS === "android" ? 230 : "85%",
      testimonial:
        "MEDICEO ist mein Digitales Schweizer Taschenmesser - so schnell bin ich noch nie an Informationen gekommen!",
      author: "Facharzt, München",
    },
  ];

  return backgroundReviews.map((reviewStyle, index) => (
    <TestimonialCard
      opacity={reviewStyle.opacity ?? 1}
      key={index}
      style={{
        position: "absolute",
        transform: reviewStyle.scale && [{ scale: reviewStyle.scale }],
        ...reviewStyle,
      }}
      testimonial={reviewStyle.testimonial}
      author={reviewStyle.author}
    />
  ));
};

export default function UserEngagementScreen({ navigation }) {
  const colorScheme = getColorScheme();

  useEffect(() => {
    loadAllData();
  }, []);

  useFocusEffect(() => {
    posthog?.capture("user_engagement_screen_opened");
  });

  const handleContinue = () => {
    // Handle continue action
    navigation.navigate("PushNotificationScreen");
  };

  return (
    <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: staticColors[colorScheme].background },
      ]}
    >
      <ProgressBar currentStep={3} subSteps={8} currentSubStep={6} />
      <View style={styles.infoContainer}>
        <BackgroundReviews />

        <View style={{ flex: 1, justifyContent: "center" }}>
          {/* <TestimonialCard
            testimonial="MEDICEO ist wie ein digitaler Assistent, der mir hilft, meinen Recherche zu optimieren!"
            author="- Dr. Schmidt, Berlin"
          /> */}
        </View>
        <Text
          style={[
            styles.subText,
            {
              color: staticColors[colorScheme].text,
            },
          ]}
        >
          Tausende Ärzte in ganz Deutschland nutzen MEDICEO.
          <Text style={styles.boldText}> Und das komplett kostenlos.</Text>
        </Text>
      </View>
      <DefaultButton
        style={styles.button}
        type={ButtonTypes.Primary}
        textStyle={{ fontWeight: "bold", fontSize: 16 }}
        title="Super, weiter!"
        action={handleContinue}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden",
    marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
  },
  infoContainer: {
    flex: 1,
    alignSelf: "stretch",
    margin: Sizes.defaultContainerPadding,
    justifyContent: "space-between", // Distribute space evenly
  },
  card: {
    borderRadius: 8,
    alignSelf: "center",
    maxWidth: 230,
    marginBottom: 20,
    justifyContent: "center", // Center content vertically
  },
  starRating: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  testimonial: {
    fontSize: fontSize,
    marginBottom: 20,
  },
  subText: {
    fontSize: fontSize + 3,
    textAlign: "center",
    marginBottom: fontSize === 15 ? 12 : -8,
  },
  boldText: {
    fontWeight: "bold",
  },
  button: {
    margin: Sizes.defaultContainerPadding,
    marginBottom: 24,
    height: 55,
  },
});
