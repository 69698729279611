import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";
import StandardBox from "../StandardBox";
import { InlineImage } from "../inline-image-types";

interface FirstResultProps {
  title: string;
  expandableContent: string | undefined;
  inlineImage: InlineImage | undefined;
  isNote?: boolean;
  isSponsor?: boolean;
  insertedContent?: any;
}

export default function ActionRecommendationBox(props: FirstResultProps) {
  const colorScheme = getColorScheme();
  return <StandardBox {...{ props, color: Colors[colorScheme].green }} />;
}
