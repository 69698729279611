import { Image } from "react-native";

export default function ImageWrapper({
  source,
  style,
}: {
  source: any;
  style: any;
}) {
  return (
    <Image
      source={{ uri: source.uri }}
      style={{ ...style, maxHeight: "80vh" }}
    />
  );
}
