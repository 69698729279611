import { Dimensions, Text, TouchableOpacity, View } from "react-native";

import { useDispatch, useSelector } from "react-redux";

import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useRef, useState } from "react";
import RenderHTML from "react-native-render-html";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { parser } from "../../../api/content/data-helpers";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { configureExpansionAnimation } from "../../../functions/configure-animations";
import {
  selectShowSources,
  shouldShowSources,
} from "../../../functions/current-result/actions";
import getColorScheme from "../../../hooks/useColorScheme";
import DefaultGradientBackground from "../../DefaultGradientBackground";
import getGeneratedSource from "./components/CitationGenerator";
import { InView } from "react-native-intersection-observer";
import {
  CoachMark,
  evaluateCoachMarkAction,
} from "../../guide-mark/components/GuideMark";
import handleOneTimeAction from "../../../functions/one-time-action-handler";
import i18n from "../../../../localization/i18n";
import { ShortcutSection } from "../../home-tab/ShortcutsSection";

export const SourcesView = ({ pageContent }) => {
  const colorScheme = getColorScheme();

  const showSources = useSelector(selectShowSources);
  const [isViewIn, setIsViewIn] = useState(false);
  const dispatch = useDispatch();
  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(false);
  const ref = useRef(null);

  const sources = useMemo(() => {
    if (pageContent.sources && pageContent.sources !== "") {
      return pageContent.sources;
    } else if (
      pageContent.appendedSources &&
      pageContent.appendedSources.length !== 0
    ) {
      return pageContent.appendedSources;
    }
  }, [pageContent]);

  useEffect(() => {
    if (!isViewIn) return;
    // evaluateCoachMarkAction(
    //   setIsCoachMarkVisible,
    //   "did_show_sources_coachmark",
    //   5
    // );
    // setTimeout(() => {
    //   setIsCoachMarkVisible(true);
    // }, 1500);
  }, [isViewIn]);

  const isNodePage = pageContent.type === "nodePage";

  if (isNodePage && !showSources) return null;

  return (
    <>
      <CoachMark
        shape="rect"
        borderRadius={8}
        positionRef={ref}
        onClose={() => setIsCoachMarkVisible(false)}
        title="Dein persönlicher Bereich"
        text="Hier findest du alle deine gespeicherten Fälle und Favoriten, sowie deinen Verlauf."
        visible={isCoachMarkVisible}
        direction="top"
      />

      <InView
        onChange={(inView) => {
          setIsViewIn(inView);
        }}
      >
        {sources && (
          <TouchableOpacity
            ref={ref}
            activeOpacity={0.7}
            onPress={() => {
              handleOneTimeAction("did_show_sources_coachmark");
              configureExpansionAnimation();
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "sources_pressed",
                "SourcesView",
                "sources pressed",
                !showSources
              );
              dispatch(shouldShowSources(!showSources));
            }}
          >
            <View
              style={{
                backgroundColor: Colors[colorScheme].foreground,
                marginTop: 20,
                padding: Sizes.defaultBoxPadding,
                paddingVertical: Sizes.defaultBoxPadding + 4,
                alignItems: "center",
                width: "100%",
                borderRadius: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "stretch",
                }}
              >
                <SourcesIcon />
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: Sizes.boxText,
                    flex: 1,
                    color: Colors[colorScheme].text,
                  }}
                >
                  {showSources
                    ? i18n.t("hide_sources")
                    : i18n.t("show_sources")}
                </Text>
                {isNodePage ? (
                  <Feather
                    name="x"
                    size={24}
                    color={Colors[colorScheme].text}
                  />
                ) : (
                  <Feather
                    name={showSources ? "chevron-up" : "chevron-down"}
                    size={24}
                    color={Colors[colorScheme].text}
                    style={{ opacity: 0.5 }}
                  />
                )}
              </View>
              {showSources && <Sources sources={sources} />}
            </View>
          </TouchableOpacity>
        )}
      </InView>
    </>
  );
};

const Sources = ({ sources }) => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  sources = useMemo(() => {
    if (typeof sources === "string") {
      return sources;
    }
    const sortedSources = [...sources]?.sort((s1, s2) => s1.index - s2.index);
    const generatedSources = sortedSources.map((source) =>
      getGeneratedSource(source)
    );

    return (sources = `<ol>
    ${generatedSources.join("")}
    </ol>`);
  }, [sources]);

  function onLinkPress(event: any, href: any) {
    navigation.navigate("WebBrowserScreen", { url: href });
  }

  return useMemo(
    () => (
      <View
        style={{
          marginTop: 20,
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <RenderHTML
          contentWidth={Dimensions.get("screen").width}
          source={{ html: parser("<p>" + sources + "</p>") }}
          tagsStyles={{
            p: {
              fontSize: 12,
              lineHeight: 12 + 5,
              color: Colors[colorScheme].text,
              margin: 0,
              marginHorizontal: 8,
              paddingBottom: 8,
            },
            a: {
              margin: 0,
              color: Colors[colorScheme].blue,
              paddingBottom: 8,
            },
            body: {
              alignSelf: "stretch",
              margin: 0,
              opacity: 0.75,
              paddingHorizontal: 0,
              marginBottom: -8,
              color: Colors[colorScheme].blue,
            },
            ul: {
              fontSize: 12,
              lineHeight: 12 + 5,
              color: Colors[colorScheme].blue,
              paddingRight: 8,
              margin: 0,
              marginLeft: -12,
            },
            ol: {
              fontSize: 12,
              lineHeight: 12 + 5,
              color: Colors[colorScheme].text,
              paddingRight: 8,
              margin: 0,
              marginLeft: -12,
            },
            li: {
              margin: 0,
              marginLeft: 6,
              paddingBottom: 8,
            },
            b: {
              margin: 0,
              fontSize: 12,
            },
          }}
          renderersProps={{
            a: {
              onPress: onLinkPress,
            },
          }}
        />
      </View>
    ),
    [sources, shouldShowSources]
  );
};

const SourcesIcon = () => {
  return (
    <View
      style={{
        padding: 10,
        borderRadius: 100,
        overflow: "hidden",
        aspectRatio: 1,
        marginRight: 12,
        alignItems: "center",
        height: 44,
        justifyContent: "center",
      }}
    >
      <DefaultGradientBackground />
      <Feather name="file-text" size={22} />
    </View>
  );
};
