import { useEffect, useRef, useState } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Dots } from "../components/TutorialSliderComponent";
import Colors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

import Carousel from "react-native-snap-carousel";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { TippsPage, TippsSlide } from "../api/content/load-all/types";
import CustomRenderHTMLComponent from "../components/CustomRenderHTMLComponent";
import ImageWrapper from "../components/ImageWrapper";
import configureAnimations from "../functions/configure-animations";
import { isWebAndNotMobile } from "../hooks/isLargerDevice";
import { removeTagsFromString } from "./TippsScreen";

export function TippsDetailScreen({ route }) {
  const tipContent = route.params?.tipContent as TippsPage;

  const colorScheme = getColorScheme();
  const sliderRef = useRef<any>();

  const [currentSelection, setCurrentSelection] = useState<TippsSlide>(
    tipContent?.listItems[0]
  );

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tips_detail_screen_opened",
      "TipsDetailScreen",
      "UserOpened",
      currentSelection?.title
    );
  }, []);

  const { width } = useWindowDimensions();

  if (!tipContent) return null;
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
      }}
    >
      {isWebAndNotMobile() && (
        <Image
          source={require("../../assets/images/OnlyYellow.png")}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            resizeMode: "cover",
            zIndex: -10,
          }}
        />
      )}
      <View
        style={{
          maxWidth: 750,
          backgroundColor: Colors[colorScheme].background,
          flex: 1,
        }}
      >
        <Carousel
          sliderWidth={750 < width ? 750 : width}
          vertical={false}
          ref={sliderRef}
          data={tipContent.listItems}
          renderItem={(item) => getCarouselView(item)}
          itemWidth={750 < width ? 750 : width}
          onSnapToItem={(index: number) => {
            configureAnimations();
            setCurrentSelection(tipContent.listItems[index]);
          }}
        />
        <View
          style={{
            position: "absolute",
            alignSelf: "center",
            paddingTop: 12,
            bottom: 0,
            backgroundColor: Colors[colorScheme].background,
            width: "100%",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            paddingHorizontal: 20,
            paddingVertical: 12,
          }}
        >
          <View style={{ paddingBottom: 12 }}>
            <Dots
              current={tipContent.listItems.indexOf(currentSelection)}
              amount={tipContent.listItems.length - 1}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

function getCarouselView(item: any) {
  return <CarouselView currentSelection={item.item} />;
}
const CarouselView = ({
  currentSelection,
}: {
  currentSelection: TippsSlide;
}) => {
  const colorScheme = getColorScheme();
  return (
    <ScrollView
      style={{ height: "100%", marginBottom: 20 }}
      contentContainerStyle={{
        flexGrow: 1,
        paddingBottom: 75,
      }}
    >
      <FeaturedImageComponent data={currentSelection} />
      <View
        style={{
          padding: 20,
        }}
      >
        <Text
          style={{
            fontSize: 22,
            marginTop: 20,
            fontWeight: "bold",
            fontFamily: "Helvetica",
            color: Colors[colorScheme].text,
          }}
        >
          {removeTagsFromString(currentSelection.title)}
        </Text>
        <CustomRenderHTMLComponent
          source={{ html: currentSelection.description }}
          tagsStyles={{
            body: {
              color: Colors[colorScheme].text,
            },
          }}
        />
      </View>
    </ScrollView>
  );
};

const FeaturedImageComponent = ({ data }) => {
  return (
    <View
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <ImageWrapper
        source={{
          uri: data.image.file_ref ?? "",
        }}
        style={{ width: "100%", aspectRatio: 3 / 2 }}
      />
    </View>
  );
};
