import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { arrayUnion, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { db } from "../api/analytics/firebase/db";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";

import Colors from "../constants/static-colors";
import { selectCurrentUser } from "../functions/user/actions";
import { User } from "../functions/user/types";
import getColorScheme from "../hooks/useColorScheme";
import { getTitle } from "./more/profile/UserComponent";
import { showInlineNotification } from "../entry/Root";
import { InlineNotificationType } from "../components/NotificationComponent";

export default function FeedbackScreen({ route, navigation }) {
  const { pageContent } = route.params;

  const currentUser = useSelector(selectCurrentUser);
  const [noteText, setNoteText] = useState("");
  const colorScheme = getColorScheme();
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "feedback_screen_opened",
        "FeedbackScreen",
        "UserOpened"
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        paddingTop: Platform.OS === "android" ? 20 : 0,
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: Colors[colorScheme].foreground,
      }}
    >
      <Header pageContent={pageContent} />
      <ScrollView
        style={{ width: "100%" }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flexGrow: 1,
          width: "100%",
          flex: 1,
          alignItems: "center",
        }}
      >
        <View
          style={{
            marginTop: 20,
            alignItems: "flex-start",
            width: "90%",
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
            }}
          >
            <TextInput
              multiline={true}
              placeholder="Dein Feedback..."
              style={[
                styles.notes_text,
                {
                  flex: 1,
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                  padding: 12,
                  borderRadius: 6,
                  color: Colors[colorScheme].text,
                },
              ]}
              placeholderTextColor={Colors[colorScheme].placeholder}
              onChangeText={(text) => {
                setNoteText(text);
              }}
              value={noteText}
            ></TextInput>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              marginVertical: 20,
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DefaultButton
                loading={waiting}
                enabled={noteText.length > 0}
                style={{ width: "50%", alignSelf: "center" }}
                title="Feedback senden"
                type={ButtonTypes.Primary}
                action={async () => {
                  setWaiting(true);
                  // await addFeedbackToResultPage(
                  //   noteText,
                  //   pageContent._id,
                  //   currentUser
                  // );
                  setWaiting(false);
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "feedback_issued_with_data",
                    "FeedbackScreen",
                    "UserIssuedFeedback",
                    {
                      id: pageContent._id,
                      feedback: noteText,
                      user: currentUser.email,
                      role: currentUser.role,
                    }
                  );
                  navigation.goBack();
                  showInlineNotification({
                    type: InlineNotificationType.SUCCESS,
                    text: "Feedback wurde gesendet - Vielen Dank!",
                  });
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export async function addFeedbackToResultPage(
  text: string,
  id: string,
  currentUser: User
) {
  try {
    await setDoc(doc(db, "feedback", id), {
      feedback: arrayUnion({
        text: text,
        isActive: true,
        timestamp: new Date(),
        lastEditBy:
          getTitle(currentUser) +
          currentUser.firstname +
          " " +
          currentUser.lastname,
      }),
    });
    return true;
  } catch {
    return false;
  }
}

function Header({ pageContent }) {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        height: 80,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        shadowColor: Colors[colorScheme].text,
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        backgroundColor: Colors[colorScheme].foreground,

        elevation: 2,
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          paddingRight: 50,
        }}
      >
        <View>
          <Text
            style={{
              fontWeight: "700",
              fontSize: 16,
              color: Colors[colorScheme].text,
            }}
          >
            Jetzt Feedback absenden
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "300",
              color: Colors[colorScheme].text,
            }}
            numberOfLines={1}
          >
            {pageContent.title ?? pageContent.fieldTitle}
          </Text>
        </View>
        <TouchableOpacity
          hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
          style={{ position: "absolute", right: 5, top: 5 }}
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Feather name="x" size={24} color={Colors[colorScheme].text} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  notes_text: {
    fontSize: 15,
    color: "black",
  },
});
