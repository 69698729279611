import { colorParser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import TextBox from "./TextBox";

export default function getTextBox(box: ResultBox) {
  return (
    <TextBox
      key={box.id}
      title={box.fieldTitle}
      color={colorParser(box.colour)}
    />
  );
}
