import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { WarningBox } from "./WarningBox";

export default function getWarningBox(box: ResultBox) {
  let expandableContent = box.expandableContent;
  if (expandableContent !== undefined)
    expandableContent = parser(expandableContent);

  return (
    <WarningBox
      key={box.id}
      title={parser(box.title)}
      expandableContent={expandableContent}
      inlineImage={box.image}
    />
  );
}
