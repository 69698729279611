import { Text, View } from "react-native";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import CustomRenderHTMLComponent from "../../CustomRenderHTMLComponent";

export default function AnnotationComponent({ annotation, colorScheme }) {
  if (annotation !== undefined) {
    let color = Colors[colorScheme].red;
    if (!annotation.isAWarning) {
      color = Colors[colorScheme].green;
    }
    return (
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: Colors[colorScheme].gray + "70",
        }}
      >
        <View
          style={{
            height: "100%",
            backgroundColor: color,
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <CustomRenderHTMLComponent
              tagsStyles={{
                body: {
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                },
                p: {
                  fontSize: Sizes.boxText,
                  color: "#fff",
                  fontWeight: "700",
                  padding: 16,
                  margin: 0,
                },
              }}
              source={{ html: annotation.title }}
            />
          </View>
        </View>
        <View
          style={{
            flex: 1,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              lineHeight: Sizes.lineHeightBoxText,
              color: Colors[colorScheme].text,
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            {annotation.content}
          </Text>
        </View>
      </View>
    );
  } else {
    return null;
  }
}
