import { Feather } from "@expo/vector-icons";
import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { showLightboxImage } from "../../../entry/Root";
import { configureExpansionAnimation } from "../../../functions/configure-animations";
import getColorScheme from "../../../hooks/useColorScheme";
import ImageWrapper from "../../ImageWrapper";
import { BoxTextComponent } from "../../custom-box-text/CustomBoxTextComponent";
import ContraindicationHintModal from "../../modals/ContraindicationHintModal";
import { getSharedStylesLargeContent } from "../SharedStyles";
import { InlineImage } from "./inline-image-types";

interface StandardBoxProps {
  title: string;
  expandableContent: string | undefined;
  inlineImage: InlineImage | undefined;
  isNote?: boolean;
  hideIcon?: boolean;
  isTeamNote?: boolean;
  insertedContent?: any;
  kontraindikation?: boolean;
}

export default function StandardBox({
  color,
  props,
}: {
  readonly color: string;
  readonly props: Readonly<StandardBoxProps>;
}) {
  const [expanded, setExpanded] = useState(false);
  const isMultiline =
    props.expandableContent ||
    (!props.inlineImage?.isImageInTitle && props.inlineImage !== undefined);

  const [
    contraindicationHintModalVisible,
    setContraindicationHintModalVisible,
  ] = useState(false);

  const colorScheme = getColorScheme();

  const backgroundColor = Colors[colorScheme].foreground;

  return (
    <>
      <ContraindicationHintModal
        open={contraindicationHintModalVisible}
        onClose={() => setContraindicationHintModalVisible(false)}
      />

      <View
        style={{
          marginTop: 20,
          width: "100%",
          borderRadius: 10,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (isMultiline) {
              configureExpansionAnimation();
              setExpanded(!expanded);
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                expanded ? "content_collapsed" : "content_expanded",
                "LargeStandardBox",
                `User ${expanded ? "Collapsed" : "Expanded"} Content`,
                { title: props.title }
              );
            }
          }}
          disabled={!isMultiline}
          style={{
            backgroundColor: Colors[colorScheme].background,
            borderRadius: 10,
          }}
          activeOpacity={0.7}
        >
          <View
            style={{
              borderRadius: 10,
              flexDirection: "row",
              overflow: "hidden",
              backgroundColor,
            }}
          >
            <View
              style={{
                height: "100%",
                width: 7,
                flex: 1,
                minWidth: 7,
                zIndex: 100,
                position: "absolute",
                backgroundColor: color,
              }}
            />
            <View
              style={{
                paddingLeft: 7,
                alignSelf: "stretch",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <View
                style={{
                  padding: Sizes.defaultBoxPadding,
                  flex: 1,
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    {props.inlineImage === undefined ||
                    !props.inlineImage.isImageInTitle ? (
                      <View style={{}}>
                        <BoxTextComponent
                          content={props.title}
                          color={color}
                          style={getSharedStylesLargeContent(colorScheme)}
                        />
                      </View>
                    ) : (
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          alignSelf: "stretch",
                          margin: -8,
                          marginLeft: -12,
                        }}
                        onLongPress={() => {
                          if (props.inlineImage !== undefined)
                            showLightboxImage({
                              uri: props.inlineImage.image.file_ref,
                            });
                        }}
                        onPress={() => {
                          if (props.inlineImage !== undefined)
                            showLightboxImage({
                              uri: props.inlineImage.image.file_ref,
                            });
                        }}
                      >
                        <ImageWrapper
                          source={{
                            uri: props.inlineImage.image.file_ref,
                          }}
                          style={{
                            alignSelf: "stretch",
                            resizeMode: "contain",
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        />
                      </TouchableOpacity>
                    )}
                    {props.insertedContent && (
                      <View style={{ marginTop: -12, alignSelf: "stretch" }}>
                        {props.insertedContent}
                      </View>
                    )}
                  </View>
                </View>

                {isMultiline && (
                  <Feather
                    name={expanded ? "chevron-up" : "chevron-down"}
                    size={24}
                    color={Colors[colorScheme].text}
                    style={{
                      marginRight: 0,
                    }}
                  />
                )}
                {props.isNote && (
                  <View style={{ flexDirection: "row", gap: 4 }}>
                    {props.isTeamNote && (
                      <Feather
                        name="users"
                        size={20}
                        color={Colors[colorScheme].text}
                        style={{
                          marginLeft: 12,
                          marginRight: 12,
                          opacity: 0.5,
                        }}
                      />
                    )}
                    <Feather
                      name="edit"
                      size={20}
                      color={Colors[colorScheme].text}
                      style={{
                        marginRight: 12,
                        opacity: 0.5,
                      }}
                    />
                  </View>
                )}
                {props.kontraindikation && (
                  <TouchableOpacity
                    onPress={() => {
                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "contraindication_hint_modal_opened",
                        "StandardBox",
                        "User Opened Contraindication Hint Modal",
                        { title: props.title }
                      );
                      setContraindicationHintModalVisible(true);
                    }}
                    style={{
                      borderRadius: 100,
                      padding: 8,
                      shadowColor: "black",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.1,
                      shadowRadius: 4,
                      elevation: 1,
                      backgroundColor,
                    }}
                  >
                    <Feather
                      name="alert-triangle"
                      size={20}
                      color={Colors[colorScheme].red}
                    />
                  </TouchableOpacity>
                )}
              </View>
              <View
                style={{
                  alignSelf: "stretch",
                  height: expanded ? "auto" : 0,
                  opacity: expanded ? 1 : 0,
                  padding: expanded ? Sizes.defaultBoxPadding : 0,
                  overflow: "hidden",
                }}
              >
                {props.inlineImage === undefined ||
                props.inlineImage.isImageInTitle ||
                props.inlineImage.isTitle ? (
                  <BoxTextComponent
                    content={props.expandableContent}
                    color={color}
                    style={getSharedStylesLargeContent(colorScheme)}
                  />
                ) : (
                  <TouchableOpacity
                    onLongPress={() => {
                      if (props.inlineImage !== undefined)
                        showLightboxImage({
                          uri:
                            props.inlineImage?.image?.file_ref ??
                            "https://" + props.inlineImage?.url,
                        });
                    }}
                    onPress={() => {
                      if (props.inlineImage !== undefined)
                        showLightboxImage({
                          uri:
                            props.inlineImage?.image?.file_ref ??
                            "https://" + props.inlineImage?.url,
                        });
                    }}
                  >
                    <ImageWrapper
                      source={{
                        uri:
                          props.inlineImage?.image?.file_ref ??
                          "https://" + props.inlineImage?.url,
                      }}
                      style={{
                        alignSelf: "stretch",
                        resizeMode: "contain",
                        marginBottom: 0,
                        marginLeft: 0,
                        borderBottomRightRadius: 8,
                      }}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </>
  );
}
