import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useMemo, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import getColorScheme from "../../hooks/useColorScheme";
import { CategoryIcon } from "../CategoryIcon";
import CustomRenderHTMLComponent from "../CustomRenderHTMLComponent";

export const LongCard = ({
  item,
  showIcon = true,
}: {
  item: any;
  showIcon?: boolean;
}) => {
  const [selected, setSelected] = useState(false);
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  const isContentDeprecated =
    !item?.content?.path || item.content.path.length <= 1;
  let title = item.title;

  if (isContentDeprecated) title = "<s>" + title + "</s> (alt)";

  function navigationAction(item) {
    if (!item) return;

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "content_navigation_attempted",
      "LongCard",
      "User Navigated",
      { contentId: item._id, deprecated: isContentDeprecated }
    );

    navigateToNextCMSPage(item._id, navigation, false, item.additionalData);
  }

  if (!item) return null;
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{ width: "100%" }}
      onPress={() => {
        navigationAction(item);
      }}
      onPressIn={() => {
        setSelected(true);
      }}
      onPressOut={() => setSelected(false)}
    >
      <View
        style={{
          opacity: 1,
          borderRadius: 8,
          paddingLeft: 20,
          paddingRight: 20,
          paddingVertical: Sizes.defaultBoxPadding,
          height: showIcon ? "auto" : 70,
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 16,
          borderColor: selected
            ? Colors[colorScheme].accent
            : Colors[colorScheme].foreground,
          backgroundColor: selected
            ? Colors[colorScheme].selectedTint
            : Colors[colorScheme].foreground,
        }}
      >
        {showIcon && (
          <CategoryIcon
            style={{ opacity: isContentDeprecated ? 0.5 : 1 }}
            size={32}
            title={item.content?.path?.[0] ?? ""}
          />
        )}
        <View style={{ flex: 1 }}>
          <TextComponent
            isContentDeprecated={isContentDeprecated}
            title={title}
          />
        </View>

        <Feather
          name="chevron-right"
          color={Colors[colorScheme].text}
          size={24}
          style={{ marginLeft: 12 }}
        />
      </View>
    </TouchableOpacity>
  );
};

const TextComponent = ({ isContentDeprecated, title }) => {
  const colorScheme = getColorScheme();
  return useMemo(
    () => (
      <CustomRenderHTMLComponent
        contentWidth={Sizes.containerWidth}
        tagsStyles={{
          body: {
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            fontWeight: "600",
            opacity: isContentDeprecated ? 0.5 : 1,
          },
          p: {
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            fontWeight: "700",
            margin: 0,
            marginRight: 20,
            marginTop: 12,
            marginBottom: 12,
          },
          b: {
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            fontWeight: "700",
            marginRight: 20,
          },
          ul: {
            margin: 0,
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            marginRight: 8,
            color: Colors[colorScheme].text,
            marginBottom: 2,
          },
          li: {
            marginLeft: 6,
            marginBottom: 5,
            color: Colors[colorScheme].text,
          },
        }}
        source={{ html: title }}
      />
    ),
    []
  );
};
