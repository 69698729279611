import { Feather } from "@expo/vector-icons";
import { useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import Colors from "../../constants/static-colors";
import { deleteNote, updateNote } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";

export default function CreateNotesScreenHeader({
  note,
  showCheck,
  navigation,
}) {
  const colorScheme = getColorScheme();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function handleDeleteNote() {
    setDeleteLoading(true);
    await deleteNote(note.id);
    navigation.goBack();
    setDeleteLoading(false);
  }

  async function handleUpdateNote() {
    setLoading(true);
    if (showCheck) {
      await updateNote(note);
    }
    setLoading(false);
    navigation.goBack();
  }

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        height: 80,
        paddingRight: 20,
        paddingTop: Platform.OS === "android" ? 32 : 12,
        paddingBottom: 12,
        backgroundColor: Colors[colorScheme].foreground,
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontWeight: "500",
            fontSize: 24,
            flex: 1,
            color: Colors[colorScheme].text,
          }}
        >
          Notiz
        </Text>
        <TouchableOpacity
          disabled={deleteLoading}
          style={{ marginRight: 20, opacity: deleteLoading ? 0.5 : 1 }}
          onPress={() => handleDeleteNote()}
        >
          <Feather name="trash-2" size={24} color={Colors[colorScheme].text} />
        </TouchableOpacity>
        <DefaultButton
          loading={loading}
          type={showCheck ? ButtonTypes.Primary : ButtonTypes.Outline}
          title={showCheck ? "Speichern" : "Schließen"}
          action={() => handleUpdateNote()}
          style={{ paddingVertical: 8, width: 120 }}
          textStyle={Platform.select({ android: { fontSize: 12 } })}
        />
      </View>
    </View>
  );
}
