import { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";

import { ScoreElement } from "../../../api/content/load-all/types";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import CustomRenderHTMLComponent from "../../../components/CustomRenderHTMLComponent";

export default function ScoreElementComponent({
  data,
  isSelecting,
  scrollViewRef,
  setCurrentSelectionIndex,
  setCurrentScore,
  setShouldChange,
  setAnswers,
  shouldChange,
  index,
  informationHeight,
  answers,
}: {
  readonly setShouldChange: any;
  readonly shouldChange: any;
  readonly data: ScoreElement;
  readonly isSelecting: boolean;
  readonly setCurrentSelectionIndex: any;
  readonly scrollViewRef: any;
  readonly index: any;
  readonly setAnswers: any;
  readonly setCurrentScore: any;
  readonly informationHeight: number;
  readonly answers: any;
}) {
  const colorScheme = getColorScheme();
  const [width, setWidth] = useState(0);

  const currentAnswer = answers.find((element) => element.title === data.title);

  return (
    <View
      style={{
        borderRadius: 12,
        backgroundColor: Colors[colorScheme].foreground,
        borderColor: isSelecting
          ? Colors[colorScheme].accent
          : Colors[colorScheme].text,
        borderWidth: isSelecting ? 2 : 1,
        marginBottom: 20,
        padding: 12,
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{ flex: 1 }}
          onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
        >
          {width !== 0 && <TextItem width={width} data={data} />}
        </View>
        <Text
          style={{
            color: Colors[colorScheme].text,
            opacity: 0.75,
            fontSize: 20,
            marginRight: 12,
            marginLeft: 12,
            fontWeight: "bold",
          }}
        >
          {!currentAnswer?.score ? "" : data.activeValue}
        </Text>
        {currentAnswer ? (
          <View
            style={{
              width: 20,
              borderRadius: 6,
              height: 20,
              backgroundColor: currentAnswer?.score
                ? Colors[colorScheme].green
                : Colors[colorScheme].red,
              borderColor: Colors[colorScheme].text,
              borderWidth: 1,
            }}
          ></View>
        ) : null}
      </View>
      {isSelecting ? (
        <SelectionButtons
          data={data}
          shouldChange={shouldChange}
          setShouldChange={setShouldChange}
          scrollViewRef={scrollViewRef}
          setAnswers={setAnswers}
          currentAnswer={currentAnswer}
          setCurrentScore={setCurrentScore}
          setCurrentSelectionIndex={setCurrentSelectionIndex}
          scrollY={index}
          informationHeight={informationHeight}
        />
      ) : null}
    </View>
  );
}

const TextItem = ({ width, data }) => {
  const colorScheme = getColorScheme();
  return useMemo(() => {
    return (
      <CustomRenderHTMLComponent
        contentWidth={width}
        tagsStyles={{
          body: {
            alignSelf: "stretch",
          },
          p: {
            margin: 0,
            fontSize: Sizes.boxText,
            marginVertical: 12,
            color: Colors[colorScheme].text,
            opacity: 0.75,
          },
        }}
        source={{ html: data.title }}
      />
    );
  }, []);
};

const SelectionButtons = ({
  setCurrentSelectionIndex,
  setCurrentScore,
  data,
  scrollViewRef,
  scrollY,
  setAnswers,
  informationHeight,
  shouldChange,
  setShouldChange,
  currentAnswer,
}) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignSelf: "stretch",
        marginTop: 12,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          setCurrentScore((s) => {
            let t = 0;
            if (
              currentAnswer?.score !== null ||
              currentAnswer?.score !== undefined
            )
              t =
                t - currentAnswer?.score
                  ? data.activeValue
                  : data.inactiveValue;
            return s + data.inactiveValue - t;
          });

          setAnswers((answer) => {
            answer = answer.filter((element) => element.title !== data.title);
            return [
              ...answer,
              { title: data.title, value: "Nein", score: data.inactiveValue },
            ];
          });

          if (shouldChange || currentAnswer?.score === null) {
            setCurrentSelectionIndex((s) => s + 1);
          } else {
            setCurrentSelectionIndex(-1);
            setShouldChange(true);
          }

          scrollViewRef.current?.scrollTo({
            y: informationHeight + scrollY * 110,
            animated: true,
          });
        }}
        style={{
          paddingHorizontal: 30,
          borderRadius: 6,
          paddingVertical: 8,
          borderColor: Colors[colorScheme].text,
          borderWidth: 1,
          backgroundColor: Colors[colorScheme].foreground,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: Colors[colorScheme].red,
          }}
        >
          X
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setCurrentScore((s) => {
            let t = 0;
            if (
              currentAnswer?.score !== null ||
              currentAnswer?.score !== undefined
            )
              t =
                t - currentAnswer?.score
                  ? data.activeValue
                  : data.inactiveValue;
            return s + data.activeValue - t;
          });
          if (shouldChange || currentAnswer?.score === null) {
            setCurrentSelectionIndex((s) => s + 1);
          } else {
            setCurrentSelectionIndex(-1);
            setShouldChange(true);
          }
          setAnswers((answer) => {
            answer = answer.filter((element) => element.title !== data.title);
            return [
              ...answer,
              { title: data.title, value: "Ja", score: data.activeValue },
            ];
          });

          scrollViewRef.current?.scrollTo({
            y: informationHeight + scrollY * 110,
            animated: true,
          });
        }}
        style={{
          paddingHorizontal: 30,
          paddingVertical: 8,
          borderRadius: 6,
          borderColor: Colors[colorScheme].text,
          borderWidth: 1,
          backgroundColor: Colors[colorScheme].foreground,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: Colors[colorScheme].green,
          }}
        >
          ✓
        </Text>
      </TouchableOpacity>
    </View>
  );
};
