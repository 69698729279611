import { createSlice } from "@reduxjs/toolkit";

const initialEnterprise: any = null;

const enterpriseSlice = createSlice({
  name: "data",
  initialState: {
    selectedEnterprise: initialEnterprise,
  },
  reducers: {
    setSelectedEnterprise(state, action) {
      state.selectedEnterprise = action.payload;
    },
  },
});

export const { setSelectedEnterprise } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;

export const selectEnterprise = (state) =>
  state.enterpriseReducer.selectedEnterprise;
