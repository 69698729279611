import { useEffect, useState } from "react";
import {
  Dimensions,
  PanResponder,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import ResultBoxes from "../../../components/result/ResultBoxComponent";
import AddCaseModal from "../../../components/modals/AddCaseModal";
import ExpandableFABMenu from "../../ResultPageScreen/components/ExpandableFABMenu";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import configureAnimations from "../../../functions/configure-animations";
import getColorScheme from "../../../hooks/useColorScheme";
import { setScoreData } from "../../../functions/calculator/actions";
import { useNavigation } from "@react-navigation/native";
import {
  selectRemoteOpenSaveScoreModal,
  setRemoteOpenSaveScoreModal,
} from "../../../functions/current-result/actions";
import AnimatedWrapper from "../../../components/AnimatedWrapper/AnimatedWrapper";
import staticColors from "../../../constants/static-colors";
import isLargerDevice from "../../../hooks/isLargerDevice";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import { Sizes } from "../../../constants/static-sizes";

export function getScoreInterpretationBoxes(score, data, answers, isEuroscore) {
  const calc = (score) => {
    const y = -5.324537 + score;
    const result = (Math.E ** y / (1 + Math.E ** y)) * 100;
    return Math.round(result * 100) / 100;
  };

  const baseItem = data?.find(
    (d) => score >= d.lowerBound && score <= d.upperBound
  );

  const _item = baseItem ? { ...baseItem } : { interpretationBoxes: [] };

  if (isEuroscore) {
    _item.interpretationBoxes = [
      {
        title: `<p>Die vorhergesagte Sterblichkeit der Angaben beträgt <b>${calc(
          score
        )}%</b></p>`,
        typeId: "hintBox",
      },
    ];
  }

  const additionalInterpretations = answers
    .map((answer) => answer.additionalScoreInterpretation)
    .filter(Boolean)
    .flatMap((interpretation) => interpretation.interpretationBoxes);

  if (additionalInterpretations.length > 0 && score < 5) {
    _item.interpretationBoxes = [
      ..._item.interpretationBoxes,
      ...additionalInterpretations,
    ];
  }

  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "score_changed",
    "ScoreScreen",
    score,
    "With interpretation: " + !!_item
  );

  configureAnimations();
  return _item;
}

const ScoreInterpretation = ({
  data,
  score,
  answers,
  currentSelectionIndex,
  isEuroscore,
  setInterpretationContent,
  item,
  setItem,
  id,
  collapsed,
  setCollapsed,
}) => {
  const colorScheme = getColorScheme();

  useEffect(() => {
    setItem(getScoreInterpretationBoxes(score, data, answers, isEuroscore));
  }, [answers, score, data]);

  useEffect(() => {
    dispatch(setScoreData({ [id]: { score, answers, currentSelectionIndex } }));
  }, [score, answers, currentSelectionIndex]);

  const navigation = useNavigation();

  const [showModal, setShowModal] = useState<any>(false);

  const remoteOpenSaveScoreModal = useSelector(selectRemoteOpenSaveScoreModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (remoteOpenSaveScoreModal) {
      setShowModal("PDF_EXPORT");
      dispatch(setRemoteOpenSaveScoreModal(false));
    }
  }, [remoteOpenSaveScoreModal]);

  if ((!data || item?.interpretationBoxes?.length === 0) && !isEuroscore)
    return (
      <ExpandableFABMenu
        collapseAction={undefined}
        pageContent={data}
        showCollapse={false}
      />
    );

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (_, gestureState) => {
      if (gestureState.dy > 0) {
        configureAnimations();
        setCollapsed(true);
      } else if (gestureState.dy < 0) {
        configureAnimations();
        setCollapsed(false);
      }
    },
  });

  return (
    <>
      <AddCaseModal
        generatePdfContent={{ answers, score, interpretation: item }}
        type={showModal === "PDF_EXPORT" ? "PDF_EXPORT" : ""}
        open={!!showModal}
        onClose={() => setShowModal(false)}
      />
      <View
        style={{
          height: 50,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: staticColors[colorScheme].selectedTint,
          zIndex: 0,
          width: "100%",
        }}
      />
      <AnimatedWrapper style={{ width: "100%", alignSelf: "stretch" }}>
        <View
          {...panResponder.panHandlers}
          style={{
            alignSelf: "stretch",
            padding: Sizes.defaultContainerPadding,
            paddingTop: 0,
            marginBottom: collapsed ? -50 : 0,
            maxHeight: collapsed
              ? 100
              : (Dimensions.get("window").height / 3) * 2,
            backgroundColor: staticColors[colorScheme].selectedTint,
            borderTopColor: staticColors[colorScheme].accentDark,
            elevation: 2,
            shadowColor: staticColors[colorScheme].text,
            shadowOffset: { width: 0, height: -5 },
            shadowOpacity: 0.08,
            shadowRadius: 4,
            borderTopWidth: 1,
          }}
        >
          {!setInterpretationContent && (
            <TouchableOpacity
              onPress={() => {
                configureAnimations();
                setCollapsed((c) => !c);
              }}
              hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
              style={{ alignSelf: "flex-end", paddingTop: 12 }}
            >
              <Ionicons
                name={collapsed ? "chevron-up" : "chevron-down"}
                size={24}
                color={staticColors[colorScheme].text}
              />
            </TouchableOpacity>
          )}
          <View
            style={{
              marginBottom: 0,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ScrollView
              keyboardDismissMode="on-drag"
              keyboardShouldPersistTaps="handled"
              horizontal={false}
              scrollEnabled={!collapsed}
              showsVerticalScrollIndicator={false}
            >
              <View style={{ paddingBottom: isLargerDevice() ? 20 : 0 }}>
                <ResultBoxes
                  pageContent={{ boxes: item?.interpretationBoxes }}
                />
              </View>
            </ScrollView>
            <ExpandableFABMenu
              small={!isLargerDevice()}
              collapseAction={undefined}
              pageContent={data}
              showCollapse={false}
              style={{
                position: "relative",
                marginLeft: isLargerDevice() ? 64 : 32,
                marginRight: -12,
                alignSelf: "center",
                bottom: isLargerDevice() ? 0 : -12,
              }}
            />
          </View>
        </View>
        {setInterpretationContent && (
          <View
            style={{
              backgroundColor: staticColors[colorScheme].selectedTint,
              width: "100%",
            }}
          >
            <DefaultButton
              title="Ergebnis übertragen"
              action={() => {
                setInterpretationContent({
                  caseData: { [id]: { score, answers, currentSelectionIndex } },
                });
                navigation.goBack();
              }}
              type={ButtonTypes.Primary}
              style={{ marginHorizontal: 20, marginBottom: 20 }}
            />
          </View>
        )}
      </AnimatedWrapper>
    </>
  );
};

export default ScoreInterpretation;
