import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { ResultBox } from "../../api/content/load-all/types";
import {
  selectResultingCalculation,
  setCurrentUnit,
} from "../../functions/calculator/actions";
import {
  selectFilterNodePages,
  selectResultPageSearchQuery,
} from "../../functions/current-result/actions";
import { getNoteToID } from "../../functions/current-result/helpers";
import { store } from "../../functions/store";
import { selectCurrentUser } from "../../functions/user/actions";
import BoxComponent from "../boxes/BoxSorter";
import { HintBox } from "../boxes/box-types/hint-box/HintBox";
import QuickHelpBox from "../boxes/box-types/quick-tools-box/QuickHelpBox";

export default function ResultBoxes({ pageContent }: { pageContent: any }) {
  const navigation = useNavigation();
  const filterNodePages = useSelector(selectFilterNodePages);
  const currentUser = useSelector(selectCurrentUser);
  const resultingCalculation = useSelector(selectResultingCalculation);
  const [boxData, setBoxData] = useState<any>([]);
  const resultPageSearchQuery = useSelector(selectResultPageSearchQuery);

  useEffect(() => {
    processBoxes();
  }, [
    filterNodePages,
    pageContent,
    resultingCalculation,
    currentUser,
    resultPageSearchQuery,
  ]);

  function processBoxes() {
    let boxesData = pageContent.boxes ?? pageContent.resultBoxes ?? [];
    const ids = new Set();
    boxesData = boxesData.filter((box) => {
      if (!ids.has(box?.id) && box) {
        ids.add(box.id);
        return true;
      }
      return false;
    });

    let boxes: any = [];

    if (boxesData)
      boxes = boxesData.map((data: ResultBox) => (
        <BoxComponent key={data.id} box={data} />
      ));

    if (pageContent.isQuickHelp && shouldInsertQuickHelpBox()) {
      boxes = insertQuickHelpBox(boxes);
    }

    setBoxData(boxes);

    checkForUserNotes();
  }

  function shouldInsertQuickHelpBox() {
    return !boxData.includes(<QuickHelpBox key={"QUICK_HELP"} />);
  }

  function insertQuickHelpBox(boxes: any[]) {
    store.dispatch(setCurrentUnit("kg"));
    const filteredBoxes = boxes.filter((b: any) => b?.key !== "QUICK_HELP");
    return [<QuickHelpBox key={"QUICK_HELP"} />, ...filteredBoxes];
  }

  function checkForUserNotes() {
    const note = getNoteToID(pageContent._id, pageContent.legacy_id);
    if (note?.showInResult && note.note !== "") {
      const noteBox = createNoteBox(note, false);
      setBoxData((boxes) => [
        noteBox,
        ...boxes.filter((b) => b?.key !== "NOTES"),
      ]);
    } else if (note && !note.showInResult) {
      setBoxData((boxes) => boxes.filter((b) => b?.key !== "NOTES"));
    }
  }

  function createNoteBox(note: any, isTeamNote) {
    return (
      <TouchableOpacity
        key={"NOTES"}
        onPress={() =>
          navigation.navigate("CreateNoteScreen", {
            note,
            currentDisplayID: pageContent._id,
            isTeam: isTeamNote,
          })
        }
      >
        <View
          style={{
            pointerEvents: "none",
          }}
        >
          <HintBox
            isTeamNote={isTeamNote}
            title={note.note}
            expandableContent={undefined}
            inlineImage={undefined}
            isNote={true}
          />
        </View>
      </TouchableOpacity>
    );
  }

  return boxData;
}
