import Ionicons from "@expo/vector-icons/Ionicons";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback, useEffect, useRef } from "react";
import { Platform, TextInput, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";

import { debounce } from "lodash";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import configureAnimations from "../../../functions/configure-animations";
import {
  selectSearchBarShouldFocus,
  setSearchBarShouldFocus,
} from "../../../functions/navigation/actions";
import getColorScheme from "../../../hooks/useColorScheme";

export const SearchBar = ({
  style,
  selected,
  setSelected,
  query,
  setQuery,
  placeholder = "Suche",
  barStyle,
  collapsed = false,
  origin = "",
}: {
  style?: any;
  selected?: boolean;
  setSelected?: any;
  query: string;
  setQuery: any;
  placeholder?: any;
  collapsed?: boolean;
  origin?: string;
  barStyle?: any;
}) => {
  const colorScheme = getColorScheme();
  const textInputRef = useRef<any>();
  const dispatch = useDispatch();
  const searchBarShouldFocus = useSelector(selectSearchBarShouldFocus);

  const debouncedUpdate = useCallback(
    debounce((query) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_initiated",
        "SearchBar",
        "User Initiated Search",
        { query }
      );
    }, 500),
    []
  );

  useEffect(() => {
    configureAnimations();
    if (!selected && query !== "") {
      AnalyticsHandler.getInstance().logSearch(
        "search_failed",
        query,
        "global_search",
        "dismissed"
      );
    }
  }, [selected]);

  useEffect(() => {
    if (searchBarShouldFocus && origin === "Search") {
      textInputRef.current?.focus();
      dispatch(setSearchBarShouldFocus(false));
    }
  }, [searchBarShouldFocus, origin, textInputRef]);

  const resetSearchBarFocus = () => {
    dispatch(setSearchBarShouldFocus(false));
  };

  useFocusEffect(() => {
    return () => {
      resetSearchBarFocus();
    };
  });

  return (
    <View key={placeholder} style={{ ...style }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: Colors[colorScheme].foreground,
            flex: 1,
            borderRadius: 12,
            paddingLeft: 16,
            paddingRight: 16,
            ...barStyle,
          }}
        >
          <Ionicons name="search" size={24} color={Colors[colorScheme].gray} />
          <TextInput
            ref={textInputRef}
            key={placeholder}
            defaultValue={query}
            onFocus={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "search_bar_focused",
                "SearchBar",
                "User Focused Search Bar",
                null
              );

              setSelected(true);
              configureAnimations();
            }}
            onBlur={(e) => {
              if (Platform.OS === "web") {
                e.preventDefault();
                return;
              }

              if (query === "") setSelected(false);
            }}
            placeholderTextColor={Colors[colorScheme].placeholder}
            placeholder={placeholder}
            style={{
              flex: 1,
              color: Colors[colorScheme].text,
              fontSize: Sizes.boxText,
              paddingHorizontal: 16,
              paddingVertical: collapsed && !selected ? 12 : 20,
              ...Platform.select({ android: { paddingVertical: 8 } }),
              paddingLeft: 0,
              marginLeft: 12,
            }}
            onChangeText={(query) => {
              debouncedUpdate(query);
              setQuery(query);
            }}
          />
          {selected && (
            <TouchableOpacity
              onPress={() => {
                setQuery("");
                textInputRef?.current.clear();

                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "search_cancelled",
                  "SearchBar",
                  "User Cancelled Search",
                  { query }
                );

                textInputRef.current?.blur();
                setSelected(false);
              }}
            >
              <Ionicons
                name="close"
                size={18}
                color={Colors[colorScheme].gray}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};
