import { useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getColorScheme from "../../../../hooks/useColorScheme";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";
import TablePopupViewHeader from "./TablePopupViewHeader";

export default function TablePopupView({ route, navigation }) {
  const { source, title } = route.params;
  const [size, setSize] = useState({ width: 0, height: 0 });
  const colorScheme = getColorScheme();
  return (
    <SafeAreaView
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].foreground,
        justifyContent: "flex-start",
      }}
    >
      <TablePopupViewHeader title={title} navigation={navigation} />
      <View
        style={{
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
        onLayout={(e) => {
          setSize({
            width: e.nativeEvent.layout.width - 20,
            height: e.nativeEvent.layout.height,
          });
        }}
      >
        <View
          style={{
            height: size.height,
            width: size.width,
            transform: [
              //   { translateX: size.width / 2 },
              //   { translateY: size.height / 2 },
            ],
          }}
        >
          <ScrollView
            style={{
              flex: 1,
              margin: 12,
            }}
            contentContainerStyle={{ paddingLeft: 12 }}
            horizontal={false}
          >
            <BoxTextComponent
              style={{
                body: {
                  alignSelf: "stretch",
                },
                table: {
                  width: size.width - 50,
                  borderColor: Colors[colorScheme].text,
                  borderWidth: 1,
                  borderRadius: 12,
                  overflow: "hidden",
                },
                tr: {},
                td: {
                  color: Colors[colorScheme].text,
                  borderColor: Colors[colorScheme].gray,
                  padding: 8,
                  margin: -0.2,
                  borderWidth: 1,
                  // borderRightColor: "#ddd",
                  // borderRightWidth: 1,
                },
                th: {
                  backgroundColor: Colors[colorScheme].accent,
                  color: Colors["light"].text,
                  borderColor: Colors[colorScheme].text,
                  borderWidth: 1,
                  margin: -0.2,
                  borderBottomWidth: 2,
                  padding: 8,
                },
                p: {
                  fontSize: Sizes.boxText,
                  lineHeight: Sizes.lineHeightBoxText,
                  textAlign: "flex-start",
                },
                b: {
                  fontSize: 15,
                },
              }}
              content={source}
            ></BoxTextComponent>
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
}
