import _ from "lodash";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import {
  default as Colors,
  default as staticColors,
} from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { selectAllAmounts } from "../../../../functions/calculator/actions";
import {
  checkBounds,
  getCurrentUnit,
  getPlaceholder,
  keyValueUnit,
  resetValues,
  setValues,
} from "../../../../functions/calculator/utilites";
import handleOneTimeAction from "../../../../functions/one-time-action-handler";
import getColorScheme from "../../../../hooks/useColorScheme";

const SIZE = "Körpergröße";

export function CalculatorBox({ title = "", collapsed = false, unit = "" }) {
  console.log("UNIT", unit);
  const key =
    unit ??
    keyValueUnit().find((item) =>
      title.toLowerCase().includes(item.value.toLowerCase())
    )?.key;

  const amounts = useSelector(selectAllAmounts);

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme, collapsed);
  const [text, setText] = useState<any>(
    amounts?.[key] ? amounts[key] + "" : null
  );

  const isSizeTitle = title.includes(SIZE);

  useEffect(() => {
    if (amounts?.[key] !== undefined && amounts[key] !== text) {
      setText(amounts[key] + "");
    }
  }, [amounts]);

  const debouncedUpdate = useCallback(
    _.debounce((textInput) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "calculator_input_changed",
        "CalculatorBox",
        "User Updated Calculator Input",
        { key, newValue: textInput }
      );
    }, 500),
    []
  );

  return useMemo(
    () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <View style={styles.container}>
          <View style={styles.innerContainer}>
            <View
              style={{
                ...styles.result_container,
                borderWidth: text ? 2 : 0,
                borderColor: staticColors[colorScheme].blue,
              }}
            >
              <View
                style={[
                  styles.sidebar,
                  {
                    backgroundColor: buttonEnabled
                      ? Colors[colorScheme].blue
                      : Colors[colorScheme].darkGray,
                  },
                ]}
              />
              <View style={styles.content}>
                <TextInput
                  style={{
                    fontWeight: "500",
                    fontSize: Sizes.boxText,
                    marginRight: Sizes.defaultBoxPadding,
                    flex: 1,
                    paddingVertical:
                      Platform.OS === "android"
                        ? 12
                        : collapsed
                        ? 14
                        : Sizes.defaultBoxPadding + 8,

                    color: Colors[colorScheme].text,
                  }}
                  placeholderTextColor={Colors[colorScheme].placeholder}
                  placeholder={getPlaceholder(title, isSizeTitle)}
                  value={text || ""}
                  keyboardType={"numeric"}
                  onChangeText={(textInput) => {
                    if (collapsed) handleOneTimeAction("did_enter_weight");
                    debouncedUpdate(textInput);
                    setText(textInput);
                    if (textInput === "") {
                      setButtonEnabled(false);
                      resetValues(textInput, title);
                    } else {
                      checkBounds(getCurrentUnit(title, unit), textInput);
                      setValues(title, textInput);
                      setButtonEnabled(true);
                    }
                  }}
                />
                <GetUnitComponent
                  collapsed={collapsed}
                  title={title}
                  unit={unit}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    ),
    [text, buttonEnabled]
  );
}

const GetUnitComponent = ({ title, collapsed, unit }) => {
  // if (isSize) return <SelectSizeUnit setIsCm={setIsCm} isCm={isCm} />;
  return <UnitComponent collapsed={collapsed} title={title} unit={unit} />;
};

export const UnitComponent = ({ title, collapsed = false, unit }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        padding: collapsed ? 4 : 6,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: Colors[colorScheme].blue,
      }}
    >
      <Text
        style={{
          fontWeight: "bold",
          fontSize: collapsed ? 13 : 15,
          color: Colors[colorScheme].blue,
        }}
      >
        {getCurrentUnit(title, unit)}
      </Text>
    </View>
  );
};

const createStyles = (scheme: string, collapsed) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      overflow: "visible",
    },
    innerContainer: {
      shadowColor: Colors[scheme].text,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      borderRadius: 20,
      shadowOpacity: collapsed ? 0.05 : 0.1,
      shadowRadius: 4,
      width: "100%",
      overflow: "visible",
      elevation: 2,
    },
    result_container: {
      borderRadius: collapsed ? 8 : 12,
      flexDirection: "row",
      overflow: "hidden",
      //flex: 1,
      backgroundColor: Colors[scheme].foreground,
      //borderWidth: 2,
      borderColor: Colors[scheme].blue,
      elevation: 3,
    },
    sidebar: {
      width: 0,
      height: "100%",
    },
    content: {
      paddingRight: 12,
      paddingLeft: 12,
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
    },
  });

  return styles;
};
