import { useNavigation } from "@react-navigation/native";
import { Text, TouchableOpacity, View } from "react-native";

import { Feather } from "@expo/vector-icons";
import i18n from "../../../localization/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import { navigateFromSearch } from "../../functions/navigation/helpers";
import getColorScheme from "../../hooks/useColorScheme";
import { isResultPageWithSkeleton } from "../../screens/ResultPageScreen/ResultPageScreen";
import { CategoryIcon } from "../CategoryIcon";
import { reverseCamelCase } from "../ResultPageSkeleton";
import transformDefaultHtmlContent from "../custom-box-text/helpers/default-content-transformation";

function findMostRelevantMatchIndex(matches, query) {
  if (!matches || !Array.isArray(matches)) return null;
  return matches?.find((item) =>
    JSON.stringify(item)
      .toLowerCase()
      .trim()
      .includes(query.toLowerCase().trim())
  );
}

function getValueByPath(obj, path, searchQuery) {
  return path.split(".").reduce((currentObject, key) => {
    // Access the first element if it's an array
    if (Array.isArray(currentObject)) {
      currentObject = findMostRelevantMatchIndex(currentObject, searchQuery);
    }

    // Proceed with accessing the property
    return currentObject && currentObject[key] !== undefined
      ? currentObject[key]
      : undefined;
  }, obj);
}

const EXCLUDED_INDICES = ["newSources"];

export const SearchResultItem = ({
  item,
  searchQuery,
  additionalAction = () => {},
}) => {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();
  const typePrefix = getTypePrefix(item.content_type_id);

  const showSection = isResultPageWithSkeleton(item.content_type_id);

  const getMatches = () => {
    if (!showSection) return null;
    const match = item._matchesPosition;
    if (!match) return null;
    return Object.keys(match)
      .map((matchPosition) => {
        if (EXCLUDED_INDICES.includes(matchPosition.split(".")?.[1]))
          return null;
        const sectionPosition = matchPosition.split(".").slice(0, 2).join(".");

        const section = findMostRelevantMatchIndex(
          getValueByPath(item, sectionPosition, searchQuery),
          searchQuery
        );

        return section;
      })
      .filter((section) => section && typeof section === "object");
  };

  let sectionMatches = getMatches();
  sectionMatches = sectionMatches?.reduce(
    (accumulator, current) => {
      if (!accumulator.seenIds.has(current.id)) {
        accumulator.result.push(current);
        accumulator.seenIds.add(current.id);
      }
      return accumulator;
    },
    { result: [], seenIds: new Set() }
  ).result;

  return (
    <View
      style={{
        width: "100%",
        borderBottomWidth: 0.3,
        paddingVertical: 16,
        borderBottomColor: Colors[colorScheme].gray,
      }}
    >
      <TouchableOpacity
        style={{ width: "100%" }}
        onPress={() => {
          additionalAction();
          AnalyticsHandler.getInstance().logSearch(
            "search_item_selected",
            searchQuery,
            "global_search",
            true,
            item._id
          );

          navigateFromSearch(item, navigation, {
            targetSections: sectionMatches,
          });
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CategoryIcon title={item.content?.path?.[0] ?? ""} size={32} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: 10,
                marginBottom: 4,
                color: Colors[colorScheme].text,
              }}
            >
              {item.content?.path?.length > 1 && typePrefix + " in  "}
              <Text
                style={{
                  color: Colors[colorScheme].accentDark,
                  fontWeight: "bold",
                }}
              >
                {item.content?.path?.[0]}
              </Text>
            </Text>

            <View style={{ marginBottom: 0, gap: 8 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  color: Colors[colorScheme].text,
                  fontSize: 15,
                  marginBottom: 0,
                }}
              >
                {(item.title ?? item.fieldTitle)?.trim()}
              </Text>
              {/* <BoxTextComponent
                content={getMatch()}
                style={{
                  ...getSharedStylesLargeContent(colorScheme, 10, 15),
                }}
              /> */}
              {showSection &&
                sectionMatches?.map((section) => (
                  <Text
                    key={section.id}
                    style={{
                      color: Colors[colorScheme].text,
                      fontSize: 12,
                      marginBottom: 0,
                      fontStyle: "italic",
                    }}
                  >
                    {transformDefaultHtmlContent(
                      section.title ??
                        section.titel ??
                        reverseCamelCase(section.typeId),
                      false,
                      undefined,
                      undefined
                    ).replace(/\$.*?\$/g, "")}
                  </Text>
                ))}
            </View>
          </View>
          <Feather
            name="chevron-right"
            size={20}
            color={Colors[colorScheme].text}
            style={{
              marginLeft: 20,
              opacity: 0.5,
            }}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

function getTypePrefix(type) {
  switch (type) {
    case "scorePage":
      return "Score";
    case "resultPage":
      return i18n.t("result");
    case "nodePage":
      return i18n.t("content");
    default:
      return "Resultat";
  }
}
