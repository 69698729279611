import { useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { isQuickToolsSelectionPage } from "../api/content/data-helpers";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import { InlineNotificationType } from "../components/NotificationComponent";
import ResultBoxes from "../components/result/ResultBoxComponent";
import { SourcesView } from "../components/result/SourcesView/SourcesView";
import TitleText from "../components/result/TitleText";
import SearchResults from "../components/search-tab/SearchResults";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { showInlineNotification } from "../entry/Root";
import {
  selectAllAmounts,
  setCalculationVar,
} from "../functions/calculator/actions";
import configureAnimations from "../functions/configure-animations";
import {
  clearCurrentFilters,
  selectCurrentFilters,
  selectFilterNodePages,
  selectHasMultipleChoice,
  setCurrentHighlightSection,
  setCurrentResultPage,
  setHasMultipleChoice,
  setNodePageFilter,
  setResultPageCaseContent,
  setResultPageHeaderContent,
  setShowTitle,
} from "../functions/current-result/actions";
import { selectAllContent } from "../functions/data/actions";
import { navigateToNextPageByCMSId } from "../functions/navigation/helpers";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../functions/user-interaction-handler";
import getColorScheme from "../hooks/useColorScheme";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";
import i18n from "../../localization/i18n";

export default function CMSNodePageScreen({ navigation, route }) {
  const { pageContent } = route.params;
  const [didGoBack, setDidGoBack] = useState(false);

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([pageContent.fieldTitle]);
  const [selected, setSelected] = useState(false);
  const [infoTextCollapsed, setInfoTextCollapsed] = useState(true);

  const hasMultipleChoice = useSelector(selectHasMultipleChoice);
  const filterNodePages = useSelector(selectFilterNodePages);
  const scoreId = undefined;
  //useSelector(selectAllContent).medicalCategories.content.scorePageId;

  const isScorePage = pageContent?._id === scoreId;

  let currentFilters = [...useSelector(selectCurrentFilters)];
  const infoText = getTextToTitle(pageContent?.fieldTitle);

  useEffect(() => {
    if (!infoText) return;

    const _eval = getValueFromInteractions("info_text_" + infoText);

    if (!_eval) {
      setInfoTextCollapsed(false);
    }
  }, [infoText]);

  const hasInlineVar = pageContent?.boxes.find(
    (b) => b.inlineVar !== undefined
  );

  async function getAndSetPageContent() {
    dispatch(setCurrentResultPage(pageContent));
  }

  useEffect(() => {
    dispatch(setShowTitle(false));
    const unsubscribe = navigation.addListener("focus", () => {
      getAndSetPageContent();
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "node_page_opened",
        "NodePageScreen",
        pageContent?.title,
        pageContent?._id
      );
      dispatch(setResultPageHeaderContent(null));
      dispatch(setCurrentHighlightSection(null));
      dispatch(setResultPageCaseContent(null));
      if (pageContent?.path?.length === 2) {
        dispatch(clearCurrentFilters());

        dispatch(setCalculationVar(0));
      }
    });

    const _unsubscribe = navigation.addListener("beforeRemove", (e) => {
      dispatch(setHasMultipleChoice(false));
      if (hasInlineVar !== undefined && !didGoBack) {
        setDidGoBack(true);
        setTimeout(() => {
          navigation.popToTop();
        }, 150);
      }
      if (currentFilters === undefined) return;
      if (currentFilters.length > 0) {
        e.preventDefault();
        dispatch(clearCurrentFilters);
        currentFilters = [];
        navigation.popToTop();
      }
    });

    return () => {
      dispatch(setNodePageFilter(null));
      dispatch(setShowTitle(false));
      unsubscribe();
      _unsubscribe();
    };
  }, [pageContent]);

  if (!pageContent) {
    navigation.goBack();
    showInlineNotification({
      text: "Ein Fehler ist aufgetreten. Bitte aktualisiere die Inhalte auf dem Home Screen",
      type: InlineNotificationType.ERROR,
    });

    return null;
  }

  return (
    <SafeAreaView
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        //flex: 1,
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          width: "100%",
          backgroundColor: Colors[colorScheme].background,
          zIndex: 10,
          paddingHorizontal: Sizes.defaultContainerPadding,
          shadowColor: Colors[colorScheme].text,
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.08,
          shadowRadius: 4,
          elevation: 2,
        }}
      >
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
          >
            <TitleText
              style={{ flexShrink: 1 }}
              title={pageContent.fieldTitle}
            />
            {infoTextCollapsed && infoText && (
              <Feather
                name="info"
                size={20}
                color={Colors[colorScheme].text}
                onPress={() => {
                  configureAnimations();
                  setInfoTextCollapsed(false);
                }}
              />
            )}
          </View>
          {!infoTextCollapsed && (
            <TouchableOpacity
              onPress={() => {
                configureAnimations();
                setInfoTextCollapsed(true);
                addValueToInteractions("info_text_" + infoText, "true");
              }}
              style={{
                flexDirection: "row",
                gap: 12,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  marginTop: 8,
                  flex: 1,
                  fontSize: Sizes.boxText,
                  color: Colors[colorScheme].text,
                }}
              >
                {infoText}
              </Text>
              <Feather
                name="chevron-down"
                size={24}
                color={Colors[colorScheme].text}
              />
            </TouchableOpacity>
          )}
        </View>
        <SearchBar
          collapsed
          style={{ marginTop: 20, paddingBottom: 16 }}
          placeholder={
            i18n.t("search_placeholder_detail") + pageContent.fieldTitle
          }
          query={query}
          setQuery={setQuery}
          filters={filters}
          setFilters={setFilters}
          selected={selected}
          setSelected={setSelected}
        />
      </View>
      {!selected ? (
        <KeyboardAwareScrollView
          style={{
            width: "100%",
            flex: 1,
            ...Platform.select({ ios: { overflow: "visible" } }),
          }}
          contentContainerStyle={{
            width: "100%",
            alignItems: "center",
            flexGrow: 1,
            paddingBottom: 20,
          }}
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={100}
          onScroll={() => {
            // if (e.nativeEvent.contentOffset.y > 60 && !showTitle) {
            //   dispatch(setShowTitle(true));
            // } else if (e.nativeEvent.contentOffset.y < 60 && showTitle) {
            //   dispatch(setShowTitle(false));
            // }
          }}
        >
          <View
            style={{
              paddingHorizontal: Sizes.defaultContainerPadding,
              width: "100%",
              flex: 1,
            }}
          >
            <View
              style={{
                width: "100%",
                flex: 1,

                justifyContent: filterNodePages ? "flex-start" : "center",
              }}
            >
              {pageContent.boxes.length > 5 && (
                <ContinueButton
                  show={hasMultipleChoice}
                  pageContent={pageContent}
                  navigation={navigation}
                />
              )}

              <ResultBoxes
                pageContent={
                  isScorePage ? getSortedNodes(pageContent) : pageContent
                }
              />
              <ContinueButton
                show={hasMultipleChoice}
                pageContent={pageContent}
                navigation={navigation}
              />
            </View>
            <SourcesView {...{ pageContent }} />
          </View>
        </KeyboardAwareScrollView>
      ) : (
        <View
          style={{
            padding: Sizes.defaultContainerPadding,
            flex: 1,
            width: "100%",
          }}
        >
          <SearchResults {...{ query, setQuery, filters }} />
        </View>
      )}
    </SafeAreaView>
  );
}

function getSortedNodes(pageContent) {
  const _pageContent = JSON.parse(JSON.stringify(pageContent));
  const boxes = _pageContent.boxes ?? _pageContent.resultBoxes;

  _pageContent.boxes = boxes.sort((d1, d2) => {
    if (
      d1.typeId === "furtherResultPageBox" &&
      d2.typeId !== "furtherResultPageBox"
    ) {
      return 1;
    }
    if (
      d1.typeId !== "furtherResultPageBox" &&
      d2.typeId === "furtherResultPageBox"
    ) {
      return -1;
    }
    return d1.fieldTitle.localeCompare(d2.fieldTitle);
  });

  return _pageContent;
}

const ContinueButton = ({ show, pageContent, navigation }) => {
  const amounts = useSelector(selectAllAmounts);

  if (!show) return null;
  return (
    <View>
      <View style={{ width: "100%", alignItems: "center", marginTop: 20 }}>
        <Button {...{ pageContent, amounts, navigation }} />
      </View>
    </View>
  );
};

const Button = ({ pageContent, amounts, navigation }) => {
  const colorScheme = getColorScheme();
  const continueAction = () => {
    const title = pageContent.fieldTitle ?? pageContent.title;
    navigateToNextPageByCMSId(navigation, pageContent.nextPage!, title);
  };

  return (
    <DefaultButton
      type={ButtonTypes.Outline}
      title={
        isQuickToolsSelectionPage() && Object.keys(amounts).length === 0
          ? "Ohne Eingabe weiter"
          : "Auswahl bestätigen"
      }
      action={continueAction}
      icon={
        <Ionicons
          name="arrow-forward"
          size={24}
          color={Colors[colorScheme].text}
        />
      }
    />
  );
};

function getTextToTitle(title) {
  switch (title) {
    case i18n.t("emergency"):
      return i18n.t("emergency_description");
    case "Fast-Track":
      return i18n.t("fast_track_description");
    case i18n.t("diseases"):
      return i18n.t("diseases_description");
    case i18n.t("interventions"):
      return i18n.t("interventions_procedures_description");
    case i18n.t("therapy_algorithms"):
      return i18n.t("therapy_algorithms_description");
    case i18n.t("scores"):
      return i18n.t("scores_description");
    case i18n.t("calculators"):
      return i18n.t("calculators_description");
    default:
      return "";
  }
}
