/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";

import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { configureExpansionAnimation } from "../../../../functions/configure-animations";
import getColorScheme from "../../../../hooks/useColorScheme";
import ExpandArrowImageComponent from "../../../ExpandArrowImageComponent";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";
import { getSharedStylesContent } from "../../SharedStyles";
import { COLOR_MAPPINGS } from "./ColorMappings";

export function EasyReadingInfoBox({
  title,
  detailTitle,
  expandableContent,
  color,
}: {
  readonly title: string;
  readonly detailTitle: string;
  readonly expandableContent: string | undefined;
  readonly color: string;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMultiline = expandableContent !== undefined;
  const [triangleSize, setTriangleSize] = useState({ height: 0, width: 0 });
  const colorScheme = getColorScheme();

  color = color.replace(/<span[^>]*>(.*?)<\/span>/g, "$1");

  const colorInfo = getColorToCode(color);

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          configureExpansionAnimation();
          setIsExpanded(!isExpanded);
        }}
        disabled={!isMultiline}
        activeOpacity={0.7}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor:
              color === "White_monochrome"
                ? Colors[colorScheme].blue
                : Colors[colorScheme].foreground,
            borderRadius: 10,
            overflow: "hidden",
            flexDirection: "column",
            backgroundColor: Colors[colorScheme].foreground,
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              justifyContent: "space-between",
              overflow: "hidden",
              alignItems: "center",
              ...Platform.select({ android: { flex: 1 }, ios: { flex: 1 } }),
              flexDirection: "row",
              gap: 12,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <View
                  onLayout={(event) => {
                    const { width, height } = event.nativeEvent.layout;
                    setTriangleSize({ height: height, width: width });
                  }}
                  style={{
                    flex: 1,
                    backgroundColor: colorInfo.color,
                  }}
                >
                  <View
                    style={{
                      height: "100%",
                      paddingHorizontal: Sizes.defaultBoxPadding,
                      //paddingVertical: Sizes.defaultBoxPadding - 8,
                      alignSelf: "stretch",
                      alignContent: "stretch",
                      flex: 1,
                      width: "100%",
                      borderTopLeftRadius: 7.8,
                      borderBottomLeftRadius: 7.8,
                    }}
                  >
                    {colorInfo.color_top && (
                      <View
                        style={{
                          position: "absolute",
                          alignSelf: "stretch",
                          height: "50%",
                          width: triangleSize.width,
                          top: 0,
                          left: 0,

                          backgroundColor: colorInfo.color_top,
                        }}
                      />
                    )}
                    {colorInfo.color_frame !== undefined ? (
                      <View
                        style={{
                          position: "absolute",
                          alignSelf: "stretch",
                          flex: 1,
                          height: "100%",
                          width: triangleSize.width,
                          top: 0,
                          left: 0,
                          borderWidth: 3,
                          overflow: "hidden",
                          borderTopLeftRadius: 8,
                          borderBottomLeftRadius: !isExpanded ? 8 : 0,
                          borderColor: colorInfo.color_frame,

                          //borderStyle: "dashed",
                        }}
                      ></View>
                    ) : null}
                    {colorInfo.color_triangle && (
                      <View
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            width: 0,
                            height: 0,
                            borderTopWidth: triangleSize.height,
                            borderLeftWidth: triangleSize.width,
                            borderTopColor: "transparent",
                            borderRightColor: "transparent",
                            borderBottomColor: "transparent",
                            borderLeftColor:
                              colorInfo.color_triangle ?? "transparent",
                          }}
                        />
                      </View>
                    )}
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        paddingVertical: Sizes.defaultBoxPadding - 8,
                      }}
                    >
                      <TitleTextComponent title={title} colorInfo={colorInfo} />
                    </View>
                  </View>
                </View>
                <View
                  style={{ flex: 1, justifyContent: "center", marginLeft: 20 }}
                >
                  <DetailTitleText detailTitle={detailTitle} />
                </View>
              </View>
            </View>
            <ExpandArrowImageComponent
              isExpanded={isExpanded}
              isMultiline={isMultiline}
            />
          </View>
          <View
            style={{
              height: isExpanded ? undefined : 0,
              alignSelf: "stretch",
              padding: isExpanded ? Sizes.defaultBoxPadding : 0,
              opacity: isExpanded ? 1 : 0,
              marginTop: isExpanded ? 12 : 0,
              overflow: "hidden",
            }}
          >
            <ExpandableContentText expandableContent={expandableContent} />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const ExpandableContentText = ({ expandableContent }) => {
  const colorScheme = getColorScheme();
  return useMemo(
    () => (
      <BoxTextComponent
        color={Colors[colorScheme].blue}
        content={expandableContent}
        style={getSharedStylesContent(expandableContent, colorScheme)}
      />
    ),
    []
  );
};

const DetailTitleText = ({ detailTitle }) => {
  const colorScheme = getColorScheme();
  return useMemo(
    () => (
      <BoxTextComponent
        color={Colors[colorScheme].blue}
        content={detailTitle}
        style={detailTextStyle(colorScheme)}
      />
    ),
    [detailTitle]
  );
};

const TitleTextComponent = ({ title, colorInfo }) => {
  return useMemo(
    () => (
      <BoxTextComponent content={title} style={titleTextStyle(colorInfo)} />
    ),
    [title]
  );
};

function getColorToCode(code: string) {
  return COLOR_MAPPINGS[code] || COLOR_MAPPINGS.default;
}

const detailTextStyle = (colorScheme) => ({
  p: {
    fontWeight: "500",
    fontSize: Sizes.boxText + 1,
    lineHeight: Sizes.lineHeightBoxText,
    color: Colors[colorScheme].text,
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,

    marginLeft: -12,
    color: Colors[colorScheme].text,
    display: "inline-block",
  },
  ol: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,
    marginLeft: -12,
    color: Colors[colorScheme].text,
    display: "inline-block",
  },
  li: {
    marginBottom: 8,
    marginLeft: 6,
  },
  b: {
    fontSize: Sizes.boxText + 1,
  },
});

const titleTextStyle = (colorInfo) => ({
  p: {
    fontWeight: "700",
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    color: colorInfo.text,
  },
  ul: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,

    marginLeft: -12,
    color: colorInfo.text,
    display: "inline-block",
  },
  ol: {
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 12,
    marginLeft: -12,
    color: colorInfo.text,
    display: "inline-block",
  },
  li: {
    marginBottom: 8,
    marginLeft: 6,
  },
});
