import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { store } from "../../../../functions/store";
import { EasyReadingInfoBox } from "./EasyReadingInfoBox";

export default function getEasyReadingInfoBox(box: ResultBox) {
  let expandableContent = box.expandableContent;
  if (expandableContent !== undefined)
    expandableContent = parser(expandableContent);

  const searchValue = store.getState().currentResultReducer
    .filterNodePages as any;
  if (
    searchValue &&
    !box.titel?.toLowerCase().includes(searchValue.toLowerCase())
  )
    return null;

  return (
    <EasyReadingInfoBox
      color={box.colour ?? ""}
      key={box.id}
      title={"<p>" + box.fieldTitle + "</p>"}
      detailTitle={"<p>" + box.detailTitle! + "</p>"}
      expandableContent={expandableContent}
    />
  );
}
