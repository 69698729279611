import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import getPageContent from "../../api/content/get-page-content";
import staticColors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";

import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import getColorScheme from "../../hooks/useColorScheme";
import { CategoryIcon } from "../CategoryIcon";
import CustomRenderHTMLComponent from "../CustomRenderHTMLComponent";

export const ResultNote = ({ note, navigation }) => {
  const page = getPageContent(note.result_page_id) as any;

  const colorScheme = getColorScheme();

  if (!page) return null;
  return (
    <TouchableOpacity
      onPress={() => {
        navigateToNextCMSPage(page._id, navigation);
      }}
      style={{
        backgroundColor: staticColors[colorScheme].foreground,
        borderRadius: 12,
        padding: 16,
        paddingLeft: 20,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottomColor: "lightgray",
          borderBottomWidth: StyleSheet.hairlineWidth,
          paddingBottom: 16,
        }}
      >
        <CategoryIcon title={page.detailPath?.[0]?.title ?? ""} size={32} />
        <Text
          style={{
            flex: 1,
            fontWeight: "bold",
            fontSize: Sizes.boxText + 3,
            color: staticColors[colorScheme].text,
          }}
        >
          {page.title ?? page.fieldTitle ?? page.pageTitle}
        </Text>
      </View>
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          alignItems: "center",
          borderBottomWidth: note.created_at ? StyleSheet.hairlineWidth : 0,
          paddingBottom: note.created_at ? 20 : 0,
          borderBottomColor: "lightgray",
        }}
      >
        <View style={{ flex: 1 }}>
          <CustomRenderHTMLComponent
            tagsStyles={{
              p: {
                margin: 0,
              },
              body: {
                margin: 0,
                color: staticColors[colorScheme].text,
              },
            }}
            source={{ html: note.text }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};
