import { useEffect, useState } from "react";
import { Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { useSelector } from "react-redux";

import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import {
  selectAllAmounts,
  setAmount,
} from "../../../../functions/calculator/actions";

import { store } from "../../../../functions/store";
import getColorScheme from "../../../../hooks/useColorScheme";
import { UnitComponent } from "../calculator-box/CalculatorBox";

export default function QuickHelpBox() {
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);
  const [age, setAge] = useState<string | undefined>();
  const [weight, setWeight] = useState<string | undefined>();

  const amounts = useSelector(selectAllAmounts);

  useEffect(() => {
    if (amounts.age !== undefined) {
      const ageString = Math.round(amounts.age * 100) / 100 + "";
      setAge(ageString);

      store.dispatch(
        setAmount({
          age: parseFloat(ageString.split(",").join(".")),
        })
      );
    }
    if (amounts.amount !== undefined) {
      const weightString = amounts.amount + "";
      setWeight(weightString);

      store.dispatch(
        setAmount({ amount: parseFloat(weightString.split(",").join(".")) })
      );
    }
  }, []);

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
      }}
    >
      <View style={styles.result_container}>
        <View
          style={{
            paddingRight: 12,
            paddingLeft: 12,
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: Colors[colorScheme].foreground,
            borderRadius: 12,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            elevation: 2,
            shadowOpacity: 0.1,
            shadowRadius: 4,
          }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <TextInput
              style={styles.result_text}
              placeholder={"Gewicht"}
              placeholderTextColor={Colors[colorScheme].placeholder}
              keyboardType={"numeric"}
              value={weight}
              onChangeText={(text) => {
                setWeight(text);
                if (text === "") {
                  store.dispatch(setAmount({ amount: undefined }));
                } else {
                  store.dispatch(
                    setAmount({ amount: parseFloat(text.split(",").join(".")) })
                  );
                }
              }}
            />
          </View>
          <View
            style={{
              padding: 6,
              borderRadius: 4,
              borderWidth: 2,
              borderColor: Colors[colorScheme].blue,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 15,
                color: Colors[colorScheme].blue,
              }}
            >
              kg
            </Text>
          </View>
        </View>

        <View
          style={{
            paddingRight: 12,
            paddingLeft: 12,
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            elevation: 2,
            shadowOpacity: 0.1,
            shadowRadius: 4,
            backgroundColor: Colors[colorScheme].foreground,
            borderRadius: 12,
          }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <TextInput
              style={styles.result_text}
              placeholder={"Alter"}
              placeholderTextColor={Colors[colorScheme].placeholder}
              keyboardType={"numeric"}
              value={age}
              onChangeText={(text) => {
                setAge(text);
                if (text === "") {
                  store.dispatch(setAmount({ age: undefined }));
                } else {
                  const value = parseFloat(text.split(",").join("."));
                  store.dispatch(setAmount({ age: value }));
                }
              }}
            />
          </View>

          <UnitComponent title={"Alter"} />
        </View>
      </View>
    </View>
  );
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    result_container: {
      gap: 8,
      flexDirection: "row",
      flex: 1,
    },
    result_text: {
      fontWeight: "700",
      fontSize: Sizes.boxText,
      flex: 1,
      paddingTop: Platform.OS === "android" ? 12 : Sizes.defaultBoxPadding + 8,
      paddingBottom:
        Platform.OS === "android" ? 12 : Sizes.defaultBoxPadding + 8,
      color: Colors[scheme].blue,
    },
  });
  return styles;
};
