import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { Picker } from "@react-native-picker/picker";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MathView from "react-native-math-view";
import { useSelector } from "react-redux";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import ResultBoxes from "../../components/result/ResultBoxComponent";

import Ionicons from "@expo/vector-icons/Ionicons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { batch, useDispatch } from "react-redux";

import WarningModal from "../../components/modals/WarningModal";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import {
  clearAmount,
  selectAllAmounts,
} from "../../functions/calculator/actions";
import {
  setCurrentResultPage,
  setShowTitle,
} from "../../functions/current-result/actions";
import { addToUserHistory } from "../../functions/user/functions";
import { isWebAndNotMobile } from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";

export default function PerfusorCalculatorScreen({ navigation, route }) {
  const currentData = route.params?.currentData;
  const hasAdjustedData = currentData !== undefined;
  const allAmounts = useSelector(selectAllAmounts);

  const presetInputs = hasAdjustedData
    ? {
        dosage: currentData.recommendedDosage,
        units: "mg/kg/h",
        weight: allAmounts.amount ? allAmounts.amount + "" : undefined,
      }
    : {
        units: "mg/kg/h",
        weight: allAmounts.amount ? allAmounts.amount + "" : undefined,
      };

  const colorScheme = getColorScheme();

  const pageContent = {
    _id: currentData?.boxID ?? "$PERFUSOR_CALC",
    type: "hide-notes",
  };
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [inputs, setInputs] = useState<any>(presetInputs);
  const [result, setResult] = useState<any>(undefined);
  const [resultState, setResultState] = useState<any>(undefined);
  const hasEmptyField =
    !inputs.weight ||
    !inputs.liquid ||
    !inputs.amount ||
    !inputs.dosage ||
    !inputs.units;
  const [unitModalVisible, setUnitModalVisible] = useState(false);
  const scrollViewRef = useRef<any>();

  const dispatch = useDispatch();

  const loadInputs = async () => {
    const _inputs = await AsyncStorage.getItem(
      "data-perfusor-calculator-inputs"
    );

    if (_inputs) {
      const data = JSON.parse(_inputs);
      if (presetInputs.dosage) delete data["dosage"];
      setInputs({ ...presetInputs, ...data });
    }
  };

  async function storeInputs(inputs) {
    await AsyncStorage.setItem(
      "data-perfusor-calculator-inputs",
      JSON.stringify(inputs)
    );
  }

  const debounceStoreInputs = useCallback(debounce(storeInputs, 250), []);

  useEffect(() => {
    debounceStoreInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    loadInputs();
    setPageContent();
    dispatch(setShowTitle(false));
    const _unsubscribe = navigation.addListener("beforeRemove", () => {
      try {
        batch(() => {
          dispatch(clearAmount());
        });
      } catch {
        //
      }
    });
    return () => {
      _unsubscribe();
    };
  }, []);

  function setPageContent() {
    addToUserHistory(pageContent._id);
    dispatch(setCurrentResultPage(pageContent));
  }

  const toggleWarningModal = () => {
    setWarningModalVisible((m) => !m);
  };

  const pageTitleAdjustment =
    hasAdjustedData && currentData.title ? " - " + currentData.title : "";

  // # MEMORY
  return (
    <SafeAreaView
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      {unitModalVisible && (
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            zIndex: 10,
          }}
        />
      )}
      <UnitModal
        indexKey={"units"}
        {...{ inputs, setInputs, unitModalVisible, setUnitModalVisible }}
      />
      <WarningModal open={warningModalVisible} onClose={toggleWarningModal} />
      <View style={{ flex: 1, alignSelf: "stretch" }}>
        <KeyboardAwareScrollView
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          style={{ width: "100%" }}
          ref={scrollViewRef}
          contentContainerStyle={{
            width: "100%",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <View
            style={{
              padding: Sizes.defaultContainerPadding,
              width: "100%",
              flex: 1,
            }}
          >
            <ResultPageUserInteractionsHeader
              pageContent={{
                title: `Perfusorenlaufratenrechner ${pageTitleAdjustment}`,
              }}
              setWarningModalVisible={toggleWarningModal}
            />
            <View
              style={{
                width: "100%",
                justifyContent: "flex-start",
                padding: 12,
                marginTop: 12,
                paddingBottom: 0,
                borderColor: Colors[colorScheme].text,
                borderRadius: 12,
                backgroundColor: Colors[colorScheme].foreground,
              }}
            >
              <EnterInformationComponent
                {...{ inputs, setInputs, setUnitModalVisible }}
              />
            </View>
            <CalculateButton
              {...{
                setResult,
                setResultState,
                inputs,
                scrollViewRef,
                hasEmptyField,
                resultState,
              }}
            />
            <ResultComponent {...{ result }} />
            {currentData?.specificHints && (
              <AdditionalInformationComponent
                data={currentData?.specificHints}
              />
            )}
          </View>
        </KeyboardAwareScrollView>
      </View>
    </SafeAreaView>
  );
}

const AdditionalInformationComponent = ({ data }) => {
  return useMemo(() => <ResultBoxes pageContent={{ boxes: data }} />, [data]);
};

const CalculateButton = ({
  setResult,
  inputs,
  scrollViewRef,
  hasEmptyField,
  resultState,
  setResultState,
}) => {
  const disabled = inputs === resultState || hasEmptyField;
  return (
    <DefaultButton
      enabled={!disabled}
      style={{ margin: 20, marginVertical: Sizes.defaultContainerPadding + 8 }}
      type={disabled ? ButtonTypes.Outline : ButtonTypes.Primary}
      icon={<Ionicons name="arrow-down" size={24} />}
      title="Ergebnis berechnen"
      action={() => {
        const r = calculate(inputs);
        if (!r) return;
        setResultState(inputs);
        setResult(r);
        scrollViewRef.current?.scrollToEnd();
      }}
    />
  );
};

const EnterInformationComponent = ({
  inputs,
  setInputs,
  setUnitModalVisible,
}) => {
  return (
    <View>
      <InputInformationView
        title={"Körpergewicht"}
        unit={"kg"}
        {...{ inputs, setInputs }}
        indexKey={"weight"}
      />
      <InputInformationView
        {...{ inputs, setInputs }}
        indexKey={"liquid"}
        title={"Flüssigkeitsvolumen\nin der Perfusorspritze"}
        unit={"ml"}
      />
      <InputInformationView
        {...{ inputs, setInputs }}
        indexKey={"amount"}
        title={"Wirkstoffmenge in der\nPerfusorspritze"}
        unit={"mg"}
      />
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          marginTop: 12,
          marginBottom: 20,
        }}
      >
        <InputInformationView
          title={"Gewünschte Dosis bzw. Förderrate"}
          unit={""}
          {...{ inputs, setInputs }}
          indexKey={"dosage"}
        />
        <SelectUnitView
          {...{ inputs, setUnitModalVisible }}
          indexKey={"units"}
        />
      </View>
      {/* <AmpullenView {...{ inputs, setInputs }} /> */}
    </View>
  );
};

const SelectUnitView = ({ inputs, indexKey, setUnitModalVisible }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={() => setUnitModalVisible(true)}
      style={{ alignSelf: "center", marginBottom: 12, marginLeft: 12 }}
    >
      <View
        style={{
          padding: 8,
          backgroundColor: Colors[colorScheme].background,

          shadowOffset: { width: 0, height: 2 },
          shadowRadius: 3,
          shadowOpacity: 0.12,
          elevation: 2,
          borderRadius: 6,
          shadowColor: "#000",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: "500",
            color: Colors[colorScheme].text,
          }}
        >
          {inputs?.[indexKey] ?? "mg/kg/h"}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const ResultComponent = ({ result }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].foreground,
        padding: 12,
        borderRadius: 12,
        paddingBottom: 4,
      }}
    >
      <ResultInformationView
        title={result ? "Laufrate:" : ""}
        result={result}
        formula={"(Gewicht * Fluessigkeit / Wirkstoffmenge) * Dosis"}
      />
    </View>
  );
};

const ResultInformationView = ({ title, result, formula }) => {
  const colorScheme = getColorScheme();

  return (
    <View style={{ marginBottom: 12 }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text
          style={{
            marginRight: result ? 8 : 0,
            fontWeight: "500",
            fontSize: 20,
            color: Colors[colorScheme].text,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            marginRight: 8,
            fontWeight: "500",
            fontSize: 20,
            color: Colors[colorScheme].text,
          }}
        >
          {result
            ? result + " ml/h"
            : "Daten eingeben, um Ergebnis zu erhalten."}
        </Text>
      </View>
      {Platform.OS === "web" ? (
        <Text
          style={{
            marginRight: 8,
            marginTop: 4,
            fontWeight: "400",
            fontSize: 12,
            paddingTop: 8,
            fontStyle: "italic",
            color: Colors[colorScheme].text,
          }}
        >
          {formula}
        </Text>
      ) : (
        <View style={{ paddingTop: 8 }}>
          <MathView
            math={formula}
            resizeMode="contain"
            fontSize={12}
            style={{
              color: Colors[colorScheme].text,
            }}
          />
        </View>
      )}
    </View>
  );
};

function calculate(inputs) {
  if (
    !inputs.weight ||
    !inputs.liquid ||
    !inputs.amount ||
    !inputs.dosage ||
    !inputs.units
  ) {
    return null;
  }
  Object.keys(inputs).forEach((key) => {
    inputs[key] = inputs[key].replace(",", ".");
  });

  if (inputs.dosage.includes("-")) {
    let numbers = inputs.dosage.split("-");
    numbers = numbers.map((n) => Number(n.trim().replace(",", ".")));
    if (numbers.length > 2) {
      return null;
    }

    const result = numbers
      .map((n) =>
        (
          ((inputs.weight * inputs.liquid) / inputs.amount) *
          n *
          getTransformerToUnit(inputs.units)
        ).toFixed(2)
      )
      .join(" - ");
    return result;
  }

  const result =
    ((inputs.weight * inputs.liquid) / inputs.amount) *
    inputs.dosage *
    getTransformerToUnit(inputs.units);
  return Math.round(result * 100) / 100;
}

// const AmpullenView = ({ inputs, setInputs }) => {
//   const colorScheme = getColorScheme();
//   const styles = createStyles(colorScheme);
//   return (
//     <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
//       <Text
//         style={{
//           marginRight: 8,
//           fontWeight: "bold",
//           fontSize: 12,
//           color: Colors[colorScheme].text,
//         }}
//       >
//         Ampullen:
//       </Text>
//       <TextInput
//         placeholder=""
//         keyboardType="numeric"
//         style={styles.textField}
//         onChangeText={(t) => {
//           setInputs((pre) => {
//             return { ...pre, ["ampullen"]: t };
//           });
//         }}
//         value={inputs?.["ampullen"] ?? ""}
//       />
//       <Text
//         style={{
//           marginRight: 8,
//           marginLeft: 8,
//           fontWeight: "bold",
//           fontSize: 12,
//           color: Colors[colorScheme].text,
//         }}
//       >
//         je
//       </Text>
//       <View style={{ flex: 2.5, justifyContent: "center" }}>
//         <TextInput
//           placeholder=""
//           keyboardType="numeric"
//           style={styles.textField}
//           onChangeText={(t) => {
//             setInputs((pre) => {
//               return { ...pre, ["ampullen"]: t };
//             });
//           }}
//           value={inputs?.["ampullen"] ?? ""}
//         />
//         <UnitComponent
//           title={"mg"}
//           style={{ position: "absolute", right: 12 }}
//         />
//       </View>
//       <View style={{ marginLeft: 8, flex: 2.5, justifyContent: "center" }}>
//         <TextInput
//           placeholder=""
//           keyboardType="numeric"
//           style={styles.textField}
//           onChangeText={(t) => {
//             setInputs((pre) => {
//               return { ...pre, ["ampullen"]: t };
//             });
//           }}
//           value={inputs?.["ampullen"] ?? ""}
//         />

//         <UnitComponent
//           title={"ml"}
//           style={{ position: "absolute", right: 12 }}
//         />
//       </View>
//     </View>
//   );
// };

const UnitModal = ({
  unitModalVisible,
  setUnitModalVisible,
  inputs,
  setInputs,
  indexKey,
}) => {
  const data = [
    { value: "μg/kg/min", label: "μg/kg/min" },
    { value: "mg/kg/h", label: "mg/kg/h" },
    { value: "μg/kg/h", label: "μg/kg/h" },
  ];
  return (
    <Modal
      animationType="slide"
      transparent={true}
      onRequestClose={() => setUnitModalVisible(false)}
      visible={unitModalVisible}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <View
          style={{ backgroundColor: "white", borderRadius: 12, paddingTop: 12 }}
        >
          <Picker
            style={{
              backgroundColor: "white",
              width: 300,
              height: isWebAndNotMobile() ? 75 : 215,
            }}
            selectedValue={inputs?.[indexKey] ?? ""}
            onValueChange={(value) => {
              setInputs((pre) => {
                return { ...pre, [indexKey]: value };
              });
            }}
          >
            {data.map((item) => (
              <Picker.Item
                label={item.label}
                value={item.label}
                key={item.label}
              />
            ))}
          </Picker>

          <DefaultButton
            title="Bestätigen"
            style={{ margin: 20, marginTop: 0 }}
            type={ButtonTypes.Primary}
            action={() => {
              setUnitModalVisible(false);
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

function getTransformerToUnit(unit) {
  switch (unit) {
    case "mg/kg/h":
      return 1;
    case "μg/kg/h":
      return 1 / 1000;
    case "μg/kg/min":
      return 0.06;
    default:
      return 1;
  }
}

const InputInformationView = ({ title, unit, indexKey, inputs, setInputs }) => {
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 12,
        flex: 2,
      }}
    >
      {title !== "" && (
        <Text
          style={{
            marginRight: 8,
            fontWeight: "500",
            fontSize: 13,
            flex: 1,
            color: Colors[colorScheme].text,
          }}
        >
          {title}
        </Text>
      )}
      <TextInput
        placeholder="Eingabe..."
        keyboardType="numeric"
        placeholderTextColor={Colors[colorScheme].placeholder}
        style={styles.textField}
        onChangeText={(t) => {
          setInputs((pre) => {
            return { ...pre, [indexKey]: t };
          });
        }}
        value={inputs?.[indexKey] ?? ""}
      />
      {unit !== "" && (
        <UnitComponent
          title={unit}
          style={{ position: "absolute", right: 12 }}
        />
      )}
    </View>
  );
};

const UnitComponent = ({ title, style }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        ...style,
        padding: 4,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: Colors[colorScheme].accent,
      }}
    >
      <Text
        style={{
          fontWeight: "bold",
          fontSize: 12,
          color: Colors[colorScheme].accent,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    textField: {
      backgroundColor: Colors[scheme].background,
      maxWidth: Sizes.containerWidth,
      borderRadius: 8,
      flex: 1,
      fontSize: 13,
      fontWeight: "500",
      color: Colors[scheme].text,
      padding: 12,
      zIndex: 0,
    },
  });
  return styles;
};
