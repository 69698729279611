import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../../../api/api-call";
import { CategoryIcon } from "../../../../components/CategoryIcon";
import alert from "../../../../components/CustomAlert";
import CustomRenderHTMLComponent from "../../../../components/CustomRenderHTMLComponent";
import { InlineNotificationType } from "../../../../components/NotificationComponent";

import i18n from "../../../../../localization/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { showInlineNotification } from "../../../../entry/Root";
import configureAnimations from "../../../../functions/configure-animations";
import { selectCurrentUser } from "../../../../functions/user/actions";
import { Export } from "../../../../functions/user/types";
import getColorScheme from "../../../../hooks/useColorScheme";
import { reloadUser } from "../../../more/MoreScreen";
import { parseDate } from "../../functions/helpers";
import { getSortedExportData } from "./functions";

const ExportListComponent = ({
  query,
  data,
  setData,
  editModeActive,
  setEditModeActive,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const debouncedDataOrganizer = useCallback(
    debounce(() => {
      let enrichedData = getSortedExportData(currentUser);
      if (query)
        enrichedData = enrichedData.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
        );

      if (JSON.stringify(data) !== JSON.stringify(enrichedData)) {
        setData(enrichedData);
      }
    }, 150),
    [currentUser, query]
  );

  const colorScheme = getColorScheme();

  useEffect(() => {
    debouncedDataOrganizer();
  }, [currentUser.user_pdf_export_urls, query]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ paddingBottom: 130 }}
      style={{ flex: 1, paddingHorizontal: Sizes.defaultContainerPadding }}
    >
      {data?.length > 0 && (
        <View
          style={{
            marginTop: 20,
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: Sizes.boxText,
              flex: 1,
              color: Colors[colorScheme].text,
            }}
          >
            {data.length} {data.length === 1 ? "Export" : "Exports"}
          </Text>
          <TouchableOpacity
            style={{
              padding: 8,
              paddingHorizontal: 16,
              shadowColor: Colors[colorScheme].text,
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderRadius: 50,
              shadowRadius: 2.62,
              backgroundColor: editModeActive
                ? Colors[colorScheme].lightGray
                : Colors[colorScheme].foreground,
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
            activeOpacity={0.7}
            onPress={() => {
              configureAnimations();
              setEditModeActive((e) => !e);
            }}
          >
            <Ionicons
              name="ios-options-outline"
              size={20}
              color={Colors[colorScheme].text}
            />
            <Text
              style={{
                fontSize: Sizes.boxText,
                color: Colors[colorScheme].text,
              }}
            >
              Bearbeiten
            </Text>
          </TouchableOpacity>
        </View>
      )}
      {data?.map((item, index) => {
        return (
          <CaseCard
            {...{ item }}
            key={item._id + index}
            editMode={editModeActive}
            currentUser={currentUser}
          />
        );
      })}
      {data?.length === 0 && (
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            alignItems: "center",

            flex: 1,
          }}
        >
          <View style={{}}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                marginTop: 12,
                textAlign: "center",
              }}
            >
              {i18n.t("empty_export_placeholder")}
            </Text>
            <Image
              source={require("../../../../../assets/images/characters/cases.png")}
              style={{
                alignSelf: "center",
                width: 250,
                height: 250,
                marginVertical: 32,
                resizeMode: "contain",
              }}
            />
          </View>
          {/* TODO: REPLACE WITH EXPORT BUTTON */}
          {/* <EmptyCaseButton /> */}
        </View>
      )}
    </ScrollView>
  );
};

export default ExportListComponent;

export const CaseCard = ({
  item,
  currentUser = {},
  editMode = false,
}: {
  item: any;
  currentUser?: any;
  editMode?: boolean;
}) => {
  const [selected, setSelected] = useState(false);
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const date = parseDate(item.created_at);

  function deleteExportConfirm(action, exportItem) {
    alert(
      "Export: " + exportItem.case_name,
      "Möchtest du diesen Fall wirklich löschen?",
      [
        {
          text: "Nein",
          style: "cancel",
        },
        {
          text: "Entfernen",
          style: "destructive",
          onPress: () => {
            action();
          },
        },
      ]
    );
  }

  async function deleteCase(exportItem: Export) {
    deleteExportConfirm(async () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "case_deletion_confirmed",
        "CaseCard",
        "User Confirmed Deletion",
        { caseId: exportItem.case_name }
      );

      setLoading(true);

      configureAnimations();

      const response = await apiCall(
        "/api/v3/pdf?filename=" + exportItem.filename,
        null,
        false,
        false,
        "DELETE"
      );

      if (response?.status !== 200) {
        showInlineNotification({
          text: "Ein Fehler ist aufgetreten, bitte versuche es erneut",
          type: InlineNotificationType.ERROR,
        });
      } else {
        reloadUser(currentUser, dispatch);
      }
      setLoading(false);
    }, exportItem);
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 16,
        gap: 12,
      }}
    >
      {editMode && (
        <TouchableOpacity
          style={{ opacity: loading ? 0.5 : 1 }}
          disabled={loading}
          onPress={() => {
            deleteCase(item);
          }}
        >
          <Feather name="trash-2" size={24} color={Colors[colorScheme].red} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        activeOpacity={1}
        style={{ flex: 1, opacity: loading ? 0.5 : 1 }}
        disabled={loading}
        onPress={() => {
          navigation.navigate("ExportPreviewScreen", { export: item });
        }}
        onPressIn={() => {
          setSelected(true);
        }}
        onPressOut={() => setSelected(false)}
      >
        <View
          style={{
            opacity: 1,
            borderRadius: 8,
            paddingLeft: 20,
            paddingRight: 20,
            paddingVertical: Sizes.defaultBoxPadding,
            height: "auto",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 12,

            borderColor: selected
              ? Colors[colorScheme].accent
              : Colors[colorScheme].foreground,
            backgroundColor: selected
              ? Colors[colorScheme].selectedTint
              : Colors[colorScheme].foreground,
          }}
        >
          <CategoryIcon size={32} title={item.path?.[0] ?? ""} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "700",
                color: Colors[colorScheme].text,
                marginBottom: 4,
              }}
            >
              {item.case_name ?? ""}
            </Text>
            {useMemo(
              () => (
                <CustomRenderHTMLComponent
                  contentWidth={Sizes.containerWidth}
                  tagsStyles={{
                    body: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "400",
                      fontStyle: "italic",
                    },
                    p: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "400",
                      margin: 0,
                      marginRight: 20,
                      marginTop: 12,
                      marginBottom: 12,
                    },
                    b: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "700",
                      marginRight: 20,
                    },
                    ul: {
                      margin: 0,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      marginRight: 8,
                      color: Colors[colorScheme].text,
                      marginBottom: 2,
                    },
                    li: {
                      marginLeft: 6,
                      marginBottom: 5,
                      color: Colors[colorScheme].text,
                    },
                  }}
                  source={{ html: item.title ?? item.fieldTitle }}
                />
              ),
              []
            )}
            <Text
              style={{
                marginTop: 8,
                opacity: 0.5,
                fontSize: 12,
                color: Colors[colorScheme].text,
              }}
            >
              {date.toLocaleDateString("de-DE")}
              {", "}
              {date.toLocaleTimeString("de-DE", {
                hour12: false,
                minute: "2-digit",
                hour: "2-digit",
              })}
              {" Uhr"}{" "}
            </Text>
          </View>
          <Feather
            name="chevron-right"
            color={Colors[colorScheme].text}
            size={24}
            key="arrow"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
