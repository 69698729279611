import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { Sizes } from "../constants/static-sizes";
import getColorScheme from "../hooks/useColorScheme";

const SkeletonLoadingAnimation = () => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        padding: Sizes.defaultContainerPadding,
        flex: 1,
        overflow: "visible",
        opacity: colorScheme === "dark" ? 0.2 : 1,
      }}
    >
      <EmptySectionPlaceholder />
      <EmptyFavoritesPlaceholder />
      <EmptyButtonsPlaceholder />
      <EmptyButtonsPlaceholder />
      <EmptySectionPlaceholder marginTop={48} />
      <EmptySectionPlaceholder />
    </View>
  );
};

const EmptyButtonsPlaceholder = () => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item
        style={{
          overflow: "visible",
        }}
        marginTop={16}
      >
        <SkeletonPlaceholder.Item>
          <SkeletonPlaceholder.Item
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <SkeletonPlaceholder.Item
              width={"100%"}
              height={60}
              borderRadius={12}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder.Item>
      </SkeletonPlaceholder.Item>
    </SkeletonPlaceholder>
  );
};

const EmptySectionPlaceholder = ({ marginTop = 0 }) => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item
        style={{
          overflow: "visible",
        }}
        marginBottom={32}
        marginTop={marginTop}
      >
        <SkeletonPlaceholder.Item width={150} height={26} borderRadius={4} />

        <SkeletonPlaceholder.Item>
          <SkeletonPlaceholder.Item
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            marginTop={16}
          >
            <SkeletonPlaceholder.Item
              width={200}
              height={120}
              marginRight={12}
              borderRadius={20}
            />
            <SkeletonPlaceholder.Item
              width={200}
              height={120}
              marginRight={12}
              borderRadius={20}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder.Item>
      </SkeletonPlaceholder.Item>
    </SkeletonPlaceholder>
  );
};

const EmptyFavoritesPlaceholder = () => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item
        style={{
          overflow: "visible",
        }}
        marginTop={32}
      >
        <SkeletonPlaceholder.Item width={150} height={26} borderRadius={4} />

        <SkeletonPlaceholder.Item>
          <SkeletonPlaceholder.Item
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            marginTop={16}
          >
            <SkeletonPlaceholder.Item
              width={"100%"}
              height={60}
              marginRight={12}
              borderRadius={12}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder.Item>
      </SkeletonPlaceholder.Item>
    </SkeletonPlaceholder>
  );
};

export default SkeletonLoadingAnimation;
