import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { store } from "../../../../functions/store";
import FurtherActionBox from "./FurtherActionBox";

export default function getFurtherActionBox(box: ResultBox) {
  const title = parser(box.title);
  const searchValue = store.getState().currentResultReducer
    .filterNodePages as any;
  if (searchValue && !title.toLowerCase().includes(searchValue.toLowerCase()))
    return null;
  return (
    <FurtherActionBox
      isComingSoon={box.isComingSoon}
      key={box.id}
      title={title}
      id={box.resultOrNodeId}
      inlineImage={box.image}
      filter={box.filterId}
      inlineVar={box.inlineVar}
    />
  );
}
