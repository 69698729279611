import * as FileSystem from "expo-file-system";
import PropTypes from "prop-types";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ImageViewer from "react-native-image-zoom-viewer";
import { DIR, isFile } from "../api/image-processor";

import Ionicons from "@expo/vector-icons/Ionicons";
import {
  Dimensions,
  Image,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { InlineNotificationComponent } from "../components/NotificationComponent";

const RootContext: any = createContext(null);

const useRootContext: any = () => {
  return useContext(RootContext);
};

export const RootProvider = ({ children }) => {
  const [image, setImage] = useState(undefined);
  const [notificationProps, setNotificationProps] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const value = useMemo(
    () => ({
      image,
      setImage,
      notificationProps,
      setNotificationProps,
      showNotification,
      setShowNotification,
    }),
    [image, notificationProps, showNotification]
  );

  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
};

const Root = ({ children }) => {
  const rootRef = useRef<any>();
  rootContext = useRootContext();
  const { image, setImage, notificationProps, showNotification } =
    useRootContext();

  // ... (rest of your component logic, using the context values instead of global variables)

  return (
    <View
      ref={rootRef}
      style={{
        ...Platform.select({ web: { height: "100vh" as any } }),
        overflow: "hidden",
        flex: 1,
      }}
    >
      {children}
      {image && (
        <View
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "black",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            position: "absolute",
            left: 0,
            paddingTop: Platform.OS === "android" ? 20 : 0,
          }}
        >
          <TouchableOpacity
            style={{
              zIndex: 100,
              position: "absolute",
              top: 40,
              right: 20,
              shadowColor: "black",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              padding: 8,
              borderRadius: 50,
              backgroundColor: "white",
              shadowRadius: 3.84,
              aspectRatio: 1,
            }}
            onPress={() => {
              setImage(undefined);
            }}
          >
            <Ionicons name="close" size={24} color="black" />
          </TouchableOpacity>

          {Platform.OS === "web" ? (
            <Image
              source={{ uri: image.uri }}
              style={{
                maxWidth: "100%",
                width: "100%",
                maxHeight: "100%",

                height: Dimensions.get("window").width / image.aspect,
                resizeMode: "contain",
              }}
            />
          ) : (
            <PinchableBox image={image} setImage={setImage} />
          )}
        </View>
      )}
      <InlineNotificationComponent
        text={notificationProps?.text}
        type={notificationProps?.type}
        hasIcon={notificationProps?.hasIcon}
        direction={notificationProps?.direction}
        duration={notificationProps?.duration}
        shouldShow={showNotification}
        closeAction={notificationProps?.closeAction}
        onPress={notificationProps?.onPress}
      />
    </View>
  );
};

const PinchableBox = ({ image, setImage }) => {
  const dimension = useWindowDimensions();
  const [source, setSource] = useState(undefined);

  useEffect(() => {
    getImageUrl(image.uri).then((url) => setSource(url));
  }, []);

  if (!source) return null;
  const images = [
    {
      url: source,
    },
  ];
  return (
    <View
      style={{
        width: dimension.width,
        flex: 1,
      }}
    >
      <ImageViewer
        imageUrls={images}
        renderIndicator={() => <></>}
        enableSwipeDown
        onSwipeDown={() => {
          setImage(undefined);
        }}
      />
    </View>
  );
};

Root.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

let rootContext;

export const showInlineNotification = (props) => {
  rootContext.setNotificationProps(props);
  rootContext.setShowNotification((prev) => !prev);
};

export const showLightboxImage = (image) => {
  rootContext.setImage(image);
};

async function getImageUrl(url) {
  if (Platform.OS === "web") return url;

  const BASE = FileSystem.documentDirectory + DIR;
  const pathComponent = url.split("/")[url.split("/").length - 1];

  if (!pathComponent) return url;
  if (await isFile(BASE + "/" + pathComponent)) {
    return BASE + "/" + pathComponent;
  }
  return url;
}

Root.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default Root;
