import { DefaultTheme, Searchbar } from "react-native-paper";
import getColorScheme from "../../../../hooks/useColorScheme";
import Colors from "../../../../constants/static-colors";
import { useDispatch } from "react-redux";
import {
  selectFilterNodePages,
  setNodePageFilter,
} from "../../../../functions/current-result/actions";
import { useSelector } from "react-redux";

export default function SearchBoxView({ placeholder }) {
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const nodePageFilter = useSelector(selectFilterNodePages);
  return (
    <Searchbar
      onChangeText={(text) => {
        dispatch(setNodePageFilter(text));
      }}
      iconColor={Colors[colorScheme].gray}
      style={{
        borderRadius: 12,

        width: "100%",
        marginTop: 20,
        alignSelf: "stretch",
        backgroundColor: Colors[colorScheme].foreground,
      }}
      value={nodePageFilter}
      inputStyle={{
        fontSize: 12,
        color: Colors[colorScheme].text,
      }}
      placeholderTextColor={Colors[colorScheme].placeholder}
      theme={{
        colors: {
          ...DefaultTheme.colors,
          primary: Colors[colorScheme].text,
        },
      }}
      placeholder={placeholder}
    />
  );
}
