import { Keyboard, StyleSheet, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";

import Ionicons from "@expo/vector-icons/Ionicons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import { useEffect } from "react";

const CustomModal = ({
  open,
  onClose,
  children,
  style,
  showClose = true,
}: {
  open: boolean;
  onClose: any;
  children: any;
  style?: any;
  showClose?: boolean;
}) => {
  function _onClose() {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "modal_closed",
      "ModalClosed",
      "User Closed Modal",
      null
    );
    onClose();
  }

  useEffect(() => {
    if (open) Keyboard.dismiss();
  }, []);

  return (
    <Modal
      style={styles.modal}
      isVisible={open}
      animationIn={"slideInUp"}
      onBackdropPress={_onClose}
      backdropTransitionOutTiming={0}
      animationOut={"slideOutDown"}
      backdropOpacity={0.85}
    >
      <CustomModalContent
        {...{ children, style, showClose, onClose: _onClose }}
      />
    </Modal>
  );
};

export const CustomModalContent = ({
  children,
  style,
  showClose = true,
  onClose,
}: {
  children: any;
  style?: any;
  showClose?: boolean;
  onClose: any;
}) => {
  const colorScheme = getColorScheme();

  return (
    <KeyboardAwareScrollView
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
      onScrollEndDrag={(e) => {
        if (e.nativeEvent.contentOffset.y < -50) onClose();
      }}
      contentContainerStyle={{ justifyContent: "flex-end", flex: 1 }}
    >
      <View
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          padding: 16,
          borderRadius: 20,
          marginBottom: 20,
          ...style,
        }}
      >
        {showClose && (
          <View
            style={{
              position: "absolute",
              top: 12,
              height: 4,
              width: 35,
              backgroundColor: Colors[colorScheme].gray,
              alignSelf: "center",
              borderRadius: 10,
            }}
          />
        )}
        {showClose && (
          <TouchableOpacity onPress={onClose} style={styles.iconContainer}>
            <Ionicons name="close" size={32} color={Colors[colorScheme].text} />
          </TouchableOpacity>
        )}
        {children}
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: 500,
    alignSelf: "center",
    width: "90%",
    marginVertical: 20,
  },
  iconContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
export default CustomModal;
