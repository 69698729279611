import uuid from "react-native-uuid";
import { store } from "../../functions/store";
import { User } from "../../functions/user/types";

import { getTitle } from "../../screens/more/profile/UserComponent";
import { posthog } from "./posthog";
import getSessionId from "../../hooks/getSessionId";

export class AnalyticsHandler {
  static _instance;
  _conversionID: string | undefined = undefined;
  _lastOpenedScreen: string | undefined = undefined;

  getConversionId() {
    if (this._conversionID === undefined) {
      this._conversionID = uuid.v4().toString();
    }
    return this._conversionID;
  }

  static getInstance() {
    if (this._instance === undefined) {
      this._instance = new AnalyticsHandler();
    }
    return this._instance;
  }

  getUser() {
    const currentUser = store.getState().userReducer.user as any;
    if (!currentUser) return null;
    return {
      user_id: currentUser?.user_id,
      user_role: currentUser?.user_type,
    };
  }

  async logUserScreenInteractionWithoutKnownTopScreen(id, action, values) {
    this.logUserScreenInteraction(id, this._lastOpenedScreen, action, values);
  }

  async logUserScreenInteraction(id, screen, action, values) {
    this._lastOpenedScreen = screen;
    const body = {
      screen,
      action,
      values,
      ...this.getUser(),
      session_id: getSessionId(),
    };
    posthog?.capture(id, body);
  }

  async logSearch(id, query, searchType, searchSuccessful, searchResult) {
    const body = {
      screen: "SearchScreen",
      query,
      searchType,
      searchSuccessful,
      searchResult,
      ...this.getUser(),
      session_id: getSessionId(),
    };
    posthog?.capture(id, body);
  }

  async logLogin() {
    // * wait for 250 ms to have user ids synced
    setTimeout(() => {
      const currentUser = store.getState().userReducer.user as User | null;
      if (!currentUser) return;
      posthog?.identify(
        currentUser?.user_id, // Replace 'distinct_id' with your user's unique identifier
        {
          email: currentUser?.email,
          name:
            getTitle(currentUser) +
            currentUser?.firstname +
            " " +
            currentUser?.lastname,
          clinic: currentUser?.clinic,
          role: currentUser?.user_type,
          additional_specialty: currentUser?.additional_specialty,
          specialty: currentUser?.specialty,
          position: currentUser?.position,
          job_description: currentUser?.job_description,
          semester: currentUser?.semester,
          university: currentUser?.university,
          preferred_specialization: currentUser?.preferred_specialization,
        }
      );
      this.logUserScreenInteraction(
        "user_login",
        "LoginScreen",
        "UserLoggedIn",
        null
      );
    }, 250);
  }

  createUID() {
    return uuid.v4().toString();
  }
}
