export const pSBC = (p, c0, c1, l) => {
  const parseColor = (d) => {
    if (d.length > 9) {
      let [r, g, b, a] = d.split(",");
      if (d.length < 3 || d.length > 4) return null;
      return {
        r: parseInt(r[3] === "a" ? r.slice(5) : r.slice(4)),
        g: parseInt(g),
        b: parseInt(b),
        a: a ? parseFloat(a) : -1,
      };
    } else {
      if (d.length === 8 || d.length === 6 || d.length < 4) return null;
      if (d.length < 6)
        d = `#${d[1]}${d[1]}${d[2]}${d[2]}${d[3]}${d[3]}${
          d.length > 4 ? d[4] + d[4] : ""
        }`;
      const i = parseInt(d.slice(1), 16);
      return d.length === 9 || d.length === 5
        ? {
            r: (i >> 24) & 255,
            g: (i >> 16) & 255,
            b: (i >> 8) & 255,
            a: Math.round((i & 255) / 0.255) / 1000,
          }
        : {
            r: i >> 16,
            g: (i >> 8) & 255,
            b: i & 255,
            a: -1,
          };
    }
  };

  if (
    typeof p !== "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== "string" ||
    !["r", "#"].includes(c0[0]) ||
    (c1 && typeof c1 !== "string")
  ) {
    return null;
  }

  const f = parseColor(c0);
  const t =
    c1 && c1 !== "c"
      ? parseColor(c1)
      : p < 0
      ? { r: 0, g: 0, b: 0, a: -1 }
      : { r: 255, g: 255, b: 255, a: -1 };

  if (!f || !t) return null;

  const P = 1 - Math.abs(p);

  const r = Math.round(
    l ? P * f.r + p * t.r : (P * f.r ** 2 + p * t.r ** 2) ** 0.5
  );
  const g = Math.round(
    l ? P * f.g + p * t.g : (P * f.g ** 2 + p * t.g ** 2) ** 0.5
  );
  const b = Math.round(
    l ? P * f.b + p * t.b : (P * f.b ** 2 + p * t.b ** 2) ** 0.5
  );

  const a =
    f.a >= 0 || t.a >= 0
      ? f.a < 0
        ? t.a
        : t.a < 0
        ? f.a
        : f.a * P + t.a * p
      : 0;

  const isRGB = c0.length > 9 || (c1 && c1.length > 9 && c1 !== "c");
  if (isRGB) {
    return `rgb${f.a >= 0 || t.a >= 0 ? "a" : ""}(${r},${g},${b}${
      f.a >= 0 || t.a >= 0 ? "," + Math.round(a * 1000) / 1000 : ""
    })`;
  } else {
    return `#${(
      4294967296 +
      r * 16777216 +
      g * 65536 +
      b * 256 +
      (f.a >= 0 || t.a >= 0 ? Math.round(a * 255) : 0)
    )
      .toString(16)
      .slice(1, f.a >= 0 || t.a >= 0 ? undefined : -2)}`;
  }
};
