import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";

import configureAnimations from "../../../../functions/configure-animations";
import { NewCalculatorBox } from "./NewCalculatorBox";

export default function PatientDataComponentBox({ calculatorBoxes }) {
  const colorScheme = getColorScheme();
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <View
        key={"patient_data"}
        style={{
          borderRadius: 12,
          padding: 8,
          marginTop: 20,
          backgroundColor: Colors[colorScheme].lightGray,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            configureAnimations();
            setExpanded((e) => !e);
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                flex: 1,
                margin: 8,
                fontSize: 13,
                fontWeight: "bold",
                color: Colors[colorScheme].text,
              }}
            >
              Daten eingeben
            </Text>

            <Ionicons
              name={expanded ? "chevron-down" : "chevron-up"}
              size={24}
              style={{ marginRight: 8 }}
              color={Colors[colorScheme].text}
            />
          </View>
        </TouchableOpacity>
        <View
          style={{ opacity: expanded ? 1 : 0, height: expanded ? "auto" : 0 }}
        >
          {calculatorBoxes.map((b) => (
            <NewCalculatorBox
              contained
              units={b.units}
              key={b.internalFieldTitle}
            />
          ))}
        </View>
      </View>
    </>
  );
}
