import { useNavigation } from "@react-navigation/native";
import {
  Image,
  Platform,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { selectCurrentUser } from "../../functions/user/actions";

import { Feather } from "@expo/vector-icons";
import { addEventListener } from "@react-native-community/netinfo";
import { useEffect, useState } from "react";
import { selectEnterprise } from "../../functions/enterprise/actions";
import { selectTabSelection } from "../../functions/navigation/actions";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import isLargerDevice from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";

import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import configureAnimations from "../../functions/configure-animations";
import BadgeComponent from "../BadgeComponent";
import { UserIcon } from "../UserIconComponent";
import { HorizontalLogo } from "../logos/HorizontalLogo";
import OfflineHintModal from "../modals/OfflineHintModal";

const LeftHeaderComponent = ({ title }) => {
  const navigation: any = useNavigation();

  const colorScheme = getColorScheme();
  const currentEnterprise = useSelector(selectEnterprise);

  const showLogo = currentEnterprise && currentEnterprise.logo?.show_logo;

  if (title.includes("Home"))
    return (
      <TouchableOpacity
        activeOpacity={0.65}
        style={{
          paddingVertical: 16,
          flexDirection: "row",
          alignItems: "center",

          gap: 10,
        }}
        hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
        onPress={() => {
          navigation.navigate("HomeScreen");
        }}
      >
        {showLogo && (
          <Image
            source={{ uri: currentEnterprise.logo?.file_ref }}
            style={{
              width: 80, //currentEnterprise?.logo?.logo_width ?? 45,
              height: 48,
              resizeMode: "contain",
            }}
          />
        )}

        {showLogo && (
          <Text
            style={{
              fontWeight: "700",
              fontSize: 20,
              marginBottom: 5,
              alignSelf: "flex-end",
              height: 28,
              color: Colors[colorScheme].text,
            }}
          >
            x
          </Text>
        )}
        <View>
          <HorizontalLogo />
        </View>
      </TouchableOpacity>
    );

  return (
    <Text
      style={{
        fontSize: 32,
        fontWeight: "700",
        height: 35,
        marginVertical: 16,
        color: Colors[colorScheme].text,
      }}
    >
      {title}
    </Text>
  );
};

export default function DefaultHeader() {
  return <MobileHeader />;
}

const MobileHeader = () => {
  const insets = useSafeAreaInsets();
  const colorScheme = getColorScheme();

  const currentTabSelection = useSelector(selectTabSelection);
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();
  const [isOffline, setIsOffline] = useState(false);
  const [showHintBadge, setShowHintBadge] = useState(false);
  const [showOfflineHint, setShowOfflineHint] = useState(false);

  useEffect(() => {
    const unsubscribe = addEventListener((state) => {
      configureAnimations();
      setIsOffline(
        !state.isConnected ||
          (state.type === "cellular" &&
            state.details.cellularGeneration === "2g")
      );
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function checkShowBadge() {
      if (isOffline) {
        if (await handleOneTimeAction("show_offline_hint")) {
          setShowHintBadge(true);
        }
      }
    }

    checkShowBadge();
  }, [isOffline]);

  return (
    <>
      <OfflineHintModal
        open={showOfflineHint}
        onClose={() => setShowOfflineHint(false)}
      />
      <View
        style={{
          paddingTop:
            (Platform.OS === "android" ? StatusBar.currentHeight : insets.top) +
            (isLargerDevice() ? 20 : 0),
          backgroundColor: Colors[colorScheme].background,
          paddingHorizontal: Sizes.defaultContainerPadding,
          overflow: "hidden",
        }}
      >
        <View
          style={{
            zIndex: 1,
            height: 70,
            paddingBottom: 4,
            backgroundColor: Colors[colorScheme].background,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <LeftHeaderComponent title={currentTabSelection} />
          <View
            style={{
              flexDirection: "row",
              gap: 20,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
              onPress={() => {
                setShowHintBadge(false);
                setShowOfflineHint(true);
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "offline_hint_modal_opened",
                  "DefaultHeader",
                  "User Opened Offline Hint Modal",
                  { isOffline }
                );
              }}
            >
              {showHintBadge && <BadgeComponent text="1" />}
              {isOffline && (
                <Feather
                  name="wifi-off"
                  color={Colors[colorScheme].text}
                  size={24}
                />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "more_screen_navigation",
                  "DefaultHeader",
                  "User Navigated to More Screen",
                  null
                );

                navigation.navigate("MoreScreen");
              }}
            >
              <UserIcon
                {...{ colorScheme, currentUser }}
                size={Platform.OS === "web" ? 52 : 48}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
};
