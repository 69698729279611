import { useState } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useDispatch } from "react-redux";

import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import {
  addNextMultiplePage,
  addToCurrentFilters,
  removeFromCurrentFilters,
  removeFromMultiplePage,
} from "../../../../functions/current-result/actions";
import getColorScheme from "../../../../hooks/useColorScheme";
import CheckBox from "../../../CheckBoxComponent";

interface MultipleChoiceProps {
  title: string;
  filterId: string | undefined;
  nextPage: string | undefined;
}

export function MultipleChoiceBox(props: MultipleChoiceProps) {
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();

  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        width: "100%",
        marginTop: 20,
        ...Platform.select({ web: { cursor: "pointer" } }),
      }}
    >
      <TouchableWithoutFeedback
        style={{ width: "100%" }}
        onPress={() => {
          if (!selected) {
            if (props.filterId !== undefined) {
              dispatch(addToCurrentFilters(props.filterId));
            } else {
              dispatch(addNextMultiplePage(props.nextPage!));
            }
          } else {
            if (props.filterId !== undefined) {
              dispatch(removeFromCurrentFilters(props.filterId));
            } else {
              dispatch(removeFromMultiplePage(props.nextPage));
            }
          }

          setSelected(!selected);
        }}
      >
        <View
          style={[
            styles.container,
            {
              borderColor: selected
                ? Colors[colorScheme].accent
                : Colors[colorScheme].foreground,
              backgroundColor: selected
                ? Colors[colorScheme].selectedTint
                : Colors[colorScheme].foreground,
            },
          ]}
        >
          <Text
            style={{
              color: Colors[colorScheme].text,
              fontSize: Sizes.boxText,
              lineHeight: Sizes.decisionTextLineHeight,
              width: "90%",
              fontWeight: "600",
            }}
          >
            {props.title}
          </Text>
          <CheckBox isSelected={selected} setSelected={null} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    borderWidth: 1,
    padding: 20,
    width: "100%",

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...Platform.select({ web: { cursor: "pointer" } }),
  },
});
