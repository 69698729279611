import { ResultBox } from "../../../../api/content/load-all/types";
import { setHasMultipleChoice } from "../../../../functions/current-result/actions";
import { store } from "../../../../functions/store";
import { MultipleChoiceBox } from "./MutlipleChoiceBox";

export default function getMultipleChoiceBox(box: ResultBox) {
  store.dispatch(setHasMultipleChoice(true));

  return (
    <MultipleChoiceBox
      title={box.fieldTitle ?? ""}
      key={box.id}
      filterId={box.filterId}
      nextPage={box.nextPage}
    />
  );
}
