import { Text, TouchableOpacity, View } from "react-native";

import { Ionicons } from "@expo/vector-icons";
import { useState } from "react";
import { TextInput } from "react-native";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import { selectCurrentUser } from "../../functions/user/actions";
import { changePwAndSendMail } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../NotificationComponent";
import CustomModal from "./CustomModal";

export default function ChangePasswordModal({ open, onClose }) {
  const colorScheme = getColorScheme();

  const [secureEntry, setSecureEntry] = useState(true);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  async function changePassword() {
    setLoading(true);
    const success = await changePwAndSendMail(currentUser?.password, password);
    onClose();
    setLoading(false);

    if (!success) {
      showInlineNotification({
        text: "Passwort ändern fehlgeschlagen",
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    } else {
      showInlineNotification({
        text: "Passwort ändern erfolgreich!",
        type: InlineNotificationType.SUCCESS,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }
  }

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Text style={{ fontWeight: "500", fontSize: 20, marginBottom: 12 }}>
          Neues Passwort eingeben:
        </Text>

        <View
          style={{
            width: "100%",
            maxWidth: Sizes.containerWidth,
            marginTop: 12,
            marginBottom: 20,
          }}
        >
          <TextInput
            placeholder="Passwort"
            placeholderTextColor={Colors[colorScheme].placeholder}
            style={{
              backgroundColor: Colors[colorScheme].background,
              color: Colors[colorScheme].text,
              borderRadius: 12,
              height: 50,
              padding: 12,
            }}
            secureTextEntry={secureEntry}
            onChangeText={(text) => {
              setPassword(text);
            }}
          />
          <TouchableOpacity
            hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
            style={{ position: "absolute", right: 20, top: 11 }}
            onPress={() => setSecureEntry(!secureEntry)}
          >
            <Ionicons
              name={secureEntry ? "eye-outline" : "eye-off-outline"}
              size={24}
              color={Colors[colorScheme].text}
            />
          </TouchableOpacity>
        </View>

        <DefaultButton
          action={changePassword}
          loading={loading}
          style={{ marginTop: 20 }}
          title="Jetzt ändern"
          type={ButtonTypes.Primary}
          enabled={password.length > 7}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
