import apiCall from "../../../../api/api-call";
import { setUser } from "../../../../functions/user/functions";
import { continueWithUser } from "../../LoginScreen";

export async function sendAuthCodeToServer(answer, navigation, user_type) {
  console.log("ANSWER", answer);
  const response = await apiCall("/api/v4/auth/apple", {
    ...answer,
    user_type,
  });
  console.log("RESPONSE", response);
  return response;
  // APPLE: familyName, givenName, namePrefix

  if (response?.status === 200) {
    if (response.data.firstname) {
      const _response = await setUser(response.data);
      if (_response.valid) {
        continueWithUser(
          _response.userData,
          _response.valid,
          false,
          response.data.email,
          "",
          navigation,
          true,
          response.data.auth
        );
      }
    }
  }

  // Verwende das zurückgegebene Passwort, um ein neues JWT-Token zu bekommen → auth/token/refresh
}
