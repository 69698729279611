import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import i18n from "../../../localization/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import {
  NodePage,
  ResultPage,
  ScorePage,
} from "../../api/content/load-all/types";
import { selectCurrentUser } from "../../functions/user/actions";
import SectionHeaderText from "../SectionHeaderText";
import { HorizontalCardSlider } from "./HorizontalCardSlider";
import { ShowAllButton } from "./ShowAllButton";

export const RecentlyUsedSection = () => {
  const [data, setData] = useState<any>([]);

  const navigation = useNavigation();

  const currentUser = useSelector(selectCurrentUser);
  const allPages = useSelector((state: any) => state.dataReducer.allData.pages);

  useEffect(() => {
    async function load() {
      let history = currentUser?.result_page_history?.history;
      history = cleanEntriesWithLatestDate(history);
      history = history.slice(0, 5);

      const _data: any[] = [];
      for (const b of history) {
        const page = allPages.find(
          (page: ResultPage | ScorePage | NodePage) =>
            page._id === b.result_page_id
        );
        if (page === undefined) continue;
        _data.push({ ...page, timestamp: b.timestamp });
      }
      if (JSON.stringify(data) !== JSON.stringify(_data)) setData(_data);
    }
    if (!allPages) return;
    load();
  }, [currentUser?.result_page_history, allPages]);

  if (!data || data.length === 0) return null;

  return (
    <View>
      <View style={{ flexDirection: "row", gap: 8, marginBottom: 16 }}>
        <SectionHeaderText style={{ flex: 1 }}>
          {i18n.t("recently_used")}
        </SectionHeaderText>

        <ShowAllButton
          title={
            i18n.t("show_all") +
            " (" +
            getAdjustedResultPageHistory(currentUser) +
            ")"
          }
          showIcon
          style={{ marginTop: 0 }}
          action={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "navigate_to_history_screen",
              "HistoryHomeScreen",
              "User Navigated"
            );
            navigation.navigate("PersonalContentScreen", { selection: 2 });
          }}
        />
      </View>

      <HorizontalCardSlider {...{ data, header: "Kürzlich verwendet" }} />
    </View>
  );
};

function getAdjustedResultPageHistory(currentUser) {
  if (currentUser.result_page_history?.total_results === 0) {
    return currentUser.result_page_history?.history.length;
  } else return currentUser.result_page_history?.total_results;
}

export function cleanEntriesWithLatestDate(entries) {
  const entriesMap = new Map();
  if (!entries) return [];
  entries.forEach((entry) => {
    if (
      !entriesMap.has(entry.result_page_id) ||
      new Date(entry.timestamp) >
        new Date(entriesMap.get(entry.result_page_id).timestamp)
    ) {
      entriesMap.set(entry.result_page_id, entry);
    }
  });

  const cleanedEntries = Array.from(entriesMap.values());
  cleanedEntries.sort((d1, d2) => {
    const date1 = new Date(d1.timestamp);
    const date2 = new Date(d2.timestamp);

    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  });
  return cleanedEntries;
}
