import { useEffect, useMemo, useRef, useState } from "react";
import {
  ImageBackground,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import WarningModal from "../../components/modals/WarningModal";
import WorkInProgressModal from "../../components/modals/WorkInProgressModal";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";

import { Feather } from "@expo/vector-icons";
import BlurViewWrapper from "../../components/BlurViewWrapper/BlurViewWrapper";
import ResultPageHeader from "../../components/header/ResultPageHeaderComponent";
import {
  default as Colors,
  default as staticColors,
} from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import {
  clearAmount,
  setResultingCalculation,
} from "../../functions/calculator/actions";
import configureAnimations from "../../functions/configure-animations";
import {
  selectResultPageCaseContent,
  selectShowTitle,
  setCurrentHighlightSection,
  setCurrentResultPage,
  setResultPageCaseContent,
  setResultPageHeaderContent,
  setShowTitle,
  shouldShowSources,
} from "../../functions/current-result/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
  increaseResultPagesCount,
} from "../../functions/current-result/helpers";
import { selectAllContent } from "../../functions/data/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import { addToUserHistory } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";
import ScoreElements from "./components/ScoreElements";
import ScoreInterpretation from "./components/ScoreInterpretation";
import ScoreOverview from "./components/ScoreOverview";
import { useFeatureFlag } from "posthog-react-native";

export default function ScoreScreen({ navigation, route }) {
  const { pageContent } = route.params;
  const screenshotUri = route.params?.screenShotUri;

  const showOverview = useFeatureFlag("show_score_overview");

  const setInterpretationContent = route.params?.setInterpretationContent;
  const additionalCaseData = route.params?.additionalCaseData;
  const resultPageCaseContent = useSelector(selectResultPageCaseContent);
  const [informationHeight, setInformationHeight] = useState(0);
  const colorScheme = getColorScheme();
  const scrollViewRef = useRef<any>();
  const [answers, setAnswers] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.answers ?? []
  );
  const [interpretationItem, setInterpretationItem] = useState<any>(null);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [workInProgressModalVisible, setWorkInProgressModalVisible] =
    useState(false);
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.currentSelectionIndex ?? 0
  );
  const currentUser = useSelector(selectCurrentUser);
  const euroscoreId =
    useSelector(selectAllContent).medicalCategories.content.euroscoreId;
  const hasBookmark = useMemo(
    () => getIsBookmarkToID(pageContent._id, pageContent.legacy_id),
    [pageContent, currentUser]
  );
  const note = useMemo(
    () => getNoteToID(pageContent._id, pageContent.legacy_id),
    [pageContent, currentUser]
  );
  const { isAssistant } = route.params;
  const isEuroscore = pageContent._id === euroscoreId;
  const [score, setScore] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.score ?? null
  );
  const showTitle = useSelector(selectShowTitle);
  const dispatch = useDispatch();

  const onlyScoreElements = showOverview
    ? true
    : pageContent?.scoreElements?.every(
        (element) => element.typeId === "scoreElement"
      );

  const [overviewExpanded, setOverviewExpanded] = useState(false);
  const [interpretationCollapsed, setInterpretationCollapsed] = useState(false);

  useEffect(() => {
    addToUserHistory(pageContent._id);
    if (pageContent.internal_rating === 0) {
      setWorkInProgressModalVisible(true);
    }
    dispatch(setCurrentResultPage(pageContent));
    increaseResultPagesCount();
    dispatch(setShowTitle(false));

    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "score_screen_opened",
        "ScoreScreen",
        pageContent.title,
        pageContent._id
      );
    });

    const _unsubscribe = navigation.addListener("beforeRemove", () => {
      try {
        batch(() => {
          dispatch(clearAmount());
          dispatch(shouldShowSources(false));
          dispatch(setResultingCalculation(null));
          dispatch(setResultPageHeaderContent(null));
          dispatch(setCurrentHighlightSection(null));
          dispatch(setResultPageCaseContent(null));
        });
      } catch {
        //
      }
    });

    return () => {
      unsubscribe();
      _unsubscribe();
    };
  }, []);

  if (pageContent === undefined) return null;

  const isModal = route.params?.modal && Platform.OS === "web";

  return (
    <SafeAreaView
      style={[
        styles.safeArea,

        { backgroundColor: Colors[colorScheme].background },
      ]}
    >
      {isModal && screenshotUri && (
        <ImageBackground
          source={{ uri: screenshotUri }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <BlurViewWrapper
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                ...StyleSheet.absoluteFillObject,
                backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent black overlay
              }}
            />
          </BlurViewWrapper>
        </ImageBackground>
      )}
      <WarningModal
        open={warningModalVisible}
        onClose={() => setWarningModalVisible(false)}
      />
      <WorkInProgressModal
        open={workInProgressModalVisible}
        setClosed={setWorkInProgressModalVisible}
        onClose={() => {
          navigation.goBack();
          setWorkInProgressModalVisible(false);
        }}
      />
      <View
        style={[
          isModal && styles.modalSafeArea,
          { flex: 1, alignSelf: "stretch" },
        ]}
      >
        <View style={[styles.container, isModal && styles.modalContainer]}>
          <View
            style={{
              shadowColor: staticColors[colorScheme].text,
              shadowOffset: { width: 0, height: 3 },
              shadowOpacity: 0.08,
              shadowRadius: 4,
              width: "100%",
              backgroundColor: Colors[colorScheme].background,
              height: 12,
              marginTop: -12,
              zIndex: 50,
              elevation: 2,
            }}
          />
          <ScrollView
            ref={scrollViewRef}
            scrollEventThrottle={20}
            style={{ width: "100%", flex: 1 }}
            contentContainerStyle={{
              width: "100%",
              alignItems: "center",
              paddingTop: resultPageCaseContent ? 50 : 0,
            }}
            onScroll={(e) => {
              if (e.nativeEvent.contentOffset.y > 60 && !showTitle) {
                dispatch(setShowTitle(true));
              } else if (e.nativeEvent.contentOffset.y < 60 && showTitle) {
                dispatch(setShowTitle(false));
              }
            }}
          >
            <View
              style={{
                alignSelf: "stretch",
              }}
            >
              {isModal && <ResultPageHeader isModal integrated />}
            </View>
            <View
              style={{ width: "100%", padding: Sizes.defaultContainerPadding }}
            >
              <ResultPageUserInteractionsHeader
                {...{
                  isAssistant,
                  pageContent,
                  hasBookmark,
                  setWarningModalVisible,
                  note,
                  currentUser,
                }}
              />
              {onlyScoreElements && (
                <TouchableOpacity
                  onPress={() => {
                    configureAnimations();
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "overview_expanded",
                      "ScoreScreen",
                      overviewExpanded ? "collapsed" : "expanded",
                      pageContent._id
                    );
                    if (!overviewExpanded) {
                      setInterpretationCollapsed(true);
                    }
                    setOverviewExpanded((prev) => !prev);
                  }}
                  style={{
                    marginTop: 12,
                    marginBottom: 12,
                    padding: 8,
                    paddingHorizontal: 20,
                    borderRadius: 20,
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    backgroundColor: Colors[colorScheme].foreground,
                    alignSelf: "flex-start",
                  }}
                >
                  <Text>
                    Übersicht {overviewExpanded ? "ausblenden" : "anzeigen"}
                  </Text>
                  <Feather
                    name={overviewExpanded ? "chevron-up" : "chevron-down"}
                    size={20}
                    color={Colors[colorScheme].text}
                  />
                </TouchableOpacity>
              )}
              {overviewExpanded && <ScoreOverview pageContent={pageContent} />}
              <View
                key={"RES_BOXES"}
                style={{ width: "100%", justifyContent: "center" }}
              >
                <View
                  onLayout={(e) => {
                    if (informationHeight === 0)
                      setInformationHeight(e.nativeEvent.layout.height);
                  }}
                >
                  <ResultBoxes pageContent={{ boxes: pageContent.boxes }} />
                </View>
                <ScoreElements
                  scrollViewRef={scrollViewRef}
                  data={pageContent}
                  {...{
                    answers,
                    setAnswers,
                    score,
                    setScore,
                    informationHeight,
                    isEuroscore,
                    setCurrentSelectionIndex,
                    currentSelectionIndex,
                  }}
                />
              </View>
              <View
                style={{
                  marginVertical: 20,
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    height: 1,
                    backgroundColor: Colors[colorScheme].text,
                  }}
                />
                <Text
                  style={{
                    fontSize: Sizes.boxText + 4,
                    marginLeft: 20,
                    fontWeight: "500",
                    color: Colors[colorScheme].text,
                  }}
                >
                  Punkte: {Math.round((score ?? 0) * 100) / 100}
                </Text>
              </View>
              <View style={{ alignSelf: "stretch" }}>
                <SourcesView {...{ pageContent }} />
              </View>
            </View>
          </ScrollView>
        </View>

        {((pageContent.scoreInterpretationElements &&
          pageContent.scoreInterpretationElements.length > 0) ||
          isEuroscore) && (
          <ScoreInterpretation
            collapsed={interpretationCollapsed}
            setCollapsed={setInterpretationCollapsed}
            item={interpretationItem}
            setItem={setInterpretationItem}
            setInterpretationContent={setInterpretationContent}
            isEuroscore={isEuroscore}
            answers={answers}
            data={pageContent.scoreInterpretationElements}
            score={score}
            {...{ currentSelectionIndex, setCurrentSelectionIndex }}
            id={pageContent._id}
          />
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  modalSafeArea: {
    alignSelf: "center",
    width: "80%",
    height: "80%",
    marginVertical: 40,
    borderRadius: 15,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    backgroundColor: Colors["light"].background,
  },
  container: {
    flex: 1,
    alignSelf: "stretch",
  },
  modalContainer: {
    borderRadius: 15,
    marginHorizontal: "5%",
    overflow: "hidden",
  },
});
