import Slider from "@react-native-community/slider";
import { useState } from "react";
import { Platform, Text, View } from "react-native";
import RNRestart from "react-native-restart";

import AsyncStorage from "@react-native-async-storage/async-storage";
import staticColors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";
// import other necessary components and styles

export default function ChangeFontSizeModal({ open, onClose }) {
  const [fontSize, setFontSize] = useState(Sizes.boxText); // Assuming 16 is the default font size
  const colorScheme = getColorScheme();

  const handleSliderChange = (value) => {
    setFontSize(value);
  };

  // Replace this with your actual font size change logic
  const applyFontSize = async () => {
    await AsyncStorage.setItem("fontSize", fontSize.toString());
    if (Platform.OS === "web") {
      window.location.reload();
    } else {
      RNRestart.Restart();
    }

    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <View style={{}}>
        <Text
          style={{
            marginBottom: 20,
            fontSize: 24,
            fontWeight: "500",
            color: staticColors[colorScheme].text,
          }}
        >
          Schriftgröße: {fontSize}
        </Text>
        <Text
          style={{
            fontSize: fontSize + 1,
            color: staticColors[colorScheme].text,
          }}
        >
          Die Größe dieses Textes ändert sich mit deiner Eingabe.
        </Text>
        <Slider
          style={{ width: "100%", height: 40, marginVertical: 20 }}
          minimumValue={10}
          maximumValue={20}
          step={1}
          value={fontSize}
          onValueChange={handleSliderChange}
          minimumTrackTintColor={staticColors["light"].accent}
          maximumTrackTintColor="#d3d3d3"
          thumbTintColor={staticColors["light"].accent}
        />
        <DefaultButton
          title="Anwenden"
          action={applyFontSize}
          type={ButtonTypes.Primary}
          // other necessary props
        />
      </View>
    </CustomModal>
  );
}
