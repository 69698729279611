import isLargerDevice, { isWebAndNotMobile } from "../hooks/isLargerDevice";

export const Sizes = {
  boxText: isWebAndNotMobile() ? 15 : 13,
  tableFontSize: isWebAndNotMobile() ? 14 : 11,
  lineHeightBoxText: isWebAndNotMobile() ? 24 : 22,
  liMarginBottom: 12,
  decisionText: 13,
  decisionTextLineHeight: 17,
  selectCategoryText: 13,
  selectCategoryTextLineHeight: 17,
  containerWidth: 800,
  defaultContainerPadding: isWebAndNotMobile()
    ? 48
    : isLargerDevice()
    ? 24
    : 16,
  defaultBoxPadding: isWebAndNotMobile() ? 15 : 12,
  webHeaderHeight: 120,
  sectionHeader: 18,
  sectionHeaderFont: "bold",
};
