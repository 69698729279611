import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import { useEffect, useState } from "react";
import {
  LayoutAnimation,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { batch, useDispatch } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ChecklistComponent } from "../../components/ChecklistComponent";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import WarningModal from "../../components/modals/WarningModal";
import staticColors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { setResultingCalculation } from "../../functions/calculator/actions";
import {
  setCurrentHighlightSection,
  setIsExporting,
  setResultPageHeaderContent,
  setShowTitle,
  shouldShowSources,
} from "../../functions/current-result/actions";
import getColorScheme from "../../hooks/useColorScheme";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";
import ResultBoxes from "../../components/result/ResultBoxComponent";

export const isSublistChecked = (sublist, checkedItems) => {
  if (!sublist) return false; // Ensures there's a sublist to check

  return sublist.every((subItem) => {
    // First, check if the subItem itself is optional; if so, it's considered "checked"
    if (subItem.isOptional) {
      return true;
    }
    // Check if the subItem is checked
    const isCurrentItemChecked = checkedItems.has(subItem.id);

    // If the subItem has further subItems (i.e., a nested sublist), check them recursively
    if (subItem.listElements) {
      return isSublistChecked(subItem.listElements, checkedItems);
    }

    // If there are no further nested subItems, return the check status of the current item
    return isCurrentItemChecked;
  });
};

const Checklist = ({ route }) => {
  const pageContent = route.params.pageContent;
  const remoteSetCheckedItems = route.params?.setCheckedItems;
  const remoteSetNote = route.params?.setNote;
  const initialNoteValue = route.params?.note ?? "";
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [checkedItems, setCheckedItems] = useState(
    route.params?.checkedItems ?? new Set()
  );

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (remoteSetCheckedItems) remoteSetCheckedItems(checkedItems);
  }, [checkedItems]);

  const listLength = pageContent?.checklistItems.filter(
    (item) => !item.isOptional && item.typeId === "checklistListElement"
  ).length;

  const completed = pageContent?.checklistItems.every((item) => {
    if (item.typeId !== "checklistListElement") return true;
    if (item.isOptional) {
      return true;
    }
    if (item.listElements) {
      return isSublistChecked(item.listElements, checkedItems);
    }
    return checkedItems.has(item.id);
  });

  const colorScheme = getColorScheme();

  function storeActionsOnLeave() {
    dispatch(setShowTitle(false));
    batch(() => {
      dispatch(shouldShowSources(false));
      dispatch(setResultingCalculation(null));
      dispatch(setResultPageHeaderContent(null));
      dispatch(setCurrentHighlightSection(null));
      dispatch(setIsExporting(false));
    });
    return "success";
  }

  const toggleWarningModal = () => {
    setWarningModalVisible((m) => !m);
  };

  function removeAnimation() {
    LayoutAnimation.configureNext({
      duration: 120, // or another very small number
      create: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
  }

  useEffect(() => {
    dispatch(setShowTitle(false));

    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "checklist_screen_opened",
        "ChecklistPage",
        pageContent?.title,
        pageContent?._id
      );
    });

    const _unsubscribe = navigation.addListener("beforeRemove", async () => {
      removeAnimation();
      storeActionsOnLeave();
    });
    return () => {
      unsubscribe();
      _unsubscribe();
    };
  }, []);

  if (pageContent === undefined) return null;

  return (
    <>
      <WarningModal open={warningModalVisible} onClose={toggleWarningModal} />
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: staticColors[colorScheme].background,
        }}
      >
        <ScrollView
          style={{
            flex: 1,
            padding: Sizes.defaultContainerPadding,

            backgroundColor: staticColors[colorScheme].background,
          }}
          contentContainerStyle={{
            paddingBottom: 50,
          }}
        >
          <ResultPageUserInteractionsHeader
            setWarningModalVisible={setWarningModalVisible}
            pageContent={pageContent}
          />
          {pageContent?.nonChecklistContent && (
            <ResultBoxes
              pageContent={{ boxes: pageContent?.nonChecklistContent }}
            />
          )}
          <ChecklistComponent
            {...{ checkedItems, setCheckedItems, pageContent }}
          />
          <Text
            style={{
              marginTop: 20,
              fontWeight: "600",
              fontSize: Sizes.boxText,
            }}
          >
            Bemerkungen
          </Text>
          <TextInput
            defaultValue={initialNoteValue}
            onChangeText={(text) => {
              remoteSetNote && remoteSetNote(text);
            }}
            placeholder="Füge hier weitere Notizen hinzu..."
            multiline
            placeholderTextColor={staticColors[colorScheme].placeholder}
            style={{
              color: staticColors[colorScheme].text,
              backgroundColor: staticColors[colorScheme].foreground,
              borderRadius: 8,
              padding: 12,
              paddingTop: 12,
              height: 100,
              marginTop: 12,
            }}
          />
        </ScrollView>
        <ChecklistInterpretation
          checklistItems={pageContent?.checklistItems}
          isRemoteScreen={remoteSetCheckedItems}
          style={{ padding: 16 }}
          {...{ completed, checkedItems, pageContent, listLength }}
        />
        <View
          style={{
            height: 100,
            position: "absolute",
            bottom: -0,
            left: 0,
            right: 0,
            backgroundColor: chroma(
              completed
                ? staticColors[colorScheme].green
                : staticColors[colorScheme].red
            )
              .darken(-0.9)
              .hex(),
            zIndex: -10,
            width: "120%",
          }}
        ></View>
      </SafeAreaView>
    </>
  );
};

export const getCheckedMainItems = (checklistItems, checkedItems) => {
  if (!checklistItems) return new Set();
  return new Set(
    checklistItems
      .filter((item) => !item.isOptional)
      .filter((item) => {
        if (item.listElements) {
          return isSublistChecked(item.listElements, checkedItems);
        }
        return checkedItems.has(item.id);
      })
      .map((item) => item.id)
  );
};

export const ChecklistInterpretation = ({
  completed,
  checkedItems,
  style,
  listLength,
  isRemoteScreen = false,
  checklistItems,
}) => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  const filteredChecklistItems = checklistItems.filter(
    (item) => item.typeId === "checklistListElement"
  );

  return (
    <View
      style={{
        padding: 12,
        alignItems: "center",
        backgroundColor: getChecklistColor(completed, colorScheme),
        ...style,
      }}
    >
      <Text style={{ fontSize: Sizes.boxText, fontWeight: "bold" }}>{`${
        getCheckedMainItems(filteredChecklistItems, checkedItems).size
      } von ${listLength} Punkten erledigt`}</Text>
      {isRemoteScreen && (
        <DefaultButton
          title="Ergebnis übertragen"
          action={() => {
            navigation.goBack();
          }}
          type={ButtonTypes.Primary}
          style={{ marginHorizontal: 20, marginTop: 12 }}
        />
      )}
    </View>
  );
};

export function getChecklistColor(completed, colorScheme) {
  return chroma(
    completed ? staticColors[colorScheme].green : staticColors[colorScheme].red
  )
    .darken(-0.9)
    .hex();
}

export default Checklist;
