import { ResultBox } from "../../../../api/content/load-all/types";
import { setCurrentUnit } from "../../../../functions/calculator/actions";
import { store } from "../../../../functions/store";
import { CalculatorBox } from "./CalculatorBox";

export default function getCalculatorBox(box: ResultBox, collapsed = false) {
  console.log("box.unit", box.unit);

  store.dispatch(setCurrentUnit(box.unit!));
  return (
    <CalculatorBox
      key={box.id}
      collapsed={collapsed}
      title={box.fieldTitle}
      unit={box.unit}
    />
  );
}
