import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { Platform, View } from "react-native";
import { SceneMap, TabView } from "react-native-tab-view";
import { useDispatch } from "react-redux";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { setCalculationVar } from "../../../functions/calculator/actions";
import { clearCurrentFilters } from "../../../functions/current-result/actions";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import { isWebAndNotMobile } from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";
import { renderTabBar } from "../Search/SearchScreen";
import { SearchBar } from "../components/SearchBar";
import FavoritesComponent from "./components/FavoritesComponent";
import HistoryComponent from "./components/HistoryComponent";
import UserCasesComponent from "./components/UserCasesComponent";
import handleOneTimeAction from "../../../functions/one-time-action-handler";
import ExportListComponent from "./components/ExportListComponent";
import i18n from "../../../../localization/i18n";

export default function PersonalContentScreen({ route }) {
  const selectedIndex = route?.params?.selection ?? undefined;

  useEffect(() => {
    handleOneTimeAction("did_open_personal_screen");
  }, []);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "personal_screen_opened",
        "PersonalContentScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("personal")));
      dispatch(clearCurrentFilters());

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        paddingHorizontal: Sizes.defaultContainerPadding,
        flex: 1,
        marginTop: Platform.OS === "web" ? 20 : 0,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          backgroundColor: Colors[colorScheme].background,
          paddingHorizontal: Sizes.defaultContainerPadding,
          marginHorizontal: -Sizes.defaultContainerPadding,
        }}
      >
        <SearchBar
          collapsed
          placeholder={i18n.t("personal_search_placeholder")}
          {...{ query, setQuery, selected, setSelected, filters, setFilters }}
          style={{ marginBottom: 8 }}
        />
      </View>
      <TabbedView selectedIndex={selectedIndex} query={query} />
    </View>
  );
}

const TabbedView = ({ selectedIndex, query }) => {
  const [favoritesData, setFavoritesData] = useState<any>([]);
  const [caseData, setCaseData] = useState<any>([]);

  const [editModeCaseActive, setEditModeCaseActive] = useState(false);
  const [editModeExportActive, setEditModeExportActive] = useState(false);

  const [exportData, setExportData] = useState<any>([]);

  const renderScene = useMemo(
    () =>
      SceneMap({
        first: () => (
          <FavoritesComponent
            data={favoritesData}
            setData={setFavoritesData}
            query={query}
          />
        ),
        second: () => (
          <UserCasesComponent
            data={caseData}
            setData={setCaseData}
            query={query}
            editModeActive={editModeCaseActive}
            setEditModeActive={setEditModeCaseActive}
          />
        ),
        third: () => <HistoryComponent />,
        fourth: () => (
          <ExportListComponent
            data={exportData}
            setData={setExportData}
            query={query}
            editModeActive={editModeExportActive}
            setEditModeActive={setEditModeExportActive}
          />
        ),
      }),
    [
      query,
      favoritesData,
      setFavoritesData,
      caseData,
      setCaseData,
      editModeCaseActive,
      setEditModeCaseActive,
      exportData,
      setExportData,
      setEditModeExportActive,
      editModeExportActive,
    ]
  );

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (selectedIndex !== undefined)
      setTimeout(() => setIndex(selectedIndex), 250);
  }, [selectedIndex]);

  const [routes] = useState([
    { key: "first", title: i18n.t("favorites") },
    { key: "second", title: i18n.t("cases") },
    { key: "third", title: i18n.t("history") },
    { key: "fourth", title: i18n.t("export") },
  ]);
  const colorScheme = getColorScheme();

  const handleIndexChange = (newIndex) => {
    // Logging the tab switch event
    const newRoute = routes[newIndex];
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tab_switch",
      "TabbedView",
      "User Switched Tab",
      {
        newTabIndex: newIndex,
        newTabKey: newRoute.key,
        newTabTitle: newRoute.title,
      }
    );

    // Updating the index to reflect the change
    setIndex(newIndex);
  };

  return (
    <View
      style={{
        flex: 1,
        marginTop: isWebAndNotMobile() ? 20 : 0,
        marginHorizontal: -Sizes.defaultContainerPadding,
      }}
    >
      <TabView
        renderTabBar={(props) => renderTabBar(props, colorScheme)}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={handleIndexChange}
      />
    </View>
  );
};
