import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import logo from "../../../assets/images/logo.png";
import logo_white from "../../../assets/images/logo_weiß.png";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/NotificationComponent";

import i18n from "../../../localization/i18n";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import { sendForgotPwMail } from "../../functions/user/functions";
import { validateEmail } from "../../functions/user/functions/registry";
import getColorScheme from "../../hooks/useColorScheme";

export function ForgotPasswordScreen({ navigation }) {
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState("");

  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 20,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <KeyboardAwareScrollView
        style={{ width: "100%" }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            width: "100%",
            justifyContent: Platform.OS === "web" ? "center" : "flex-start",
            alignItems: "center",
          }}
        >
          <Image
            source={colorScheme === "light" ? logo : logo_white}
            style={{
              width: "90%",
              height: 280,
              resizeMode: "contain",
              ...Platform.select({
                web: { height: "20vh" as any, marginBottom: 50 },
              }),
            }}
          />

          <Text style={styles.welcomeText}>
            {i18n.t("reset_password.title")}
          </Text>
          <Text style={styles.greetingText}>
            {i18n.t("reset_password.greeting")}
          </Text>

          <TextInput
            placeholder={i18n.t("reset_password.placeholder")}
            textContentType="emailAddress"
            placeholderTextColor={Colors[colorScheme].placeholder}
            keyboardType="email-address"
            style={styles.textField}
            onChangeText={(t) => setMail(t)}
          />
        </View>
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              width: "85%",
              maxWidth: Sizes.containerWidth,
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <DefaultButton
              type={ButtonTypes.Primary}
              title={i18n.t("reset_password.reset")}
              enabled={validateEmail(mail)}
              loading={loading}
              icon={<Ionicons name="arrow-forward" size={24} />}
              action={async () => {
                setLoading(true);
                const success = await sendForgotPwMail(mail);
                setLoading(false);
                if (success) {
                  showInlineNotification({
                    text: i18n.t("reset_password.success"),
                    type: InlineNotificationType.SUCCESS,
                    direction: InlineNotificationDirection.FROM_BOTTOM,
                  });
                } else {
                  showInlineNotification({
                    text: i18n.t("reset_password.failure"),
                    type: InlineNotificationType.ERROR,
                    direction: InlineNotificationDirection.FROM_BOTTOM,
                  });
                }
              }}
            />
            <DefaultButton
              style={{ marginTop: 20 }}
              title={i18n.t("reset_password.login")}
              type={ButtonTypes.Outline}
              action={() => navigation.navigate("LoginScreen")}
              // icon={<Feather name="arrow-left" size={20} />}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    welcomeText: {
      fontSize: 20,
      color: Colors[scheme].text,
      fontWeight: "700",
      textTransform: "uppercase",
    },
    greetingText: {
      marginTop: 3,
      fontSize: 14,
      fontWeight: "700",
      color: Colors[scheme].gray,
      marginBottom: 30,
    },
    textField: {
      backgroundColor: Colors[scheme].foreground,
      color: Colors[scheme].text,
      borderRadius: 12,
      width: "85%",
      height: 50,
      padding: 12,
      marginBottom: 12,
      maxWidth: Sizes.containerWidth,
    },
  });

  return styles;
};
