import Colors from "../constants/static-colors";
import { Text, View } from "react-native";

export default function BadgeComponent({
  text,
  condition,
  size,
  style = {},
}: {
  text: string;
  condition?: boolean;
  size?: number;
  style?: any;
}) {
  if (!size) size = 18;
  if (condition) return null;
  return (
    <View
      style={{
        minWidth: size,
        height: size,
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRadius: size / 2,
        backgroundColor: Colors["light"].red,
        position: "absolute",
        top: -size / 2,
        right: -size / 2,
        ...style,
      }}
    >
      <Text
        style={{
          color: "white",
          fontSize: (size / 3) * 2,
          fontStyle: "normal",
          fontWeight: "bold",
        }}
      >
        {text}
      </Text>
    </View>
  );
}
