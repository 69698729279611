import Colors from "../../constants/static-colors";
import { store } from "../../functions/store";
import getColorScheme from "../../hooks/useColorScheme";

export default function getLinkParsedContent(content: string) {
  const colorScheme = getColorScheme();

  const resultingCalculation =
    store.getState().calculatorReducer.resultingCalculation;

  if (!content) return "";

  content = content.replace(
    /\$(LINK|LINK_INFO)\{([^}]+)\}/g,
    (match, type, link) => {
      let target;
      const pageIdentifier = type === "LINK_INFO" ? "inline_info:" : "inline:";

      if (!link) link = "";
      link = link.replace(/,.*/, (match) => {
        target = match.slice(1).trim();
        return "";
      });
      target = target.replace(/<[^>]*>/g, "");

      return (
        "<a style='color: " +
        Colors[colorScheme].text +
        "; text-decoration: none' href='" +
        pageIdentifier +
        target +
        "'><span style='text-decoration: underline; text-decoration-color:" +
        Colors[colorScheme].text +
        ";'>" +
        link +
        "</span> <span style='display: inline-block; font-weight: 800; color: " +
        Colors[colorScheme].accentDark +
        "'>ⓘ</span></a>" +
        (resultingCalculation ? resultingCalculation : "")
      );
    }
  );

  return content;
}
