export const HEALTHCARE_JOBS_FOR_STUDENTS = [
  "Medizinstudent/in",
  "Atemtherapeut",
  "Altenpfleger/in",
  "Physician Assistant",
  "Gesundheits- und Kinderkrankenpfleger/in",
  "Gesundheits- und Krankenpfleger/in",
  "Hebamme",
  "MFA (Medizinische/r Fachangestellte/r)",
  "MTA für Laboratoriumsanalytik (Medizinisch-technische/r Assistent/in)",
  "MTA für Funktionsdiagnostik",
  "MTA Radiologie",
  "Notfallsanitäter",
  "OTA (Operationstechnische/r Assistent/in)",
  "ATA (Anästhesietechnische/r Assistent/in)",
  "Pflegefachkraft (generalisierte Pflege) (seit 2020)",
  "Physician Assistant (Studium)",
  "Rettungssanitäter",
  "Logopäde/Logopädin (Speech Therapist)",
  "Physiotherapeut/in",
  "Ergotherapeut/in (Occupational Therapist)",
  "Diätassistent/in",
  "Pharmazeutisch-technische/r Assistent/in (PTA)",
  "Sozialarbeiter/in im Gesundheitswesen",
  "Psychologische/r Psychotherapeut/in",
  "Orthoptist/in",
  "Podologe/Podologin (Podiatrist)",
  "Gesundheitsberater/in",
  "Krankenhausmanager/in",
  "Zahntechniker/in (Dental Technician)",
  "Gesundheitsökonom/in",
  "Audiologe/Audiologin (Audiologist)",
  "Heilpädagoge/Heilpädagogin",
  "Rehabilitationsberater/in",
  "Diabetesberater/in",
  "Gesundheitswissenschaftler/in",
];
