import { default as LottieView } from "lottie-react-native";
import { useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Colors from "../constants/static-colors";

import { Ionicons } from "@expo/vector-icons";
import checkmark from "../../assets/animations/checkmark.json";
import error from "../../assets/animations/error.json";
import { Sizes } from "../constants/static-sizes";
import getColorScheme from "../hooks/useColorScheme";
import DefaultGradientBackground from "./DefaultGradientBackground";

interface InlineNotificationProps {
  text: any;
  type: InlineNotificationType;
  hasIcon?: boolean;
  direction?: InlineNotificationDirection;
  duration?: number;
  shouldShow: boolean;
  closeAction?: any;
  onPress?: any;
}

export enum InlineNotificationDirection {
  FROM_TOP,
  FROM_BOTTOM,
}

export enum InlineNotificationType {
  ERROR,
  SUCCESS,
  NEUTRAL,
  INFO,
}

export const InlineNotificationComponent = (props: InlineNotificationProps) => {
  let { hasIcon, direction, duration } = props;
  const { closeAction, text, type, shouldShow, onPress } = props;

  const colorScheme = getColorScheme();
  const lottieRef = useRef<any>();
  const move = useRef(new Animated.Value(-200)).current;

  if (hasIcon === undefined) {
    hasIcon = true;
  }
  if (!direction) {
    direction = InlineNotificationDirection.FROM_TOP;
  }
  if (!duration) {
    duration = 2000;
  }

  let backgroundColor = "";
  let animationSource: any;
  let height = 90;
  const isTip = type === InlineNotificationType.INFO;

  switch (type) {
    case InlineNotificationType.ERROR:
      backgroundColor = Colors["light"].red;
      height = 70;
      animationSource = error;
      break;
    case InlineNotificationType.SUCCESS:
      backgroundColor = Colors["light"].accentDark;
      animationSource = checkmark;
      break;
    case InlineNotificationType.NEUTRAL:
    case InlineNotificationType.INFO:
      backgroundColor = Colors["light"].foreground;
      animationSource = checkmark;
      break;
  }

  let moveProp: any = { top: move };
  if (direction === InlineNotificationDirection.FROM_BOTTOM) {
    moveProp = { bottom: move };
  }

  const moveIn = () => {
    Animated.timing(move, {
      toValue: 60,
      duration: 750,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      lottieRef.current?.play();

      if (Platform.OS === "web" || !hasIcon) {
        setTimeout(() => {
          moveOut();
        }, duration);
      }
    });
  };
  const moveOut = () => {
    Animated.timing(move, {
      toValue: -200,
      duration: 1000,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start();
    if (closeAction) closeAction();
  };

  useEffect(() => {
    moveIn();
  }, [shouldShow]);

  if (type === undefined) return null;
  return (
    <Animated.ScrollView
      onScrollEndDrag={(e) => {
        if (
          e.nativeEvent.contentOffset.y < -50 ||
          e.nativeEvent.contentOffset.y > -50
        )
          moveOut();
      }}
      style={{
        position: "absolute",
        ...moveProp,
        width: "100%",
        zIndex: 10000,
        overflow: "hidden",
        //opacity: opacity,
      }}
    >
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          onPress?.();
          moveOut();
        }}
        disabled={Platform.OS === "ios" && !onPress}
        style={{
          alignSelf: "stretch",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          elevation: 3,
        }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: Sizes.containerWidth,
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              alignSelf: "stretch",
              flex: 1,
              margin: 12,
              shadowColor: Colors["light"].text,
              elevation: 2,
              shadowOffset: { width: 2, height: 2 },
              shadowRadius: 5,
              shadowOpacity: 0.2,
              borderRadius: 12,
              backgroundColor: backgroundColor,
              padding: 24,
            }}
          >
            {type === InlineNotificationType.SUCCESS && (
              <DefaultGradientBackground
                style={{ overflow: "hidden", borderRadius: 12 }}
              />
            )}
            <Text
              style={{
                color: "black",
                flex: 1,
                marginRight: 42,
              }}
            >
              {text}
            </Text>

            {hasIcon ? (
              <View
                style={{
                  margin: -30,
                  marginVertical: -60,
                  alignItems: "center",
                  justifyContent: "center",
                  width: 72,
                }}
              >
                {isTip ? (
                  <Ionicons
                    name="bulb-sharp"
                    size={25}
                    color={Colors[colorScheme].text}
                    style={{
                      marginLeft: 12,
                      marginRight: 30,
                    }}
                  />
                ) : (
                  <LottieView
                    ref={lottieRef}
                    style={{
                      height: height,
                    }}
                    source={animationSource}
                    autoPlay={false}
                    loop={false}
                    onAnimationFinish={() => {
                      setTimeout(() => {
                        moveOut();
                      }, duration);
                    }}
                  />
                )}
              </View>
            ) : null}
          </View>
        </View>
      </TouchableOpacity>
    </Animated.ScrollView>
  );
};
