import Ionicons from "@expo/vector-icons/Ionicons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import { Text, TouchableOpacity } from "react-native";

import {
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  useWindowDimensions,
  View,
} from "react-native";
import AnimatedNumbers from "react-native-animated-numbers";
import { LineChart } from "react-native-chart-kit";
import DeviceInfo from "react-native-device-info";
import Carousel from "react-native-snap-carousel";
import { useDispatch, useSelector } from "react-redux";
import loadAllData from "../../api/content/load-all/load-all-content";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";

import { CaseSection } from "../../components/home-tab/CaseSection";

import { ShortcutSection } from "../../components/home-tab/ShortcutsSection";
import Colors from "../../constants/static-colors";

import handleOneTimeAction from "../../functions/one-time-action-handler";
import {
  addToUserCreationObject,
  selectCurrentUser,
} from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";

import { Feather } from "@expo/vector-icons";
import i18n from "../../../localization/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { posthog } from "../../api/analytics/posthog";
import DefaultGradientBackground from "../../components/DefaultGradientBackground";
import FullScreenProgressBar from "../../components/SkeletonLoadingAnimation";
import { RecommendationSection } from "../../components/home-tab/RecommendationSection";

import {
  useFeatureFlag,
  useFeatureFlagWithPayload,
} from "posthog-react-native";
import semver from "semver";
import apiCall from "../../api/api-call";
import { getPagesToIDs } from "../../api/content/data-helpers";
import SectionHeaderText from "../../components/SectionHeaderText";
import {
  ArrowBackWeb,
  ArrowForwardWeb,
  Dots,
} from "../../components/TutorialSliderComponent";
import { CustomCMSSection } from "../../components/home-tab/CustomCMSSection";
import { RecentlyUsedSection } from "../../components/home-tab/RecentlyUsedSection";
import UserSourceModal from "../../components/modals/UserSourceModal";
import SearchResults from "../../components/search-tab/SearchResults";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import { setResultPageCaseContent } from "../../functions/current-result/actions";
import { getResultPagesCount } from "../../functions/current-result/helpers";
import {
  selectAllContent,
  selectIsLoading,
  selectVersionRequirements,
} from "../../functions/data/actions";
import { selectEnterprise } from "../../functions/enterprise/actions";
import {
  selectNavigationIntent,
  setCurrentTabSelection,
  setNavigationIntent,
  setShowNavbar,
} from "../../functions/navigation/actions";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../functions/user-interaction-handler";
import { addAdditionalUserInformation } from "../../functions/user/functions/registry";
import isLargerDevice from "../../hooks/isLargerDevice";
import useGreeting from "../../hooks/useGreeting";
import { showMissingProfileInformation } from "../more/MoreScreen";
import { getDisplayName } from "../more/profile/UserComponent";
import { SearchBar } from "./components/SearchBar";

function normalizeVersion(version) {
  const segments = version.split(".");
  while (segments.length < 3) {
    segments.push("0");
  }
  return segments.join(".");
}

function checkCurrentVersion(data) {
  if (!data || Platform.OS === "web") return false;
  const currentVersion = DeviceInfo.getVersion();

  const requiredVersion = data?.requiredVersion;

  // Normalize both versions to the same format
  const normalizedCurrentVersion = normalizeVersion(currentVersion);
  const normalizedRequiredVersion = normalizeVersion(requiredVersion);

  // Check if the current version is greater than or equal to the required version
  const isOutdated = semver.gte(
    normalizedCurrentVersion,
    normalizedRequiredVersion
  );

  return !isOutdated;
}

export default function HomeScreen({ route }) {
  const [pageId, setPageId] = useState("");

  const maintenanceWork = useFeatureFlagWithPayload("maintenance");
  const selectedEnterprise = useSelector(selectEnterprise);
  const allData = useSelector(selectAllContent);
  const versionRequirements = useSelector(selectVersionRequirements);

  const showLicense =
    useFeatureFlag("show-license-information") && !selectedEnterprise;

  useEffect(() => {
    const isOutdated = checkCurrentVersion(versionRequirements?.system);

    if (isOutdated) {
      navigation.navigate("UpdateEnforcerScreen");
    }
  }, [versionRequirements]);

  useEffect(() => {
    if (selectedEnterprise?.default_language !== "en") {
      i18n.locale = "de";
    }
  }, [selectedEnterprise]);

  useEffect(() => {
    if (route.params?.pageId) {
      setPageId("");
      setTimeout(() => {
        setPageId(route.params?.pageId);
      }, 250);
    }
    if (route.params?.caseData) {
      const caseData = JSON.parse(route.params?.caseData);
      dispatch(setResultPageCaseContent(caseData));
    }
  }, [route]);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [tippsCollapsed, setTippsCollapsed] = useState(true);
  const currentUser = useSelector(selectCurrentUser);

  const navigationIntent = useSelector(selectNavigationIntent);

  const homeScreenContent = allData?.homeScreenContent?.content;

  const loading = useSelector(selectIsLoading);

  const [userSourceModalVisible, setUserSourceModalVisible] = useState(false);

  async function loadTippsSettings() {
    const dataString = getValueFromInteractions("hide_tipps");
    if (dataString) {
      const data = JSON.parse(dataString) as boolean;
      if (data) {
        setTippsCollapsed(true);
        return;
      }
    }
    setTippsCollapsed(false);
  }

  useEffect(() => {
    setTimeout(() => {
      if (showMissingProfileInformation(currentUser)) {
        navigation.navigate("EnterMissingInformationModalScreen");
        dispatch(addToUserCreationObject(currentUser));
      }
    }, 1500);
  }, [currentUser]);

  const navigateDebounced = useCallback(
    (_navigationIntent) => {
      navigateToNextCMSPage(_navigationIntent, navigation);
    },
    [navigation, dispatch]
  );

  // Memoize the debounced version of navigate to prevent it from being recreated on every render
  const debouncedNavigate = useCallback(debounce(navigateDebounced, 150), [
    navigateDebounced,
  ]);

  const navigateToPage = useCallback(
    (pageId) => {
      dispatch(setNavigationIntent(pageId));

      if (
        !currentUser?.firstname &&
        currentUser.user_type !== "enterprise-lite"
      ) {
        navigation.reset({
          index: 0,
          routes: [{ name: "LaunchScreen" }],
        });
        return;
      }

      setPageId("");
    },
    [navigation, currentUser, dispatch]
  );

  const debouncedNavigateToPage = useCallback(debounce(navigateToPage, 100), [
    navigateToPage,
  ]);

  useEffect(() => {
    if (!pageId) {
      if (
        !currentUser?.firstname &&
        currentUser.user_type !== "enterprise-lite"
      ) {
        navigation.reset({
          index: 0,
          routes: [{ name: "LaunchScreen" }],
        });
        return;
      } else return;
    }

    // Call the debounced function
    debouncedNavigateToPage(pageId);
    dispatch(setNavigationIntent(""));

    // Cancel the debounce on cleanup
    return () => debouncedNavigateToPage.cancel();
  }, [pageId, route]);

  useEffect(() => {
    // Only attempt to navigate if there's a valid navigationIntent
    if (navigationIntent && pageId === "") {
      // Now, debouncedNavigate is stable and won't be recreated unless `navigate` changes
      debouncedNavigate(navigationIntent);
      dispatch(setNavigationIntent(""));

      // Cleanup function to cancel the debounce if the component unmounts or navigate changes
      //return () => debouncedNavigate.cancel();
    }
  }, [navigationIntent]);

  useFocusEffect(() => {
    dispatch(setShowNavbar(true));
  });

  async function checkPushNotificationSubscription() {
    if (Platform.OS === "web") return;

    async function getPreviousState(key) {
      const value = (await AsyncStorage.getItem(key)) ?? "true";
      return value === "true";
    }

    const prevUpdatesEnabled = await getPreviousState("updatesEnabled");
    const prevMotivationEnabled = await getPreviousState("motivationEnabled");
    const previousPreferences: string[] = [];
    if (prevUpdatesEnabled) previousPreferences.push("UPDATES");
    if (prevMotivationEnabled) previousPreferences.push("MOTIVATIONAL");

    if (
      !currentUser?.pn_preference ||
      JSON.stringify(
        currentUser?.pn_preference.filter((item) => item !== "PERSONALIZED")
      ) !== JSON.stringify(previousPreferences)
    ) {
      if (!selectedEnterprise && currentUser?.firstname)
        navigation.navigate("PushNotificationScreen", {
          isFromSettings: true,
          preventClosing: true,
        });
    }
  }

  async function load() {
    await loadAllData(selectedEnterprise?.internal_customer_id);
  }

  async function openingActions() {
    checkPushNotificationSubscription();
    loadTippsSettings();
  }

  useEffect(() => {
    load();
    openingActions();
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "home_screen_opened",
        "HomeScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection("Home"));
      (async () => {
        if (
          getResultPagesCount() > 3 &&
          (await handleOneTimeAction("show_user_source"))
        ) {
          setUserSourceModalVisible(true);
        }
      })();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState(false);

  const [refreshing, setRefreshing] = useState(false);
  const [reloading, setReloading] = useState(false);

  const hasHistory = currentUser?.result_page_history?.history?.length > 0;

  const greeting = useGreeting();

  const onRefresh = async () => {
    setRefreshing(true);

    setReloading(true);
    await loadAllData(selectedEnterprise?.internal_customer_id);
    setReloading(false);
    setTimeout(() => {
      setRefreshing(false);
    }, 1250);
  };

  if (loading) {
    return (
      <View
        style={{
          backgroundColor: Colors[colorScheme].background,
          flex: 1,
        }}
      >
        <FullScreenProgressBar />
      </View>
    );
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        marginLeft: 0,
      }}
    >
      <UserSourceModal
        open={userSourceModalVisible}
        onClose={() => setUserSourceModalVisible(false)}
      />
      {refreshing && Platform.OS !== "android" && (
        <View
          style={{
            position: "absolute",
            top: 120,
            opacity: 0.5,
            zIndex: 10,
            alignSelf: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {reloading ? (
            <ActivityIndicator />
          ) : (
            <Text
              style={{
                color: Colors[colorScheme].text,
                fontSize: 14,
                marginRight: 8,
              }}
            >
              Gerade aktualisiert
            </Text>
          )}
          {!reloading && (
            <Ionicons
              name="checkmark-circle"
              size={20}
              color={Colors[colorScheme].text}
            />
          )}
        </View>
      )}
      <View
        style={{
          paddingHorizontal: Sizes.defaultContainerPadding,
          backgroundColor: Colors[colorScheme].background,
          shadowColor: Colors[colorScheme].text,
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.08,
          shadowRadius: 4,
          zIndex: 10,
          elevation: 2,
          gap: 12,

          alignItems: "flex-start",
          paddingBottom: 16,
        }}
      >
        <Text
          style={{
            fontSize: Sizes.sectionHeader,
            alignSelf: "stretch",
            fontWeight: "500",
          }}
        >
          {greeting},{" "}
          <Text style={{ fontWeight: "bold" }}>
            {getDisplayName(currentUser, selectedEnterprise)}
          </Text>
          !
        </Text>
        <SearchBar
          collapsed
          placeholder={i18n.t("default_search_placeholder")}
          origin={"Home"}
          {...{ query, setQuery, selected, setSelected, filters, setFilters }}
          style={{ width: "100%", marginTop: Platform.OS === "web" ? 12 : 0 }}
        />
      </View>
      {maintenanceWork?.[1] &&
        getValueFromInteractions("hide_maintenance_info") !== "true" && (
          <MaintenanceInfoComponent info={maintenanceWork} />
        )}
      {!selected ? (
        <ScrollView
          nestedScrollEnabled
          style={{
            //overflow: Platform.OS !== "web" ? "visible" : "hidden",
            flex: 1,
          }}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              tintColor="transparent"
              colors={["transparent"]}
              style={{ backgroundColor: Colors[colorScheme].transparent }}
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          onScrollBeginDrag={() => {
            if (refreshing) {
              setRefreshing(false);
            }
          }}
          keyboardShouldPersistTaps="handled"
          keyboardDismissMode="on-drag"
          contentContainerStyle={{
            //overflow: Platform.OS !== "web" ? "visible" : "auto",
            padding: Sizes.defaultContainerPadding,
            flexGrow: 1,
            paddingBottom: 120,
            gap: isLargerDevice() ? 48 : 38,
            paddingTop: isLargerDevice() ? 48 : 24,
          }}
        >
          <>
            <SocialProofSlider {...{ currentUser, selectedEnterprise }} />
            <HotFeaturesComponent />
          </>

          {/* <PartnerSection /> */}
          {hasHistory ? <RecentlyUsedSection /> : <RecommendationSection />}
          {/* <SearchBar style={{ marginBottom: 32 }} usable={false} /> */}

          <ShortcutSection />
          {showLicense && <LicenseHintCard />}

          <CaseSection />
          {homeScreenContent?.showCustomSectionOne &&
            homeScreenContent.customSectionOneHeader && (
              <CustomCMSSection
                header={homeScreenContent.customSectionOneHeader}
                ids={homeScreenContent.customSectionOneIds}
              />
            )}

          <AllContentCard />

          <TippsContainer
            collapsed={tippsCollapsed}
            setTippsCollapsed={setTippsCollapsed}
          />

          {hasHistory ? <RecommendationSection /> : <RecentlyUsedSection />}
        </ScrollView>
      ) : (
        <View
          style={{
            padding: Sizes.defaultContainerPadding,
            flex: 1,
            alignSelf: "center",
            width: "100%",
          }}
        >
          <SearchResults {...{ query, setQuery, filters }} />
        </View>
      )}
    </SafeAreaView>
  );
}

const HotFeaturesComponent = () => {
  const navigation = useNavigation();

  const HotFeatureItem = ({ icon, text, tIndex }) => {
    const colorScheme = getColorScheme();

    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("PersonalContentScreen", { selection: tIndex });
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "hot_feature_clicked",
            text,
            "Hot Feature Clicked"
          );
        }}
        style={{
          gap: 8,
          marginTop: 12,
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: 16,
          paddingVertical: 12,
          borderRadius: 12,
          backgroundColor: Colors[colorScheme].foreground,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: "#ddddddcc",
            padding: 10,
          }}
        >
          <Feather name={icon} size={20} color="black" />
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText + 1,
            fontWeight: "500",
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <SectionHeaderText>{i18n.t("hot_features")}</SectionHeaderText>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 20,
        }}
      >
        <HotFeatureItem icon={"heart"} text={i18n.t("favorites")} tIndex={0} />
        <HotFeatureItem icon={"save"} text={i18n.t("cases")} tIndex={1} />
        <HotFeatureItem icon="rotate-cw" text={i18n.t("history")} tIndex={2} />
      </View>
    </View>
  );
};

const SocialProofSlider = ({ currentUser }) => {
  const sliderRef = useRef<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowDimensions();

  const renderItem = ({ item }) => {
    switch (item.type) {
      case "kpi":
        return <KPISlide />;
      case "testimonial":
        return (
          <TestimonialSlide
            buttonTitle={item.buttonTitle}
            link={item.link}
            image={item.image}
            text={item.text}
            currentUser={currentUser}
          />
        );
      default:
        return <KPISlide />;
    }
  };

  return (
    <View style={{ width: "100%" }}>
      <Carousel
        vertical={false}
        ref={sliderRef}
        data={[
          { type: "kpi" },
          {
            type: "testimonial",
            text: i18n.t("quote_stefan"),
            buttonTitle: i18n.t("add_wishes"),
            link: "https://form.typeform.com/to/frB6E6vN",
            image: require("../../../assets/images/stefan.png"),
          },
          {
            type: "testimonial",
            text: i18n.t("quote_leon"),
            buttonTitle: i18n.t("discover_now"),
            link: "https://mediceo.com/kliniken",
            image: require("../../../assets/images/leon.png"),
          },
        ]}
        renderItem={renderItem}
        sliderWidth={width - Sizes.defaultContainerPadding * 2}
        enableSnap
        layout="default"
        itemWidth={width - Sizes.defaultContainerPadding * 2}
        onScrollIndexChanged={(index: number) => {
          configureAnimations();
          setCurrentIndex(index);
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "slide_change",
            "SocialProofSlider",
            "SlideChanged",
            { newIndex: index }
          );
        }}
      />
      <View style={{ marginTop: 8, zIndex: 100 }}>
        {Platform.OS === "web" && <ArrowForwardWeb sliderRef={sliderRef} />}
        {Platform.OS === "web" && <ArrowBackWeb sliderRef={sliderRef} />}
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: 12,
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Dots current={currentIndex} amount={2} />
        </View>
      </View>
    </View>
  );
};

const PartnerSection = () => {
  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );
  const recommendationData =
    useSelector(selectAllContent)?.homeScreenContent?.content
      ?.recommendedContent;

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    async function load() {
      const _data: any[] = getPagesToIDs(recommendationData, allPages);
      if (JSON.stringify(data) !== JSON.stringify(_data)) setData(_data);
    }
    if (!allPages) return;
    load();
  }, [allPages]);

  const LargePartnerCard = () => {
    return (
      <TouchableOpacity
        style={{
          flex: 1,
          marginTop: 16,
          padding: 12,
          overflow: "hidden",
          borderRadius: 12,
          alignItems: "center",
          justifyContent: "space-between",
          gap: 12,
          backgroundColor: "white",
        }}
      >
        {/* <CustomGradientBackground /> */}
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "space-between",
            height: 60,
          }}
        >
          <Image
            source={require("../../../assets/images/draeger.png")}
            style={{
              width: 120,
              position: "absolute",
              opacity: 0.34,
              left: 0,
              top: 0,
              resizeMode: "contain",
              height: 55,
            }}
          />
          <Text
            android_hyphenationFrequency="full"
            style={{
              fontSize: Sizes.boxText,
              fontWeight: "600",
              alignSelf: "flex-end",
              color: "black",
              flex: 1,
            }}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            Beispielüberschrift: Banneranzeige
          </Text>

          <Feather name="arrow-up-right" size={24} color="black" style={{}} />
        </View>
      </TouchableOpacity>
    );
  };

  const SmallPartnerCard = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          flex: 1,
          padding: 12,
          overflow: "hidden",
          borderRadius: 12,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 12,
          backgroundColor: "white",
        }}
      >
        {/* <DefaultGradientBackground /> */}

        <Text
          android_hyphenationFrequency="full"
          style={{
            marginBottom: 0,
            fontSize: Sizes.boxText,
            fontWeight: "600",

            flex: 1,
          }}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          Sattelite
        </Text>
        <Feather
          name="arrow-up-right"
          size={24}
          color="black"
          style={{ marginRight: -12 }}
        />
      </TouchableOpacity>
    );
  };
  return (
    <View>
      <SectionHeaderText>Partner</SectionHeaderText>
      <LargePartnerCard />
      <View style={{ flexDirection: "row", gap: 12, marginTop: 12 }}>
        <SmallPartnerCard item={data[0]} />
        <SmallPartnerCard item={data[1]} />
      </View>
      <View style={{ flexDirection: "row", gap: 12, marginTop: 12 }}>
        <SmallPartnerCard item={data[2]} />
        <SmallPartnerCard item={data[3]} />
      </View>
    </View>
  );
};

const TestimonialSlide = ({ image, text, currentUser, buttonTitle, link }) => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          width: "100%",
          shadowColor: "black",
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.02,
          shadowRadius: 4,
        }}
      >
        <View
          style={{
            overflow: "hidden",
            padding: 16,
            gap: 20,
            borderRadius: 20,
            flexDirection: "row",
            backgroundColor: "white",
          }}
        >
          <Image
            style={{
              width: 52,
              backgroundColor: "#ddd",
              height: 52,
              borderRadius: 64,
              alignSelf: "center",
            }}
            source={
              image ?? require("../../../assets/images/characters/doctor.png")
            }
          />

          <View style={{ gap: 12, flex: 1 }}>
            <Text style={{ fontSize: Sizes.boxText - 1 }}>
              Hi {currentUser.firstname}, {text}
            </Text>
            <DefaultButton
              title={buttonTitle}
              action={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "navigate_to_feature",
                  "TestimonialSlide",
                  "UserNavigated",
                  null
                );
                navigation.navigate("WebBrowserScreen", { url: link });
              }}
              style={{
                padding: 8,
                ...Platform.select({
                  web: { alignSelf: "flex-start", minWidth: 300 },
                }),
              }}
              icon={<Feather name="arrow-up-right" size={24} color={"black"} />}
              type={ButtonTypes.Outline}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const KPISlide = () => {
  const [kpi, setKpi] = useState(270045);
  const [incrementRate, setIncrementRate] = useState(3);

  useEffect(() => {
    const loadInitialKpi = async () => {
      try {
        const storedKpi = await AsyncStorage.getItem("kpi");
        if (storedKpi !== null) {
          setKpi(parseFloat(storedKpi));
        }
      } catch (error) {
        console.error("Error loading initial KPI from AsyncStorage", error);
      }
    };

    const fetchKpiFromApi = async () => {
      try {
        const data = await apiCall("/api/v2/kpi", null, false, false, "GET");
        const apiKpi = data.data.total; // adjust based on your API response

        setKpi((prevKpi) => {
          console.log("prevKpi", prevKpi, apiKpi);
          if (prevKpi > apiKpi) {
            setIncrementRate(1); // Reduce increment rate if local KPI is higher
            return prevKpi;
          } else {
            AsyncStorage.setItem("kpi", apiKpi.toString());
            return apiKpi;
          }
        });
      } catch (error) {
        console.error("Error fetching KPI from API", error);
      }
    };

    const incrementKpi = () => {
      setKpi((prevKpi) => {
        const newKpi = prevKpi + Math.random() * incrementRate;
        AsyncStorage.setItem("kpi", newKpi.toString());
        return newKpi;
      });
    };

    loadInitialKpi();
    fetchKpiFromApi();

    const fetchInterval = setInterval(fetchKpiFromApi, 10 * 60 * 1000); // 10 minutes
    const incrementInterval = setInterval(incrementKpi, 15000); // 7.5 seconds

    return () => {
      clearInterval(fetchInterval);
      clearInterval(incrementInterval);
    };
  }, []);

  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          width: "100%",
          shadowColor: "black",
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.02,
          shadowRadius: 4,
        }}
      >
        <View
          style={{
            overflow: "hidden",
            backgroundColor: "white",
            padding: 16,
            gap: 8,
            borderRadius: 20,
          }}
        >
          <Text
            style={{
              fontSize: Sizes.boxText,
              zIndex: 10,
              fontWeight: "300",
            }}
          >
            {i18n.t("kpi_slide_text")}
          </Text>
          <View
            style={{
              zIndex: 10,
            }}
          >
            <AnimatedNumbers
              includeComma
              language="de"
              animateToNumber={kpi}
              fontStyle={{ fontSize: 45, fontWeight: "600", zIndex: 100 }}
            />
          </View>
          <ChartComponent />
        </View>
      </View>
    </View>
  );
};

const screenWidth = Dimensions.get("window").width;

const data = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [3701, 9149, 11744, 11436, 18845, 27241],
      strokeWidth: 2, // optional
    },
  ],
};

const chartConfig = {
  backgroundColor: "gray",
  backgroundGradientFrom: "white",
  backgroundGradientTo: "white",
  fillShadowGradientFrom: "#f4c430",
  fillShadowGradientFromOpacity: 1,
  fillShadowGradientToOpacity: 0.4,
  fillShadowGradientTo: "white",

  barPercentage: 0.5,
  decimalPlaces: 2, // optional, defaults to 2dp

  color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  propsForDots: {
    r: "0",
    strokeWidth: "0",
    stroke: "gray",
  },
};

const ChartComponent = () => {
  return (
    <View
      style={{
        position: "absolute",
        right: -(screenWidth / 10),
        zIndex: 0,
      }}
    >
      <LineChart
        data={data}
        width={(screenWidth / 5) * 3}
        height={100}
        chartConfig={chartConfig}
        bezier
        withInnerLines={false}
        withOuterLines={false}
        withVerticalLines={false}
        withHorizontalLines={false}
        withVerticalLabels={false}
        withHorizontalLabels={false}
      />
    </View>
  );
};

const LicenseHintCard = () => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "navigate_to_kliniklizenz",
          "LicenseHintCard",
          "UserNavigated",
          null
        );
        navigation.navigate("WebBrowserScreen", {
          url: "https://mediceo.com/kliniken",
          title: "MEDICEO - Kliniklizenz",
        });
      }}
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 16,
        backgroundColor: Colors[colorScheme].foreground,
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          flex: 1,
          fontSize: Sizes.boxText,
          color: Colors[colorScheme].text,
          fontStyle: "italic",
        }}
      >
        <Text style={{ fontWeight: "bold" }}>Hausinterne Inhalte</Text> - Alle
        Prozesse auf Abruf
      </Text>
      <Feather name="info" size={20} color={Colors[colorScheme].accent} />
    </TouchableOpacity>
  );
};

const TippsContainer = ({
  collapsed = false,
  setTippsCollapsed,
}: {
  collapsed?: boolean;
  setTippsCollapsed?: any;
}) => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  const handleNavigateToTippsScreen = () => {
    navigation.navigate("TippsScreen");
    // Tracking navigation to TippsScreen
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "navigate_to_tipps_screen",
      "TippsContainer",
      "User Navigated",
      { collapsed }
    );
  };

  const handleCloseTipps = async () => {
    setTippsCollapsed(true);
    await addValueToInteractions("hide_tipps", JSON.stringify(true));
    // Tracking collapsing of tips container
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tipps_collapsed",
      "TippsContainer",
      "User Collapsed Tips",
      null
    );
  };

  if (collapsed)
    return (
      <TouchableOpacity
        onPress={handleNavigateToTippsScreen}
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          borderRadius: 12,
          width: "100%",
          padding: 16,
          overflow: "hidden",
          alignItems: "center",

          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <DefaultGradientBackground />
        <Text
          style={{
            fontSize: Sizes.boxText,
            fontWeight: "500",
            color: "black",
          }}
        >
          {i18n.t("explore_mediceo")}
        </Text>
        <Ionicons name="arrow-forward" size={24} color="black" />
      </TouchableOpacity>
    );

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].foreground,
        borderRadius: 12,
        width: "100%",
        marginVertical: 16,
        overflow: "hidden",
      }}
    >
      <DefaultGradientBackground />
      <View style={{ marginVertical: 32, marginHorizontal: 16 }}>
        <TouchableOpacity
          style={{
            position: "absolute",
            top: -16,
            right: 0,
            zIndex: 10,
          }}
          onPress={handleCloseTipps}
        >
          <Ionicons name="close" size={24} style={{}} />
        </TouchableOpacity>
        <Text style={{ fontSize: 20, fontWeight: "500" }}>
          {i18n.t("explore_mediceo")}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              marginTop: 12,
              fontSize: 14,
              fontWeight: "400",
              flex: 1,
              marginRight: 20,
            }}
          >
            {i18n.t("explore_detail")}
          </Text>
          <Text style={{ fontSize: 36 }}>💡</Text>
        </View>
        <DefaultButton
          type={ButtonTypes.Outline}
          title={i18n.t("discover_now")}
          style={{
            marginTop: 20,
            borderColor: "black",
            maxWidth: 400,
            width: "100%",
            alignSelf: "flex-start",
          }}
          textStyle={{ color: "black" }}
          icon={<Ionicons name="arrow-forward" size={24} color={"black"} />}
          action={handleNavigateToTippsScreen}
        />
      </View>
    </View>
  );
};

const MaintenanceInfoComponent = ({ info }) => {
  const colorScheme = getColorScheme();

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].red,
        padding: 12,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text
          style={{
            fontWeight: "bold",
            color: "white",
          }}
        >
          {info}
        </Text>
      </View>
      <TouchableOpacity
        style={{
          marginLeft: 20,
        }}
        onPress={() => addValueToInteractions("hide_maintenance_info", "true")}
      >
        <Feather name="x" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );
};

const AllContentCard = () => {
  const navigation = useNavigation();

  const colorScheme = getColorScheme();
  const color = Colors[colorScheme].accent;
  const dispatch = useDispatch();
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: Colors[colorScheme].foreground,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          posthog?.capture("all_content_card_selected");
          navigation.navigate("SearchScreen");
          dispatch(setCurrentTabSelection(i18n.t("content")));
        }}
        activeOpacity={0.3}
      >
        <View
          style={{
            borderRadius: 10,
            borderColor: color,
            overflow: "hidden",
            flexDirection: "row",
            alignItems: "center",
            padding: 5,
          }}
        >
          <InfoIcon />
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingVertical: 16,
                paddingRight: 16,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  width: "90%",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    fontWeight: "500",
                    marginLeft: 12,
                  }}
                >
                  {i18n.t("show_all_content")}
                </Text>
              </View>

              <Feather
                name="chevron-right"
                size={24}
                style={{ marginLeft: 12 }}
                color={Colors[colorScheme].text}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const InfoIcon = () => {
  return (
    <View
      style={{
        borderRadius: 100,
        overflow: "hidden",

        aspectRatio: 1,
        height: 44,
        marginLeft: 16,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DefaultGradientBackground />
      <Feather name="search" size={24} style={{}} />
    </View>
  );
};
