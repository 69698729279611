import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import FurtherActionBox from "../further-action-box/FurtherActionBox";

export default function getPerfusorFurtherActionBox(box: ResultBox) {
  return (
    <FurtherActionBox
      isPerfusorFurtherAction
      boxID={box.id}
      key={box.id}
      title={parser(box.title)}
      recommendedDosage={box.recommendedDosage ?? ""}
      specificHints={box.specificHints}
      possibleRecommendedUnits={box.possibleRecommendedUnits ?? []}
    />
  );
}
