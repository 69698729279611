import { Feather } from "@expo/vector-icons";
import { Text, View } from "react-native";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

const defaultText =
  "Die Dosierungen und Behandlungsempfehlungen sind allgemeine Empfehlungen aus aktuellen Leitlinien und peer-reviewed Quellen und können unter gewissen Umständen abweichen. Bitte beachte individuelle Anpassungen für deine Patient:innen.\n\nDie Dosierungshilfen dienen als Instrument zur Berechnung und stellen keine umfassenden oder endgültigen Informationen über das Medikament und die zu applizierende Dosis. Überprüfe die Dosierung jedes Medikaments und konsultiere einen weiteren Arzt, wenn nötig.";
const lyraText =
  "Diese Beta von MEDICEO LYRA ist nicht von den regulatorischen Anforderungen eines Medizinproduktes der Klasse I abgedeckt. Wir versichern, dass alle Daten, die in LYRA einfließen, ausschließlich aus AWMF-Leitlinien stammen.";

export default function WarningModal({ open, onClose, isLyra = false }) {
  let text = defaultText;
  if (isLyra) text = lyraText;

  const navigation = useNavigation();

  useEffect(() => {
    navigation.addListener("focus", () => {
      if (open)
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "warning_modal_presented",
          "WarningModal",
          "presented"
        );
    });
  }, []);

  const colorScheme = getColorScheme();
  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Feather
            name="alert-triangle"
            size={150}
            color={Colors[colorScheme].red}
            style={{
              alignSelf: "center",
              opacity: 0.08,
            }}
          />
        </View>
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Achtung!
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          {text}
        </Text>
        <DefaultButton
          type={ButtonTypes.Primary}
          action={onClose}
          style={{ marginTop: 20 }}
          title="Schließen"
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
