import { StyleSheet, Text, View } from "react-native";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import ScoreElementConditionalStaticComponent from "./ScoreElementCondidtionalStaticComponent";

const ScoreOverview = ({ pageContent }) => {
  const colorScheme = getColorScheme();

  const data = pageContent.scoreElements.map((elem) => ({
    type: elem.typeId,
    title: elem.title,
    activeValue: elem.activeValue,
    scoreSelectionItems: elem.scoreSelectionItems || [],
    positiveConditionScoreElement: elem.positiveConditionScoreElement || [],
    negativeConditionScoreElement: elem.negativeConditionScoreElement || [],
    conditionTitle: elem.conditionTitle || "",
    positiveAnswerTitle: elem.positiveAnswerTitle || "",
    negativeAnswerTitle: elem.negativeAnswerTitle || "",
  }));

  const hasMultiple = !data.every((item) => item.type === "scoreElement");

  return (
    <View style={styles.tableContainer}>
      {hasMultiple ? <TableHeaderThreeRows /> : <TableHeaderTwoRows />}
      {data.map((item, index) => {
        if (item.type === "scoreElementWithConditional") {
          return (
            <ScoreElementConditionalStaticComponent key={index} data={item} />
          );
        }

        if (item.type === "scoreElementInput") {
          return (
            <View key={index} style={styles.row}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  borderColor: "#ddd",
                  padding: 14,
                  borderBottomWidth: index === data.length - 1 ? 0 : 1,
                  backgroundColor: Colors[colorScheme].foreground,
                }}
              >
                <BoxTextComponent
                  style={{
                    body: {
                      ...styles.cell,
                      borderBottomWidth: 0,
                    },
                    p: {
                      margin: 0,
                      padding: 0,
                    },
                  }}
                  content={`Eingabe: ${item.title}`}
                />
              </View>
            </View>
          );
        }

        if (item.type === undefined) {
          return (
            <View key={index} style={styles.row}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  borderColor: "#ddd",
                  padding: 14,
                  borderBottomWidth: index === data.length - 1 ? 0 : 1,
                  backgroundColor: Colors[colorScheme].foreground,
                }}
              >
                <Text
                  style={[
                    styles.cell,
                    {
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      backgroundColor: Colors[colorScheme].foreground,
                    },
                  ]}
                >
                  {item.title}
                </Text>
              </View>
            </View>
          );
        }

        return (
          <View key={index} style={styles.row}>
            <View
              style={{
                flex:
                  item.type === undefined
                    ? 1
                    : hasMultiple && item.type === "scoreElement"
                    ? 2.75
                    : hasMultiple
                    ? 0.75
                    : 2,
                justifyContent: "center",
                borderColor: "#ddd",
                padding: 14,
                borderBottomWidth: index === data.length - 1 ? 0 : 1,
                backgroundColor: Colors[colorScheme].foreground,
              }}
            >
              <BoxTextComponent
                style={{
                  body: {
                    ...styles.cell,
                    borderBottomWidth: 0,
                  },
                  p: {
                    margin: 0,
                    padding: 0,
                  },
                }}
                content={item.title}
              />
            </View>
            {item.type === undefined ? null : item.type === "scoreElement" ? (
              <View
                style={{
                  flex: hasMultiple ? 1 : 1,
                  justifyContent: "center",
                  borderColor: "#ddd",
                  padding: 14,
                  borderBottomWidth: index === data.length - 1 ? 0 : 1,
                  backgroundColor: Colors[colorScheme].foreground,
                }}
              >
                <Text
                  style={[
                    styles.cell,
                    { backgroundColor: Colors[colorScheme].foreground },
                  ]}
                >
                  {item.activeValue}
                </Text>
              </View>
            ) : (
              <View style={{ flex: 2 }}>
                {item.scoreSelectionItems.map((subItem, subIndex) => (
                  <View key={subIndex} style={styles.row}>
                    <View
                      style={{
                        flex: 2,
                        justifyContent: "center",
                        borderColor: "#ddd",
                        borderBottomWidth:
                          index === data.length - 1 &&
                          subIndex === item.scoreSelectionItems.length - 1
                            ? 0
                            : 1,
                        padding: 14,
                        backgroundColor: Colors[colorScheme].foreground,
                      }}
                    >
                      <BoxTextComponent
                        style={{
                          body: {
                            ...styles.cell,
                            backgroundColor: Colors[colorScheme].foreground,
                          },
                          p: {
                            margin: 0,
                            padding: 0,
                          },
                        }}
                        content={subItem.title}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,

                        justifyContent: "center",
                        borderColor: "#ddd",
                        borderBottomWidth:
                          index === data.length - 1 &&
                          subIndex === item.scoreSelectionItems.length - 1
                            ? 0
                            : 1,
                        padding: 14,
                        backgroundColor: Colors[colorScheme].foreground,
                      }}
                    >
                      <Text
                        style={[
                          styles.cell,
                          { backgroundColor: Colors[colorScheme].foreground },
                        ]}
                      >
                        {subItem.value}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const TableHeaderTwoRows = () => {
  return (
    <View style={styles.row}>
      <View
        style={{
          flex: 2,
          padding: 14,
        }}
      >
        <Text style={[styles.headerCell]}>Risikofaktor</Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          padding: 14,
        }}
      >
        <Text style={[styles.headerCell]}>Wert</Text>
      </View>
    </View>
  );
};

const TableHeaderThreeRows = () => {
  return (
    <View style={styles.row}>
      <View
        style={{
          flex: 0.75,
          padding: 14,
        }}
      >
        <Text style={[styles.headerCell]}>Risikofaktor</Text>
      </View>
      <View
        style={{
          flex: 2,
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <View style={{ flex: 2, padding: 14 }}>
          <Text style={[styles.headerCell]}>Auswahl</Text>
        </View>

        <View style={{ flex: 1, padding: 14 }}>
          <Text style={[styles.headerCell]}>Wert</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 12,
    overflow: "hidden",
  },
  row: {
    flexDirection: "row",
  },
  headerCell: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: Sizes.boxText,
  },
  cell: {
    flex: 1,
    textAlign: "left",
    fontSize: Sizes.boxText,
  },
});

export default ScoreOverview;
