import { useState } from "react";
import { View } from "react-native";

import { Picker } from "@react-native-picker/picker";
import staticColors from "../../constants/static-colors";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../functions/user-interaction-handler";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";
// import other necessary components and styles

export default function ChangeSortOrderModal({ open, onClose }) {
  const selection = getValueFromInteractions("sort_order_favorites");
  const [loading, setLoading] = useState(false);
  const colorScheme = getColorScheme();

  const items = [
    { label: "Kürzlich hinzugefügt", value: "recent" },
    { label: "Meist verwendet", value: "most" },
  ];

  const [selectedValue, setSelectedValue] = useState(selection || "recent");

  // Replace this with your actual font size change logic

  async function handleConfirm() {
    setLoading(true);
    await addValueToInteractions("sort_order_favorites", selectedValue);
    setLoading(false);
    onClose(selectedValue);
  }

  return (
    <CustomModal open={open} onClose={() => onClose(null)}>
      <View style={{}}>
        <View style={{}}>
          <Picker
            selectedValue={selectedValue}
            onValueChange={(itemValue) => setSelectedValue(itemValue)}
          >
            {items.map((item) => (
              <Picker.Item
                key={item.value}
                label={item.label}
                value={item.value}
                color={staticColors[colorScheme].text}
              />
            ))}
          </Picker>
          <DefaultButton
            loading={loading}
            type={ButtonTypes.Primary}
            title="Anwenden"
            action={handleConfirm}
          />
        </View>
      </View>
    </CustomModal>
  );
}
