import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { HintBox } from "./HintBox";

export default function getHintBox(box: ResultBox) {
  let expandableContent = box.expandableContent;
  if (expandableContent !== undefined)
    expandableContent = parser(expandableContent);

  return (
    <HintBox
      key={box.id}
      title={parser(box.title)}
      expandableContent={expandableContent}
      inlineImage={box.image}
    />
  );
}
