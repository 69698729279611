import { Feather } from "@expo/vector-icons";
import { TouchableOpacity, View } from "react-native";
import getColorScheme from "../../../../hooks/useColorScheme";
import Colors from "../../../../constants/static-colors";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";

export default function TablePopupViewHeader({ title, navigation }) {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
        width: "100%",
        paddingLeft: 20,
        height: 80,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: Colors[colorScheme].background,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 12,
        }}
      >
        <View style={{ flex: 1 }}>
          <BoxTextComponent
            style={{
              p: {
                fontWeight: "700",
                fontSize: 16,
                margin: 0,

                color: Colors[colorScheme].text,
              },
            }}
            content={title}
          ></BoxTextComponent>
        </View>
        <TouchableOpacity
          hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
          style={{ marginLeft: 12 }}
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Feather name="x" color={Colors[colorScheme].text} size={20} />
        </TouchableOpacity>
      </View>
    </View>
  );
}
