import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Platform, StatusBar, StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import {
  selectCurrentHighlightSection,
  selectCurrentResultPage,
  selectResultPageCaseContent,
  selectResultPageHeaderContent,
  selectShowTitle,
} from "../../functions/current-result/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../../functions/current-result/helpers";
import { selectIsDemoMode } from "../../functions/navigation/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import { isWebAndNotMobile } from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";
import LargeScreenResultPageUserInteractionsComponent from "../LargeScreenResultPageUserInteractionsComponent";

import BoxComponent from "../boxes/BoxSorter";
import {
  CoachMark,
  evaluateCoachMarkAction,
} from "../guide-mark/components/GuideMark";
import { BackButton } from "./header-components/HeaderBackButton";

export default function ResultPageHeader({
  isModal = false,
  integrated = false,
}) {
  const insets = useSafeAreaInsets();
  const colorScheme = getColorScheme();

  const isDemoMode = useSelector(selectIsDemoMode);

  const highlightedSection = useSelector(selectCurrentHighlightSection);
  const resultPageHeaderContent = useSelector(selectResultPageHeaderContent);
  const resultPageCaseContent = useSelector(selectResultPageCaseContent);

  const calcBoxRef = useRef(null);

  const showTitle = useSelector(selectShowTitle);
  const [height, setHeight] = useState(0);
  const currentResultPage = useSelector(selectCurrentResultPage);
  const currentUser = useSelector(selectCurrentUser);
  const navigation = useNavigation();
  const note = useMemo(
    () => getNoteToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );
  const hasBookmark = useMemo(
    () =>
      getIsBookmarkToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );

  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(false);

  // ...

  useEffect(() => {
    const debouncedFunction = debounce(async () => {
      if (!resultPageHeaderContent) return;
      evaluateCoachMarkAction(setIsCoachMarkVisible, "did_enter_weight", 1);
    }, 300);

    debouncedFunction();

    return () => {
      debouncedFunction.cancel();
    };
  }, [resultPageHeaderContent]);

  useEffect(() => {
    if (Platform.OS === "ios" && highlightedSection) configureAnimations(250);
  }, [highlightedSection]);

  return (
    <>
      <CoachMark
        shape="rect"
        borderRadius={8}
        positionRef={calcBoxRef}
        onClose={() => setIsCoachMarkVisible(false)}
        direction="bottom"
        title="Einfache Eingabe von Patientenparametern"
        text="Hier kannst du Patientenparametern eingeben und die entsprechenden Werte passen sich automatisch im Text an."
        visible={isCoachMarkVisible}
      />

      <View
        onLayout={(event) => {
          setHeight(event.nativeEvent.layout.height);
        }}
        style={{
          backgroundColor: Colors[colorScheme].background,
          paddingHorizontal: integrated ? 0 : Sizes.defaultContainerPadding,
          zIndex: 10,

          left: 0,
          right: 0,
          paddingTop: integrated
            ? 24
            : isModal
            ? Platform.OS === "android"
              ? StatusBar.currentHeight
              : 0
            : Platform.OS === "android"
            ? StatusBar.currentHeight
            : insets.top + 12,
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingVertical: integrated ? 0 : 12,
            marginTop: 4,
            marginBottom: integrated ? 0 : isWebAndNotMobile() ? 8 : 0,
          }}
        >
          {!isDemoMode && (
            <BackButton isModal={isModal} navigation={navigation} />
          )}

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              marginLeft: 8,
              marginRight: 12,
            }}
          >
            {showTitle && (
              <Text
                style={{
                  fontSize: 15,
                  marginBottom: -0.5,
                  fontWeight: "700",
                  color: Colors[colorScheme].text,
                }}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {currentResultPage.title ??
                  currentResultPage.pageTitle ??
                  currentResultPage.fieldTitle ??
                  ""}
              </Text>
            )}
          </View>
          <LargeScreenResultPageUserInteractionsComponent
            isDemoMode={isDemoMode}
            currentUser={currentUser}
            pageContent={currentResultPage}
            hasBookmark={hasBookmark}
            note={note}
          />
        </View>
        <View
          style={{
            position: "absolute",
            top: height,
            backgroundColor: Colors[colorScheme].background,
            opacity: 1,
            left: 0,
            zIndex: 10,
            right: 0,
          }}
        >
          {(resultPageHeaderContent || resultPageCaseContent) && (
            <View
              ref={calcBoxRef}
              style={{
                width: "100%",
                zIndex: 5,
                opacity: 1,
                marginBottom: 16,
                flexDirection: "row",
                borderTopColor: colorScheme === "light" ? "#ddd" : "#444",
                borderTopWidth: StyleSheet.hairlineWidth,
                paddingTop: 12,
                backgroundColor: Colors[colorScheme].background,
                paddingHorizontal: Sizes.defaultContainerPadding,
                gap: 12,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 2,
                  marginTop: -16,
                }}
              >
                {resultPageHeaderContent && (
                  <BoxComponent box={resultPageHeaderContent} collapsed />
                )}
              </View>
              {resultPageCaseContent && (
                <View style={{ alignItems: "flex-end", flex: 1 }}>
                  <Text
                    style={{
                      fontSize: Sizes.boxText,
                      fontWeight: "bold",
                      marginBottom: 2,
                      color: Colors[colorScheme].text,
                    }}
                  >
                    {resultPageCaseContent?.note}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: Colors[colorScheme].text,
                      opacity: 0.5,
                    }}
                    numberOfLines={3}
                  >
                    {formatDate(resultPageCaseContent.last_updated)}
                  </Text>
                </View>
              )}
            </View>
          )}
          <View
            style={{
              paddingHorizontal: Sizes.defaultContainerPadding,
              backgroundColor: Colors[colorScheme].background,
              zIndex: 5,
              opacity: 1,
            }}
          >
            {highlightedSection}
          </View>
          {(resultPageCaseContent ||
            resultPageHeaderContent ||
            highlightedSection) && (
            <View
              style={{
                shadowColor: Colors[colorScheme].text,
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowOpacity: 0.08,
                shadowRadius: 4,
                width: "100%",
                backgroundColor: Colors[colorScheme].background,
                height: 30,
                marginTop: -30,
                opacity: 1,
                zIndex: -10,
                elevation: 2,
              }}
            />
          )}
        </View>
      </View>
    </>
  );
}

function formatDate(entry: string) {
  entry = entry.replace(/^(\d{3})/, "$1").split(".")[0];

  const date = new Date(entry);
  if (isNaN(date.getTime())) {
    return "Invalid";
  }

  const germanFormattedDate = date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour12: false,
    minute: "2-digit",
    hour: "2-digit",
  });
  return germanFormattedDate;
}
