import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { useSelector } from "react-redux";
import i18n from "../../../localization/i18n";
import { getPagesToIDs } from "../../api/content/data-helpers";
import getAllTips from "../../api/tipps/get-all-tips";
import Colors from "../../constants/static-colors";
import { getValueFromInteractions } from "../../functions/user-interaction-handler";
import { selectCurrentUser } from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import SectionHeaderText from "../SectionHeaderText";
import { LongCard } from "./LongCard";
import { ShowAllButton } from "./ShowAllButton";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

export const ShortcutSection = ({ title = i18n.t("my_favorites") }) => {
  const bookmarkData = useSelector(selectCurrentUser)?.bookmarks;

  let bookmarks = [...(bookmarkData ?? [])];

  const [toDisplayBookmarks, setToDisplayBookmarks] = useState<any>([]);
  const hasElements = toDisplayBookmarks.length > 0;

  const navigation = useNavigation();

  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );

  useEffect(() => {
    if (toDisplayBookmarks.length > 0) {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "shortcut_section_loaded",
        "ShortcutSection",
        "Section Loaded",
        { bookmarkCount: toDisplayBookmarks.length }
      );
    }
  }, [toDisplayBookmarks]);

  function getPagesToBoxes() {
    if (!allPages) return;
    if (!bookmarks) return;

    if (getValueFromInteractions("sort_order_favorites") === "recent") {
      bookmarks = bookmarks.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else {
      bookmarks = bookmarks.sort((a, b) => a.score - b.score);
    }

    const pages = getPagesToIDs(
      bookmarks.map((item) => item.result_page_id),
      allPages
    );

    if (!pages) return;

    setToDisplayBookmarks(pages);
  }

  useEffect(() => {
    getPagesToBoxes();
  }, [bookmarkData, allPages]);

  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <SectionHeaderText style={{ flex: 1 }}>{title}</SectionHeaderText>
        {hasElements && (
          <ShowAllButton
            style={{
              marginTop: 0,
            }}
            title={i18n.t("show_all") + " (" + toDisplayBookmarks.length + ")"}
            showIcon
            action={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "navigate_to_personal_content_screen",
                "ShortcutSection",
                "User Navigated",
                { bookmarkCount: toDisplayBookmarks.length }
              );
              navigation.navigate("PersonalContentScreen", { selection: 0 });
            }}
          />
        )}
      </View>
      {hasElements ? (
        <ItemList {...{ toDisplayBookmarks }} />
      ) : (
        <EmptySectionView />
      )}
    </View>
  );
};

const ItemList = ({ toDisplayBookmarks }) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(toDisplayBookmarks.slice(0, 3));
  }, [toDisplayBookmarks]);

  const renderItem = ({ item, drag }: { item; drag?: any }) =>
    item && <LongCard {...{ drag, item }} key={item._id} />;

  return (
    <FlatList
      data={data}
      nestedScrollEnabled
      scrollEnabled={false}
      renderItem={renderItem}
      keyExtractor={(item, index) =>
        item ? index + "" : item?._id + "_draggable_list_" + index
      }
    />
  );
};

export const EmptyFavoritesButton = () => {
  const navigation = useNavigation();

  return (
    <DefaultButton
      type={ButtonTypes.Primary}
      title={i18n.t("add_favorites")}
      action={() => {
        const tipsContent = getAllTips();

        const favoritesItem = tipsContent.content.listItems.find((item) =>
          item.pageTitle.includes("Favoriten")
        );

        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "add_favorites",
          "ShortcutSection",
          "User Adding Favorites",
          null
        );

        navigation.navigate("TippsDetailScreen", {
          tipContent: favoritesItem,
        });
      }}
      style={{
        width: "60%",
        maxWidth: 500,
        alignSelf: "center",
        paddingVertical: 8,
      }}
    />
  );
};

const EmptySectionView = () => {
  const colorScheme = getColorScheme();

  return (
    <View style={{ marginTop: 16 }}>
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
          alignItems: "center",

          zIndex: 10,
          flex: 1,
        }}
      >
        <EmptyFavoritesButton />
      </View>
      <View
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          padding: 20,
          borderRadius: 8,
          flexDirection: "row",
          alignItems: "center",
          opacity: 0.5,
          height: 75,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <View
            style={{
              height: 44,
              backgroundColor: Colors[colorScheme].background,
              width: 44,
              marginRight: 20,
              borderRadius: 25,
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            <Feather color={Colors[colorScheme].text} name="heart" size={24} />
          </View>
        </View>
        <Feather
          name="chevron-right"
          color={Colors[colorScheme].text}
          size={24}
        />
      </View>
    </View>
  );
};
