import { colorParser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { ArrowBox } from "./ArrowBox";

export default function getArrowBox(box: ResultBox) {
  return (
    <ArrowBox
      key={box.id}
      title={box.fieldTitle}
      color={colorParser(box.colour)}
    />
  );
}
