export default function fallbackSearch(
  query: string,
  limit,
  offset,
  filters,
  allPages
) {
  const relevantSearchSet = allPages.filter(
    (item) => item.content?.path?.length > 0 && item.internal_rating > 2
  );
  const allResults = relevantSearchSet.filter((page) =>
    JSON.stringify(page).toLowerCase().includes(query.toLowerCase())
  );

  // TODO : Adjust count with filters

  let results = allResults.slice(offset, offset + limit);

  if (filters.length > 0)
    results = results.filter((_result) =>
      _result.content?.path?.some((item) => filters.includes(item))
    );

  return {
    hits: results,
    estimatedTotalHits:
      allResults.length > 10 ? allResults.length : results.length,
  };
}
