import { useEffect } from "react";

export default function DefaultScreen({ navigation }) {
  useEffect(() => {
    navigation.reset(
      {
        index: 0,
        routes: [{ name: "LaunchScreen" }],
      },
      0
    );
  }, []);

  return null;
}
