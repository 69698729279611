import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import isLargerDevice from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";

export function getSharedStylesDosingRecommendation(color: string) {
  const htmlStylesTitle = {
    p: {
      fontWeight: "600",
      fontSize: Sizes.boxText,
      lineHeight: Sizes.lineHeightBoxText,
      margin: 0,
      color: color,
      whiteSpace: "pre",
    },
    h3: {
      fontWeight: "600",
      fontSize: Sizes.boxText,
      lineHeight: Sizes.lineHeightBoxText,
      margin: 0,
      color: color,
    },
  };

  return htmlStylesTitle;
}

export function getSharedStylesLargeContent(
  colorScheme,
  fontSize = Sizes.boxText,
  lineHeight = Sizes.lineHeightBoxText
) {
  return {
    body: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      alignSelf: "stretch",
      color: Colors[colorScheme].text,
      //whiteSpace: "pre",
    },
    table: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: Colors[colorScheme].text,

      margin: 0,
      marginBottom: 8,
      width: "40%",
      minWidth: isLargerDevice() ? "70%" : "100%",
      // Borders for the whole table
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderColor: Colors[colorScheme].gray,
      // Other table styles
      alignSelf: "flex-start",
      flex: 1,
    },
    tr: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: Colors[colorScheme].gray,
    },
    td: {
      paddingHorizontal: 8, // padding within cells
      paddingVertical: 4, // padding within cells
      borderRightWidth: 1,
      borderRightColor: Colors[colorScheme].gray,
    },
    th: {
      paddingHorizontal: 8, // padding within cells
      paddingVertical: 4, // padding within cells
      borderRightWidth: 1,
      borderRightColor: Colors[colorScheme].gray,
      borderBottomColor: Colors[colorScheme].accent,
      borderBottomWidth: 1,
    },
    p: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: Colors[colorScheme].text,
      margin: 0,
      padding: 0,
    },
    h3: {
      fontSize: fontSize,
      textDecoration: "underline",
      fontWeight: "700",
      color: Colors[colorScheme].text,
    },
    h4: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: Colors[colorScheme].text,
      paddingBottom: 5,
      margin: 0,
    },
    ul: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: Colors[colorScheme].text,
      paddingRight: 8,
      marginLeft: -12,
      marginBottom: 4,
    },
    ol: {
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: Colors[colorScheme].text,
      paddingRight: 8,
      marginLeft: -12,
      marginBottom: 4,
    },
    li: {
      marginLeft: 6,
      marginBottom: 8,
    },
    b: {
      fontSize: fontSize,
    },
  };
}

export function getSharedStylesContent(
  text: string | undefined,
  colorScheme,
  fontSize = Sizes.boxText,
  color = Colors[colorScheme].text,
  tableColor = Colors["light"].accent
) {
  if (text === undefined) return {};
  if (text.includes("<table>")) {
    return getSharedTableStyle(colorScheme, tableColor);
  } else {
    return {
      p: {
        fontSize: fontSize,
        lineHeight: Sizes.lineHeightBoxText,
        color: color,
        margin: 0,
        padding: 0,
        paddingTop: 12,
      },
      h3: {
        fontSize: Sizes.boxText + 2,
        fontWeight: "700",
        color: color,
      },
      body: {
        alignSelf: "stretch",
        margin: 0,
        marginVertical: 0,
        color: color,
        marginHorizontal: 6,
      },
      ul: {
        fontSize: fontSize,
        lineHeight: Sizes.lineHeightBoxText,
        color: color,

        margin: 0,
        marginLeft: -12,
        paddingRight: 8,
        paddingTop: -8,
      },
      ol: {
        fontSize: fontSize,
        lineHeight: Sizes.lineHeightBoxText,
        color: color,
        margin: 0,
        marginLeft: -12,
        paddingRight: 8,
        paddingTop: -8,
      },
      li: {
        margin: 0,
        marginLeft: 6,
        marginVertical: 3,
        //marginBottom: 8,
      },
      b: {
        margin: 0,
        fontSize: fontSize,
      },
    };
  }
}

export function getSharedTableStyle(
  colorScheme: any,
  tableColor = Colors["light"].accent,
  fontSize = Sizes.tableFontSize
) {
  return {
    body: {
      alignSelf: "stretch",
      //marginBottom: 16,
      marginTop: 0,
      marginHorizontal: 6,
      marginRight: -4,
    },
    table: { marginLeft: -8, marginRight: 8 },
    tr: {
      borderRadius: 14,
      overflow: "hidden",
      textAlign: "start",
      marginBottom: 2,
    },
    td: {
      backgroundColor: Colors[colorScheme].veryLightGray,
      margin: 0,
      padding: 0,

      paddingHorizontal: 12,
    },
    th: {
      backgroundColor: tableColor,
      color: "black",
      textAlign: "start",
      padding: 4,
      margin: -1,
      marginVertical: -4,
      paddingHorizontal: 12,
    },
    p: {
      fontSize: fontSize,
      lineHeight: Sizes.lineHeightBoxText,

      color: Colors[colorScheme].text,
    },
    ul: {
      fontSize: Sizes.boxText,
      lineHeight: Sizes.lineHeightBoxText,
      color: Colors[colorScheme].text,
      paddingRight: 8,
      margin: 0,
      marginLeft: -12,
    },
    ol: {
      fontSize: Sizes.boxText,
      lineHeight: Sizes.lineHeightBoxText,
      color: Colors[colorScheme].text,
      paddingRight: 8,
      margin: 0,
      marginLeft: -12,
    },
    li: {
      margin: 0,
      marginLeft: 6,
      marginBottom: 8,
    },
  };
}
