import { StyleSheet, View } from "react-native";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";
import { Sizes } from "../../../../constants/static-sizes";

export default function TextBox({ title = "", color = "" }) {
  return (
    <View style={{ alignSelf: "stretch" }}>
      <View
        style={[
          styles.container,
          { borderWidth: 0, flexDirection: "row", alignItems: "center" },
        ]}
      >
        <BoxTextComponent
          style={{
            body: {
              color: color,
              fontSize: Sizes.boxText + 4,
              lineHeight: 22,
              fontWeight: 600,
              alignSelf: "flex-start",
              margin: 0,
              padding: 0,
            },
            p: {
              margin: 0,
              padding: 0,
            },
            h4: {
              margin: 0,
              padding: 0,
            },
          }}
          content={"<p>" + title + "</p>"}
        />
        <View
          style={{
            flex: 1,
            height: 2,
            borderRadius: 10,
            backgroundColor: color,
            marginLeft: 12,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    marginTop: 32,
  },
});
