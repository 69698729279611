import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { SafeAreaView, Text, View } from "react-native";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../localization/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import SearchResults from "../../../components/search-tab/SearchResults";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { setCalculationVar } from "../../../functions/calculator/actions";

import { selectAllContent } from "../../../functions/data/actions";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import isLargerDevice, {
  isWebAndNotMobile,
} from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";
import { SearchBar } from "../components/SearchBar";
import ContentListComponent from "./components/ContentListComponent";
import LexiconComponent from "./components/LexiconComponent";

export default function SearchScreen() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);

  const allData = useSelector(selectAllContent);

  const contentList = allData.contentList?.content?.medicalSubjectAreaItems;

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_screen_opened",
        "SearchScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("content")));

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          paddingHorizontal: Sizes.defaultContainerPadding,
          paddingTop: isLargerDevice() ? 24 : 0,
          flex: 1,
        }}
      >
        <View
          style={{
            backgroundColor: Colors[colorScheme].background,
          }}
        >
          <SearchBar
            origin="Search"
            placeholder={i18n.t("default_search_placeholder")}
            {...{ query, setQuery, selected, setSelected, filters, setFilters }}
            style={{ marginBottom: 8 }}
          />
        </View>
        {!selected ? (
          <TabbedView content={contentList} />
        ) : (
          <View
            style={{
              padding: isWebAndNotMobile() ? 16 : 0,
              flex: 1,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <SearchResults {...{ query, setQuery, filters }} />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
}

const TabbedView = ({ content }) => {
  const renderScene = useMemo(
    () =>
      SceneMap({
        first: () => <LexiconComponent />,
        second: () => <ContentListComponent content={content} />,
      }),
    [content]
  );

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "second", title: i18n.t("topics") },
    { key: "first", title: i18n.t("content") },
  ]);

  const handleIndexChange = (newIndex) => {
    // Logging the tab switch event
    const newRoute = routes[newIndex];
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tab_switch",
      "TabbedView",
      "User Switched Tab",
      {
        newTabIndex: newIndex,
        newTabKey: newRoute.key,
        newTabTitle: newRoute.title,
      }
    );

    // Updating the index to reflect the change
    setIndex(newIndex);
  };

  const colorScheme = getColorScheme();

  return (
    <View
      style={{
        flex: 1,
        marginHorizontal: -Sizes.defaultContainerPadding,

        marginTop: isWebAndNotMobile() ? 20 : 0,
      }}
    >
      <TabView
        renderTabBar={(props) => renderTabBar(props, colorScheme)}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={handleIndexChange}
      />
    </View>
  );
};

export const renderTabBar = (props, colorScheme) => {
  return (
    <View
      style={{
        shadowColor: Colors[colorScheme].text,
        shadowOffset: {
          width: 0,
          height: 3,
        },
        paddingHorizontal: Sizes.defaultContainerPadding,
        marginHorizontal: -Sizes.defaultContainerPadding,
        shadowOpacity: 0.08,
        shadowRadius: 4,
        zIndex: 10,
        elevation: 2,
        overflow: "visible",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <TabBar
        {...props}
        indicatorStyle={{
          backgroundColor: Colors[colorScheme].accent,
          paddingTop: 4,
        }}
        style={{ backgroundColor: "transparent" }}
        renderLabel={({ route, focused }) => (
          <Text
            style={{
              color: focused
                ? Colors[colorScheme].text
                : Colors[colorScheme].gray,
              fontSize: Sizes.boxText,
              textAlign: "center",
              textTransform: "none",
              fontWeight: "500",
            }}
          >
            {route.title}
          </Text>
        )}
        tabStyle={{
          borderBottomColor: Colors[colorScheme].accent,
          borderBottomWidth: 1,
        }}
      />
    </View>
  );
};
