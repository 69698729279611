import { Text, TouchableOpacity, View } from "react-native";

import staticColors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";

export const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: staticColors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
