import { useEffect, useState } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import i18n from "../../../localization/i18n";
import { getPagesToIDs } from "../../api/content/data-helpers";
import { selectAllContent } from "../../functions/data/actions";
import SectionHeaderText from "../SectionHeaderText";
import { shuffleArray } from "../search-tab/SearchHistoryView";
import { HorizontalCardSlider } from "./HorizontalCardSlider";

export const RecommendationSection = () => {
  const [data, setData] = useState<any>([]);

  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );
  const recommendationData =
    useSelector(selectAllContent)?.homeScreenContent?.content
      ?.recommendedContent;

  useEffect(() => {
    async function load() {
      const _data: any[] = getPagesToIDs(recommendationData, allPages);
      if (JSON.stringify(data) !== JSON.stringify(_data))
        setData(shuffleArray(_data));
    }
    if (!allPages) return;
    load();
  }, [allPages]);

  if (!data.length) return null;
  return (
    <View style={{ overflow: "visible" }}>
      <SectionHeaderText style={{ marginBottom: 16 }}>
        {i18n.t("our_recommendations")}
      </SectionHeaderText>
      <HorizontalCardSlider {...{ data, header: "Recommendation" }} />
    </View>
  );
};
