import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as ScreenOrientation from "expo-screen-orientation";
import { useEffect, useRef, useState } from "react";
import {
  Image,
  Platform,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import Carousel from "react-native-snap-carousel";
import tut_1 from "../../assets/images/tutorial/tut-0.png";
import tut_2 from "../../assets/images/tutorial/tut-1.png";
import tut_3 from "../../assets/images/tutorial/tut-2.png";
import tut_5 from "../../assets/images/tutorial/tut-4.png";
import i18n from "../../localization/i18n";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import Colors from "../constants/static-colors";
import configureAnimations from "../functions/configure-animations";
import getColorScheme from "../hooks/useColorScheme";

export default function TutorialSlider() {
  const navigation = useNavigation();
  const tutorialImages = [tut_1, tut_2, tut_3, tut_5];
  const tutorialImagesEN = [
    require("../../assets/images/tutorial/en/0.png"),
    require("../../assets/images/tutorial/en/1.png"),
    require("../../assets/images/tutorial/en/2.png"),
    require("../../assets/images/tutorial/en/3.png"),
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<any>();
  const { width } = useWindowDimensions();

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tutorial_slider_slide_changed",
      "TutorialSlider",
      "UserChangedSlide",
      { slideIndex: currentIndex }
    );
  }, [currentIndex]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "tutorial_slider_loaded",
        "TutorialScreen",
        "UserOpened"
      );
    });

    (async () => {
      const platformIOS = Platform as any;
      if (platformIOS.isPad) {
        ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT_UP
        );
      }
    })();
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        height: "100%",
        maxWidth: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        <View style={{ height: "100%", width: "100%" }}>
          <Carousel
            vertical={false}
            ref={sliderRef}
            data={i18n.locale === "en" ? tutorialImagesEN : tutorialImages}
            renderItem={renderItem}
            sliderWidth={width}
            enableSnap
            layout="stack"
            itemWidth={width}
            onScrollIndexChanged={(index: number) => {
              configureAnimations();
              setCurrentIndex(index);
            }}
          />
          {Platform.OS === "web" && <ArrowForwardWeb sliderRef={sliderRef} />}
          {Platform.OS === "web" && <ArrowBackWeb sliderRef={sliderRef} />}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 20,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Dots current={currentIndex} amount={3} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export const ArrowForwardWeb = ({ sliderRef }) => {
  const colorScheme = getColorScheme();
  const index = sliderRef.current?.currentIndex;

  function forwardAction() {
    sliderRef.current?.snapToNext(false);
  }
  if (index > 3) return null;
  return (
    <View
      style={{
        position: "absolute",
        top: 20,
        bottom: 0,
        height: "100%",
        width: "30%",
        alignItems: "center",
        justifyContent: "center",
        right: 12,
      }}
    >
      <TouchableOpacity
        onPress={forwardAction}
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          height: 36,
          borderRadius: 25,
          aspectRatio: 1,
          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Ionicons
          name="chevron-forward"
          size={24}
          color={Colors[colorScheme].text}
        />
      </TouchableOpacity>
    </View>
  );
};

export const ArrowBackWeb = ({ sliderRef }) => {
  const colorScheme = getColorScheme();
  const index = sliderRef.current?.currentIndex;

  function backwardsAction() {
    sliderRef.current?.snapToPrev(false);
  }
  if (index === 0 || !index) return null;
  return (
    <View
      style={{
        position: "absolute",
        top: 20,
        bottom: 0,
        height: "100%",
        width: "30%",
        alignItems: "center",
        justifyContent: "center",
        left: 12,
      }}
    >
      <TouchableOpacity
        onPress={backwardsAction}
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          height: 36,
          borderRadius: 25,
          aspectRatio: 1,
          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Ionicons
          name="chevron-back"
          size={24}
          color={Colors[colorScheme].text}
        />
      </TouchableOpacity>
    </View>
  );
};

export function Dots({ current, amount }) {
  const n: number[] = [];
  for (let i = 0; i <= amount; i++) {
    n.push(i);
  }

  return (
    <View style={{ flexDirection: "row" }}>
      {n.map((t, index) => {
        const selected = t === current;
        return (
          <View
            key={index}
            style={{
              backgroundColor: selected ? Colors["light"].accent : "#aaa",
              height: 10,
              width: selected ? 34 : 10,
              borderRadius: 5,
              marginRight: 12,
            }}
          />
        );
      })}
    </View>
  );
}

const renderItem = ({ item }) => {
  return (
    <View style={{ flex: 1, overflow: "visible" }}>
      <View
        style={{
          flex: 1,
          width: "100%",
          overflow: "visible",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={item}
          style={{
            flex: 1,
            width: "80%",
            height: "100%",
            zIndex: 10,
            resizeMode: "contain",
          }}
        />
      </View>
    </View>
  );
};
