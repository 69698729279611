import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Dimensions, Platform } from "react-native";

import { Feather } from "@expo/vector-icons";
import Colors from "../constants/static-colors";
import { RootStackParamList, RootTabParamList } from "../types/types";

import TablePopupView from "../components/boxes/box-types/large-content-box/TablePopupView";
import DefaultBackHeader from "../components/header/DefaultBackHeaderComponent";
import DefaultHeader from "../components/header/DefaultHeaderComponents";
import ResultPageHeader from "../components/header/ResultPageHeaderComponent";

import CMSNodePageScreen from "../screens/CMSNodePageScreen";
import { CreateNoteScreen } from "../screens/CreateNoteScreen";
import FeedbackScreen from "../screens/FeedbackScreen";

import InfoResultPageScreen from "../screens/InfoResultPageScreen";
import LaunchScreen from "../screens/LaunchScreen";
import { ForgotPasswordScreen } from "../screens/login-registry/ForgotPasswordScreen";
import LandingScreen from "../screens/login-registry/LandingScreen/LandingScreen";
import LoginScreen from "../screens/login-registry/LoginScreen";
import { MobileFirstLandingScreen } from "../screens/login-registry/MobileFirstLanding";
import { PreLoginDatenschutz } from "../screens/login-registry/PreLoginDatenschutz";
import { PreLoginPrivacyPolicy } from "../screens/login-registry/PreLoginPrivacyPolicy";
import EnterLoginDataScreen from "../screens/login-registry/registry/EnterLoginData";
import NewRegistryScreen from "../screens/login-registry/registry/EnterUserInformationScreen";
import { RoleSelectionScreen } from "../screens/login-registry/RoleSelectionScreen";
import { WaitingForRegistrationScreen } from "../screens/login-registry/WaitingForRegistrationScreen";

import ResultPageScreen from "../screens/ResultPageScreen/ResultPageScreen";
import { TippsDetailScreen } from "../screens/TippsDetailScreen";
import TippsScreen from "../screens/TippsScreen";

import ScoreScreen from "../screens/ScoreScreen/ScoreScreen";
import HomeScreen from "../screens/tab-navigator-screens/HomeScreen";
import SearchScreen from "../screens/tab-navigator-screens/Search/SearchScreen";

import UnitCalculatorScreen from "../screens/toolbox/unit-calculator/UnitCalculatorScreen";
import UnitCalculatorSubstancesScreen from "../screens/toolbox/unit-calculator/UnitCalculatorSubstancesScreen";
import { WebBrowserScreen } from "../screens/WebBrowserScreen";

import { PostHogProvider } from "posthog-react-native";
import i18n from "../../localization/i18n";
import { posthogAsync } from "../api/analytics/posthog";
import { DefaultTabBar } from "../components/DefaultTabBar/DefaultTabBar";
import getColorScheme from "../hooks/useColorScheme";

import AuthorsScreen from "../screens/AuthorsScreen";
import StoryScreen from "../screens/ChangelogStoryScreen";

import EnterAdditionalUserInformationScreen from "../screens/EnterAdditionalUserInformationScreen";

import SelectionInformationFromListComponent from "../screens/login-registry/registry/AdditionalInformation/SelectionInformationFromListComponent";
import UserEngagementScreen from "../screens/login-registry/registry/UserEngagementScreen";

import MoreScreen from "../screens/more/MoreScreen";
import { AccountScreen } from "../screens/more/profile/AccountScreen";
import { ImpressScreen } from "../screens/more/profile/legal/ImpressScreen";
import { LegalScreen } from "../screens/more/profile/legal/LegalScreen";
import { PrivacyPolicyScreen } from "../screens/more/profile/legal/PrivacyPolicyScreen";
import { TermsAndConditionsScreen } from "../screens/more/profile/legal/TermsAndConditionsScreen";
import DefaultScreen from "../screens/NavigationScreens/DefaultScreen";

import ChecklistScreen from "../screens/ChecklistScreen/ChecklistScreen";
import ExportPreviewScreen from "../screens/ExportPreviewScreen";
import NonMedicalRegistrationScreen from "../screens/login-registry/NonMedicalRegistryScreen";
import GoogleMapsLocationAutoCompleteInputComponent from "../screens/login-registry/registry/AdditionalInformation/GoogleMapsLocationAutoCompleteInputComponent";
import ResultPageDemo from "../screens/NavigationScreens/ResultPageDemoRouter";
import NotesOverviewScreen from "../screens/NotesOverviewScreen";
import PushNotificationScreen from "../screens/PushNotificationScreen";
import PersonalContentScreen from "../screens/tab-navigator-screens/PersonalContent/PersonalContentScreen";
import UpdatePopover from "../screens/UpdateEnforceScreen";
import { NotificationHandlingWrapper } from "./NotificationHandlingWrapper";
import EnterMissingInformationModalScreen from "../screens/login-registry/registry/EnterMissingInformationModal";
import AddPatientScreen from "../screens/AddPatientScreen";

const isMobileWeb =
  Platform.OS === "web" && Dimensions.get("window").width < 500;

export default function Navigation() {
  const colorScheme = getColorScheme();

  const linking = {
    prefixes: ["com.mediceo.app://", "app.mediceo.com://"],
    config: {
      screens: {
        LoginScreen: "path",
        ResultPageDemo: "demo",
        BottomTabNavigator: {
          screens: {
            HomeScreen: "shared",
            // Define other tabs if needed
          },
        },
        DefaultScreen: "*",
      },
    },
  };

  return (
    <NavigationContainer
      linking={linking as any}
      fallback={DefaultScreen as any}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <NotificationHandlingWrapper>
        <PostHogProvider client={posthogAsync as any}>
          <RootNavigator />
        </PostHogProvider>
      </NotificationHandlingWrapper>
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const colorScheme = getColorScheme();

  return (
    <Stack.Navigator
      initialRouteName={
        isMobileWeb ? "MobileFirstLandingScreen" : "LaunchScreen"
      }
    >
      <Stack.Group
        screenOptions={{
          headerTintColor: Colors[colorScheme].text,
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      >
        <Stack.Screen
          name="LaunchScreen"
          component={LaunchScreen}
          options={{ headerShown: false, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="MobileFirstLandingScreen"
          component={MobileFirstLandingScreen}
          options={{ headerShown: false, title: "MEDICEO®" }}
        />

        <Stack.Screen
          name="LandingScreen"
          component={LandingScreen}
          options={{ headerShown: false, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="WaitingForRegistrationScreen"
          component={WaitingForRegistrationScreen}
          options={{ headerShown: false, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="PreLoginDatenschutz"
          component={PreLoginDatenschutz}
          options={{ headerShown: true, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="PreLoginPrivacyPolicy"
          component={PreLoginPrivacyPolicy}
          options={{ headerShown: true, title: "MEDICEO®" }}
        />
        <Stack.Screen
          options={{
            title: "MEDICEO®",
            header: () => <DefaultBackHeader title={i18n.t("log_in")} />,
          }}
          name="LoginScreen"
          component={LoginScreen}
        />
        <Stack.Screen
          options={{
            title: "MEDICEO®",
            header: () => <DefaultBackHeader title={""} />,
          }}
          name="NonMedicalRegistrationScreen"
          component={NonMedicalRegistrationScreen}
        />
        <Stack.Screen
          name="NewRegistryScreen"
          component={NewRegistryScreen}
          options={{
            title: "MEDICEO®",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="EnterLoginDataScreen"
          component={EnterLoginDataScreen}
          options={{
            title: "MEDICEO®",
            header: () => <DefaultBackHeader />,
          }}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={{
            title: i18n.t("reset_password.title"),
            header: () => (
              <DefaultBackHeader title={i18n.t("reset_password.title")} />
            ),
          }}
        />
        <Stack.Screen
          name="SelectionInformationFromListComponent"
          component={SelectionInformationFromListComponent}
          options={{
            presentation: "modal",
            headerShown: false,
            title: "Auswahl",
          }}
        />
        <Stack.Screen
          name="EnterMissingInformationModalScreen"
          component={EnterMissingInformationModalScreen}
          options={{
            presentation: "modal",
            title: "",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="RoleSelectionScreen"
          component={RoleSelectionScreen}
          options={() => ({
            header: () => <DefaultBackHeader />,
            title: "MEDICEO®",
          })}
        />
      </Stack.Group>
      <Stack.Group
        screenOptions={{
          title: "MEDICEO®",
        }}
      >
        <Stack.Screen
          name="ResultPageScreen"
          component={ResultPageScreen}
          options={{ header: () => <ResultPageHeader />, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="ChecklistScreen"
          component={ChecklistScreen}
          options={({ route }) => ({
            header: () =>
              route.params?.modal ? (
                <DefaultBackHeader isModal={route.params?.modal} />
              ) : (
                <ResultPageHeader isModal={route.params?.modal} />
              ),
            title: "MEDICEO®",
            presentation: route.params?.modal ? "modal" : "card", // Use 'card' or 'fullScreenModal' depending on what you want
          })}
        />
        <Stack.Screen
          name="AddPatientScreen"
          component={AddPatientScreen}
          options={() => ({
            header: () => <DefaultBackHeader isModal />,
            title: "MEDICEO®",
            presentation: "modal",
          })}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            presentation: "containedModal",
          }}
          name="StoryScreen"
          component={StoryScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="DefaultScreen"
          component={DefaultScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="ResultPageDemo"
          component={ResultPageDemo}
        />
        <Stack.Screen
          options={({ route }) => ({
            headerShown: false,
            gestureEnabled: false,
            presentation: route.params?.useCardPresentation
              ? "card"
              : "containedModal",
          })}
          name="PushNotificationScreen"
          component={PushNotificationScreen}
        />
        <Stack.Screen
          options={({ route }) => ({
            headerShown: false,
            gestureEnabled: false,
            presentation: route.params?.useCardPresentation
              ? "card"
              : "containedModal",
          })}
          name="UpdateEnforcerScreen"
          component={UpdatePopover}
        />

        <Stack.Screen
          name="GoogleMapsLocationAutoCompleteInputComponent"
          component={GoogleMapsLocationAutoCompleteInputComponent}
          options={{
            presentation: "modal",
            title: "Auswahl",
          }}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="UserEngagementScreen"
          component={UserEngagementScreen}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader />,
          }}
          name="TippsScreen"
          component={TippsScreen}
        />
        <Stack.Screen
          options={{ header: () => <DefaultBackHeader />, title: "MEDICEO®" }}
          name="TippsDetailScreen"
          component={TippsDetailScreen}
        />
        <Stack.Screen
          name="CMSNodePageScreen"
          component={CMSNodePageScreen}
          options={{ header: () => <ResultPageHeader />, title: "MEDICEO®" }}
        />
        <Stack.Screen
          name="InfoResultPageScreen"
          component={InfoResultPageScreen}
          options={{
            headerShown: false,
            title: "MEDICEO®",
            presentation: "modal",
          }}
        />
        <Stack.Screen
          name="CreateNoteScreen"
          component={CreateNoteScreen}
          options={{
            headerShown: false,
            title: "MEDICEO®",
            presentation: "modal",
          }}
        />
        <Stack.Screen
          name="ScoreScreen"
          component={ScoreScreen}
          options={({ route }) => ({
            header: () => <ResultPageHeader isModal={route.params?.modal} />,

            title: "MEDICEO®",
            presentation: route.params?.modal ? "modal" : "card", // Use 'card' or 'fullScreenModal' depending on what you want
          })}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Impressum" />,
            title: "MEDICEO®",
          }}
          name="ImpressScreen"
          component={ImpressScreen}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Datenschutzerklärung" />,
            title: "MEDICEO®",
          }}
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Nutzungsbedingungen" />,
            title: "MEDICEO®",
          }}
          name="TermsAndConditionsScreen"
          component={TermsAndConditionsScreen}
        />
        <Stack.Screen
          name="TablePopupView"
          component={TablePopupView}
          options={{ presentation: "modal", headerShown: false }}
        />
        <Stack.Screen
          name="FeedbackScreen"
          component={FeedbackScreen}
          options={{ presentation: "containedModal", headerShown: false }}
        />
        <Stack.Screen
          name="WebBrowserScreen"
          component={WebBrowserScreen}
          options={{ presentation: "modal", headerShown: false }}
        />
        <Stack.Screen
          name="ExportPreviewScreen"
          component={ExportPreviewScreen}
          options={{ presentation: "modal", headerShown: false }}
        />
        <Stack.Screen
          name="MoreScreen"
          component={MoreScreen}
          options={{
            header: () => <DefaultBackHeader title="Profil" />,
          }}
        />
        <Stack.Screen
          name="AuthorsScreen"
          component={AuthorsScreen}
          options={{
            header: () => <DefaultBackHeader title="Autoren" />,
            title: "MEDICEO®",
          }}
        />
        <Stack.Screen
          name="LegalScreen"
          component={LegalScreen}
          options={{
            header: () => <DefaultBackHeader title="Rechtliches" />,
            title: "MEDICEO®",
          }}
        />
        <Stack.Screen
          name="AccountScreen"
          component={AccountScreen}
          options={{
            header: () => <DefaultBackHeader title="Mein Konto" />,
            title: "MEDICEO®",
          }}
        />
        <Stack.Screen
          name="NotesOverviewScreen"
          component={NotesOverviewScreen}
          options={{
            header: () => <DefaultBackHeader title="Meine Notizen" />,
            title: "MEDICEO®",
          }}
        />

        <Stack.Screen
          options={{
            title: "MEDICEO®",
            headerShown: false,
            presentation: "modal",
          }}
          name="EnterAdditionalUserInformationScreen"
          component={EnterAdditionalUserInformationScreen}
        />
        <Stack.Screen
          options={{
            title: "MEDICEO®",
            header: () => <ResultPageHeader />,
          }}
          name="UnitCalculatorScreen"
          component={UnitCalculatorScreen}
        />
        <Stack.Screen
          options={{
            title: "MEDICEO®",
            header: () => <ResultPageHeader />,
          }}
          name="UnitCalculatorScreenSubstances"
          component={UnitCalculatorSubstancesScreen}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={{
            header: () => <DefaultHeader />,
            title: "MEDICEO®",
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const tabBarIconSize = 22;

  return (
    <BottomTab.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: false,
        title: "MEDICEO®",
      }}
      tabBar={(props) => <DefaultTabBar {...props} />}
    >
      <BottomTab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          tabBarLabel: "Home",
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"home"} size={tabBarIconSize} />
          ),
        }}
      />
      <BottomTab.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={{
          tabBarLabel: i18n.t("content"),
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"search"} size={tabBarIconSize} />
          ),
        }}
      />

      <BottomTab.Screen
        name="PersonalContentScreen"
        component={PersonalContentScreen}
        options={{
          tabBarLabel: i18n.t("more"),
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"heart"} size={tabBarIconSize} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}
