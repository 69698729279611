import { useEffect, useState } from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";

import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../api/api-call";
import staticColors from "../../constants/static-colors";
import { showInlineNotification } from "../../entry/Root";
import {
  selectCurrentResultPage,
  selectExportList,
  selectResultPageCaseContent,
  setResultPageCaseContent,
} from "../../functions/current-result/actions";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";
import CheckBox from "../CheckBoxComponent";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../NotificationComponent";
import { getIsOffline } from "../search-tab/SearchResults";
import CustomModal from "./CustomModal";
// import other necessary components and styles
import { useNavigation } from "@react-navigation/native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { generatePDF, generatePDFScore } from "../../api/pdf/generate-pdf";
import {
  selectAllAmounts,
  selectChecklistData,
  selectChecklistNote,
  selectScoreData,
} from "../../functions/calculator/actions";
import { updateCase } from "../../functions/case-functions";
import { updateOfflineUser } from "../../functions/user/functions";
import { Case } from "../../functions/user/types";
import { omitUndefinedDeep } from "../../screens/ResultPageScreen/components/ExpandableFABMenu";
import { reloadUser } from "../../screens/more/MoreScreen";

export default function AddCaseModal({
  open,
  onClose,
  type = "",
  generatePdfContent,
}: {
  readonly open;
  readonly onClose;
  readonly type?: string;
  readonly generatePdfContent?: any;
}) {
  const updateData = useSelector(selectResultPageCaseContent);
  const [text, setText] = useState(updateData?.note ?? "");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();
  const exportList = useSelector(selectExportList);

  // AMOUNT

  const amounts = useSelector(selectAllAmounts);
  const scoreData = useSelector(selectScoreData);
  const checklistData = useSelector(selectChecklistData);
  const checklistNote = useSelector(selectChecklistNote);
  const currentPage = useSelector(selectCurrentResultPage);

  const caseData = {
    amounts,
    ...(scoreData ?? {}),
    checklistData,
    checklistNote,
  };

  const pageId = currentPage?._id;

  useEffect(() => {
    if (!open) return;
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "rating_modal_opened",
      "RatingModal",
      "Presented"
    );
  }, []);

  async function saveCase() {
    const body = {
      case_data: omitUndefinedDeep(caseData),
      never_expire: checked,
      note: text ?? null,
      result_page_id: currentPage?._id,
    };

    setLoading(true);

    let newCase: Case;
    let allCases;
    if (await getIsOffline()) {
      newCase = {
        ...body,
        case_id: Math.random(),
        last_updated: new Date().toISOString(),
      } as any;
      allCases = [...(currentUser?.cases ?? [])];
      allCases.push(newCase);
    } else {
      const response = await apiCall("/api/v3/users/case", body);

      if (response?.data?.cases) {
        newCase = response.data.cases.reduce((latest, current) => {
          // Convert last_updated string to Date object for comparison
          const latestDate = new Date(latest.last_updated);
          const currentDate = new Date(current.last_updated);

          // Return the case with the more recent last_updated date
          return currentDate > latestDate ? current : latest;
        });
        allCases = response.data.cases;
      } else {
        showInlineNotification({
          title: "Ein Fehler ist aufgetreten, bitte versuche es erneut",
          type: InlineNotificationType.ERROR,
        });
        setLoading(false);
        return;
      }
    }

    if (newCase) {
      // Assuming newCase is defined and means the operation was successful
      // Analytics tracking for creating a new case
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "case_created",
        "AddCaseModal",
        "User Created a New Case",
        { caseId: newCase.case_id, neverExpire: checked, note: text }
      );
    }

    const _user = { ...currentUser };
    _user.cases = allCases;
    dispatch(setCurrentUser(_user));

    dispatch(
      setResultPageCaseContent({
        note: newCase?.note ?? "",
        last_updated: newCase.last_updated,
        caseData: newCase.case_data,
        caseId: newCase.case_id,
      })
    );
    updateOfflineUser(_user);

    setLoading(false);
    onClose();
  }

  function getTitle() {
    if (type === "PDF_EXPORT") return "PDF exportieren";
    else if (updateData) return "Fall bearbeiten";
    else return "Neuen Fall speichern";
  }

  function getDescription() {
    if (type === "PDF_EXPORT") return "Gib deinem Export eine Beschreibung:";
    else if (updateData) return "Bearbeite deine Beschreibung:";
    else return "Gib deinem Fall eine Beschreibung, um ihn wiederzuerkennen.";
  }

  return (
    <CustomModal open={open} onClose={onClose}>
      <View style={{}}>
        <Text
          style={{
            marginBottom: 20,
            fontSize: 24,
            fontWeight: "500",
            color: staticColors[colorScheme].text,
          }}
        >
          {getTitle()}
        </Text>
        <Text
          style={{
            color: staticColors[colorScheme].text,
          }}
        >
          {getDescription()}
        </Text>
        <TextInput
          placeholder={"Fallnummer, Symptome, ..."}
          placeholderTextColor={staticColors[colorScheme].placeholder}
          style={{
            backgroundColor: staticColors[colorScheme].background,
            color: staticColors[colorScheme].text,
            borderRadius: 12,
            height: 50,
            padding: 12,
            marginVertical: 20,
          }}
          defaultValue={updateData?.note}
          onChangeText={(_text) => {
            setText(_text);
          }}
        />

        {!updateData && type !== "PDF_EXPORT" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 30,
              alignSelf: "center",
            }}
          >
            <CheckBox isSelected={checked} setSelected={setChecked} />
            <TouchableOpacity onPress={() => setChecked((s) => !s)}>
              <Text
                style={{
                  marginLeft: 8,
                  fontWeight: "500",
                  fontSize: 14,
                  color: staticColors[colorScheme].text,
                }}
              >
                Fall automatisch nach 48h löschen
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <DefaultButton
          title={type === "PDF_EXPORT" ? "Exportieren" : "Speichern"}
          action={async () => {
            if (type === "PDF_EXPORT") {
              setLoading(true);
              let response;
              if (generatePdfContent) {
                response = await generatePDFScore(
                  currentPage.title ?? currentPage.fieldTitle,
                  generatePdfContent.answers,
                  generatePdfContent.interpretation,
                  generatePdfContent.score,
                  text,
                  pageId
                );
              } else {
                response = await generatePDF(
                  currentPage?.title,
                  exportList,
                  text,
                  pageId
                );
              }
              setLoading(false);
              if (response?.status === 200) {
                onClose();
                reloadUser(currentUser, dispatch);
                showInlineNotification({
                  text: (
                    <Text>
                      PDF erfolgreich exportiert{"\n"}
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate("ExportPreviewScreen", {
                            export: response.data,
                          });
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginTop: 8,
                            textDecorationLine: "underline",
                          }}
                        >
                          Jetzt öffnen
                        </Text>
                      </TouchableOpacity>
                    </Text>
                  ),
                  type: InlineNotificationType.SUCCESS,
                  direction: InlineNotificationDirection.FROM_BOTTOM,
                  duration: 5000,
                });
              } else {
                showInlineNotification({
                  title: "Ein Fehler ist aufgetreten, bitte versuche es erneut",
                  type: InlineNotificationType.ERROR,
                });
              }
            } else if (updateData) {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "case_updated",
                "AddCaseModal",
                "User Updated a Case",
                { caseId: updateData.caseId, note: text }
              );
              await updateCase(updateData.caseId, caseData, currentUser, text);
              setLoading(false);
              onClose();
            } else saveCase();
          }}
          loading={loading}
          type={ButtonTypes.Primary}
        />
      </View>
    </CustomModal>
  );
}
