import { Feather } from "@expo/vector-icons";
import { Text, TouchableOpacity } from "react-native";
import Colors from "../../constants/static-colors";

export const ShowAllButton = ({
  action,
  title,
  style,
  showIcon = false,
}: {
  action: any;
  title: string;
  style?: any;
  showIcon?: boolean;
}) => {
  return (
    <TouchableOpacity
      style={{
        alignSelf: "flex-end",
        marginTop: 12,
        ...style,
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={action}
    >
      <Text style={{ color: Colors["light"].accentDark }}>{title}</Text>
      {showIcon && (
        <Feather
          name="arrow-right"
          style={{ marginLeft: 4 }}
          size={15}
          color={Colors["light"].accentDark}
        />
      )}
    </TouchableOpacity>
  );
};
