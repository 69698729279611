import { useNavigation } from "@react-navigation/native";
import { FlatList, SafeAreaView } from "react-native";
import { useSelector } from "react-redux";
import { ResultNote } from "../components/notes/ResultNote";
import { Sizes } from "../constants/static-sizes";
import { selectCurrentUser } from "../functions/user/actions";
import isLargerDevice from "../hooks/isLargerDevice";

export default function NotesOverviewScreen() {
  const currentUser = useSelector(selectCurrentUser);
  const notes = currentUser.notes;
  const navigation = useNavigation();

  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <FlatList
        data={notes.filter((note) => note?.note !== "").reverse()}
        contentContainerStyle={{
          paddingVertical: Sizes.defaultContainerPadding,
          gap: isLargerDevice() ? 32 : 16,
          paddingHorizontal: Sizes.defaultContainerPadding,
        }}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          return (
            <ResultNote
              note={{ result_page_id: item.id, text: item.note }}
              navigation={navigation}
            />
          );
        }}
      />
    </SafeAreaView>
  );
}
