import React from "react";
import { Text, View } from "react-native";
import RenderHtml from "react-native-render-html";
import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";

export default function ScoreElementConditionalStaticComponent({ data }) {
  const colorScheme = getColorScheme();

  return (
    <View style={styles.rowContainer}>
      <View
        style={{
          flex: 2,
          justifyContent: "center",
          borderColor: "#ddd",
          padding: 14,
          borderBottomWidth: 1,
          backgroundColor: staticColors[colorScheme].foreground,
        }}
      >
        <BoxTextComponent
          style={{
            body: {
              fontSize: Sizes.boxText,
              color: staticColors[colorScheme].text,
            },
            p: {
              margin: 0,
              padding: 0,
            },
          }}
          content={data.conditionTitle}
        />
      </View>

      <View style={{ flex: 3 }}>
        <View style={styles.answerContainer}>
          <RenderHtml
            contentWidth={Sizes.boxText}
            source={{ html: data.positiveAnswerTitle }}
            tagsStyles={{
              strong: {
                fontWeight: "bold",
                color: staticColors[colorScheme].text,
              },
              p: {
                color: staticColors[colorScheme].text,
                fontSize: Sizes.boxText,
              },
              ul: { marginVertical: 4, paddingLeft: 20 },
              li: {
                color: staticColors[colorScheme].text,
                fontSize: Sizes.boxText,
              },
            }}
          />
        </View>
        {data.positiveConditionScoreElement.map((item, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderColor: "#ddd",
              borderBottomWidth:
                index === data.positiveConditionScoreElement.length - 1 &&
                data.negativeConditionScoreElement.length === 0
                  ? 1
                  : 1,
              padding: 14,
              backgroundColor: staticColors[colorScheme].foreground,
            }}
          >
            <View style={{ flex: 2 }}>
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  color: staticColors[colorScheme].text,
                }}
              >
                {item.title}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  color: staticColors[colorScheme].text,
                }}
              >
                {item.value || "N/A"}
              </Text>
            </View>
          </View>
        ))}
        <View style={styles.answerContainer}>
          <RenderHtml
            contentWidth={Sizes.boxText}
            source={{ html: data.negativeAnswerTitle }}
            tagsStyles={{
              strong: {
                fontWeight: "bold",
                color: staticColors[colorScheme].text,
              },
              p: {
                color: staticColors[colorScheme].text,
                fontSize: Sizes.boxText,
              },
              ul: { marginVertical: 4, paddingLeft: 20 },
              li: {
                color: staticColors[colorScheme].text,
                fontSize: Sizes.boxText,
              },
            }}
          />
        </View>
        {data.negativeConditionScoreElement.map((item, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderColor: "#ddd",
              borderBottomWidth:
                index === data.negativeConditionScoreElement.length - 1 ? 1 : 1,
              padding: 14,
              backgroundColor: staticColors[colorScheme].foreground,
            }}
          >
            <View style={{ flex: 2 }}>
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  color: staticColors[colorScheme].text,
                }}
              >
                {item.title}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  color: staticColors[colorScheme].text,
                }}
              >
                {item.value || "N/A"}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = {
  rowContainer: {
    flexDirection: "row",
    borderColor: "#ddd",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    overflow: "hidden",
  },
  answerContainer: {
    padding: 14,
    borderBottomWidth: 1,
    borderColor: "#ddd",
    backgroundColor: staticColors[getColorScheme()].foreground,
  },
};
