import { Dimensions, Platform } from "react-native";

export default function isLargerDevice() {
  return Dimensions.get("window").width > 700;
}

export function isWebAndNotMobile() {
  const condition =
    Dimensions.get("window").width > 500 && Platform.OS === "web";
  return condition;
}
