import i18n from "../../../localization/i18n";
import staticColors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { isNumeric } from "../../functions/current-result/helpers";
import getColorScheme from "../../hooks/useColorScheme";

export function getSourceParsedText(
  text: string | undefined,
  shouldShowSource: boolean
) {
  if (!text) {
    return "";
  }
  const COMPARISON = i18n.locale.includes("en") ? "{_source" : "{_quelle";

  text = replaceSourceIndexWithString(text, shouldShowSource);

  if (!text) {
    return "";
  }

  if (!text.includes(COMPARISON)) return text;

  let textParts: string[] = text.split(COMPARISON);

  // # BEISPIEL "Das ist der Text einer Quelle{_quelle1}. Vielleicht sind hier sogar zwei Quellen vorhanden{_quelle1, 2}"
  textParts = textParts.map((part, index) => {
    if (index === 0) return part;
    let i = 0;
    let startsWithSpace = false;
    if (part[0].startsWith(" ")) {
      startsWithSpace = true;
      part = part.slice(1);
    }

    for (const char of part) {
      if (!isNumeric(char) && char !== " " && char !== ",") {
        break;
      }
      i += 1;
    }

    const numberString = part.substring(0, i);
    let numbers: string[] = [];
    if (numberString.includes(",")) {
      numbers = numberString.split(",").map((n) => n.trim().replace(/,/g, ""));
    } else {
      numbers = [numberString];
    }

    part = part.slice(i + 1);
    const superscripts = numbers
      .map((number) => {
        return superscript(number);
      })
      .join("˒");
    if (shouldShowSource) {
      part = "<span style='font-size: 17px'>" + superscripts + "</span>" + part;
    }
    if (startsWithSpace) part = " " + part;
    return part;
  });
  return textParts.join("");
}

function replaceSourceIndexWithString(inputString, useIndex) {
  // Regex pattern to match $source_index$ where "index" is a digit or digits
  const colorScheme = getColorScheme();
  const pattern = /\$source_(\d+)\$/g;

  // Replacement function
  const replacementFunction = (match, index) => {
    // Check the boolean condition to decide replacement value
    if (useIndex) {
      return `<span style="font-size: ${Sizes.boxText - 2}px; color: ${
        staticColors[colorScheme].text
      }; opacity: 0.75;">(${index})</span>`; // If true, replace with (index)
    } else {
      return ""; // If false, replace with an empty string
    }
  };

  // Replace in the input string using the pattern and replacement function
  let resultString = inputString.replace(pattern, replacementFunction);
  resultString = processHtmlString(resultString);

  return resultString;
  //return consolidateIndices(resultString) ?? "";
}

const processHtmlString = (htmlString) => {
  // Function to process each section of HTML where spans with numbers appear
  const processSection = (section) => {
    const numberPattern = /<span[^>]*>\((\d+)\)<\/span>/g;
    let match;
    const numbers = new Set();

    // Extract numbers within the current section and add to Set (to remove duplicates)
    while ((match = numberPattern.exec(section)) !== null) {
      numbers.add(parseInt(match[1], 10));
    }

    // Convert Set to array and sort the numbers
    const sortedNumbers = Array.from(numbers).sort((a: any, b: any) => a - b);

    // Create a new span with the sorted numbers
    const sortedNumbersSpan =
      sortedNumbers.length > 0
        ? `<span style="font-size: 13px; color: #000000; opacity: 0.75;">(${sortedNumbers.join(
            ","
          )})</span>`
        : "";

    // Replace the old spans with the new sorted span
    return section
      .replace(numberPattern, "")
      .replace(/\s*<\/(li|p)>/, ` ${sortedNumbersSpan}</$1>`);
  };

  // Regex to match either list items <li>...</li> or paragraphs <p>...</p> separately
  const listItemPattern = /<li[^>]*>.*?<\/li>/gs;
  const paragraphPattern = /<p[^>]*>.*?<\/p>/gs;

  // Process all <li> elements
  htmlString = htmlString.replace(listItemPattern, processSection);

  // Process all <p> elements
  htmlString = htmlString.replace(paragraphPattern, processSection);

  return htmlString;
};

const DIGITS = {
  "0": "⁰",
  "1": "¹",
  "2": "²",
  "3": "³",
  "4": "⁴",
  "5": "⁵",
  "6": "⁶",
  "7": "⁷",
  "8": "⁸",
  "9": "⁹",
};

function superscript(s: any) {
  return s
    .split("")
    .map(function (ch: any) {
      if (ch in DIGITS) {
        return DIGITS[ch];
      }
      return ch;
    })
    .join("");
}
