import { colorParser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import { InfoBox } from "./InfoBox";

export default function getInfoBox(box: ResultBox) {
  return (
    <InfoBox
      key={box.id}
      title={box.fieldTitle}
      color={colorParser(box.colour)}
      id={box.resultId}
    />
  );
}
