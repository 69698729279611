import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";
import StandardBox from "../StandardBox";
import { InlineImage } from "../inline-image-types";

interface FirstResultProps {
  title: string;
  expandableContent: string | undefined;
  inlineImage: InlineImage | undefined;
  hideIcon?: boolean;
  insertedContent?: any;
}

export function WarningBox(props: FirstResultProps) {
  const colorScheme = getColorScheme();

  return (
    <StandardBox
      {...{ color: Colors[colorScheme].red, isWarning: true, props: props }}
    />
  );
}
