import { Platform, TouchableOpacity, View } from "react-native";
import RNHapticFeedback from "react-native-haptic-feedback";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import i18n from "../../../../localization/i18n";

import { setSearchBarShouldFocus } from "../../../functions/navigation/actions";

import { useEffect, useRef, useState } from "react";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import staticColors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";

import {
  CoachMark,
  evaluateCoachMarkAction,
} from "../../guide-mark/components/GuideMark";
import BlurViewWrapper from "../../BlurViewWrapper/BlurViewWrapper";

const MobileTabBar = ({ state, descriptors, navigation }) => {
  const insets = useSafeAreaInsets();

  const dispatch = useDispatch();

  const colorScheme = getColorScheme();

  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReload((r) => !r);
    }, 50);
  }, [navigation]);

  const ref = useRef<any>(null);

  useEffect(() => {
    evaluateCoachMarkAction(
      setIsCoachMarkVisible,
      "did_show_personal_screen",
      4
    );
  }, []);

  return (
    <>
      <CoachMark
        shape="rect"
        borderRadius={8}
        positionRef={ref}
        onClose={() => setIsCoachMarkVisible(false)}
        title="Dein persönlicher Bereich"
        text="Hier findest du alle deine gespeicherten Fälle und Favoriten, sowie deinen Verlauf."
        visible={isCoachMarkVisible}
        direction="top"
      />

      <View
        style={{
          margin: 8,
          paddingBottom: insets.bottom > 0 ? insets.bottom : 8,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <View
          style={{
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 3,
            },
            flex: 1,
            width: "80%",
            alignSelf: "center",
            maxWidth: 400,
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            borderRadius: 34,
            elevation: 3,
            overflow: Platform.OS === "android" ? "hidden" : "visible",
          }}
        >
          <BlurViewWrapper
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: 34,
              alignSelf: "stretch",
              padding: 6,
              backgroundColor: staticColors[colorScheme].foreground + "4d",
            }}
          >
            {state?.routes?.map((route, index) => {
              const { options } = descriptors[route.key];
              const isFocused = state.index === index;

              const onPress = () => {
                RNHapticFeedback.trigger("impactLight");
                const event = navigation.emit({
                  type: "tabPress",
                  target: route.key,
                  canPreventDefault: true,
                });

                if (!isFocused && !event.defaultPrevented) {
                  navigation.navigate(route.name);
                }

                if (
                  isFocused &&
                  !event.defaultPrevented &&
                  (options.tabBarLabel === i18n.t("content") ||
                    options.tabBarLabel === "Toolbox")
                ) {
                  dispatch(setSearchBarShouldFocus(true));
                }

                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "tab_press",
                  "MobileTabBar",
                  "User Pressed Tab",
                  { tabName: route.name, focused: isFocused }
                );
              };

              const onLongPress = () => {
                navigation.emit({
                  type: "tabLongPress",
                  target: route.key,
                });

                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "tab_long_press",
                  "MobileTabBar",
                  "User Long Pressed Tab",
                  { tabName: route.name }
                );
              };

              const icon = options.tabBarIcon({
                focused: isFocused,
                color: isFocused ? "white" : "black",
                size: 30,
              });

              return (
                <TouchableOpacity
                  ref={(_ref) => {
                    if (index === 2) ref.current = _ref;
                  }}
                  key={route.key + (reload ? "_" : "")}
                  onPress={onPress}
                  onLongPress={onLongPress}
                  style={{
                    alignItems: "center",
                    borderRadius: 28,
                    backgroundColor: isFocused ? "#1C1C1E" : "#1C1C1E14",
                    height: 56,
                    width: 56,
                    justifyContent: "center",
                  }}
                >
                  {icon}
                </TouchableOpacity>
              );
            })}
          </BlurViewWrapper>
        </View>
      </View>
    </>
  );
};

export default MobileTabBar;
