import { Image, View } from "react-native";
import staticColors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";
import { Feather } from "@expo/vector-icons";
import i18n from "../../localization/i18n";
import { selectAllContent } from "../functions/data/actions";
import { useSelector } from "react-redux";
import ImageWrapper from "./ImageWrapper";

export const CategoryIcon = ({
  style = {},
  title,
  size = 44,
  iconColor,
}: {
  style?: any;
  title: string;
  size?: number;
  iconColor?: any;
}) => {
  return (
    <View
      style={{
        aspectRatio: 1,
        padding: 0,
        marginRight: 12,

        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <IconToCategory
        iconColor={iconColor}
        title={title}
        size={size}
        style={style}
      />
    </View>
  );
};

export function IconToCategory({
  title,
  size = 24,
  style,
  iconColor = null,
}: {
  readonly title: string;
  readonly size?: number;
  readonly style?: any;
  readonly iconColor?: any;
}) {
  const allData = useSelector(selectAllContent);
  const contentList = allData.contentList?.content?.medicalSubjectAreaItems;

  const contentListItem = contentList?.find((item) => item.title === title);

  const colorScheme = getColorScheme();
  const imageSource = getImageToTitle(title);

  if (contentListItem?.categoryImage?.file_ref)
    return (
      <ImageWrapper
        source={{ uri: contentListItem?.categoryImage?.file_ref }}
        style={{ ...style, width: size, height: size }}
      />
    );
  if (imageSource)
    return (
      <Image
        source={imageSource}
        style={{
          ...style,
          width: size,
          height: size,
          resizeMode: "contain",
          tintColor: iconColor ?? staticColors[colorScheme].text,
        }}
      />
    );
  else
    return (
      <Feather
        name="file-text"
        size={size}
        style={{ padding: 2, ...style }}
        color={iconColor ?? staticColors[colorScheme].text}
      />
    );
}

function getImageToTitle(title: string) {
  switch (title) {
    case i18n.t("emergency"):
      return require("../../assets/images/icons/new_icons/emergency.png");
    case "Fast-Track":
      return require("../../assets/images/icons/new_icons/fast-track.png");
    case i18n.t("diseases"):
      return require("../../assets/images/icons/new_icons/disease.png");
    case i18n.t("interventions"):
      return require("../../assets/images/icons/new_icons/interventions.png");
    case i18n.t("therapy_algorithms"):
      return require("../../assets/images/icons/new_icons/algorithms.png");
    case "Wirkstoffe & Arzneimittel":
      return require("../../assets/images/icons/new_icons/substances.png");
    case i18n.t("scores"):
      return require("../../assets/images/icons/new_icons/scores.png");
    case "Rechner":
      return require("../../assets/images/icons/new_icons/calc.png");
    case "Checklisten":
      return require("../../assets/images/icons/new_icons/clipboard.png");
    default:
      return null;
  }
}
