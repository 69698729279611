export const contentStudentEN = [
  {
    title: "What is your first name?",
    textInput: {
      placeholder: "First name",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "firstname",
  },
  {
    title: "What is your last name?",
    textInput: {
      placeholder: "Last name",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "lastname",
  },
  {
    title: "Which university are you at?",
    textInput: {
      placeholder: "University",
      type: "default",
      contentType: "uni",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "clinic",
  },
  {
    title: "Which semester are you in?",
    textInput: {
      placeholder: "Semester",
      type: "number-pad",
      contentType: "number",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "semester",
  },
];

export const contentDocEN = [
  {
    title: "Do you have a title?",
    textInput: {
      placeholder: "Title",
      type: "default",
      contentType: "title",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "title",
  },
  {
    title: "What is your first name?",
    textInput: {
      placeholder: "First name",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "firstname",
  },
  {
    title: "What is your last name?",
    textInput: {
      placeholder: "Last name",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "lastname",
  },
  {
    title: "Where do you practice?",
    textInput: {
      placeholder: "Clinic / Practice",
      type: "default",
      contentType: "clinic",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "clinic",
  },
];

export const contentNurseEN = [
  {
    title: "What is your first name?",
    textInput: {
      placeholder: "First name",
      type: "default",
      contentType: "givenName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "firstname",
  },
  {
    title: "What is your last name?",
    textInput: {
      placeholder: "Last name",
      type: "default",
      contentType: "familyName",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "lastname",
  },
  {
    title: "Where do you practice?",
    textInput: {
      placeholder: "Clinic / Practice",
      type: "default",
      contentType: "clinic",
    },
    buttonForward: "Next",
    buttonBack: "Back",
    key: "clinic",
  },
];
