import { Text, View, TouchableOpacity } from "react-native";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";
import { Feather } from "@expo/vector-icons";
import staticColors from "../../constants/static-colors";

export default function WorkInProgressModal({ open, onClose, setClosed }) {
  const colorScheme = getColorScheme();
  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Feather
          name="alert-triangle"
          size={150}
          color={Colors[colorScheme].red}
          style={{ alignSelf: "center", marginBottom: 20, opacity: 0.5 }}
        />
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Wartungsarbeiten
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          Auf dieser Seite nehmen wir gerade Wartungsarbeiten vor. Bald ist der
          Inhalt wieder verfügbar.
        </Text>
        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text="Schließen"
          icon={null}
        />
        <TouchableOpacity
          onPress={() => setClosed(false)}
          style={{ marginTop: 20, alignSelf: "center" }}
        >
          <Text
            style={{ opacity: 0.75, color: staticColors[colorScheme].text }}
          >
            Trotzdem ansehen (nicht empfohlen)
          </Text>
        </TouchableOpacity>
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
