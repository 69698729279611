/* eslint-disable @typescript-eslint/no-explicit-any */

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../../components/NotificationComponent";
import { showInlineNotification } from "../../../entry/Root";
import {
  setAllData,
  setIsLoading,
  setVersionRequirements,
} from "../../../functions/data/actions";
import { store } from "../../../functions/store";
import { downloadImage } from "../../image-processor";

import { AnalyticsHandler } from "../../analytics/AnalyticsHandler";
import { CMSData } from "./types";

const CMS_SERVER = "https://cdn.mediceo.com/v3/";

export async function loadEnvironment() {
  const isDevUser =
    store.getState().userReducer.user?.user_type === "dev" || __DEV__;
  if (!isDevUser) return "production";
  const data = await AsyncStorage.getItem("environment");
  if (data) {
    return getParsedEnv(data);
  } else return !isDevUser ? "production" : "development";
}

function getParsedEnv(_data) {
  const data = JSON.parse(_data ?? "null");
  if (data === "dev") return "development";
  if (data === "prod") return "production";
  return data;
}

async function getContentURL(customerId) {
  const environment = await loadEnvironment();
  return (
    CMS_SERVER +
    environment +
    "/de" +
    (customerId ? "?customer_id=" + customerId : "")
  );
}

export default async function loadAllData(customerId = undefined) {
  let hasOfflineData = false;
  let downloadSuccess = false;

  try {
    const data = await AsyncStorage.getItem("storedData");
    if (data !== null) {
      const parsedData = JSON.parse(data);
      if (!parsedData?.pages) throw new Error("No data received");
      if (!parsedData?.contentList) throw new Error("Old data received");
      store.dispatch(setAllData(parsedData));
      hasOfflineData = true;
    }
  } catch {
    hasOfflineData = false;
  }
  if (!hasOfflineData) {
    store.dispatch(setIsLoading(true));
  }

  let _data: any;

  try {
    const signal = new AbortController();
    setTimeout(() => signal?.abort(), 25000); //! ENABLE
    const url = await getContentURL(customerId);

    const response = await fetch(url, {
      signal: signal.signal,
    });

    const jsonData = await response.json();
    if (jsonData) {
      _data = sortData(jsonData);
      store.dispatch(setAllData(_data));
      downloadSuccess = true;
    } else {
      throw new Error("No data received");
    }
  } catch (e) {
    downloadSuccess = false;
  }
  try {
    await AsyncStorage.setItem("storedData", JSON.stringify(_data));
  } catch {
    console.log("Error saving data");
  }

  store.dispatch(setIsLoading(false));

  if (!downloadSuccess && !hasOfflineData) {
    showInlineNotification({
      text: "Keine Daten vorhanden. Bitte verbinde dich einmal mit einer aktiven Internetverbindung",
      type: InlineNotificationType.ERROR,
      direction: InlineNotificationDirection.FROM_BOTTOM,
    });
    return;
  }

  const data = store.getState().dataReducer.allData;
  adjustForImages(data);

  const versionRequirements = await getVersionRequirements();

  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "data_loaded",
    "DataLoaded",
    versionRequirements?.content?.latestVersion,
    null
  );

  store.dispatch(setVersionRequirements(versionRequirements));

  return true;
}

async function getVersionRequirements() {
  try {
    const url = CMS_SERVER + "version_requirements?language=de";
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch {
    return null;
  }
}

async function adjustForImages(data: CMSData) {
  if (Platform.OS === "web") return;
  getAllImageUrls(data);
}

async function getAllImageUrls(data: CMSData) {
  let images: string[] = recurseForImage(data?.tippsData);
  images.push(...recurseForImage(data?.pages));
  images = [...new Set(images)];

  for (const image of images) {
    await downloadImage(image);
  }
}

function recurseForImage(data: any) {
  const _images: string[] = [];

  function _recurse(data: any) {
    for (const key of Object.keys(data)) {
      const value = data[key];
      if (!value) continue;
      if (
        typeof value === "string" &&
        (value.includes("https://mediceocmsassets") ||
          value.includes("//images"))
      ) {
        _images.push(value);
      } else if (typeof value === "object" && Object.keys(value)) {
        _recurse(value);
      }
    }
  }
  _recurse(data);

  return _images;
}

function sortData(_allData) {
  const newData: any = {};

  const allData = _allData["data"];

  const sortedPages = allData.filter(
    (item) =>
      item.content_type_id === "scorePage" ||
      item.content_type_id === "resultPage" ||
      item.content_type_id === "nodePage" ||
      item.content_type_id === "erkrankungResultPageType" ||
      item.content_type_id === "interventionResultPageType" ||
      item.content_type_id === "drugResultPageType" ||
      item.content_type_id === "fastTrackResultPageType" ||
      item.content_type_id === "checklistPageType"
  ); // Filter items with all the required keys
  const medicalCategories = allData.filter(
    (item) => item.content_type_id === "medicalCategories"
  );

  const contentList = allData.filter(
    (item) => item.content_type_id === "medicalSubjectAreas"
  );

  const homeScreenContent = allData.filter(
    (item) => item.content_type_id === "homeScreenContent"
  );
  const tippsContent = allData.filter(
    (item) => item.content_type_id === "tippsPage"
  );

  newData.pages = sortedPages;

  newData.medicalCategories = medicalCategories[0];

  newData.homeScreenContent = homeScreenContent[0];
  newData.contentList = contentList[0];
  newData.tippsData = tippsContent[0];
  return newData;
}
