import { useEffect } from "react";
import { View } from "react-native";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { useDispatch, useSelector } from "react-redux";
import Colors from "../../../../constants/static-colors";
import {
  selectAllAmounts,
  setFormulaResult,
} from "../../../../functions/calculator/actions";
import getColorScheme from "../../../../hooks/useColorScheme";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";

import ActionRecommendationBox from "../action-recommendation-box/ActionRecommendationBox";
import { HintBox } from "../hint-box/HintBox";

import { Sizes } from "../../../../constants/static-sizes";
import BoxComponent from "../../BoxSorter";
import LargeStandardBox from "../LargeStandardBox";
import { WarningBox } from "../warning-box/WarningBox";

export default function FormulaContentContainer({
  emptyPlaceholderBox,
  boxTitle,
  containedFormulas,
  type,
}) {
  const allAmounts = useSelector(selectAllAmounts);
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setFormulaResult(null));
    };
  }, []);

  const isCalculated = () => {
    function evalFormula(formula) {
      formula = formula.replace(/,/g, ".");
      for (const [key, value] of Object.entries(allAmounts)) {
        window[key] = value;
      }
      try {
        const formulaResult = eval(formula);
        for (const [key] of Object.entries(allAmounts)) {
          delete window[key];
        }
        return formulaResult;
      } catch (e) {
        for (const [key] of Object.entries(allAmounts)) {
          delete window[key];
        }
        return false;
      }
    }

    let evaluations = containedFormulas.map((item) => {
      let evalItems: any = [];

      if (!item.exchangeableFormulas) {
        evalItems = [
          {
            formula: item,
            title: "",
            value: evalFormula(item.formula),
          },
        ];
      } else {
        evalItems = item.exchangeableFormulas.map((formula) => {
          return {
            formula: formula,
            title: item.fieldTitle,
            value: evalFormula(formula.formula),
          };
        });
      }
      evalItems = evalItems.filter((item) => item.value);
      if (evalItems.length > 0) {
        dispatch(setFormulaResult(evalItems[0].value));
        return evalItems;
      }
    });
    evaluations = evaluations.filter((item) => item);
    if (evaluations.length === 0) return false;
    else return evaluations;
  };

  const formulas = isCalculated();

  if (!formulas) {
    if (!emptyPlaceholderBox) return null;
    if (Array.isArray(emptyPlaceholderBox)) {
      return emptyPlaceholderBox.map((box) => (
        <BoxComponent key={box.id} box={box} />
      ));
    }
    return <BoxComponent box={emptyPlaceholderBox} />;
  }

  return (
    <View key={boxTitle}>
      <View style={{ zIndex: 1 }}>
        <InlineBox
          {...{ boxTitle, type }}
          insertedContent={
            <View
              style={{
                zIndex: 10,
                width: "100%",
                paddingTop: 8,
              }}
            >
              {formulas.map((f, index) => {
                const inlineF = f[0];
                return (
                  <View
                    style={{
                      //padding: 20,
                      //marginTop: 12,
                      paddingBottom: 32,
                      marginLeft: 4,
                    }}
                    key={inlineF.formula.displayFormula}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        overflow: "hidden",
                        flex: 1,
                      }}
                    >
                      {inlineF.title && (
                        <BoxTextComponent
                          content={`<p>${inlineF.title}:</p>`}
                          style={{
                            body: { margin: 0, padding: 0 },
                            p: {
                              fontSize: Sizes.boxText,
                              paddingRight: 4,
                              padding: 0,
                              margin: 0,
                              marginBottom: 12,
                              marginTop:
                                index !== 0 && formulas.length > 0 ? 12 : 0,
                              fontWeight: "600",
                              color: Colors[colorScheme].text,
                            },
                          }}
                        />
                      )}
                      <BoxTextComponent
                        style={{
                          body: {
                            fontSize: Sizes.boxText + 2,
                            fontWeight: "700",
                            color: Colors[colorScheme].blue,
                          },
                        }}
                        content={
                          Math.round(inlineF.value * 100) / 100 +
                          " " +
                          inlineF.formula.resultUnit
                        }
                      ></BoxTextComponent>
                    </View>
                    <FormulaBoxComponent
                      displayedFormula={inlineF.formula.displayFormula}
                    />
                    {index !== formulas.length - 1 && (
                      <View
                        style={{
                          height: 1,
                          backgroundColor: Colors[colorScheme].lightGray,
                          width: "100%",
                          bottom: 0,
                          alignSelf: "center",
                          position: "absolute",
                        }}
                      />
                    )}
                  </View>
                );
              })}
            </View>
          }
        />
      </View>
    </View>
  );
}

function FormulaBoxComponent({ displayedFormula }) {
  if (!displayedFormula) return null;
  const colorScheme = getColorScheme();

  return (
    <View style={{}}>
      <View
        style={{
          borderRadius: 6,
          zIndex: 2,
          paddingRight: 12,
          overflow: "visible",
          marginTop: 12,
          marginBottom: -20,
        }}
      >
        <BoxTextComponent
          content={displayedFormula}
          style={{
            body: {
              margin: 0,
              padding: 0,
              fontStyle: "italic",
              fontSize: Sizes.boxText,
              color: Colors[colorScheme].text,
            },
            p: {
              margin: 0,
              padding: 0,
            },
          }}
        />
      </View>
    </View>
  );
}

const InlineBox = ({ boxTitle, type, insertedContent }) => {
  switch (type) {
    case "warning":
      return (
        <WarningBox
          title={"<p><b>" + boxTitle + "</b>"}
          expandableContent={undefined}
          inlineImage={undefined}
          hideIcon={true}
          insertedContent={insertedContent}
        />
      );
    case "hint":
      return (
        <HintBox
          title={"<p><b>" + boxTitle + "</b>"}
          expandableContent={undefined}
          inlineImage={undefined}
          insertedContent={insertedContent}
        />
      );
    case "action-recommendation":
      return (
        <ActionRecommendationBox
          title={"<p><b>" + boxTitle + "</b>"}
          expandableContent={undefined}
          inlineImage={undefined}
          insertedContent={insertedContent}
        />
      );
    case "dosing-recommendation-box":
      return (
        <LargeStandardBox
          title={"<p><b>" + boxTitle + "</b>"}
          content={""}
          inlineImage={undefined}
          boxType="dosing"
          insertedContent={insertedContent}
        />
      );
    default:
      return (
        <LargeStandardBox
          title={"<p><b>" + boxTitle + "</b>"}
          content={""}
          inlineImage={undefined}
          boxType="largeContent"
          insertedContent={insertedContent}
        />
      );
  }
};
