import { Feather } from "@expo/vector-icons";
import * as StoreReview from "expo-store-review";
import { default as LottieView } from "lottie-react-native";
import { useEffect, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { posthog } from "../../api/analytics/posthog";
import Colors from "../../constants/static-colors";
import { selectCurrentUser, setHasRated } from "../../functions/user/actions";
import { updateUserPropertyCall } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";

import configureAnimations from "../../functions/configure-animations";
import CustomModal from "./CustomModal";

export default function RatingModal({ open, onClose }) {
  useEffect(() => {
    if (!open) return;
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "rating_modal_opened",
      "RatingModal",
      "Presented"
    );
  }, []);
  return (
    <CustomModal {...{ open, onClose }}>
      <ViewToResponse {...{ onClose }} />
    </CustomModal>
  );
}

const ViewToResponse = ({ onClose }) => {
  const [viewMode, setViewMode] = useState(0); // * 0 init, 1 positive, 2 negative, 3 feedback completed
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    configureAnimations();
    if (viewMode === 1) {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "rating_modal_positive",
        "RatingModal",
        "rating issued",
        "4 - 5"
      );
      setTimeout(() => {
        StoreReview.requestReview().then(async () => {
          if (
            await updateUserPropertyCall(currentUser, "has_rated_the_app", true)
          )
            dispatch(setHasRated(true));
        });
      }, 0);
    } else if (viewMode === 2) {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "rating_modal_negative",
        "RatingModal",
        "rating issued",
        "1-3"
      );
    }
  }, [viewMode]);
  if (viewMode === 0) return <Question {...{ setViewMode }} />;
  else if (viewMode === 1) return <PositiveResponse {...{ onClose }} />;
  else if (viewMode === 2)
    return <NegativeResponse {...{ onClose, setViewMode }} />;
  else return <FeedbackIssued {...{ onClose }} />;
};

const Question = ({ setViewMode }) => {
  const colorScheme = getColorScheme();
  return (
    <View style={{ marginBottom: 12 }}>
      <Text
        style={{
          fontWeight: "500",
          fontSize: 20,
          marginBottom: 12,
          color: Colors[colorScheme].text,
        }}
      >
        Wie gefällt dir MEDICEO?
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          paddingVertical: 12,
          opacity: 0.5,
        }}
      >
        <TouchableOpacity
          style={{ alignItems: "center" }}
          onPress={() => setViewMode(2)}
        >
          <Feather
            name="thumbs-down"
            size={64}
            color={Colors[colorScheme].text}
          />
          <Text
            style={{
              paddingTop: 8,
              fontSize: 15,
              fontWeight: "600",
              marginTop: 4,
              color: Colors[colorScheme].text,
            }}
          >
            Schlecht
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ alignItems: "center" }}
          onPress={() => setViewMode(1)}
        >
          <Feather
            name="thumbs-up"
            size={64}
            color={Colors[colorScheme].text}
          />
          <Text
            style={{
              paddingTop: 8,
              fontSize: 15,
              fontWeight: "600",
              color: Colors[colorScheme].text,
              marginTop: 4,
            }}
          >
            Gut
          </Text>
        </TouchableOpacity>
      </View>
      {/* <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text="Jetzt bewerten"
          icon={null}
        /> */}
    </View>
  );
};

const PositiveResponse = ({ onClose }) => {
  const colorScheme = getColorScheme();
  return (
    <View style={{ marginBottom: 12 }}>
      <Text
        style={{
          fontWeight: "500",
          fontSize: 20,
          marginBottom: 12,
          color: Colors[colorScheme].text,
        }}
      >
        Danke für das Feedback!
      </Text>
      <Text
        style={{
          fontWeight: "400",
          fontSize: 14,
          marginBottom: 12,
          color: Colors[colorScheme].text,
        }}
      >
        Eine Bewertung im{" "}
        {Platform.OS === "android" ? "Play Store" : "AppStore"} hilft uns sehr
        weiter.
      </Text>
      <LottieView
        style={{
          width: "100%",
          height: 150,
          alignSelf: "center",
          zIndex: 10,
        }}
        speed={0.8}
        source={require("../../../assets/animations/cheers.json")}
        autoPlay={true}
        loop={true}
      />
      <DefaultButton
        action={onClose}
        style={{ marginTop: 20 }}
        title="Schließen"
        type={ButtonTypes.Primary}
        icon={null}
      />
    </View>
  );
};

const feedbackItems = [
  "Navigation in der App",
  "Umfang der Inhalte",
  "Qualität der Inhalte",
  "Funktionen der App",
  "Etwas anderes",
];

const NegativeResponse = ({ setViewMode }) => {
  const colorScheme = getColorScheme();
  return (
    <View style={{ marginBottom: 12, marginTop: 20 }}>
      <Text
        style={{
          fontWeight: "500",
          fontSize: 20,
          marginBottom: 12,
          color: Colors[colorScheme].text,
        }}
      >
        Schade, was können wir verbessern?
      </Text>
      {feedbackItems.map((item) => (
        <FeedbackItem setViewMode={setViewMode} key={item} title={item} />
      ))}
    </View>
  );
};

const FeedbackItem = ({ title, setViewMode }) => {
  const colorScheme = getColorScheme();
  const currentUser = useSelector(selectCurrentUser);
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        borderRadius: 12,
        padding: 12,
        alignItems: "center",
        marginVertical: 8,
        backgroundColor: Colors[colorScheme].background,
      }}
      onPress={async () => {
        setViewMode(3);
        // await addFeedbackToResultPage(title, "Rating Feedback", currentUser);
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "feedback_issued_with_data",
          "FeedbackScreen",
          "UserIssuedFeedback",
          {
            id: "Rating Feedback",
            feedback: title,
            user: currentUser.email,
            role: currentUser.role,
          }
        );
        posthog?.capture("user_negative_feedback", {
          action: currentUser?.email,
          values: title,
        });
      }}
    >
      <Text
        style={{ flex: 1, marginRight: 12, color: Colors[colorScheme].text }}
      >
        {title}
      </Text>
      <Feather
        name="chevron-right"
        size={24}
        color={Colors[colorScheme].text}
      />
    </TouchableOpacity>
  );
};

const FeedbackIssued = ({ onClose }) => {
  const colorScheme = getColorScheme();
  return (
    <View style={{ marginBottom: 12 }}>
      <Text
        style={{
          fontWeight: "500",
          fontSize: 20,
          marginBottom: 12,
          color: Colors[colorScheme].text,
        }}
      >
        Danke für das Feedback!
      </Text>
      <DefaultButton
        action={onClose}
        style={{ marginTop: 20 }}
        title="Schließen"
        type={ButtonTypes.Primary}
        icon={null}
      />
    </View>
  );
};
