/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useState } from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import chroma from "chroma-js";
import i18n from "../../localization/i18n";
import { posthogAsync } from "../api/analytics/posthog";
import staticColors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { setSelectedEnterprise } from "../functions/enterprise/actions";
import { store } from "../functions/store";

export default function useInitialAppStateLoading() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  async function getData() {
    // * await posthog initialization
    await posthogAsync;

    // * await font size settings
    let fontSize = await AsyncStorage.getItem("fontSize");
    if (!fontSize) fontSize = Sizes.boxText + "";
    Sizes.boxText = parseInt(fontSize);
    Sizes.lineHeightBoxText = parseInt(fontSize) + 5;

    const selectedEnterpriseData = await AsyncStorage.getItem(
      "selectedEnterprise"
    );
    if (selectedEnterpriseData) {
      const selectedEnterprise = JSON.parse(selectedEnterpriseData);
      if (selectedEnterprise) {
        if (selectedEnterprise.default_language === "en") {
          i18n.locale = "en";
        }

        store.dispatch(setSelectedEnterprise(selectedEnterprise));
        staticColors["light"].accentLight =
          selectedEnterprise.colors.accent_light;
        staticColors["light"].accent = selectedEnterprise.colors.accent_dark;
        staticColors["light"].accentDark = chroma(
          selectedEnterprise.colors.accent_dark
        )
          .darken(1)
          .hex();
        staticColors["light"].selectedTint = chroma(
          selectedEnterprise.colors.accent_light
        )
          .brighten(3)
          .hex();

        //
        staticColors["dark"].accentLight =
          selectedEnterprise.colors.accent_light;
        staticColors["dark"].accent = selectedEnterprise.colors.accent_dark;
        staticColors["dark"].accentDark = chroma(
          selectedEnterprise.colors.accent_dark
        )
          .darken(1)
          .hex();
        staticColors["dark"].selectedTint = chroma(
          selectedEnterprise.colors.accent_light
        )
          .darken(3)
          .hex();
      }
    }
    setIsLoadingComplete(true);
  }

  useEffect(() => {
    getData();
  }, []);

  return isLoadingComplete;
}
