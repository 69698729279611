import {
  addValueToInteractions,
  getValueFromInteractions,
} from "./user-interaction-handler";

export default async function handleOneTimeAction(key) {
  async function add() {
    await addValueToInteractions(key, "false");
  }
  const shouldShow = getValueFromInteractions(key);

  if (shouldShow === null || shouldShow === undefined) {
    await add();
    return true;
  }
  return false;
}
