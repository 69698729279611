import MobileTabBar from "./components/MobileTabBar";

export const DefaultTabBar = ({
  state,
  descriptors,
  navigation,
}: {
  state?: any;
  descriptors?: any;
  navigation?: any;
}) => {
  // if (isLargerDevice()) return <WebTabBar />;
  // else if (Platform.OS !== "web")

  return <MobileTabBar {...{ state, descriptors, navigation }} />;
};
