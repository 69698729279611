import SegmentedControl from "@react-native-segmented-control/segmented-control";
import { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";

import { ScrollView } from "react-native";
import TitleText from "../../../components/result/TitleText";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";

export default function UnitCalculatorScreen() {
  const colorScheme = getColorScheme();

  const styles = createStyles(colorScheme);
  const [selectedIndexU1, setSelectedIndexU1] = useState(0);
  const [selectedIndexU2, setSelectedIndexU2] = useState(0);
  const [selectedIndexU3, setSelectedIndexU3] = useState(0);
  const [selectedIndexU4, setSelectedIndexU4] = useState(0);

  const [inputValueUpper, setInputValueUpper] = useState<string | null>(null);
  const [inputValueLower, setInputValueLower] = useState<string | null>(null);
  const [changedUpper, setChangedUpper] = useState(false);
  const [changedLower, setChangedLower] = useState(false);

  const U1 = ["g", "mg", "ug", "ng", "pg"];
  const U2 = ["l", "dl", "ml"];

  const calculationsToMg = {
    g: 1000,
    mg: 1,
    ug: 0.001,
    ng: 0.000001,
    pg: 0.000000001,
  };

  const calculationsToL = {
    ml: 1000,
    dl: 10,
    l: 1,
  };

  useEffect(() => {
    if (changedUpper) return;
    if (inputValueUpper === null) {
      setInputValueLower(null);
      return;
    }
    const calc =
      Math.round(
        parseFloat(inputValueUpper.replace(",", ".")) *
          calculateFactor() *
          1000000
      ) / 1000000;
    setChangedLower(true);
    setInputValueLower((calc + "").replace(".", ","));
    setTimeout(() => {
      setChangedLower(false);
    }, 250);
  }, [inputValueUpper, selectedIndexU3, selectedIndexU4]);

  useEffect(() => {
    if (changedLower) return;
    if (inputValueLower === null) {
      setInputValueUpper(null);
      return;
    }
    const calc =
      Math.round(
        (parseFloat(inputValueLower.replace(",", ".")) / calculateFactor()) *
          1000000
      ) / 1000000;
    setChangedUpper(true);
    setInputValueUpper((calc + "").replace(".", ","));

    setTimeout(() => {
      setChangedUpper(false);
    }, 250);
  }, [inputValueLower, selectedIndexU1, selectedIndexU2]);

  function calculateFactor() {
    const one = calculationsToMg[U1[selectedIndexU1]];
    const two = calculationsToL[U2[selectedIndexU2]];
    const three = calculationsToMg[U1[selectedIndexU3]];
    const four = calculationsToL[U2[selectedIndexU4]];

    return (one * two) / (three * four);
    //const calc = (inputValueUpper * one * two * three / 46)
  }

  function calculateQuotient() {
    const one = calculationsToMg[U1[selectedIndexU1]];
    const two = calculationsToL[U2[selectedIndexU2]];
    const three = calculationsToMg[U1[selectedIndexU3]];
    const four = calculationsToL[U2[selectedIndexU4]];

    return (three * four) / (one * two);
    //const calc = (inputValueUpper * one * two * three / 46)
  }

  return (
    <View
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        flex: 1,
      }}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
          alignSelf: "stretch",
          padding: Sizes.defaultContainerPadding,
        }}
      >
        <TitleText
          style={{
            marginRight: 12,
          }}
          title={"Einheitenrechner"}
        />
        <View
          style={{
            width: "100%",
            flex: 1,
            paddingTop: 20,
            justifyContent: "center",
          }}
        >
          <View>
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                backgroundColor: Colors[colorScheme].foreground,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <TextInput
                  placeholder="Eingabe..."
                  placeholderTextColor={Colors[colorScheme].placeholder}
                  keyboardType="numeric"
                  value={inputValueUpper === null ? "" : inputValueUpper + ""}
                  onChangeText={(t) => {
                    if (t === "") setInputValueUpper(null);
                    else setInputValueUpper(t);
                  }}
                  style={styles.textField}
                />
                <Text style={styles.unitText}>
                  {U1[selectedIndexU1] + "/" + U2[selectedIndexU2]}
                </Text>
              </View>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <SegmentedControl
                values={U1}
                selectedIndex={selectedIndexU1}
                tintColor={Colors[colorScheme].accent}
                backgroundColor={Colors[colorScheme].background}
                fontStyle={{
                  color: Colors[colorScheme].text,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                activeFontStyle={{
                  color: Colors[colorScheme].background,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                onChange={(event) => {
                  setSelectedIndexU1(event.nativeEvent.selectedSegmentIndex);
                }}
              />
              <Text style={styles.proText}>pro</Text>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <SegmentedControl
                values={U2}
                selectedIndex={selectedIndexU2}
                tintColor={Colors[colorScheme].accent}
                backgroundColor={Colors[colorScheme].background}
                fontStyle={{
                  color: Colors[colorScheme].text,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                activeFontStyle={{
                  color: Colors[colorScheme].background,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                onChange={(event) => {
                  setSelectedIndexU2(event.nativeEvent.selectedSegmentIndex);
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  marginRight: 20,
                  width: 100,
                  color: Colors[colorScheme].text,
                  opacity: 0.35,
                  fontSize: 12,
                  textAlign: "right",
                }}
              >
                (Faktor {Math.round(calculateQuotient() * 1000000) / 1000000})
              </Text>

              <Image
                source={require("../../../../assets/images/icons/arrow_up_down.png")}
                style={{
                  width: 44,
                  height: 44,
                  marginVertical: 25,
                  tintColor: Colors[colorScheme].text,
                  resizeMode: "contain",
                }}
              />
              <Text
                style={{
                  marginLeft: 20,
                  width: 100,
                  color: Colors[colorScheme].text,
                  opacity: 0.35,
                  fontSize: 12,
                }}
              >
                (Faktor {Math.round(calculateFactor() * 1000000) / 1000000})
              </Text>
            </View>
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                backgroundColor: Colors[colorScheme].foreground,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <TextInput
                  placeholder="Eingabe..."
                  placeholderTextColor={Colors[colorScheme].placeholder}
                  keyboardType="numeric"
                  value={inputValueLower === null ? "" : inputValueLower + ""}
                  onChangeText={(t) => {
                    if (t === "") setInputValueLower(null);
                    else setInputValueLower(t);
                  }}
                  style={styles.textField}
                />
                <Text style={styles.unitText}>
                  {U1[selectedIndexU3] + "/" + U2[selectedIndexU4]}
                </Text>
              </View>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <SegmentedControl
                values={U1}
                selectedIndex={selectedIndexU3}
                tintColor={Colors[colorScheme].accent}
                backgroundColor={Colors[colorScheme].background}
                fontStyle={{
                  color: Colors[colorScheme].text,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                activeFontStyle={{
                  color: Colors[colorScheme].background,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                onChange={(event) => {
                  setSelectedIndexU3(event.nativeEvent.selectedSegmentIndex);
                }}
              />
              <Text style={styles.proText}>pro</Text>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <SegmentedControl
                values={U2}
                selectedIndex={selectedIndexU4}
                tintColor={Colors[colorScheme].accent}
                backgroundColor={Colors[colorScheme].background}
                fontStyle={{
                  color: Colors[colorScheme].text,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                activeFontStyle={{
                  color: Colors[colorScheme].background,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
                onChange={(event) => {
                  setSelectedIndexU4(event.nativeEvent.selectedSegmentIndex);
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    proText: {
      width: "100%",
      textAlign: "center",
      marginVertical: 12,
      fontWeight: "500",
      fontSize: 20,
      color: "white",
      opacity: 0.75,
    },
    unitText: {
      width: 50,
      fontSize: 20,
      fontWeight: "500",
      marginBottom: 12,
      color: "white",
    },
    textField: {
      backgroundColor: Colors[scheme].inputBackground,
      color: Colors[scheme].text,
      marginRight: 12,
      fontSize: 17,
      width: 200,
      alignSelf: "center",
      fontWeight: "500",
      maxWidth: Sizes.containerWidth,
      borderRadius: 12,
      height: 50,
      padding: 12,
      marginBottom: 12,
      zIndex: 0,
    },
  });
  return styles;
};
