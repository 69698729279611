import { StyleSheet, View } from "react-native";

export default function BlurViewWrapper({ style, children }) {
  return (
    <View
      style={{
        ...style,
        backdropFilter: "blur(5px)",
      }}
    >
      {children}
    </View>
  );
}
