import _ from "lodash";
import apiCall from "../api/api-call";
import {
  addBookmarkCall,
  deleteNoteCall,
  removeBookmarkCall,
  updateNoteCall,
} from "./user/functions";
import { updateValuesInInteractions } from "./user-interaction-handler";

export const CASE_ID = "TEMP_CASES";
export const SHORTCUT_ID = "TEMP_SHORTCUTS";
export const NOTE_ID = "TEMP_NOTES";
export const INTERACTIONS_ID = "TEMP_INTERACTIONS";

export async function syncOfflineKeys(offlineUserData, userData) {
  const offlineRecords = offlineUserData?.interaction_records || [];
  const onlineRecords = userData?.interaction_records || [];

  // Combine both arrays, prioritizing offline records
  const combinedRecords = [...offlineRecords, ...onlineRecords];

  // Create a Map to ensure unique keys and prioritize offline values
  const recordsMap = new Map();

  combinedRecords.forEach((record) => {
    // If the record key already exists and the current record is from offline,
    // or if the record key does not exist in the map, update/add the record.
    if (!recordsMap.has(record.key) || offlineRecords.includes(record)) {
      recordsMap.set(record.key, record.value);
    }
  });
  const synchronizedRecords = Array.from(recordsMap, ([key, value]) => ({
    key,
    value,
  }));
  await updateValuesInInteractions(synchronizedRecords);
}

export async function handleComparisonTempAndOnline(
  offlineItems: any,
  onlineItems: any,
  key: string,
  userId,
  comparator
) {
  if (!_.isEqual(offlineItems, onlineItems)) {
    const addedItems = _.differenceWith(offlineItems, onlineItems, comparator);
    const removedItems = _.differenceWith(
      onlineItems,
      offlineItems,
      comparator
    );

    for (const addedItem of addedItems) {
      await _getFunctionToKeyAndAction(key, "added", addedItem, userId);
    }

    return false;
  }

  return true;
}

async function _getFunctionToKeyAndAction(
  tempKey,
  action: string,
  item: any,
  userId
) {
  if (tempKey === CASE_ID) {
    if (action === "added") {
      delete item.case_id;
      delete item.last_updated;
      await apiCall("/api/v3/users/case", item);
    } else {
      await apiCall(
        "/api/v3/users/case/" + item.case_id,
        null,
        false,
        false,
        "DELETE"
      );
    }
  } else if (tempKey === SHORTCUT_ID) {
    if (action === "added") {
      await addBookmarkCall(item.result_page_id, userId);
    } else {
      await removeBookmarkCall(item.result_page_id, userId);
    }
  } else if (tempKey === NOTE_ID) {
    if (action === "added") {
      await updateNoteCall(item, userId);
    } else {
      await deleteNoteCall(item.id, userId);
    }
  }
}
