/**
 * CoachMark component displays a coach mark modal with customizable shape, background color, and text.
 *
 * @param shape - The shape of the coach mark. Can be "circle" or "rect". Default is "circle".
 * @param direction - The direction of the coach mark. Can be "top" or "bottom". Default is "bottom".
 * @param backgroundColor - The background color of the coach mark. Default is "#000".
 * @param visible - Determines whether the coach mark is visible or not. Default is false.
 * @param opacity - The opacity of the coach mark. Default is 0.85.
 * @param borderRadius - The border radius of the coach mark. Default is 10.
 * @param onClose - The callback function to be called when the coach mark is closed.
 * @param positionRef - The reference to the position of the coach mark.
 * @param title - The title of the coach mark.
 * @param text - The text content of the coach mark.
 */

import { useEffect, useRef, useState } from "react";
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import Modal from "react-native-modal";
import Svg, { Circle, ClipPath, Defs, Rect } from "react-native-svg";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import staticColors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";
import { CustomModalContent } from "../../modals/CustomModal";
import { DefaultButton } from "./DefaultGuideButton";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../../functions/user-interaction-handler";
import handleOneTimeAction from "../../../functions/one-time-action-handler";

export const CoachMark = ({
  shape = "circle",
  direction = "bottom",
  backgroundColor = "#000",
  visible = false,
  opacity = 0.85,
  borderRadius = 10,
  onClose = () => {},
  positionRef = null,
  title = "Das ist ein title",
  text = "Your guide text here", // Add a text prop
}: {
  shape?: "circle" | "rect";
  backgroundColor?: string;
  opacity?: number;
  onClose?: () => void;
  visible?: boolean;
  borderRadius?: number;
  positionRef?: any;
  title?: string;
  direction?: "top" | "bottom";
  text?: string;
}) => {
  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
  const colorScheme = getColorScheme();

  const slideAnim = useRef(new Animated.Value(-500)).current;
  const safeAreaInsets = useSafeAreaInsets();

  const [openTime, setOpenTime] = useState<any>(null);

  useEffect(() => {
    if (!visible) return;
    setTimeout(() => {
      Animated.timing(slideAnim, {
        toValue: 20 + (direction === "top" ? safeAreaInsets.top : 0),
        duration: 600,
        useNativeDriver: false,
        easing: Easing.bounce,
      }).start();
    }, 1000);

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "coach_mark",
      "ModalVisibility",
      "Coach Mark Modal Displayed",
      { title, text }
    );
    setOpenTime(Date.now());
  }, [slideAnim, visible]);

  const [positionData, setPositionData] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  function _onClose() {
    const duration = Date.now() - openTime;
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "coach_mark",
      "ModalClose",
      "User closed the Coach Mark Modal",
      { duration: duration / 1000 } // duration in seconds
    );
    // Reset the open time
    setOpenTime(null);
    onClose();
  }

  useEffect(() => {
    positionRef?.current?.measureInWindow((fx, fy, width, height) => {
      const margin = 8;

      if (positionData?.x !== fx - margin && positionData?.y !== fy - margin) {
        setPositionData({
          x: fx - margin,
          y: fy - margin,
          width: width + margin * 2,
          height: height + margin * 2,
        });
      }
    });
  }, [positionRef, visible]);

  return null;
  return (
    <Modal
      style={{
        margin: 0,
        padding: 0,
      }}
      animationIn={"fadeIn"}
      animationOut={"fadeOut"}
      hardwareAccelerated={true}
      statusBarTranslucent={true}
      isVisible={visible}
      backdropOpacity={0}
    >
      <View style={styles.container}>
        <Svg
          width={windowWidth}
          height={windowHeight}
          viewBox={`0 0 ${windowWidth} ${windowHeight}`}
        >
          <Defs>
            <ClipPath id="clip">
              {shape === "circle" ? (
                <Circle
                  cx={positionData.x}
                  cy={positionData.y}
                  r={borderRadius}
                />
              ) : shape === "rect" ? (
                <Rect
                  x={positionData.x}
                  y={positionData.y}
                  width={positionData.width}
                  height={positionData.height}
                  rx={borderRadius}
                  ry={borderRadius}
                />
              ) : null}
              <Rect x={0} y={0} width={windowWidth} height={windowHeight} />
            </ClipPath>
          </Defs>
          <Rect
            x={0}
            y={0}
            width={windowWidth}
            height={windowHeight}
            fill={backgroundColor}
            clipPath="url(#clip)"
            opacity={opacity}
          >
            <ClipPath />
          </Rect>
        </Svg>
        <Animated.View
          style={{
            position: "absolute",
            width: "90%",
            alignSelf: "center",
            top: direction === "top" ? slideAnim : undefined,
            bottom: direction === "bottom" ? slideAnim : undefined,
          }}
        >
          <CustomModalContent onClose={_onClose} style={[]}>
            <Text
              style={{
                fontWeight: "500",
                fontSize: 20,
                marginBottom: 12,
                color: staticColors[colorScheme].text,
              }}
            >
              {title}
            </Text>
            <Text
              style={{
                fontSize: 14,
                lineHeight: 18,
                color: staticColors[colorScheme].text,
              }}
            >
              {text}
            </Text>
            <DefaultButton
              action={_onClose}
              style={{ marginTop: 20 }}
              text="Verstanden!"
              icon={null}
            />
          </CustomModalContent>
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flex: 1,
    position: "relative",
  },
});

export function getCurrentCoachMarkStep() {
  return getValueFromInteractions("coach_mark_step") ?? 0;
}

export async function increaseCoachMarkStep() {
  const step = getCurrentCoachMarkStep();
  await addValueToInteractions("coach_mark_step", step + 1);
}

export async function evaluateCoachMarkAction(action, identifier, step) {
  if (getCurrentCoachMarkStep() === step) {
    setTimeout(() => {
      // add timeout to prevent multiple coach marks from appearing at the same time
      increaseCoachMarkStep();
    }, 500);

    if (await handleOneTimeAction(identifier)) {
      action(true);
    }
  }
}

/**
 * Coach Marks
 * 0: LargeScreenResultPageUserInteractionsComponent
 * 1: ResultPageHeaderComponent
 * 2: ExpandableFABMenu
 * 3: SaveButton (CalculatorBox) -> //TODO: NEEDS TO BE CHANGED
 * 4: SourcesView
 * 5: PersonalContentScreen
 */
