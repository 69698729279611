import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import ReactNativeModal from "react-native-modal";
import Feather from "react-native-vector-icons/Feather";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import {
  CoachMark,
  evaluateCoachMarkAction,
} from "../../../components/guide-mark/components/GuideMark";
import AddCaseModal from "../../../components/modals/AddCaseModal";
import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  selectAllAmounts,
  selectChecklistData,
  selectChecklistNote,
  selectScoreData,
} from "../../../functions/calculator/actions";
import { updateCase } from "../../../functions/case-functions";
import { selectResultPageCaseContent } from "../../../functions/current-result/actions";
import { selectIsDemoMode } from "../../../functions/navigation/actions";
import handleOneTimeAction from "../../../functions/one-time-action-handler";
import { selectCurrentUser } from "../../../functions/user/actions";
import getColorScheme from "../../../hooks/useColorScheme";

export function omitUndefinedDeep(obj) {
  if (_.isArray(obj)) {
    return obj.map(omitUndefinedDeep);
  } else if (_.isPlainObject(obj)) {
    return _.omitBy(_.mapValues(obj, omitUndefinedDeep), _.isUndefined);
  } else if (obj instanceof Set) {
    return Array.from(obj).map(omitUndefinedDeep);
  } else {
    return obj;
  }
}

const ExpandableFABMenu = ({
  style = {},
  small = false,
  collapseAction,
  pageContent,
  showCollapse,
}) => {
  const navigation = useNavigation() as any;
  const resultPageCaseContent = useSelector(selectResultPageCaseContent);
  const [addCaseModalOpen, setAddCaseModalOpen] = useState(false);
  const colorScheme = getColorScheme();
  const isDemoMode = useSelector(selectIsDemoMode);
  const [menuOpen, setMenuOpen] = useState(false);

  const amounts = useSelector(selectAllAmounts);
  const scoreData = useSelector(selectScoreData);
  const checklistData = useSelector(selectChecklistData);
  const checklistNote = useSelector(selectChecklistNote);
  const currentUser = useSelector(selectCurrentUser);

  const caseData = {
    amounts,
    ...(scoreData ?? {}),
    checklistData,
    checklistNote,
  };

  const cleanedCaseData = omitUndefinedDeep(caseData);
  const cleanedResultPageCaseContent = omitUndefinedDeep(
    resultPageCaseContent?.caseData
  );

  if (cleanedCaseData && _.isEmpty(cleanedCaseData?.checklistData))
    cleanedCaseData.checklistData = [];
  if (
    cleanedResultPageCaseContent &&
    _.isEmpty(cleanedResultPageCaseContent?.checklistData)
  )
    cleanedResultPageCaseContent.checklistData = [];

  const areObjectsEqual = _.isEqual(
    cleanedCaseData,
    cleanedResultPageCaseContent
  );

  // Neuer Zustand, um das Rendering der Menüelemente zu steuern
  const [shouldRenderMenuItems, setShouldRenderMenuItems] = useState(false);

  const animationController = useRef(new Animated.Value(0)).current; // For animation

  useEffect(() => {
    evaluateCoachMarkAction(setIsCoachMarkVisible, "did_select_button", 2);
  }, []);

  const ref = useRef(null);

  // Animate menu expansion and collapse
  useEffect(() => {
    if (menuOpen) setShouldRenderMenuItems(true);
    Animated.timing(animationController, {
      toValue: menuOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      // Menüelemente entfernen, wenn die Animation beim Schließen endet
      if (!menuOpen) setShouldRenderMenuItems(false);
    });
  }, [menuOpen]);

  // Animated styles for menu items
  const menuItemStyle = (index) => ({
    transform: [
      {
        translateY: animationController.interpolate({
          inputRange: [0, 1],
          outputRange: [20 * (index + 1), 0], // Adjust based on item index and desired effect
        }),
      },
    ],
    opacity: animationController,
  });

  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(false);

  if (isDemoMode) return null;

  return (
    <>
      <AddCaseModal
        open={addCaseModalOpen}
        onClose={() => setAddCaseModalOpen(false)}
      />

      <CoachMark
        shape="rect"
        borderRadius={8}
        positionRef={ref}
        onClose={() => setIsCoachMarkVisible(false)}
        title="Dein Action - Button"
        direction="top"
        text="Hier kannst du direkt zurück ins Hauptmenü, Feedback geben oder die Seite einklappen."
        visible={isCoachMarkVisible}
      />

      <View
        style={{
          position: "absolute",
          bottom: 50,
          zIndex: 1000000000,
          right: Sizes.defaultContainerPadding,
          ...style,
        }}
      >
        <ReactNativeModal
          isVisible={menuOpen}
          onBackdropPress={() => setMenuOpen(false)}
          backdropColor="black"
          backdropOpacity={0.5}
          backdropTransitionOutTiming={0}
          style={{
            margin: 0,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {shouldRenderMenuItems && (
            <View
              style={{
                position: "absolute",
                right: Sizes.defaultContainerPadding + 8,
                bottom: Sizes.defaultContainerPadding + 50,
              }}
            >
              {/* Back to Home Button */}
              <Animated.View style={[styles.menuItem, menuItemStyle(1)]}>
                <TouchableOpacity
                  disabled={!menuOpen}
                  onPress={() => {
                    setMenuOpen(false);
                    setTimeout(() => {
                      navigation.popToTop();
                    }, 250);

                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "fab_menu_item_selected",
                      "ExpandableFABMenu",
                      "Back to Home Selected",
                      null // If there's a relevant ID or context, include it here
                    );
                  }}
                  style={{
                    ...styles.actionButton,
                    backgroundColor: staticColors[colorScheme].foreground,
                    shadowColor: staticColors[colorScheme].text,
                  }}
                >
                  <Feather
                    name="home"
                    size={24}
                    color={staticColors[colorScheme].text}
                  />
                  <Text
                    style={{
                      ...styles.actionText,
                      color: staticColors[colorScheme].text,
                    }}
                  >
                    Home
                  </Text>
                </TouchableOpacity>
              </Animated.View>

              {/* Back to Home Button */}
              <Animated.View style={[styles.menuItem, menuItemStyle(2)]}>
                <TouchableOpacity
                  disabled={!menuOpen}
                  onPress={() => {
                    setMenuOpen(false);

                    setTimeout(() => {
                      navigation.navigate("FeedbackScreen", {
                        pageContent,
                      });
                    }, 500);

                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "fab_menu_item_selected",
                      "ExpandableFABMenu",
                      "Feedback Selected",
                      pageContent?._id // Assuming pageContent might have an _id field
                    );
                  }}
                  style={{
                    ...styles.actionButton,
                    backgroundColor: staticColors[colorScheme].foreground,
                    shadowColor: staticColors[colorScheme].text,
                  }}
                >
                  <Ionicons
                    name="chatbubble-ellipses-outline"
                    size={24}
                    color={staticColors[colorScheme].text}
                  />
                  <Text
                    style={{
                      ...styles.actionText,
                      color: staticColors[colorScheme].text,
                    }}
                  >
                    Feedback
                  </Text>
                </TouchableOpacity>
              </Animated.View>
              {/* Collapse Page Button */}
              {showCollapse && (
                <Animated.View style={[styles.menuItem, menuItemStyle(3)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      handleOneTimeAction("did_select_button");
                      setTimeout(() => {
                        collapseAction();
                      }, 500);

                      // Track collapse action
                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_collapse_action",
                        "ExpandableFABMenu",
                        "Collapse Action Taken",
                        pageContent?._id // Assuming pageContent might have an _id field
                      );
                      setMenuOpen(false);
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: staticColors[colorScheme].foreground,
                    }}
                  >
                    <Feather
                      name="minimize"
                      size={24}
                      color={staticColors[colorScheme].text}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: staticColors[colorScheme].text,
                      }}
                    >
                      Einklappen
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
              )}
              {resultPageCaseContent ? (
                <>
                  <Animated.View style={[styles.menuItem, menuItemStyle(4)]}>
                    <TouchableOpacity
                      disabled={!menuOpen}
                      onPress={() => {
                        setMenuOpen(false);
                        setTimeout(() => {
                          setAddCaseModalOpen(true);
                        }, 500);

                        AnalyticsHandler.getInstance().logUserScreenInteraction(
                          "fab_menu_item_selected",
                          "ExpandableFABMenu",
                          "Back to Home Selected",
                          null // If there's a relevant ID or context, include it here
                        );
                      }}
                      style={{
                        ...styles.actionButton,
                        backgroundColor: staticColors[colorScheme].foreground,
                        shadowColor: staticColors[colorScheme].text,
                      }}
                    >
                      <Feather
                        name="edit-3"
                        size={24}
                        color={staticColors[colorScheme].text}
                      />
                      <Text
                        style={{
                          ...styles.actionText,
                          color: staticColors[colorScheme].text,
                        }}
                      >
                        Aktuellen Fall bearbeiten
                      </Text>
                    </TouchableOpacity>
                  </Animated.View>
                  {
                    <Animated.View
                      style={[
                        styles.menuItem,
                        menuItemStyle(5),
                        {
                          opacity: areObjectsEqual ? 0.5 : 1,
                          pointerEvents: areObjectsEqual ? "none" : "auto",
                        },
                      ]}
                    >
                      <TouchableOpacity
                        disabled={!menuOpen}
                        onPress={() => {
                          setMenuOpen(false);

                          updateCase(
                            resultPageCaseContent.caseId,
                            caseData,
                            currentUser,
                            resultPageCaseContent.note
                          );

                          AnalyticsHandler.getInstance().logUserScreenInteraction(
                            "fab_menu_item_selected",
                            "ExpandableFABMenu",
                            "Back to Home Selected",
                            null // If there's a relevant ID or context, include it here
                          );
                        }}
                        style={{
                          ...styles.actionButton,
                          backgroundColor: staticColors[colorScheme].foreground,
                          shadowColor: staticColors[colorScheme].text,
                        }}
                      >
                        <Feather
                          name="refresh-cw"
                          size={24}
                          color={staticColors[colorScheme].text}
                        />
                        <Text
                          style={{
                            ...styles.actionText,
                            color: staticColors[colorScheme].text,
                          }}
                        >
                          Aktuellen Fall aktualisieren
                        </Text>
                      </TouchableOpacity>
                    </Animated.View>
                  }
                  <Animated.View style={[styles.menuItem, menuItemStyle(6)]}>
                    <TouchableOpacity
                      disabled={!menuOpen}
                      onPress={() => {
                        setMenuOpen(false);
                        setTimeout(() => {
                          setAddCaseModalOpen(true);
                        }, 500);

                        AnalyticsHandler.getInstance().logUserScreenInteraction(
                          "fab_menu_item_selected",
                          "ExpandableFABMenu",
                          "Back to Home Selected",
                          null // If there's a relevant ID or context, include it here
                        );
                      }}
                      style={{
                        ...styles.actionButton,
                        backgroundColor: staticColors[colorScheme].foreground,
                        shadowColor: staticColors[colorScheme].text,
                      }}
                    >
                      <Feather
                        name="save"
                        size={24}
                        color={staticColors[colorScheme].text}
                      />
                      <Text
                        style={{
                          ...styles.actionText,
                          color: staticColors[colorScheme].text,
                        }}
                      >
                        Neuen Fall anlegen
                      </Text>
                    </TouchableOpacity>
                  </Animated.View>
                </>
              ) : (
                <Animated.View style={[styles.menuItem, menuItemStyle(4)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      setMenuOpen(false);
                      setTimeout(() => {
                        setAddCaseModalOpen(true);
                      }, 500);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_item_selected",
                        "ExpandableFABMenu",
                        "Back to Home Selected",
                        null // If there's a relevant ID or context, include it here
                      );
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: staticColors[colorScheme].foreground,
                      shadowColor: staticColors[colorScheme].text,
                    }}
                  >
                    <Feather
                      name="save"
                      size={24}
                      color={staticColors[colorScheme].text}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: staticColors[colorScheme].text,
                      }}
                    >
                      Fall speichern
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
              )}
            </View>
          )}
        </ReactNativeModal>
        <FAB
          small={small}
          _ref={ref}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          colorScheme={colorScheme}
          pageContent={pageContent}
        />
      </View>
    </>
  );
};

const FAB = ({
  _ref,
  menuOpen,
  setMenuOpen,
  colorScheme,
  pageContent,
  small,
}) => {
  const webShadowStyle = {
    boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.12)", // Customize as needed
  } as any;

  return (
    <TouchableOpacity
      ref={_ref}
      onPress={() => {
        setMenuOpen(!menuOpen);
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          menuOpen ? "fab_menu_closed" : "fab_menu_opened",
          "ExpandableFABMenu",
          menuOpen ? "FAB Menu Closed" : "FAB Menu Opened",
          pageContent?._id // Assuming pageContent might have an _id field
        );
      }}
      style={[
        {
          backgroundColor: staticColors[colorScheme].foreground,
          shadowColor: staticColors[colorScheme].text,
          padding: small ? 12 : 16,
          borderRadius: 50,
          elevation: 2,
          aspectRatio: 1,
          shadowOffset: { width: 1, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 5,
        },
        Platform.OS === "web" ? webShadowStyle : {},
      ]}
    >
      <Feather
        size={small ? 20 : 28}
        color={staticColors[colorScheme].text}
        name={"menu"}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    marginBottom: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",

    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 20,
    elevation: 3,

    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  actionText: {
    marginLeft: 8,
    fontWeight: "bold",
  },
});

export default ExpandableFABMenu;
