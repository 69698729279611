import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  SafeAreaView,
  SectionList,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { IconToCategory } from "../../../../components/CategoryIcon";

import { Sizes } from "../../../../constants/static-sizes";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import getColorScheme from "../../../../hooks/useColorScheme";
import { parseDate } from "../../functions/helpers";

import { useSelector } from "react-redux";
import apiCall from "../../../../api/api-call";

import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import {
  ButtonTypes,
  DefaultButton,
} from "../../../../components/DefaultButton";
import staticColors from "../../../../constants/static-colors";
import { selectCurrentUser } from "../../../../functions/user/actions";
import i18n from "../../../../../localization/i18n";

const PAGINATION_AMOUNT = 10;

const HistoryComponent = () => {
  const currentUser = useSelector(selectCurrentUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(
    currentUser.result_page_history?.total_results ?? 0
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "history_screen_viewed",
      "HistoryComponent",
      "History Screen Viewed",
      currentUser?.id
    );
  }, [currentUser?.id]);

  const navigation = useNavigation();

  function getPageToId(id: string) {
    if (!allPages) return undefined;
    const page = allPages.find(
      (page: any) => page._id === id || page.content.legacy_id === id
    );
    if (page) return page;
    else return undefined;
  }

  const colorScheme = getColorScheme();

  function groupEntriesByDay(entries) {
    if (!entries || !Array.isArray(entries)) return [];
    entries = entries
      .filter((entry) => entry.result_page_id)
      .map((entry) => ({
        ...entry,
        page: getPageToId(entry.result_page_id),
      }));

    entries = entries.filter((entry) => entry.page);
    entries = entries.sort((d1, d2) => {
      const date1 = new Date(d1.timestamp);
      const date2 = new Date(d2.timestamp);

      if (date1 > date2) return -1;
      if (date1 < date2) return 1;
      return 0;
    });
    const groupedEntries = {};

    entries.forEach((entry) => {
      const date = new Date(entry.timestamp);
      const germanFormattedDate = date.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      if (groupedEntries[germanFormattedDate]) {
        groupedEntries[germanFormattedDate].push(entry);
      } else {
        groupedEntries[germanFormattedDate] = [entry];
      }
    });

    return groupedEntries;
  }

  const debouncedLoadAdditionalData = debounce(loadAdditionalData, 250);

  async function loadAdditionalData() {
    setLoading(true);

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "history_load_more",
      "HistoryComponent",
      `Loading More Items, Current Page: ${currentPage}`,
      null // If there's a relevant ID or context, include it here
    );

    const data = await apiCall(
      `/api/v3/users/result_page/history?limit=${
        (currentPage + 1) * PAGINATION_AMOUNT
      }&offset=${currentPage * PAGINATION_AMOUNT}`,
      null,
      false,
      false,
      "GET"
    );
    setLoading(false);

    if (data?.data?.history) {
      setCurrentPage((p) => p + 1);
      setInitialData((_data) => [..._data, ...data.data.history]);
    }
  }

  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );
  const [data, setData] = useState<any>([]);

  const [initialData, setInitialData] = useState<any>(
    currentUser.result_page_history
  );

  useEffect(() => {
    setInitialData(currentUser?.result_page_history?.history);
  }, [currentUser?.result_page_history]);

  useEffect(() => {
    const _data = groupEntriesByDay(initialData);

    if (JSON.stringify(data) !== JSON.stringify(_data)) setData(_data);
  }, [initialData, allPages]);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 50; // you can adjust this threshold
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const sections = Object.keys(data).map((key) => ({
    title: key,
    data: data[key],
  }));

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <SectionList
        ListHeaderComponent={() => (
          <View
            style={{
              alignSelf: "stretch",
              marginTop: 20,
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: Sizes.boxText,
                color: staticColors[colorScheme].text,
              }}
            >
              {totalPages} {i18n.t("pages")}
            </Text>
          </View>
        )}
        sections={sections}
        keyExtractor={(item, index) => item._id ?? index.toString()}
        renderItem={({ item }) => <HistoryItem item={item} />}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            debouncedLoadAdditionalData();
          }
        }}
        ListEmptyComponent={() => {
          return (
            <View
              style={{
                margin: Sizes.defaultContainerPadding,
                alignItems: "center",

                flex: 1,
              }}
            >
              <View style={{}}>
                <Text
                  style={{
                    color: staticColors[colorScheme].text,
                    marginTop: 12,
                    textAlign: "center",
                  }}
                >
                  {i18n.t("empty_history_placeholder")}
                </Text>
                <Image
                  source={require("../../../../../assets/images/image_no_team.png")}
                  style={{
                    alignSelf: "center",
                    width: 250,
                    height: 250,
                    marginVertical: 32,
                    resizeMode: "contain",
                  }}
                />
              </View>
              <DefaultButton
                type={ButtonTypes.Primary}
                title={i18n.t("go_to_content")}
                action={() => {
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "history_screen_empty_navigation",
                    "HistoryScreen",
                    "Navigated To Content from Empty History Screen",
                    null
                  );
                  navigation.navigate("SearchScreen");
                }}
                style={{
                  width: "60%",
                  maxWidth: 500,
                  alignSelf: "center",
                  paddingVertical: 8,
                }}
              />
            </View>
          );
        }}
        renderSectionHeader={({ section: { title } }) => (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 16,
              backgroundColor: staticColors[colorScheme].background,
            }}
          >
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "400",
                color: staticColors[colorScheme].text,
              }}
            >
              {title}
            </Text>
            <View
              style={{
                height: 1,
                flex: 1,
                backgroundColor: "black",
                opacity: 0.2,
                margin: 12,
              }}
            />
          </View>
        )}
        ListFooterComponent={() => {
          if (data.length >= totalPages) return null;
          return (
            <ActivityIndicator
              animating={loading}
              color={staticColors[colorScheme].text}
              style={{
                alignSelf: "center",
                padding: 16,
              }}
            />
          );
        }}
        contentContainerStyle={{ paddingBottom: 110 }}
        style={{ flex: 1, paddingHorizontal: Sizes.defaultContainerPadding }}
      />
    </SafeAreaView>
  );
};

export default HistoryComponent;

const HistoryItem = ({ item }) => {
  const date = parseDate(item.timestamp);
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  if (!item.page?.title) return null;

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        padding: Sizes.defaultBoxPadding,

        borderRadius: 12,
        marginVertical: 8,
        backgroundColor: staticColors[colorScheme].foreground,
        overflow: "hidden",
        alignItems: "center",
      }}
      onPress={() => {
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "history_item_selected",
          "HistoryComponent",
          item.page.title,
          item.page._id
        );
        navigateToNextCMSPage(item.page?._id ?? item.page?.id, navigation);
      }}
    >
      <IconToCategory size={32} title={item.page.content.path?.[0] ?? ""} />
      <View style={{ marginLeft: 12, alignSelf: "stretch", flex: 1 }}>
        <Text
          style={{
            fontSize: Sizes.boxText,
            fontWeight: "600",
            color: staticColors[colorScheme].text,
          }}
        >
          {item.page.title.trim()}
        </Text>
        <Text
          style={{
            marginTop: 8,
            opacity: 0.5,
            fontSize: 12,
            color: staticColors[colorScheme].text,
          }}
        >
          {date.toLocaleTimeString("de-DE", {
            hour12: false,
            minute: "2-digit",
            hour: "2-digit",
          })}
          {" Uhr"}{" "}
        </Text>
      </View>
      <Feather
        name="chevron-right"
        size={24}
        style={{ marginLeft: 12 }}
        color={staticColors[colorScheme].text}
      />
    </TouchableOpacity>
  );
};
