import transformDefaultHtmlContent from "../../components/custom-box-text/helpers/default-content-transformation";
import { store } from "../../functions/store";
import apiCall from "../api-call";

export async function generatePDFScore(
  title,
  answers,
  interpretation,
  score,
  caseName,
  resultPageId
) {
  const response = await apiCall("/api/v3/pdf/score", {
    title,
    case_name: caseName,
    pdf_data: answers,
    result: { interpretation: interpretation?.interpretationBoxes?.[0], score },
    result_page_id: resultPageId,
  });

  return response;
}

export async function generatePDF(title, exportList, caseName, resultPageId) {
  const _exportList = JSON.parse(JSON.stringify(exportList));
  const transformedExportList = transformExportContent(_exportList);

  const response = await apiCall("/api/v3/pdf/result", {
    title: title,
    case_name: caseName,
    pdf_data: transformedExportList,
    result_page_id: resultPageId,
  });

  return response;
}

function transformExportContent(exportList) {
  const shouldShowSources =
    store.getState().currentResultReducer.shouldShowSources;
  const allUnits = store.getState().calculatorReducer.amounts;
  const currentUnit = store.getState().calculatorReducer.currentUnit;

  function traverseAndParse(element) {
    if (typeof element === "string") {
      if (element.includes("<") && element.includes(">")) {
        return transformDefaultHtmlContent(
          element,
          shouldShowSources,
          allUnits,
          currentUnit
        );
      }
    } else if (Array.isArray(element)) {
      return element.map(traverseAndParse);
    } else if (typeof element === "object") {
      for (const key in element) {
        const e = traverseAndParse(element[key]);
        element[key] = e;
      }
    }
    return element;
  }

  return traverseAndParse(exportList);
}
