import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import loadAllData from "../../api/content/load-all/load-all-content";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import { useDispatch } from "react-redux";
import { setIsDemoMode } from "../../functions/navigation/actions";

export default function ResultPageDemo({ route }) {
  const pageId = route.params?.pageId;
  const navigation = useNavigation();
  const dispatch = useDispatch();

  async function load() {
    if (!pageId) return;
    await loadAllData();
    dispatch(setIsDemoMode(true));
    navigateToNextCMSPage(pageId, navigation);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator size={"large"}></ActivityIndicator>
    </View>
  );
}
