import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import {
  Dimensions,
  Image,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const StoryScreen = ({ route }) => {
  const { uri } = route.params;

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="light" />
      <View style={{ flex: 1, height: "100%", overflow: "hidden" }}>
        <StoryHeadline />
        <Image source={{ uri }} style={styles.storyImage} />
      </View>
    </SafeAreaView>
  );
};

const StoryHeadline = () => {
  const navigation = useNavigation();

  const StoryItemIndicator = () => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#ccc",
          height: 3,
          borderRadius: 30,
        }}
      ></View>
    );
  };

  return (
    <View
      style={{
        alignSelf: "stretch",
        position: "absolute",
        top: 20,
        right: 12,
        left: 12,
        zIndex: 100,
        gap: 8,
      }}
    >
      <View style={{ flexDirection: "row", flex: 1, gap: 6 }}>
        <StoryItemIndicator />
        <StoryItemIndicator />
      </View>
      <TouchableOpacity
        onPress={() => navigation.goBack()}
        style={{ alignSelf: "flex-end" }}
      >
        <Feather name="x" size={42} color="white" />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
  },
  storyImage: {
    width: windowWidth,
    height: windowHeight,
    resizeMode: "cover",
  },
});

export default StoryScreen;
