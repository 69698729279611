import { Platform, TextInput, TouchableOpacity, View } from "react-native";

import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useRef, useState } from "react";

import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import Colors from "../constants/static-colors";
import { addBookmark, removeBookmark } from "../functions/user/functions";
import isLargerDevice from "../hooks/isLargerDevice";
import getColorScheme from "../hooks/useColorScheme";

import { Feather } from "@expo/vector-icons";

import { useDispatch } from "react-redux";
import { Sizes } from "../constants/static-sizes";
import configureAnimations from "../functions/configure-animations";
import { setResultPageSearchQuery } from "../functions/current-result/actions";
import {
  CoachMark,
  evaluateCoachMarkAction,
} from "./guide-mark/components/GuideMark";
import ShareOptionModal from "./modals/ShareOptionsModal";
import { debounce } from "lodash";

export default function LargeScreenResultPageUserInteractionsComponent({
  hasBookmark,
  pageContent,
  note,
  currentUser,
  isDemoMode = false,
}) {
  const colorScheme = getColorScheme();

  const ref = useRef(null);

  const [isSearching, setIsSearching] = useState(false);

  const navigation = useNavigation();

  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(false);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResultPageSearchQuery(query));
  }, [query]);

  useEffect(() => {
    evaluateCoachMarkAction(
      setIsCoachMarkVisible,
      "did_show_result_page_user_interactions_coachmark",
      0
    );
  }, []);

  if (isDemoMode) return <View style={{ height: 30 }} />;
  return (
    <>
      <ShareOptionModal
        pageContent={pageContent}
        open={shareModalVisible}
        onClose={() => setShareModalVisible(false)}
      />
      <CoachMark
        shape="rect"
        borderRadius={8}
        positionRef={ref}
        onClose={() => setIsCoachMarkVisible(false)}
        title="Notizen, Favoriten und Teilen"
        text="Hier kannst du eigene Notizen hinzufügen, die Seite als Favorit markieren oder einen direkten Link mit Kollegen und Kolleginnen teilen."
        visible={isCoachMarkVisible}
      />

      {isSearching && (
        <View style={{ flex: 1000 }}>
          <ResultPageSearchBar
            query={query}
            setQuery={setQuery}
            closeAction={() => {
              configureAnimations();
              setIsSearching(false);
            }}
          />
        </View>
      )}
      {!isSearching && (
        <View
          ref={ref}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            //gap: 16,
            marginBottom: 4,
            marginRight: isLargerDevice() ? 16 : 0,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("CreateNoteScreen", {
                note,
                currentDisplayID: pageContent._id,
              });
            }}
          >
            <Ionicons
              name="create-outline"
              size={26}
              color={Colors[colorScheme].text}
            />
          </TouchableOpacity>

          <View
            style={{
              marginLeft: 16,
            }}
          >
            <TouchableOpacity
              onPress={async () => {
                setFavoriteLoading(true);
                if (hasBookmark) {
                  if (
                    currentUser?.bookmarks?.find(
                      (b) => b.result_page_id === pageContent._id
                    )
                  )
                    await removeBookmark(pageContent._id);
                  else await removeBookmark(pageContent.legacy_id);
                } else await addBookmark(pageContent._id);
                setFavoriteLoading(false);
              }}
            >
              <View
                style={{
                  opacity: favoriteLoading ? 0.5 : 1,
                  pointerEvents: favoriteLoading ? "none" : "auto",
                }}
              >
                <Ionicons
                  name={hasBookmark ? "heart-sharp" : "heart-outline"}
                  size={28}
                  color={Colors[colorScheme].text}
                />
                {hasBookmark && (
                  <Ionicons
                    name={"heart-sharp"}
                    size={28}
                    style={{ position: "absolute", width: "50%" }}
                    color={Colors[colorScheme].text}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>
          <View style={{ marginLeft: 16 }}>
            <TouchableOpacity
              onPress={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "share_modal_opened",
                  "LargeScreenResultPageUserInteractionsComponent",
                  "User Opened Share Modal",
                  { pageId: pageContent._id, pageTitle: pageContent?.title }
                );
                setShareModalVisible(true);
              }}
            >
              <Ionicons
                name="ios-share-outline"
                size={26}
                color={Colors[colorScheme].text}
              />
            </TouchableOpacity>
          </View>
          {pageContent?.type !== "scorePage" &&
            pageContent?.type !== "nodePage" && (
              <View style={{ marginLeft: 16 }}>
                <TouchableOpacity
                  onPress={() => {
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "search_page_pressed",
                      "LargeScreenResultPageUserInteractionsComponent",
                      "User Presser Share Page",
                      { pageId: pageContent._id, pageTitle: pageContent?.title }
                    );
                    setIsSearching(true);
                    configureAnimations();
                  }}
                >
                  <Feather
                    name="search"
                    size={24}
                    color={Colors[colorScheme].text}
                  />
                </TouchableOpacity>
              </View>
            )}
        </View>
      )}
    </>
  );
}

const ResultPageSearchBar = ({ query, setQuery, closeAction }) => {
  const colorScheme = getColorScheme();
  const textInputRef = useRef<any>(null);

  const debouncedUpdate = useCallback(
    debounce((query) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_initiated_on_result_page",
        "SearchBarOnResultPage",
        "User Initiated Search on Result Page",
        { query }
      );
    }, 500),
    []
  );

  useEffect(() => {
    if (textInputRef.current) textInputRef.current.focus();
  }, [textInputRef]);
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: Colors[colorScheme].foreground,
          flex: 1,
          borderRadius: 12,
          paddingLeft: 16,
          paddingRight: 16,
          height: 50,
        }}
      >
        <Ionicons name="search" size={24} color={Colors[colorScheme].gray} />
        <TextInput
          ref={textInputRef}
          defaultValue={query}
          onFocus={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "search_bar_focused_on_result_page",
              "SearchBarOnResultPage",
              "User Focused Search Bar on Result Page",
              null
            );

            configureAnimations();
          }}
          placeholderTextColor={Colors[colorScheme].placeholder}
          placeholder={"Gib hier deinen Suchbegriff ein"}
          style={{
            flex: 1,
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            paddingHorizontal: 16,

            ...Platform.select({ android: { paddingVertical: 8 } }),
            paddingLeft: 0,
            marginLeft: 12,
          }}
          onChangeText={(query) => {
            debouncedUpdate(query);
            setQuery(query);
          }}
        />

        <TouchableOpacity
          onPress={() => {
            setQuery("");
            textInputRef?.current.clear();

            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "search_cancelled_on_result_page",
              "SearchBarOnResultPage",
              "User Cancelled Search on Result Page",
              { query }
            );
            closeAction();
            textInputRef.current?.blur();
          }}
        >
          <Ionicons name="close" size={18} color={Colors[colorScheme].gray} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
