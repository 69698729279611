import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../../../api/api-call";
import { CategoryIcon } from "../../../../components/CategoryIcon";
import alert from "../../../../components/CustomAlert";
import CustomRenderHTMLComponent from "../../../../components/CustomRenderHTMLComponent";
import { InlineNotificationType } from "../../../../components/NotificationComponent";

import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import { EmptyCaseButton } from "../../../../components/home-tab/CaseSection";
import { getIsOffline } from "../../../../components/search-tab/SearchResults";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { showInlineNotification } from "../../../../entry/Root";
import { setAmount } from "../../../../functions/calculator/actions";
import configureAnimations from "../../../../functions/configure-animations";
import { setResultPageCaseContent } from "../../../../functions/current-result/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../../../../functions/user/actions";
import { updateOfflineUser } from "../../../../functions/user/functions";
import { Case } from "../../../../functions/user/types";
import getColorScheme from "../../../../hooks/useColorScheme";
import { parseDate } from "../../functions/helpers";
import { getSortedCaseData } from "./functions";
import i18n from "../../../../../localization/i18n";

const UserCasesComponent = ({
  query,
  data,
  setData,
  editModeActive,
  setEditModeActive,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const debouncedDataOrganizer = useCallback(() => {
    let enrichedData = getSortedCaseData(currentUser);

    if (query)
      enrichedData = enrichedData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      );

    if (JSON.stringify(data) !== JSON.stringify(enrichedData))
      setData(enrichedData);
  }, [currentUser, query]);

  const colorScheme = getColorScheme();

  useEffect(() => {
    debouncedDataOrganizer();
  }, [currentUser.cases, query]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ paddingBottom: 130 }}
      style={{ flex: 1, paddingHorizontal: Sizes.defaultContainerPadding }}
    >
      {data?.length > 0 && (
        <View
          style={{
            marginTop: 20,
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: Sizes.boxText,
              flex: 1,
              color: Colors[colorScheme].text,
            }}
          >
            {data.length} {data.length === 1 ? i18n.t("case") : i18n.t("cases")}
          </Text>
          <TouchableOpacity
            style={{
              padding: 8,
              paddingHorizontal: 16,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.1,
              elevation: 2,
              borderRadius: 50,
              shadowRadius: 2.62,
              backgroundColor: editModeActive
                ? Colors[colorScheme].lightGray
                : Colors[colorScheme].foreground,
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
            activeOpacity={0.7}
            onPress={() => {
              configureAnimations();
              setEditModeActive((e) => !e);
            }}
          >
            <Ionicons
              name="ios-options-outline"
              size={20}
              color={Colors[colorScheme].text}
            />
            <Text
              style={{
                fontSize: Sizes.boxText,
                color: Colors[colorScheme].text,
              }}
            >
              {i18n.t("edit")}
            </Text>
          </TouchableOpacity>
        </View>
      )}
      {data?.map((item, index) => {
        return (
          <CaseCard
            {...{ item }}
            key={item._id + index}
            editMode={editModeActive}
            currentUser={currentUser}
          />
        );
      })}
      {data?.length === 0 && (
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            alignItems: "center",

            flex: 1,
          }}
        >
          <View style={{}}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                marginTop: 12,
                textAlign: "center",
              }}
            >
              {i18n.t("empty_cases_placeholder")}
            </Text>
            <Image
              source={require("../../../../../assets/images/characters/cases.png")}
              style={{
                alignSelf: "center",
                width: 250,
                height: 250,
                marginVertical: 32,
                resizeMode: "contain",
              }}
            />
          </View>
          <EmptyCaseButton />
        </View>
      )}
    </ScrollView>
  );
};

export default UserCasesComponent;

export const CaseCard = ({
  item,
  currentUser = {},
  editMode = false,
}: {
  item: any;
  currentUser?: any;
  editMode?: boolean;
}) => {
  const [selected, setSelected] = useState(false);
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const date = parseDate(item.last_updated);

  function deleteCaseConfirm(action, caseItem) {
    alert(
      "Fall: " + caseItem.note,
      "Möchtest du diesen Fall wirklich löschen?",
      [
        {
          text: "Nein",
          style: "cancel",
        },
        {
          text: "Entfernen",
          style: "destructive",
          onPress: () => {
            action();
          },
        },
      ]
    );
  }

  async function deleteCase(caseItem: Case) {
    deleteCaseConfirm(async () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "case_deletion_confirmed",
        "CaseCard",
        "User Confirmed Deletion",
        { caseId: caseItem.case_id }
      );

      setLoading(true);
      let allCases: Case[];
      configureAnimations();
      if (await getIsOffline()) {
        allCases = [...(currentUser.cases ?? [])];
        allCases = allCases.filter(
          (_case: Case) => _case.case_id !== caseItem.case_id
        );
      } else {
        const response = await apiCall(
          "/api/v3/users/case/" + caseItem.case_id,
          null,
          false,
          false,
          "DELETE"
        );
        if (response?.data?.cases) {
          allCases = response.data.cases;
        } else {
          showInlineNotification({
            text: "Ein Fehler ist aufgetreten, bitte versuche es erneut",
            type: InlineNotificationType.ERROR,
          });
          return;
        }
      }

      const _user = { ...currentUser };
      _user.cases = allCases;
      dispatch(setCurrentUser(_user));
      await updateOfflineUser(_user);
      setLoading(false);
    }, caseItem);
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 16,
        gap: 12,
      }}
    >
      {editMode && (
        <TouchableOpacity
          style={{ opacity: loading ? 0.5 : 1 }}
          disabled={loading}
          onPress={() => {
            deleteCase(item);
          }}
        >
          <Feather name="trash-2" size={24} color={Colors[colorScheme].red} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        activeOpacity={1}
        style={{ flex: 1, opacity: loading ? 0.5 : 1 }}
        disabled={loading}
        onPress={() => {
          const data = item.case_data;

          if (data.amounts) {
            for (const [key, value] of Object.entries(data.amounts)) {
              dispatch(setAmount({ [key]: value }));
            }
          }
          const additionalCaseData = {
            note: item.note,
            last_updated: item.last_updated,
            caseData: item.case_data,
            caseId: item.case_id,
          };
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "case_selected",
            "CaseCard",
            "User Selected Case",
            { caseId: item.case_id }
          );

          navigateToNextCMSPage(item._id, navigation, false, {
            additionalCaseData,
          });
          dispatch(setResultPageCaseContent(additionalCaseData));
        }}
        onPressIn={() => {
          setSelected(true);
        }}
        onPressOut={() => setSelected(false)}
      >
        <View
          style={{
            opacity: 1,
            borderRadius: 8,
            paddingLeft: 20,
            paddingRight: 20,
            paddingVertical: Sizes.defaultBoxPadding,
            height: "auto",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 12,

            borderColor: selected
              ? Colors[colorScheme].accent
              : Colors[colorScheme].foreground,
            backgroundColor: selected
              ? Colors[colorScheme].selectedTint
              : Colors[colorScheme].foreground,
          }}
        >
          <CategoryIcon size={32} title={item.path?.[0] ?? ""} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "700",
                color: Colors[colorScheme].text,
                marginBottom: 4,
              }}
            >
              {item.note ?? ""}
            </Text>
            {useMemo(
              () => (
                <CustomRenderHTMLComponent
                  contentWidth={Sizes.containerWidth}
                  tagsStyles={{
                    body: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "400",
                      fontStyle: "italic",
                    },
                    p: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "400",
                      margin: 0,
                      marginRight: 20,
                      marginTop: 12,
                      marginBottom: 12,
                    },
                    b: {
                      color: Colors[colorScheme].text,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      fontWeight: "700",
                      marginRight: 20,
                    },
                    ul: {
                      margin: 0,
                      fontSize: Sizes.boxText,
                      lineHeight: Sizes.lineHeightBoxText,
                      marginRight: 8,
                      color: Colors[colorScheme].text,
                      marginBottom: 2,
                    },
                    li: {
                      marginLeft: 6,
                      marginBottom: 5,
                      color: Colors[colorScheme].text,
                    },
                  }}
                  source={{ html: item.title ?? item.fieldTitle }}
                />
              ),
              []
            )}
            <Text
              style={{
                marginTop: 8,
                opacity: 0.5,
                fontSize: 12,
                color: Colors[colorScheme].text,
              }}
            >
              {date.toLocaleDateString("de-DE")}
              {", "}
              {date.toLocaleTimeString("de-DE", {
                hour12: false,
                minute: "2-digit",
                hour: "2-digit",
              })}
              {" Uhr"}{" "}
            </Text>
          </View>

          <Feather
            name="chevron-right"
            color={Colors[colorScheme].text}
            size={24}
            key="arrow"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
