import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Searchbar } from "react-native-paper";
import { useDispatch } from "react-redux";
import Colors from "../../../../constants/static-colors";
import { addToUserCreationObject } from "../../../../functions/user/actions";
import getColorScheme from "../../../../hooks/useColorScheme";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";

export default function SelectionInformationFromListComponent({ route }) {
  const navigation = useNavigation();
  const { data, indexKey } = route.params;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState(data);

  const dispatch = useDispatch();

  const colorScheme = getColorScheme();

  const onChangeSearch = (query: string) => {
    setSearchQuery(query);

    if (query === "") {
      setSearchData(data);
    }
    if (query.length > 0) {
      const searchData = data.filter((d: any) =>
        d.toLowerCase().includes(query.toLowerCase())
      );
      setSearchData(searchData);
    }
  };

  return (
    <SafeAreaView
      style={{
        height: "100%",
        width: "100%",
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Searchbar
        iconColor={Colors[colorScheme].text}
        inputStyle={{
          color: Colors[colorScheme].text,
          fontSize: 15,
        }}
        style={{
          borderColor: Colors[colorScheme].accent,
          borderWidth: 1,
          backgroundColor: Colors[colorScheme].foreground,
          borderRadius: 12,
          alignSelf: "stretch",
          margin: 12,
        }}
        placeholder="Suche..."
        placeholderTextColor={Colors[colorScheme].placeholder}
        onChangeText={onChangeSearch}
        value={searchQuery}
        onClearIconPress={() => {
          setSearchData(data);
        }}
        onSubmitEditing={() => {
          if (searchQuery.length >= 1) {
            const searchData = data.filter((d: any) =>
              d.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchData(searchData);
          }
          if (searchQuery === "") {
            setSearchData(data);
          }
        }}
      />

      <FlatList
        data={searchData}
        style={{ width: "100%" }}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: Colors[colorScheme].foreground,
              }}
              onPress={() => {
                dispatch(addToUserCreationObject({ [indexKey]: item }));
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "added_information_from_list",
                  "SelectInformationFromListComponent",
                  "InformationAdded",
                  { indexKey, item }
                );
                navigation.goBack();
              }}
            >
              <View
                style={{
                  width: "100%",
                  padding: 20,
                  borderBottomColor: Colors[colorScheme].gray,
                  borderBottomWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                  }}
                >
                  {item}
                </Text>
                <Feather
                  name="chevron-right"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={{ marginLeft: 20 }}
                />
              </View>
            </TouchableOpacity>
          );
        }}
      />
    </SafeAreaView>
  );
}
