import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import {
  Dimensions,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "../../../../localization/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import { HorizontalLogo } from "../../../components/logos/HorizontalLogo";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import isLargerDevice from "../../../hooks/isLargerDevice";

export const CustomSignInButton = ({ provider, text, onPress, loading }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={loading}
      style={{
        opacity: loading ? 0.5 : 1,
        marginHorizontal: 16,
        alignSelf: "stretch",
        borderWidth: 2,
        borderColor: Colors[colorScheme].text,
        borderRadius: 30,
        padding: 16,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          left: 20,
          position: "absolute",
        }}
      >
        {provider}
      </View>
      <Text
        style={{
          fontSize: 16,
          marginLeft: 8,
          color: Colors[colorScheme].text,
          fontWeight: "700",
          alignSelf: "center",
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default function LandingScreen() {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  // Record user interaction when screen opens
  useEffect(() => {
    AsyncStorage.setItem("user", "null");
    AsyncStorage.setItem("selectedEnterprise", "null");
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "landing_screen_opened",
      "LandingScreen",
      "UserOpened"
    );
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {/* Logo and Intro Section */}

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginVertical: 20,
        }}
      >
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            position: "absolute",
            top: isLargerDevice() ? 32 : 12,
            left: 0,
            right: 0,
            alignItems: "center",
          }}
        >
          <View
            style={{
              maxWidth: 700,
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 24 }}>{i18n.t("welcome_to")}</Text>
            <Image
              source={require("../../../../assets/images/logo_r.png")}
              style={{
                width: "70%",
                aspectRatio: 5,
                height: isLargerDevice() ? 100 : 75,
                maxWidth: Dimensions.get("window").width - 75,
                resizeMode: "contain",
              }}
            />
          </View>
        </View>
        <Image
          source={require("../../../../assets/images/landing_m.png")}
          style={{
            width: "200%",
            resizeMode: "contain",
            height: "50%",
            top: 100,
            right: 75,
            transform: [{ rotate: "14deg" }],
          }}
        />
      </View>
      {/* Button Section */}
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            alignSelf: "stretch",

            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              maxWidth: 700,
              width: "100%",
              gap: 12,
            }}
          >
            {/* <SignInWithGoogleButton />
            {Platform.OS !== "web" && <SignInWithAppleButton />} */}
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            padding: Sizes.defaultContainerPadding,
            width: "100%",
            maxWidth: 700,
          }}
        >
          <DefaultButton
            textStyle={{ fontWeight: "bold", fontSize: 16 }}
            style={{ height: 55 }}
            title={i18n.t("register_email")}
            type={ButtonTypes.Primary}
            action={() => navigation.navigate("RoleSelectionScreen")}
          />
          <DefaultButton
            textStyle={{ fontWeight: "bold", fontSize: 16 }}
            style={{ height: 55, marginTop: 20 }}
            title={i18n.t("log_in")}
            type={ButtonTypes.Secondary}
            action={() => navigation.navigate("LoginScreen")}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
