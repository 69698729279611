import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import i18n from "../../../localization/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import getAllTips from "../../api/tipps/get-all-tips";
import staticColors from "../../constants/static-colors";
import { selectCurrentUser } from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";
import { CaseCard } from "../../screens/tab-navigator-screens/PersonalContent/components/UserCasesComponent";
import { getSortedCaseData } from "../../screens/tab-navigator-screens/PersonalContent/components/functions";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import SectionHeaderText from "../SectionHeaderText";
import { ShowAllButton } from "./ShowAllButton";

export const CaseSection = () => {
  const [data, setData] = useState<any>([]);

  const navigation = useNavigation();
  const currentUser = useSelector(selectCurrentUser);
  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );

  useEffect(() => {
    async function load() {
      const enrichedData = getSortedCaseData(currentUser).slice(0, 3);

      if (JSON.stringify(data) !== JSON.stringify(enrichedData))
        setData(enrichedData);
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "case_section_loaded",
        "CaseSection",
        "Section Loaded",
        { caseCount: enrichedData.length } // Optional: any additional info
      );
    }

    if (!allPages) return;
    load();
  }, [currentUser?.cases, allPages]);

  return (
    <View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SectionHeaderText style={{ marginBottom: 0, flex: 1 }}>
          {i18n.t("my_cases")}
        </SectionHeaderText>
        {data.length > 0 && (
          <ShowAllButton
            title={i18n.t("show_all") + " (" + currentUser?.cases?.length + ")"}
            showIcon
            style={{
              marginTop: 0,
            }}
            action={() => {
              navigation.navigate("PersonalContentScreen", { selection: 1 });
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "show_all_clicked",
                "CaseSection",
                "Show All Clicked",
                { totalCases: currentUser?.cases?.length } // Optional: any additional info
              );
            }}
          />
        )}
      </View>
      {data.map((item) => (
        <CaseCard {...{ item }} key={item._id + item.case_id} />
      ))}
      {data.length === 0 && <EmptySectionView />}
    </View>
  );
};

export const EmptyCaseButton = () => {
  const navigation = useNavigation();

  return (
    <DefaultButton
      type={ButtonTypes.Primary}
      title={i18n.t("add_case")}
      action={() => {
        const tipsContent = getAllTips();

        const favoritesItem = tipsContent.content.listItems.find((item) =>
          item.pageTitle.includes("Fälle")
        );
        navigation.navigate("TippsDetailScreen", {
          tipContent: favoritesItem,
        });
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "add_case_clicked",
          "EmptyCaseSection",
          "Add Case Clicked",
          null // No additional info needed
        );
      }}
      style={{
        width: "60%",
        maxWidth: 500,
        alignSelf: "center",
        paddingVertical: 8,
      }}
    />
  );
};

const EmptySectionView = () => {
  const colorScheme = getColorScheme();

  return (
    <View style={{ marginTop: 16 }}>
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: "center",
          alignItems: "center",

          zIndex: 10,
          flex: 1,
        }}
      >
        <EmptyCaseButton />
      </View>
      <View
        style={{
          backgroundColor: staticColors[colorScheme].foreground,
          padding: 20,
          borderRadius: 8,
          flexDirection: "row",
          alignItems: "center",
          opacity: 0.5,
          height: 75,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <View
            style={{
              height: 44,
              backgroundColor: staticColors[colorScheme].background,
              width: 44,
              marginRight: 20,
              borderRadius: 25,
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            <Feather
              color={staticColors[colorScheme].text}
              name="save"
              size={24}
            />
          </View>
        </View>
        <Feather
          name="chevron-right"
          color={staticColors[colorScheme].text}
          size={24}
        />
      </View>
    </View>
  );
};
