export default function parsePageForNewSources(data) {
  let counter = 0; // Global counter
  const sourceFirstAppearanceMap = {}; // Map to track the first appearance of sources
  const appendedSources: any = []; // Array to store appended source objects

  function processData(item) {
    if (typeof item === "string") {
      // Directly process the string

      return item.replace(/\$source_(.*?)\$/g, (match, sourceId) => {
        let index;

        if (
          !Object.prototype.hasOwnProperty.call(
            sourceFirstAppearanceMap,
            sourceId
          )
        ) {
          index = counter++;
          sourceFirstAppearanceMap[sourceId] = index;
          const sourceObject = data.newSources.find(
            (source: any) => source._id === sourceId
          );
          if (!sourceObject) return "";
          appendedSources.push({ ...sourceObject, index }); // Append the source object at the index
        } else {
          index = sourceFirstAppearanceMap[sourceId];
        }

        const newIndex = `$source_${index + 1}$`;

        return newIndex;
      });
    } else if (Array.isArray(item)) {
      // Process each item in the array
      return item.map((box) => processData(box));
    } else if (typeof item === "object" && item !== null) {
      // If boxes is an object, iterate over its properties
      Object.keys(item).forEach((key) => {
        item[key] = processData(item[key]); // Apply updates
      });
      return item; // Return the updated object explicitly
    } else {
      // Return the input as is if it's neither a string, an array, nor an object
      return item;
    }
  }
  data = processData(data);

  data.appendedSources = appendedSources;
  delete data.newSources;
}
