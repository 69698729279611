import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useEffect, useRef } from "react";
import {
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import LargeScreenResultPageUserInteractionsComponent from "../components/LargeScreenResultPageUserInteractionsComponent";
import ResultBoxes from "../components/result/ResultBoxComponent";
import { SourcesView } from "../components/result/SourcesView/SourcesView";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import {
  selectFormulaResult,
  setResultingCalculation,
} from "../functions/calculator/actions";
import { shouldShowSources } from "../functions/current-result/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../functions/current-result/helpers";
import { selectCurrentUser } from "../functions/user/actions";
import { addToUserHistory } from "../functions/user/functions";
import getColorScheme from "../hooks/useColorScheme";
import {
  ResultPageContentView,
  ResultPageContentWrapper,
} from "./ResultPageScreen/ResultPageScreen";

export default function InfoResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);
  const formulaResult = useSelector(selectFormulaResult);
  const hasResultingCalculation = pageContent.resultingCalculationFormula;
  const currentUser = useSelector(selectCurrentUser);
  const hasBookmark = getIsBookmarkToID(pageContent._id, pageContent.legacy_id);
  const note = getNoteToID(pageContent._id, pageContent.legacy_id);

  const scrollViewRef = useRef<any>();

  useEffect(() => {
    addToUserHistory(pageContent._id);
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "info_result_screen_opened",
        "InfoResultPageScreen",
        "UserOpened",
        pageContent._id
      );
    });

    const _unsubscribe = navigation.addListener("beforeRemove", () => {
      dispatch(shouldShowSources(false));
    });

    return () => {
      unsubscribe();
      _unsubscribe();
    };
  }, []);

  const safelyEvaluateExpression = (expression, RESULT) => {
    // Define allowed variables and operations for safety

    const allowedOps = ["*", "/", "+", "-", "**"];

    // Check if expression contains only allowed operations and variables
    if (
      allowedOps.some((op) => expression.includes(op)) &&
      expression.includes("RESULT")
    ) {
      return new Function("RESULT", `return ${expression}`)(RESULT);
    } else {
      throw new Error("Unsafe expression detected");
    }
  };

  const insertCalculation = () => {
    // Extracting the expression inside the cur ly braces
    const expressionMatch = hasResultingCalculation.match(/\{(.*?)\}/);
    if (expressionMatch) {
      const expression = expressionMatch[1].trim();
      try {
        const evaluatedValue = safelyEvaluateExpression(
          expression,
          formulaResult
        );
        const roundedValue = Math.round(parseFloat(evaluatedValue) * 100) / 100;

        const updatedString = hasResultingCalculation.replace(
          `{${expression}}`,
          roundedValue
        );
        const inline = `<b style='color: ${Colors["light"].blue};'>${updatedString}</b>`;
        dispatch(setResultingCalculation(inline));
        navigation.goBack();
      } catch (error) {
        console.error("Error evaluating expression:", error);
      }
    }
  };

  if (pageContent === undefined) return null;

  return (
    <View
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          alignSelf: "stretch",
          marginTop: 20,
          alignItems: "center",
          zIndex: 100,
          flexDirection: "row",
          justifyContent: "space-between",
          paddingRight: 16,
        }}
      >
        <TouchableOpacity
          style={{
            zIndex: 100,
            marginLeft: 16,
          }}
          onPress={() => navigation.goBack()}
        >
          <Ionicons
            name="close-circle"
            size={36}
            style={{
              shadowOpacity: Platform.OS === "web" ? 0 : 0.2,
              shadowColor: Colors["light"].text,
              shadowRadius: 4,
              shadowOffset: { width: 0, height: 0 },
              elevation: 2,
            }}
            color={Colors[colorScheme].text}
          />
        </TouchableOpacity>

        <LargeScreenResultPageUserInteractionsComponent
          hasBookmark={hasBookmark}
          pageContent={pageContent}
          note={note}
          currentUser={currentUser}
        />
      </View>
      <BackgroundIcon />

      <View
        style={{
          width: "100%",
          flex: 1,
        }}
      >
        <ResultPageContentWrapper
          pageContent={pageContent}
          targetSections={undefined}
          isExporting={false}
          setWarningModalVisible={() => {}}
          hasResultingCalculation={hasResultingCalculation}
          formulaResult={formulaResult}
          insertCalculation={insertCalculation}
        />
      </View>
    </View>
  );
}

const BackgroundIcon = () => {
  const colorScheme = getColorScheme();
  return (
    <Feather
      name="info"
      color={Colors[colorScheme].text}
      size={500}
      width={600}
      style={{
        position: "absolute",
        bottom: -100,
        left: -150,
        opacity: 0.03,
      }}
    />
  );
};

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    title: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 24,

      letterSpacing: 0.7,
      textAlign: "left",
      color: Colors[scheme].text,
      //textTransform: "uppercase",
    },
    sub_title: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 12,
      lineHeight: 15,
      textAlign: "left",
      color: Colors[scheme].accent,
      marginBottom: 5,
      textTransform: "uppercase",
    },
  });
  return styles;
};
