import { Text, TouchableOpacity, View } from "react-native";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";
import { useSelector } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { posthog } from "../../api/analytics/posthog";
import { selectCurrentUser } from "../../functions/user/actions";

const feedbackItems = [
  "Empfehlung eines Freundes",
  "Social Media",
  "Website",
  "Events/Messen",
  "E-Mail/Newsletter",
  "Sonstiges",
];

export default function UserSourceModal({ open, onClose }) {
  const colorScheme = getColorScheme();
  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 12, marginTop: 20 }}>
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Wie hast du von uns mitbekommen?
        </Text>
        {feedbackItems.map((item) => (
          <FeedbackItem key={item} title={item} onClose={onClose} />
        ))}
      </View>
    </CustomModal>
  );
}

const FeedbackItem = ({ title, onClose }) => {
  const colorScheme = getColorScheme();
  const currentUser = useSelector(selectCurrentUser);
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        borderRadius: 12,
        padding: 12,
        alignItems: "center",
        marginVertical: 8,
        backgroundColor: Colors[colorScheme].background,
      }}
      onPress={async () => {
        posthog?.capture("user_source", {
          action: currentUser?.email,
          values: title,
        });
        onClose();
      }}
    >
      <Text
        style={{ flex: 1, marginRight: 12, color: Colors[colorScheme].text }}
      >
        {title}
      </Text>
      <Feather
        name="chevron-right"
        size={24}
        color={Colors[colorScheme].text}
      />
    </TouchableOpacity>
  );
};
