import { Feather } from "@expo/vector-icons";
import Colors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

export default function ExpandArrowImageComponent({ isExpanded }) {
  const colorScheme = getColorScheme();
  return (
    <Feather
      name={isExpanded ? "chevron-up" : "chevron-down"}
      size={24}
      color={Colors[colorScheme].text}
      style={{
        marginRight: 12,
      }}
    />
  );
}
