import { _ } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import configureAnimations from "../configure-animations";

const initialAmounts: any = {};
const initialUnit: any = undefined;

const calculatorSlice = createSlice({
  name: "calculator",
  initialState: {
    amounts: initialAmounts,
    currentUnit: initialUnit,
    calculationVar: 0,
    gender: "m",
    formulaResult: null,
    resultingCalculation: null,
    scoreData: null,
    checklistData: null,
    checklistNote: "",
    patientData: null,
    patientHeaderExpanded: true,
  },
  reducers: {
    setAmount(state, action) {
      Object.assign(state.amounts, action.payload);
    },
    setPatientHeaderExpanded(state, action) {
      state.patientHeaderExpanded = action.payload;
    },
    setScoreData(state, action) {
      state.scoreData = { ...(state.scoreData ?? {}), ...action.payload };
    },
    setChecklistData(state, action) {
      state.checklistData = action.payload;
    },
    clearAmount(state) {
      state.amounts = {};
    },

    setCurrentUnit(state, action) {
      state.currentUnit.push(action.payload);
      _.uniq(state.currentUnit);
    },
    setCalculationVar(state, action) {
      state.calculationVar = action.payload;
    },
    setFormulaResult(state, action) {
      state.formulaResult = action.payload;
    },
    setResultingCalculation(state, action) {
      state.resultingCalculation = action.payload;
    },
    setChecklistNote(state, action) {
      state.checklistNote = action.payload;
    },
    setPatientData(state, action) {
      state.patientData = action.payload;
      if (action.payload?.weight) {
        Object.assign(state.amounts, {
          kg: action.payload?.weight,
          g: action.payload?.weight * 1000,
          amount: action.payload?.weight,
        });
        if (!state.currentUnit) state.currentUnit = ["kg"];
        else if (Array.isArray(state.currentUnit)) {
          state.currentUnit = [...state.currentUnit, "kg"];
        } else {
          state.currentUnit = [state.currentUnit, "kg"];
        }
      }

      if (action.payload?.size) {
        Object.assign(state.amounts, {
          m: action.payload?.size / 100,
          cm: action.payload?.size,
          size: action.payload?.size,
        });
        // if (!state.currentUnit) state.currentUnit = ["cm", "m"];
        // else if (Array.isArray(state.currentUnit)) {
        //   state.currentUnit = [...state.currentUnit, "cm", "m"];
        // } else {
        //   state.currentUnit = [state.currentUnit, "cm", "m"];
        // }
      }

      configureAnimations();
    },
  },
});

export default calculatorSlice.reducer;
export const {
  setAmount,
  setCurrentUnit,
  setCalculationVar,
  clearAmount,
  setFormulaResult,
  setResultingCalculation,
  setScoreData,
  setChecklistData,
  setChecklistNote,
  setPatientData,
  setPatientHeaderExpanded,
} = calculatorSlice.actions;

export const selectPatientHeaderExpanded = (state: any) =>
  state.calculatorReducer.patientHeaderExpanded;
export const selectPatientData = (state: any) =>
  state.calculatorReducer.patientData;
export const selectAllAmounts = (state: any) => state.calculatorReducer.amounts;
export const selectScoreData = (state: any) =>
  state.calculatorReducer.scoreData;
export const selectCurrentUnit = (state: any) =>
  state.calculatorReducer.currentUnit;
export const selectChecklistData = (state: any) =>
  state.calculatorReducer.checklistData;
export const selectCalculationVar = (state: any) =>
  state.calculatorReducer.calculationVar;
export const selectFormulaResult = (state: any) =>
  state.calculatorReducer.formulaResult;
export const selectResultingCalculation = (state: any) =>
  state.calculatorReducer.resultingCalculation;
export const selectChecklistNote = (state: any) =>
  state.calculatorReducer.checklistNote;
