export const ADDITIONAL_SPECIALTIES = [
  "Ärztliches Qualitätsmanagement",
  "Akupunktur",
  "Allergologie",
  "Andrologie",
  "Balneologie und Medizinische Klimatologie",
  "Betriebsmedizin",
  "Dermatopathologie",
  "Diabetologie",
  "Ernährungsmedizin",
  "Flugmedizin",
  "Geriatrie",
  "Gynäkologische Exfoliativ-Zytologie",
  "Hämostaseologie",
  "Handchirurgie",
  "Homöopathie",
  "Immunologie",
  "Infektiologie",
  "Intensivmedizin",
  "Kardiale Magnetresonanztomographie",
  "Jugend-Endokrinologie und Diabetologie",
  "Kinder- und Jugend-Gastroenterologie",
  "Kinder- und Jugend-Nephrologie",
  "Kinder- und Jugend-Orthopädie",
  "Kinder- und Jugend-Pneumologie",
  "Kinder- und Jugend-Rheumatologie",
  "Klinische Akut- und Notfallmedizin",
  "Krankenhaushygiene",
  "Magnetresonanztomographie",
  "Manuelle Medizin",
  "Medikamentöse Tumortherapie",
  "Medizinische Informatik",
  "Naturheilverfahren",
  "Notfallmedizin",
  "Nuklearmedizinische Diagnostik für Radiologen",
  "Orthopädische Rheumatologie",
  "Palliativmedizin",
  "Phlebologie",
  "Physikalische Therapie",
  "Plastische und Ästhetische Operationen",
  "Proktologie",
  "Psychoanalyse",
  "Psychotherapie",
  "Rehabilitationswesen",
  "Röntgendiagnostik für Nuklearmediziner",
  "Schlafmedizin",
  "Sexualmedizin",
  "Sozialmedizin",
  "Spezielle Kardiologie für Erwachsene mit angeborenem Herzfehler",
  "Spezielle Kinder- und Jugend-Urologie",
  "Spezielle Orthopädische Chirurgie",
  "Schmerztherapie",
  "Unfallchirurgie",
  "Viszeralchirurgie",
  "Sportmedizin",
  "Suchtmedizinische Grundversorgung",
  "Transplantationsmedizin",
  "Tropenmedizin",
  "Sonstiges",
];
