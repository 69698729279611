import { useNavigation } from "@react-navigation/native";
import { Platform, StatusBar, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Colors from "../../constants/static-colors";
import isLargerDevice from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";
import { BackButton } from "./header-components/HeaderBackButton";
import { Sizes } from "../../constants/static-sizes";

export default function DefaultBackHeader({ title = "", isModal = false }) {
  const insets = useSafeAreaInsets();
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  // Define padding top with consideration for Android's status bar height
  const headerPaddingTop =
    Platform.OS === "android"
      ? (isModal ? 16 : 8) + (StatusBar.currentHeight ?? 0)
      : isModal
      ? 8
      : insets.top + 8;

  // Define header height dynamically based on modal state and device type
  const headerHeight = isModal
    ? 60 + headerPaddingTop
    : isLargerDevice()
    ? 80
    : 60 + headerPaddingTop;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: Colors[colorScheme].background,
        paddingTop: headerPaddingTop,
        paddingHorizontal: Sizes.defaultContainerPadding,
        height: headerHeight,
        shadowColor: Colors[colorScheme].text,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,
        width: "100%",
      }}
    >
      {/* Back Button */}
      <BackButton isModal={isModal} navigation={navigation} />

      {/* Header Title */}
      <Text
        style={{
          fontSize: 18,
          fontWeight: "500",
          color: Colors[colorScheme].text,
          textAlign: "center",
          flex: 1,
        }}
      >
        {title}
      </Text>

      {/* Empty view to balance out the layout for the back button */}
      <View style={{ width: 50 }} />
    </View>
  );
}
