import { useEffect, useState } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { getPagesToIDs } from "../../api/content/data-helpers";
import SectionHeaderText from "../SectionHeaderText";
import { HorizontalCardSlider } from "./HorizontalCardSlider";

export const CustomCMSSection = ({ header, ids }) => {
  const [data, setData] = useState<any>([]);

  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );

  useEffect(() => {
    async function load() {
      const _data: any[] = getPagesToIDs(ids, allPages);

      if (JSON.stringify(data) !== JSON.stringify(_data)) setData(_data);
    }
    if (!allPages) return;
    load();
  }, [ids, allPages]);

  return (
    <View
      style={
        {
          //overflow: "visible",
        }
      }
    >
      <SectionHeaderText
        style={{
          marginBottom: 16,
        }}
      >
        {header}
      </SectionHeaderText>
      <HorizontalCardSlider {...{ data: data, header }} />
    </View>
  );
};
