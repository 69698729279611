import { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import CustomRenderHTMLComponent from "../components/CustomRenderHTMLComponent";
import { getSharedStylesLargeContent } from "../components/boxes/SharedStyles";
import staticColors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import configureAnimations from "../functions/configure-animations";
import getColorScheme from "../hooks/useColorScheme";
import { isSublistChecked } from "../screens/ChecklistScreen/ChecklistScreen";
import BoxComponent from "./boxes/BoxSorter";

export const ChecklistComponent = ({
  checkedItems,
  setCheckedItems,
  pageContent,
}) => {
  const [expandedIds, setExpandedIds] = useState(new Set());

  return (
    <View style={{ marginTop: 12 }}>
      {pageContent.checklistItems?.map((item) => {
        const marginBottom =
          pageContent.checklistItems[
            pageContent.checklistItems.findIndex(
              (_item) => _item.id === item.id
            ) + 1
          ]?.typeId === "checklistListElement"
            ? 20
            : 0;

        if (item.typeId === "checklistListElement") {
          return (
            <ChecklistItem
              key={item.id}
              item={item}
              expandedIds={expandedIds}
              setExpandedIds={setExpandedIds}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          );
        } else {
          return (
            <View
              key={item.id}
              style={{
                marginBottom,
              }}
            >
              <BoxComponent key={item.id} box={item} />
            </View>
          );
        }
      })}
    </View>
  );
};

const ChecklistItem = ({
  item,
  expandedIds,
  setExpandedIds,
  checkedItems,
  setCheckedItems,
  subItem = false,
}) => {
  const colorScheme = getColorScheme();

  const toggleCheckbox = (id) => {
    const newChecked = new Set(checkedItems);
    if (newChecked.has(id)) {
      newChecked.delete(id);
    } else {
      newChecked.add(id);
    }
    setCheckedItems(newChecked);
  };

  const toggleExpanded = (id) => {
    configureAnimations();
    const newSet = new Set(expandedIds);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedIds(newSet);
  };

  const getMainItemIcon = (item) => {
    //TODO: Add different box for optional elements

    if (isSublistChecked(item.listElements, checkedItems)) {
      return <Icon name="check-box" size={24} color="#f4c430" />;
    } else if (item.listElements) {
      return (
        <Icon
          name="check-box-outline-blank"
          size={24}
          color="gray"
          style={{ opacity: 0.5 }}
        />
      );
    } else {
      return (
        <Icon
          name={
            checkedItems.has(item.id) ? "check-box" : "check-box-outline-blank"
          }
          size={24}
          color="#f4c430"
        />
      );
    }
  };

  return (
    <View
      key={item.id}
      style={{
        marginBottom: subItem ? 0 : 12,
        marginTop: subItem ? 8 : 0,
        padding: subItem ? 8 : 12,
        backgroundColor: staticColors[colorScheme].foreground,
        borderRadius: 5,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (!item.listElements) {
              toggleCheckbox(item.id);
            } else {
              toggleExpanded(item.id);
            }
          }}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
            marginRight: 12,
          }}
        >
          {getMainItemIcon(item)}

          <View style={{ flex: 1, marginLeft: 12, marginBottom: -4 }}>
            <MemoText
              text={item.title + (item.isOptional ? " (optional)" : "")}
              style={{
                ...getSharedStylesLargeContent(colorScheme, Sizes.boxText),
              }}
            />
          </View>
        </TouchableOpacity>
        {item.listElements && (
          <Text style={{ fontWeight: "bold", marginRight: 8 }}>
            {"(" +
              item.listElements.filter((subItem) =>
                checkedItems.has(subItem.id)
              ).length +
              ")"}
          </Text>
        )}
        {(item.expandableContent || item.listElements) && (
          <TouchableOpacity
            style={{
              padding: subItem ? 2 : 4,
              borderRadius: 5,
              backgroundColor: staticColors[colorScheme].veryLightGray,
            }}
            onPress={() => toggleExpanded(item.id)}
          >
            <Icon
              name={expandedIds.has(item.id) ? "expand-less" : "expand-more"}
              size={24}
              color={staticColors[colorScheme].text}
            />
          </TouchableOpacity>
        )}
      </View>
      {expandedIds.has(item.id) && (
        <View>
          {item.expandableContent && (
            <View style={{ marginTop: 12 }}>
              <MemoText
                style={{
                  ...getSharedStylesLargeContent(colorScheme, Sizes.boxText),
                }}
                text={item.expandableContent}
              />
            </View>
          )}
          <View
            style={{
              marginTop: expandedIds.has(item.id) ? 8 : 0,
            }}
          >
            {item.listElements &&
              item.listElements?.map((subItem) => (
                <ChecklistItem
                  key={subItem.id}
                  item={subItem}
                  expandedIds={expandedIds}
                  setExpandedIds={setExpandedIds}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  subItem
                />
              ))}
          </View>
        </View>
      )}
    </View>
  );
};

const MemoText = ({ text, style }) => {
  const colorScheme = getColorScheme();
  return useMemo(
    () => (
      <CustomRenderHTMLComponent
        source={{ html: "<p>" + text + "</p>" }}
        tagsStyles={style}
      />
    ),
    [text, colorScheme]
  );
};
