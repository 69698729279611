import * as Sentry from "@sentry/react-native";
import PostHog from "posthog-react-native";
import { Platform } from "react-native";

export let posthog: PostHog | undefined = undefined;
export let posthogAsync: Promise<PostHog | undefined>;

const DSN =
  "https://fc5070db4c5f452985505e2bf379357e@o4504752536289280.ingest.sentry.io/4504752559554560";

if (!__DEV__) {
  if (Platform.OS !== "web")
    Sentry.init({
      dsn: DSN,
    });
  posthogAsync = PostHog.initAsync(
    "phc_RYChOHs7WUKynt9w1LPcoGJgDKeVThBpV2kMBLJES6i",
    { host: "https://eu.posthog.com" }
  );

  posthogAsync.then((client) => {
    posthog = client;
  });
} else {
  // In development, you can just resolve the promise with a mock or leave it undefined
  posthogAsync = Promise.resolve(undefined);
}
