import { Platform, SafeAreaView, ScrollView, View } from "react-native";
import Pdf from "react-native-pdf";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getColorScheme from "../../../../hooks/useColorScheme";

const PP_URL =
  "https://firebasestorage.googleapis.com/v0/b/medify-826de.appspot.com/o/privacy_policy.pdf?alt=media&token=944d3748-22ac-4df0-88aa-6aab3c043cf6&_gl=1*1qquid7*_ga*MTIwNTQwOTI1OC4xNjczNzgzNzc3*_ga_CW55HF8NVT*MTY5OTM4NTA1OC4xNjcuMS4xNjk5Mzg1NTkxLjUzLjAuMA..";

export function PrivacyPolicyScreen() {
  const colorScheme = getColorScheme();

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            marginTop: 0,
            width: "100%",
            alignItems: "center",
            flex: 1,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",

              alignItems: "center",
              height: "100%",
              maxWidth: Sizes.containerWidth,
            }}
          >
            <PrivacyPolicyContent />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

export const PrivacyPolicyContent = () => {
  return (
    <ScrollView
      style={{ width: "100%", flex: 1 }}
      contentContainerStyle={{
        alignItems: "center",
        width: "100%",
        flexGrow: 1,
      }}
      horizontal={false}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        {Platform.OS !== "web" ? (
          <Pdf
            trustAllCerts={false}
            source={{ uri: PP_URL }}
            style={{ flex: 1 }}
          />
        ) : (
          <iframe src={PP_URL} style={{ flex: 1 }} />
        )}
      </View>
    </ScrollView>
  );
};
