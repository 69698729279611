import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import Colors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

export default function CheckBox({ isSelected, setSelected, style = {} }) {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      disabled={!setSelected}
      onPress={() => setSelected((s) => !s)}
      style={{
        borderRadius: 6,
        aspectRatio: 1,
        width: 20,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: !isSelected
          ? Colors[colorScheme].text
          : Colors[colorScheme].accent,
        backgroundColor: !isSelected
          ? "transparent"
          : Colors[colorScheme].accent,
        ...style,
      }}
    >
      {isSelected && (
        <Ionicons name="checkmark-sharp" size={17} color={"white"} />
      )}
    </TouchableOpacity>
  );
}
