import { configureStore } from "@reduxjs/toolkit";
import calculatorReducer from "./calculator/actions";
import currentResultReducer from "./current-result/actions";
import dataReducer from "./data/actions";
import enterpriseReducer from "./enterprise/actions";
import navigationReducer from "./navigation/actions";
import userReducer from "./user/actions";

export const store = configureStore({
  reducer: {
    userReducer: userReducer,
    navigationReducer: navigationReducer,
    dataReducer: dataReducer,
    calculatorReducer: calculatorReducer,
    currentResultReducer: currentResultReducer,
    enterpriseReducer: enterpriseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
