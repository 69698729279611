import { TextInput, View } from "react-native";
import staticColors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import getColorScheme from "../../hooks/useColorScheme";

export const DefaultInputComponent = ({
  textFieldRef,
  textObject,
  value,
  setValue,
  marginTop = 30,
  marginBottom = 12,
}) => {
  const colorScheme = getColorScheme();
  return (
    <View style={{ width: "100%" }}>
      <TextInput
        key={textObject.contentType}
        value={value}
        placeholderTextColor={staticColors[colorScheme].placeholder}
        textContentType={textObject.contentType}
        ref={textFieldRef}
        keyboardType={textObject.type}
        onChangeText={(t) => setValue(t)}
        style={{
          backgroundColor: staticColors[colorScheme].foreground,
          color: staticColors[colorScheme].text,
          maxWidth: Sizes.containerWidth,
          borderRadius: 12,
          marginTop,
          width: "100%",
          height: 50,
          padding: 12,
          marginBottom,
          zIndex: 0,
        }}
        placeholder={textObject.placeholder}
      />
    </View>
  );
};
