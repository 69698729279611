import { Feather } from "@expo/vector-icons";
import { View } from "react-native";
import { useDispatch } from "react-redux";
import {
  ButtonTypes,
  DefaultButton,
} from "../../../../components/DefaultButton";
import { addToUserCreationObject } from "../../../../functions/user/actions";
import {
  InputWithTitle,
  WhyWeNeedThisInformation,
} from "./AdditionalInformationComponent";
import { NURSE_JOBS } from "./nurse-job";
import { ClinicInputComponent } from "./GoogleMapsLocationAutoCompleteInputComponent";

export const NurseContent = ({
  userCreationObject,
  showHint,
  backAction,
  continueAction,
  loading,
}) => {
  const dispatch = useDispatch();

  const isButtonEnabled = () => {
    const evaluation =
      userCreationObject.job_description?.trim() !== "" &&
      userCreationObject.clinic?.trim() !== "";

    return evaluation;
  };

  const handleInputChange = (name, value) => {
    dispatch(
      addToUserCreationObject({
        ...userCreationObject,
        [name]: value,
      })
    );
  };

  return (
    <View>
      <View style={{ marginBottom: 32 }}>
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues: userCreationObject,
          }}
          presetSelection={NURSE_JOBS}
          title="Welchem Beruf gehst du nach?"
          name="job_description"
        />
        <ClinicInputComponent inputValues={userCreationObject} />
      </View>
      {backAction && <WhyWeNeedThisInformation showHint={showHint} />}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {backAction && (
          <DefaultButton
            title={"zurück"}
            action={backAction}
            type={ButtonTypes.Outline}
            style={{ width: "30%" }}
          />
        )}
        <DefaultButton
          action={continueAction}
          title="Weiter"
          textStyle={{ alignSelf: "flex-start" }}
          enabled={isButtonEnabled()}
          style={{
            width: "50%",
            marginTop: backAction ? 0 : 20,
            marginLeft: backAction ? 20 : 0,
            paddingHorizontal: 20,
          }}
          loading={loading}
          icon={<Feather name="arrow-right" size={24} />}
          type={ButtonTypes.Primary}
        />
      </View>
    </View>
  );
};
