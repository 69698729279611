import { Ionicons } from "@expo/vector-icons";
import React, { useCallback, useEffect, useState } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import i18n from "../../../../../localization/i18n";
import { getPagesToIDs } from "../../../../api/content/data-helpers";
import { LongCard } from "../../../../components/home-tab/LongCard";
import { EmptyFavoritesButton } from "../../../../components/home-tab/ShortcutsSection";
import ChangeSortOrderModal from "../../../../components/modals/ChangeSortOrderModal";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { getValueFromInteractions } from "../../../../functions/user-interaction-handler";
import { selectCurrentUser } from "../../../../functions/user/actions";
import getColorScheme from "../../../../hooks/useColorScheme";

const FavoritesComponent = ({ query, data, setData }) => {
  const allPages = useSelector(
    (state: any) => state.dataReducer.allData?.pages
  );

  const currentUser = useSelector(selectCurrentUser);
  const [changeSortOrderModalVisible, setChangeSortOrderModalVisible] =
    useState(false);
  const [sortOrder, setSortOrder] = useState(
    getValueFromInteractions("sort_order_favorites") || "recent"
  );

  const colorScheme = getColorScheme();

  const debouncedDataOrganizer = useCallback(() => {
    let items = [...(currentUser.bookmarks ?? [])];
    if (sortOrder === "recent") {
      items = items.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else {
      items = items.sort((a, b) => a.score - b.score);
    }
    let _data = getPagesToIDs(
      items.map((item) => item.result_page_id),
      allPages
    );

    if (query)
      _data = _data.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      );
    if (JSON.stringify(data) !== JSON.stringify(_data)) setData(_data);
  }, [currentUser, query]);

  useEffect(() => {
    debouncedDataOrganizer();
  }, [sortOrder, query, currentUser?.bookmarks]);

  return (
    <>
      <ChangeSortOrderModal
        open={changeSortOrderModalVisible}
        onClose={(selection) => {
          setChangeSortOrderModalVisible(false);
          if (selection) setSortOrder(selection);
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 110 }}
        style={{ flex: 1, paddingHorizontal: Sizes.defaultContainerPadding }}
      >
        {data?.length > 0 && (
          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: Sizes.boxText,
                flex: 1,
                color: Colors[colorScheme].text,
              }}
            >
              {data.length} {i18n.t("favorites")}
            </Text>

            <TouchableOpacity
              onPress={() => setChangeSortOrderModalVisible(true)}
              style={{
                padding: 8,
                paddingHorizontal: 16,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.1,
                borderRadius: 50,
                shadowRadius: 2.62,
                backgroundColor: Colors[colorScheme].foreground,
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
                elevation: 2,
              }}
            >
              <Ionicons
                name="filter-outline"
                size={20}
                color={Colors[colorScheme].text}
              />
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  color: Colors[colorScheme].text,
                }}
              >
                {sortOrder === "recent"
                  ? i18n.t("recently_used")
                  : i18n.t("most_used")}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {data?.map((item, index) => {
          return item && <LongCard {...{ item }} key={item?._id + index} />;
        })}
        {data?.length === 0 && (
          <View
            style={{
              margin: Sizes.defaultContainerPadding,
              alignItems: "center",

              flex: 1,
            }}
          >
            <View style={{}}>
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  marginTop: 12,
                  textAlign: "center",
                }}
              >
                {i18n.t("empty_favorites_placeholder")}
              </Text>
              <Image
                source={require("../../../../../assets/images/characters/shortcuts.png")}
                style={{
                  alignSelf: "center",
                  width: 250,
                  height: 250,
                  marginVertical: 32,
                  resizeMode: "contain",
                }}
              />
            </View>
            <EmptyFavoritesButton />
          </View>
        )}
      </ScrollView>
    </>
  );
};

export default React.memo(FavoritesComponent);
