import { LayoutAnimation } from "react-native";
import { CustomExpansionAnimation } from "../constants/static-animations";

export default function configureAnimations(duration = 300) {
  LayoutAnimation.configureNext({
    duration,
    create: {
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity,
    },
    update: {
      type: LayoutAnimation.Types.easeInEaseOut,
    },
  });
}

export function configureExpansionAnimation() {
  LayoutAnimation.configureNext(CustomExpansionAnimation);
}
