import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useFeatureFlag } from "posthog-react-native";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import { IconToCategory } from "../../../../components/CategoryIcon";
import ImageWrapper from "../../../../components/ImageWrapper";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { selectEnterprise } from "../../../../functions/enterprise/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import { isWebAndNotMobile } from "../../../../hooks/isLargerDevice";
import getColorScheme from "../../../../hooks/useColorScheme";

export default function ContentListComponent({ content }) {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const selectedEnterprise = useSelector(selectEnterprise);

  const showLicense =
    useFeatureFlag("show-license-information") && !selectedEnterprise;

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingBottom: 110,
      }}
      style={{
        flex: 1,
        flexGrow: 1,
        paddingHorizontal: Sizes.defaultContainerPadding,
      }}
    >
      {showLicense && (
        <TouchableOpacity
          style={{
            backgroundColor: staticColors[colorScheme].foreground,
            borderRadius: 12,
            overflow: "hidden",
            marginTop: 12,
            flexDirection: "row",
            alignItems: "center",
          }}
          onPress={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "navigate_to_kliniklizenz_content_screen",
              "LicenseHintCard",
              "UserNavigated",
              null
            );
            navigation.navigate("WebBrowserScreen", {
              url: "https://mediceo.com/kliniken",
              title: "MEDICEO - Kliniklizenz",
            });
          }}
        >
          <Image
            source={getBackgroundToCategory("Scores")}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              resizeMode: "cover",
            }}
          />
          <Text
            style={{
              margin: 12,
              marginHorizontal: 16,
              flex: 1,
              fontSize: Sizes.boxText,
              fontStyle: "italic",
              color: staticColors[colorScheme].text,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                color: staticColors[colorScheme].text,
              }}
            >
              Hausinterne Inhalte
            </Text>{" "}
            - Alle Prozesse auf Abruf
          </Text>
          <Feather
            style={{ marginRight: 16 }}
            name="info"
            color={staticColors[colorScheme].text}
            size={20}
          />
        </TouchableOpacity>
      )}
      {content?.map((item) => {
        return (
          <ContentCard
            key={item.initialPageId}
            title={item.title}
            id={item.initialPageId}
            image={item.categoryImage?.file_ref}
          />
        );
      })}
    </ScrollView>
  );
}

const ContentCard = ({ title, id, image = undefined }) => {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={() => {
        navigateToNextCMSPage(id, navigation);
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "content_card_selected",
          "ContentListComponent",
          "User Selected Content Card",
          { contentId: id, contentType: title }
        );
      }}
      style={{
        borderRadius: 12,

        marginTop: Sizes.defaultBoxPadding + 4,
        overflow: "hidden",
        backgroundColor: staticColors["dark"].foreground,
        position: "relative",
      }}
    >
      {
        <Image
          source={getBackgroundToCategory(title)}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      }
      <View
        style={{
          padding: isWebAndNotMobile() ? 26 : 16,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View style={{ marginRight: 16 }}>
          {image ? (
            <ImageWrapper
              source={{ uri: image }}
              style={{ width: 32, height: 32, resizeMode: "cover" }}
            />
          ) : (
            <IconToCategory
              iconColor={staticColors[colorScheme].text}
              title={title}
              size={32}
            />
          )}
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText + 1,
            fontWeight: "bold",
            flex: 1,
            color: staticColors[colorScheme].text,
          }}
        >
          {title}
        </Text>
        <Feather
          name="chevron-right"
          color={staticColors[colorScheme].text}
          size={24}
        />
      </View>
    </TouchableOpacity>
  );
};

function getBackgroundToCategory(title) {
  const colorScheme = getColorScheme();

  if (title === "Notfall" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Notfall.png");
  else if (title === "Notfall" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Notfall.png");
  else if (title === "Interventionen & Prozeduren" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Interventionen_Prozeduren.png");
  else if (title === "Interventionen & Prozeduren" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Interventionen_Prozeduren.png");
  else if (title === "Fast-Track" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Fast_Track.png");
  else if (title === "Fast-Track" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Fast_Track.png");
  else if (title === "Therapiealgorithmen" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Therapiealgo.png");
  else if (title === "Therapiealgorithmen" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Therapiealgo.png");
  else if (title === "Erkrankungen" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Erkrankungen.png");
  else if (title === "Erkrankungen" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Erkrankungen.png");
  else if (title === "Scores" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Scores.png");
  else if (title === "Scores" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Scores.png");
  else if (title === "Rechner" && colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Rechner.png");
  else if (title === "Rechner" && colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Rechner.png");
  else if (colorScheme === "dark")
    return require("../../../../../assets/images/cards/dark/Rechner.png");
  else if (colorScheme === "light")
    return require("../../../../../assets/images/cards/light/Rechner.png");
}
