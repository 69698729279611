import { createSelector, createSlice } from "@reduxjs/toolkit";

const currentResultSlice = createSlice({
  name: "currentResult",
  initialState: {
    hasMultipleChoice: false,
    currentFilters: [],
    isQuickHelp: false,
    shouldShowSources: false,
    nextMultiplePages: [],
    filterNodePages: null,
    showTitle: false,
    currentResultPage: null,
    currentHighlightSection: null,
    resultPageHeaderContent: null,
    resultPageCaseContent: null,
    remoteOpenSaveScoreModal: false,
    isExporting: false,
    exportList: [],
    resultPageSearchQuery: "",
  },
  reducers: {
    setCurrentHighlightSection(state, action) {
      state.currentHighlightSection = action.payload;
    },
    setIsExporting(state, action) {
      state.isExporting = action.payload;
      if (action.payload === false) {
        state.exportList = [];
      }
    },
    setRemoteOpenSaveScoreModal(state, action) {
      state.remoteOpenSaveScoreModal = action.payload;
    },
    setResultPageCaseContent(state, action) {
      state.resultPageCaseContent = action.payload;
    },
    setResultPageHeaderContent(state, action) {
      state.resultPageHeaderContent = action.payload;
    },
    setHasMultipleChoice(state, action) {
      state.hasMultipleChoice = action.payload;
    },
    setShowTitle(state, action) {
      state.showTitle = action.payload;
    },
    setCurrentResultPage(state, action) {
      state.currentResultPage = action.payload;
    },
    setNodePageFilter(state, action) {
      state.filterNodePages = action.payload;
    },
    addToCurrentFilters(state, action) {
      state.currentFilters.push(action.payload);
    },
    removeFromCurrentFilters(state, action) {
      state.currentFilters = state.currentFilters.filter(
        (filter) => filter !== action.payload
      );
    },
    clearCurrentFilters(state) {
      state.currentFilters = [];
    },
    shouldShowSources(state, action) {
      state.shouldShowSources = action.payload;
    },
    addNextMultiplePage(state, action) {
      state.nextMultiplePages.push(action.payload);
    },
    removeFromMultiplePage(state, action) {
      state.nextMultiplePages = state.nextMultiplePages.filter(
        (page) => page !== action.payload
      );
    },
    setIsQuickHelp(state, action) {
      state.isQuickHelp = action.payload;
    },
    addToExportList(state, action) {
      state.exportList.push(action.payload);
    },
    removeFromExportList(state, action) {
      state.exportList = state.exportList.filter(
        (item) => item.id !== action.payload.id
      );
    },
    setResultPageSearchQuery(state, action) {
      state.resultPageSearchQuery = action.payload;
    },
  },
});

export const {
  setHasMultipleChoice,
  addToCurrentFilters,
  addNextMultiplePage,
  removeFromCurrentFilters,
  shouldShowSources,
  setIsQuickHelp,
  removeFromMultiplePage,
  clearCurrentFilters,
  setNodePageFilter,
  setCurrentResultPage,
  setShowTitle,
  setCurrentHighlightSection,
  setResultPageHeaderContent,
  setResultPageCaseContent,
  setRemoteOpenSaveScoreModal,
  setIsExporting,
  addToExportList,
  removeFromExportList,
  setResultPageSearchQuery,
} = currentResultSlice.actions;
export default currentResultSlice.reducer;
export const selectHasMultipleChoice = (state) =>
  state.currentResultReducer.hasMultipleChoice;

export const selectFilterNodePages = (state) =>
  state.currentResultReducer.filterNodePages;
export const selectShowSources = (state) =>
  state.currentResultReducer.shouldShowSources;
export const selectCurrentFilters = (state) =>
  state.currentResultReducer.currentFilters;
export const selectShowTitle = (state) => state.currentResultReducer.showTitle;
export const selectCurrentResultPage = (state) =>
  state.currentResultReducer.currentResultPage;

export const selectCurrentHighlightSection = (state) =>
  state.currentResultReducer.currentHighlightSection;
export const selectResultPageHeaderContent = (state) =>
  state.currentResultReducer.resultPageHeaderContent;
export const selectResultPageCaseContent = (state) =>
  state.currentResultReducer.resultPageCaseContent;
export const selectRemoteOpenSaveScoreModal = (state) =>
  state.currentResultReducer.remoteOpenSaveScoreModal;
export const selectIsExporting = (state) =>
  state.currentResultReducer.isExporting;
export const selectExportList = (state) =>
  state.currentResultReducer.exportList;

export const selectShouldShowSources = createSelector(
  (state) => state.currentResultReducer.shouldShowSources,
  (shouldShowSources) => shouldShowSources
);

export const selectResultPageSearchQuery = (state) =>
  state.currentResultReducer.resultPageSearchQuery;
