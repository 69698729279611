import { useEffect, useMemo, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import ScoreElementToContent from "./ScoreElementToContent";

const ScoreElements = ({
  data,
  scrollViewRef,
  setScore,
  informationHeight,
  setAnswers,
  currentSelectionIndex,
  setCurrentSelectionIndex,
  isEuroscore,
  answers,
}) => {
  const [shouldChange, setShouldChange] = useState(true);

  function handleScroll() {
    setTimeout(() => {
      scrollViewRef?.current.scrollTo({ y: 100000, animated: true });
    }, 150);
  }

  useEffect(() => {
    if (data === undefined) return;
    if (currentSelectionIndex === data.scoreElements?.length) {
      handleScroll();
    }
  }, [currentSelectionIndex]);

  return useMemo(
    () => (
      <View style={{ marginTop: 20 }}>
        {data.scoreElements?.map((d, index) => {
          return (
            <TouchableOpacity
              key={d.id ?? index}
              disabled={d.type === "text" || index === currentSelectionIndex}
              onPress={() => {
                if (index === currentSelectionIndex) return;
                setShouldChange(false);
                setCurrentSelectionIndex(index);
              }}
            >
              <ScoreElementToContent
                {...{
                  scrollViewRef,
                  setScore,
                  answers,
                  informationHeight,
                  setAnswers,
                  index,
                  d,
                  setShouldChange,
                  shouldChange,
                  setCurrentSelectionIndex,
                  currentSelectionIndex,
                  isEuroscore,
                }}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    ),
    [
      currentSelectionIndex,
      shouldChange,
      data.scoreElements,
      answers,
      scrollViewRef,
      informationHeight,
      setAnswers,
      setScore,
      setCurrentSelectionIndex,
      isEuroscore,
    ]
  );
};

export default ScoreElements;
