import { Image, SafeAreaView, ScrollView, Text, View } from "react-native";
import ce from "../../../../../assets/images/ce.png";
import factory from "../../../../../assets/images/factory.png";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { VERSION } from "../../../../constants/current-version";
import getColorScheme from "../../../../hooks/useColorScheme";

export function ImpressScreen() {
  const colorScheme = getColorScheme();

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            marginTop: 0,
            width: "100%",
            alignItems: "center",
            flex: 1,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "center",
              height: "100%",
              paddingTop: 12,
              maxWidth: Sizes.containerWidth,
            }}
          >
            <ScrollView
              style={{ width: "100%", flex: 1 }}
              contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
              horizontal={false}
            >
              <View
                style={{
                  marginTop: 20,
                  paddingHorizontal: 16,
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: 60,
                  height: "100%",
                  overflow: "visible",
                  justifyContent: "flex-start",
                }}
              >
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                  }}
                >{`
Version ${VERSION}

MEDICEO GmbH
Neue Mainzer Straße 52 - 58,
60311 Frankfurt am Main
`}</Text>
                <Image
                  source={factory}
                  style={{
                    tintColor: Colors[colorScheme].text,
                    marginVertical: 20,
                    width: 75,
                    resizeMode: "contain",
                    height: 75,
                  }}
                />
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                  }}
                >{`Geschäftsführung: Tom Godbersen, Matthias Hippe

E-Mail-Adresse: info@mediceo.com
Umsatzsteuer-Identifikationsnummer: DE353993825
Gerichtsstand: HRB127644 | Amtsgericht Frankfurt

Verantwortlich für den Inhalt: Mediceo GmbH
`}</Text>
                <Image
                  source={ce}
                  style={{
                    marginVertical: 20,
                    width: 75,
                    tintColor: Colors[colorScheme].text,
                    resizeMode: "contain",
                    height: 75,
                  }}
                />
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                  }}
                >
                  Die MEDICEO App ist ein Medizinprodukt der Risikoklasse I im
                  Sinne der Verordnung (EU) 2017/745. Der Hersteller erklärt die
                  Konformität dieses Produktes mit den grundlegenden
                  Anforderungen gemäß Verordnung (EU) 2017/745, Anhang I und
                  dokumentiert dies durch die CE-Kennzeichnung.{"\n\n"}Basis
                  UDI: 426237899001DN
                </Text>
              </View>
            </ScrollView>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
