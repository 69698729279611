import { Feather } from "@expo/vector-icons";
import { hyphenate } from "hyphen/de";
import { useEffect, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { IconToCategory } from "../../../components/CategoryIcon";
import DefaultGradientBackground from "../../../components/DefaultGradientBackground";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import isLargerDevice, {
  isWebAndNotMobile,
} from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";
import { parseDate } from "../functions/helpers";

export const Card = ({
  title,
  id,
  fixedWidth,
  action,
  time,
  sectionHeader,
}: {
  title: string;
  id: string;
  fixedWidth: number;
  action?: () => void;
  time?: string;
  sectionHeader: string;
}) => {
  const [_title, setTitle] = useState(title);
  useEffect(() => {
    (async () => {
      const hypen = await hyphenateDe(title);
      setTitle(hypen);
    })();
  }, []);

  const colorScheme = getColorScheme();

  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "card_selected",
          "Card",
          "UserPressedCard",
          { section: sectionHeader.split(" ").join("") }
        );
        if (action) action();
      }}
      style={{
        backgroundColor: Colors[colorScheme].foreground,
        flex: 1,
        borderRadius: 12,
        padding: isLargerDevice() ? 20 : 16,

        width: isLargerDevice()
          ? fixedWidth * (isWebAndNotMobile() ? 1.5 : 1.3)
          : fixedWidth,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        overflow: "hidden",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
          paddingBottom: 12,
        }}
      >
        <CategoryIcon
          size={isLargerDevice() ? 32 : 24}
          title={id}
          style={{
            zIndex: 10,
            alignSelf: "flex-start",
            borderRadius: 100,
            backgroundColor: "#ddddddcc",
            padding: 10,
          }}
        />

        <Feather name="arrow-up-right" size={24} color="black" style={{}} />
      </View>
      <DefaultGradientBackground />
      <View style={{ flex: 1 }}>
        <Text
          android_hyphenationFrequency="full"
          style={{
            marginBottom: 0,
            fontSize: Sizes.boxText,
            fontWeight: "600",
            alignSelf: "stretch",
            flex: 1,
          }}
          numberOfLines={Platform.OS === "web" ? 3 : 2}
          ellipsizeMode="tail"
        >
          {_title.trim() ?? title.trim()}
        </Text>
        {time && (
          <Text
            style={{
              fontSize: 10,
              color: Colors["light"].text,
              opacity: 0.5,
              paddingTop: 8,
            }}
          >
            {getStringToTime(time)}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export async function hyphenateDe(text) {
  return await hyphenate(text);
}

const CategoryIcon = ({ title, style = {}, size }) => {
  return (
    <View
      style={{
        aspectRatio: 1,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <IconToCategory iconColor={"black"} title={title} size={size} />
    </View>
  );
};

function getStringToTime(time: string | undefined) {
  if (!time) return "";
  const date = parseDate(time);

  return formatDate(date);
}

function formatDate(date) {
  const now = new Date();

  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInMinutes < 1) {
    return "vor kurzem";
  } else if (diffInMinutes < 60) {
    return "vor " + diffInMinutes + " Minuten";
  } else if (diffInHours < 24) {
    if (diffInHours === 1) {
      return "vor einer Stunde";
    } else {
      return "vor " + diffInHours + " Stunden";
    }
  } else if (diffInDays < 2) {
    return "gestern";
  } else if (diffInDays < 7) {
    return "diese Woche";
  } else {
    return "vor einiger Zeit";
  }
}
