import { Feather } from "@expo/vector-icons";
import { TouchableOpacity, View } from "react-native";
import Colors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";

export const LargeHeaderBackButton = ({ navigation, isModal }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.goBack();
      }}
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    >
      <View
        style={{
          backgroundColor: Colors[colorScheme].lightGray,
          width: 32,
          height: 32,
          maxWidth: 32,
          aspectRatio: 1,
          borderRadius: 16,
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Feather
          name={isModal ? "x" : "chevron-left"}
          color={Colors[colorScheme].text}
          size={24}
        />
      </View>
    </TouchableOpacity>
  );
};
