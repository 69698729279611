import { TextInput, Text, TouchableOpacity, View } from "react-native";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import {
  changeUserInformation,
  selectCurrentUser,
} from "../../functions/user/actions";
import { updateUserPropertyCall } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../NotificationComponent";
import CustomModal from "./CustomModal";

// TODO ADD UNI LIST

export default function ChangeUserInformationModal({
  open,
  onClose,
  indexKey,
  placeholder,
  title,
}) {
  const colorScheme = getColorScheme();

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const isUniversity = false; //!title.includes("Universität");

  async function change() {
    setLoading(true);

    const success = await updateUserPropertyCall(currentUser, indexKey, text);
    if (!success) return;
    dispatch(
      changeUserInformation({
        indexKey,
        value: text,
      })
    );

    showInlineNotification({
      text: "Ändern der Information erfolgreich!",
      type: InlineNotificationType.SUCCESS,
      direction: InlineNotificationDirection.FROM_BOTTOM,
    });
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    setText(currentUser[indexKey] ?? "");
  }, [indexKey]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          {title}
        </Text>

        <TouchableOpacity
          disabled={!isUniversity}
          style={{
            width: "100%",
            maxWidth: Sizes.containerWidth,
            marginTop: 12,
            marginBottom: 20,
          }}
        >
          <TextInput
            placeholder={placeholder}
            placeholderTextColor={Colors[colorScheme].placeholder}
            value={text}
            style={{
              backgroundColor: Colors[colorScheme].background,
              color: Colors[colorScheme].text,
              borderRadius: 12,
              height: 50,
              padding: 12,
              pointerEvents: isUniversity ? "none" : "auto",
            }}
            onChangeText={(_text) => {
              setText(_text);
            }}
          />
        </TouchableOpacity>

        <DefaultButton
          action={change}
          loading={loading}
          style={{ marginTop: 20 }}
          title="Jetzt ändern"
          type={ButtonTypes.Primary}
          enabled={indexKey === "title" || text !== ""}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
