import { Text, TouchableOpacity, View } from "react-native";

import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";

export default function OfflineHintModal({ open, onClose }) {
  const colorScheme = getColorScheme();

  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "offline_hint_modal_presented",
        "OfflineHintModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></View>
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          MEDICEO ist Offline
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          Keine Sorge! Natürlich kannst du MEDICEO ganz bequem offline nutzen.
          Um aber sicherzustellen, dass du immer auf dem neuesten Stand bleibst,
          bitten wir dich, die App einmal alle 10 Tage mit dem Internet zu
          verbinden. So verpasst du keine unserer Aktualisierungen.
        </Text>
        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text="Verstanden!"
          icon={null}
        />
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
