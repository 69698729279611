import { useNavigation } from "@react-navigation/native";
import { FlatList, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import { Sizes } from "../constants/static-sizes";

const authorsData = [
  {
    id: 1,
    name: "Stefan Schöning",
    bio: "Chief Medical Officer",
  },
  {
    id: 2,
    name: "Nicole Schulz",
    bio: "Medical Research",
  },
  {
    id: 3,
    name: "Lourdes Salman",
    bio: "Medical Research",
  },
  {
    id: 4,
    name: "Marie Kind",
    bio: "Medical Research",
  },
  {
    id: 5,
    name: "Zsofia Zatik",
    bio: "Medical Research",
  },
  {
    id: 6,
    name: "Kira Liu",
    bio: "Medical Research",
  },
  {
    id: 7,
    name: "Anna Freundt",
    bio: "Medical Research",
  },

  // Add more authors as needed
];

const AuthorsScreen = () => {
  const navigation = useNavigation();
  return (
    <SafeAreaView style={styles.container}>
      <Text style={styles.heading}>
        Ein großer Dank gilt dem Medical Department 💛
      </Text>
      <FlatList
        data={authorsData}
        contentContainerStyle={styles.listContent}
        style={{ flex: 1, width: "100%" }}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <View style={styles.authorCard}>
            <Text style={styles.authorName}>{item.name}</Text>
            <Text style={styles.authorBio}>{item.bio}</Text>
          </View>
        )}
        ListFooterComponent={
          <DefaultButton
            style={{ marginTop: 20 }}
            type={ButtonTypes.Outline}
            title="Jetzt bewerben"
            action={() => {
              navigation.navigate("WebBrowserScreen", {
                url: "https://www.mediceo.com/karriere",
                title: "Jobs",
              });
            }}
          />
        }
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",

    alignItems: "center",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    paddingBottom: 12,
    marginBottom: 20,
    padding: Sizes.defaultContainerPadding,
    marginTop: 12,
    textAlign: "center",
  },
  listContent: {
    justifyContent: "flex-start",
    width: "100%",
    paddingBottom: 32,
    padding: Sizes.defaultContainerPadding,
    gap: 12,
  },
  authorCard: {
    width: "100%",
    borderRadius: 8,
    padding: 16,
    borderBottomColor: "#e0e0e0",
    borderBottomWidth: 1,
  },
  authorName: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 4,
  },
  authorBio: {
    fontSize: 14,
    color: "#555",
  },
});

export default AuthorsScreen;
