import { ScrollView, Text, TouchableOpacity, View } from "react-native";

import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";

import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { isWebAndNotMobile } from "../../../../hooks/isLargerDevice";

export const LegalScreen = () => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].background,
        flex: 1,
        paddingTop: 20,
        paddingHorizontal: isWebAndNotMobile() ? 32 : 0,
      }}
    >
      <ScrollView>
        {legalData(navigation, colorScheme).map((item) => (
          <SettingListItem data={item} key={item.id} />
        ))}
      </ScrollView>
    </View>
  );
};

const legalData = (navigation, colorScheme) => [
  {
    id: 1,
    title: "Impressum",
    icon: (
      <Feather name="paperclip" size={24} color={Colors[colorScheme].text} />
    ),
    action: () => navigation.navigate("ImpressScreen"),
  },
  {
    id: 2,
    title: "Datenschutzerklärung",
    icon: <Feather name="lock" size={24} color={Colors[colorScheme].text} />,
    action: () => navigation.navigate("PrivacyPolicyScreen"),
  },
  {
    id: 3,
    title: "Nutzungsbedingungen",
    icon: (
      <Feather name="file-text" size={24} color={Colors[colorScheme].text} />
    ),
    action: () => navigation.navigate("TermsAndConditionsScreen"),
  },
];

const SettingListItem = ({ data }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={data.action}
      style={{
        paddingHorizontal: 20,
        marginVertical: 15,
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Text style={{ flex: 1, fontSize: 15, color: Colors[colorScheme].text }}>
        {data.title}
      </Text>
      {data.icon}
    </TouchableOpacity>
  );
};
