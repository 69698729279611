import { Image } from "react-native";
import getColorScheme from "../../hooks/useColorScheme";

export const HorizontalLogo = ({
  width = 200,
  customColorScheme,
}: {
  width?: number;
  customColorScheme?: string;
}) => {
  const colorScheme = customColorScheme ?? getColorScheme();

  width = width - (colorScheme === "dark" ? 70 : 50);

  return (
    <Image
      source={
        colorScheme === "light"
          ? require("../../../assets/images/logo_r.png")
          : require("../../../assets/images/logo_r_white.png")
      }
      style={{
        width: width,
        height: colorScheme === "dark" ? 45 : 35,
        resizeMode: "contain",
      }}
    />
  );
};
