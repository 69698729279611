import { useEffect, useMemo, useState } from "react";
import { Text, View } from "react-native";

import { TextInput } from "react-native";

import { Feather } from "@expo/vector-icons";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getColorScheme from "../../../hooks/useColorScheme";
import { getSharedStylesContent } from "../../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";

export default function ScoreElementInput({
  data,
  isSelecting,
  setCurrentScore,
  isEuroscore,
  setAnswers,
  answers,
}: {
  data: any;
  isSelecting: boolean;
  setCurrentScore: any;
  isEuroscore;
  setAnswers: any;
  answers: any;
}) {
  const currentAnswer = answers.find((element) => element.title === data.title);

  const [currentValue, setCurrentValue] = useState<number | null>(
    currentAnswer?.score ?? null
  );
  const [input, setInput] = useState(currentAnswer?.value ?? "");
  const colorScheme = getColorScheme();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (input === "") return;
    if (isEuroscore) {
      let v = parseInt(input);
      if (v <= 60) v = 1;
      else v = v - 60;
      v = v * 0.0285181;
      const prev = currentValue ?? 0;
      setCurrentValue(Math.round(v * 100) / 100);
      setCurrentScore((score) => score - prev + v);

      setAnswers((answer) => {
        answer = answer.filter((element) => element.title !== data.title);
        return [
          ...answer,
          {
            title: data.title,
            value: input,
            score: v,
            data: null,
          },
        ];
      });

      return;
    }
    if (data.isReverse !== 1) {
      let v = parseInt(input);
      if (!v) v = 0;

      if (v < data.lowerBound) v = data.lowerBound;
      v = v - data.lowerBound;
      v = v / data.divisionNumber;
      const prev = currentValue ?? 0;
      if (parseInt(input) >= data.upperBound) v = 25;

      setCurrentValue(Math.round(v * 100) / 100);

      setCurrentScore((score) => score - prev + v);
      setAnswers((answer) => {
        answer = answer.filter((element) => element.title !== data.title);
        return [
          ...answer,
          {
            title: data.title,
            value: input,
            score: v,
            data: null,
          },
        ];
      });
    } else {
      let v = parseInt(input);
      if (!v) v = data.upperBound;
      if (v > data.upperBound) {
        v = data.upperBound;
      }

      v = data.upperBound - v;
      v = v / data.divisionNumber;
      const prev = currentValue ?? 0;
      if (v > data.upperBound) v = 25;

      if (parseInt(input) <= data.lowerBound) v = 25;
      setCurrentValue(Math.round(v * 100) / 100);
      setCurrentScore((score) => score - prev + v);
      setAnswers((answer) => {
        answer = answer.filter((element) => element.title !== data.title);
        return [
          ...answer,
          {
            title: data.title,
            value: input,
            score: v,
            data: null,
          },
        ];
      });
    }
  }, [input]);

  return (
    <View
      style={{
        borderRadius: 12,
        backgroundColor: Colors[colorScheme].foreground,
        borderColor: isSelecting
          ? Colors[colorScheme].accent
          : Colors[colorScheme].text,
        borderWidth: isSelecting ? 2 : 1,
        marginBottom: 20,
        padding: 12,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{ flex: 1 }}
          onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
        >
          {width !== 0 && <TextItem data={data} />}
        </View>
        {!isSelecting && currentValue ? (
          <View
            style={{
              borderRadius: 6,
              borderColor: Colors[colorScheme].text,
              borderWidth: 1,
            }}
          >
            <Text
              style={{
                color: Colors[colorScheme].text,
                opacity: 0.75,
                fontSize: 20,
                marginRight: 12,
                marginLeft: 12,
                fontWeight: "bold",
              }}
            >
              {currentValue ?? 0}
            </Text>
          </View>
        ) : (
          !isSelecting && <Feather name="chevron-down" size={20} />
        )}
      </View>
      {isSelecting ? (
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",

            marginTop: 12,
          }}
        >
          <TextInput
            placeholder={data.placeholder}
            placeholderTextColor={Colors[colorScheme].placeholder}
            keyboardType="numeric"
            value={input}
            onChangeText={(e) => setInput(e)}
            style={{
              padding: 12,
              borderRadius: 12,
              borderColor: Colors[colorScheme].darkGray,
              borderWidth: 1,
              flex: 1,
              color: Colors[colorScheme].text,
              fontSize: Sizes.boxText,
            }}
          />
          <Text
            style={{
              fontSize: 17,
              fontWeight: "bold",
              marginLeft: 12,
              color: Colors[colorScheme].text,
            }}
          >
            {currentValue ?? ""}
          </Text>
        </View>
      ) : null}
    </View>
  );
}

const TextItem = ({ data }) => {
  const colorScheme = getColorScheme();
  return useMemo(() => {
    return (
      <BoxTextComponent
        style={getSharedStylesContent(data.title, colorScheme)}
        content={"<b>" + data.title + "</b>"}
      />
    );
  }, []);
};
