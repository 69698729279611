import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";
import StandardBox from "../StandardBox";
import { InlineImage } from "../inline-image-types";

interface FirstResultProps {
  title: string;
  expandableContent: string | undefined;
  inlineImage: InlineImage | undefined;
  isNote?: boolean;
  isTeamNote?: boolean;
  insertedContent?: any;
}

export function HintBox(props: FirstResultProps) {
  const colorScheme = getColorScheme();
  return (
    <StandardBox
      {...{
        color: props.isTeamNote
          ? Colors[colorScheme].accentDark
          : Colors[colorScheme].darkGray,
        props,
      }}
    />
  );
}
