import { Appearance, ColorSchemeName } from "react-native";
// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.

export default function getColorScheme(): NonNullable<ColorSchemeName> {
  // if (LOCAL_CS) return LOCAL_CS;

  // TODO: REDO

  return "light";

  const colorScheme = Appearance.getColorScheme();
  if (!colorScheme) return "dark";
  return colorScheme;
}
