import { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import i18n from "../../../localization/i18n";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import { SearchHistoryItem } from "./SearchHistoryItem";

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const SearchHistoryView = ({
  searchHistory,
  issueSearchWithQuery,
  deleteSearchItem,
  deleteAllSearchItems,
  showTrending = true,
}) => {
  const [deleteMode, setDeleteMode] = useState(false);
  const [data, setData] = useState([]);
  const colorScheme = getColorScheme();

  function injectData() {
    const _data = searchHistory.map((history) => ({
      item: history,
      isHistory: true,
    }));

    setData(_data);
  }

  useEffect(() => {
    if (!deleteMode && showTrending) injectData();
    else
      setData(
        searchHistory.map((history) => ({
          item: history,
          isHistory: true,
        }))
      );
  }, [deleteMode, searchHistory]);

  return (
    <View
      style={{
        width: "100%",
        alignSelf: "center",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      {data.map((item: any, index) => (
        <SearchHistoryItem
          key={Math.random()}
          deleteMode={deleteMode}
          isHistory={item.isHistory}
          issueSearchWithQuery={issueSearchWithQuery}
          title={item.item}
          index={index}
          deleteItem={deleteSearchItem}
        />
      ))}
      {searchHistory.length > 0 && (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          {deleteMode && (
            <TouchableOpacity
              onPress={() => {
                // configureAnimations();
                setDeleteMode((d) => !d);
              }}
              style={{
                alignSelf: "flex-end",
                padding: 20,
                paddingTop: 12,
                paddingRight: 0,
              }}
            >
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {i18n.t("done")}
              </Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            onPress={() => {
              //configureAnimations();
              if (deleteMode) deleteAllSearchItems();

              setDeleteMode((d) => !d);
            }}
            style={{ alignSelf: "flex-end", padding: 20, paddingTop: 12 }}
          >
            <Text
              style={{
                color: Colors[colorScheme].accent,
                fontSize: 13,
              }}
            >
              {deleteMode
                ? i18n.t("delete_all_search_results")
                : i18n.t("delete_search_results")}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
