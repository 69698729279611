export const DOC_SPECIALTIES = [
  "Allgemeinmedizin",
  "Anästhesie",
  "Anatomie",
  "Arbeitsmedizin",
  "Augenheilkunde",
  "Biochemie",
  "Chirurgie",
  "Allgemeinchirurgie",
  "Gefäßchirurgie",
  "Herzchirurgie",
  "Kinderchirurgie",
  "Orthopädie und Unfallchirurgie",
  "Plastische, Rekonstruktive, Ästhetische Chirurgie",
  "Thoraxchirurgie",
  "Viszeralchirurgie",
  "Dermatologie",
  "Gynäkologie",
  "Hals-Nasen-Ohrenheilkunde",
  "Humangenetik",
  "Hygiene und Umweltmedizin",
  "Innere Medizin",
  "Angiologie",
  "Endokrinologie und Diabetologie",
  "Gastroenterologie",
  "Hämatologie und Onkologie",
  "Kardiologie",
  "Nephrologie",
  "Pneumologie",
  "Rheumatologie",
  "Kinder- und Jugendmedizin",
  "Kinder- und Jugendpsychiatrie",
  "Laboratoriumsmedizin",
  "Mikrobiologie, Virologie und Infektionsepidemiologie",
  "Mund-Kiefer-Gesichtschirurgie",
  "Neurochirurgie",
  "Neurologie",
  "Nuklearmedizin",
  "Öffentliches Gesundheitswesen",
  "Pathologie",
  "Pharmakologie und Toxikologie",
  "Phoniatrie und Pädaudiologie",
  "Physikalische und Rehabilitative Medizin",
  "Physiologie",
  "Psychiatrie und Psychotherapie",
  "Psychosomatik",
  "Radiologie",
  "Rechtsmedizin",
  "Strahlentherapie",
  "Transfusionsmedizin",
  "Urologie",
  "Sonstiges",
];
