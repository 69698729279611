import UnitCalculatorScreen from "../../../screens/toolbox/unit-calculator/UnitCalculatorScreen";
import UnitCalculatorSubstancesScreen from "../../../screens/toolbox/unit-calculator/UnitCalculatorSubstancesScreen";

export default function CustomResultComponentSorter({ pageContent }) {
  const type = pageContent.customPageContent;

  switch (type) {
    case "unit-calculator":
      return <UnitCalculatorScreen />;
    case "unit-calc-specific":
      return <UnitCalculatorSubstancesScreen />;
  }
}
