import apiCall from "../api/api-call";
import { store } from "./store";
import { setUserInteractionRecords } from "./user/actions";
import { updateOfflineUser } from "./user/functions";
import { User } from "./user/types";

export async function addValueToInteractions(key, value) {
  let currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser) return;

  let _interactions = [...(currentUser?.interaction_records ?? [])];
  _interactions = _interactions.filter((i) => !Object.keys(i).includes(key));
  _interactions.push({ [key]: value });

  const response = await apiCall("/api/v3/users/interaction_records", {
    interaction_records: _interactions,
  });
  if (response)
    store.dispatch(
      setUserInteractionRecords(response.data?.interaction_records ?? [])
    );
  else store.dispatch(setUserInteractionRecords(_interactions));

  currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser) return;
  updateOfflineUser(currentUser);
}

export async function updateValuesInInteractions(values) {
  const response = await apiCall("/api/v3/users/interaction_records", {
    interaction_records: values,
  });
  if (response)
    store.dispatch(
      setUserInteractionRecords(response.data?.interaction_records ?? [])
    );
  else store.dispatch(setUserInteractionRecords(values));
}

export function getValueFromInteractions(key) {
  const currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser) return;

  const record = currentUser.interaction_records?.find((i) =>
    Object.keys(i).includes(key)
  );
  if (record) {
    return record[key]; // Correctly access the value using the key
  }
  return null; // Return null if the key is not found
}
