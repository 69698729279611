import { captureScreen } from "react-native-view-shot";

import getPageContent from "../../api/content/get-page-content";
import {
  NodePage,
  ResultPage,
  ScorePage,
} from "../../api/content/load-all/types";
import { clearAmount } from "../calculator/actions";
import {
  removeFromMultiplePage,
  setHasMultipleChoice,
  setIsQuickHelp,
} from "../current-result/actions";
import { store } from "../store";
import { Platform } from "react-native";

export function navigateToNextPageByCMSId(
  navigation: any,
  id: string,
  title: string,
  modalPresentation?: boolean
) {
  let parsedTitle = title.replace(/<p>/g, "");
  parsedTitle = parsedTitle.replace(/<\/p>/g, "");
  if (parsedTitle.includes("<ul>")) parsedTitle = parsedTitle.split("<ul>")[0];
  const navigationId = correctNavigationId(id);
  navigateToNextCMSPage(navigationId, navigation, modalPresentation);
}

function correctNavigationId(id: string | undefined) {
  const nextMultiplePages =
    store.getState().currentResultReducer.nextMultiplePages;
  let navigationId = id;

  if (nextMultiplePages.length > 0) {
    navigationId = nextMultiplePages[0];
    store.dispatch(removeFromMultiplePage(navigationId));
  }
  if (navigationId === undefined || navigationId === "final_selection") {
    navigationId = getPageIdFromFilters();
    if (navigationId === undefined) navigationId = "final_multiple";
  }
  return navigationId;
}

function getPageIdFromFilters() {
  const filters = store.getState().currentResultReducer.currentFilters;
  const allPages = store.getState().dataReducer.allData.pages;
  return getPageIdWithKnownFilters(filters, allPages);
}

type AllPages = ResultPage | NodePage | ScorePage;

function getPageIdWithKnownFilters(
  filters: string[],
  allPages: AllPages[]
): string | undefined {
  const areFiltersEqual = (arr1?: string[], arr2?: string[]): boolean => {
    if (!arr1 || !arr2) return false;
    return (
      [...arr1]
        .map((item) => item.trim())
        .sort()
        .toString() ===
      [...arr2]
        .map((item) => item.trim())
        .sort()
        .toString()
    );
  };

  // Check newFilters first
  const newRes = allPages.find((page: any) => {
    const foundFilter = page.newFilters?.find((f) =>
      areFiltersEqual(f.fliters, filters)
    );
    return foundFilter !== undefined;
  });

  if (newRes) return newRes._id;

  // If not found in newFilters, check filters
  const res = allPages.find((page: any) =>
    areFiltersEqual(page.filters, filters)
  );

  return res?._id;
}

function defaultActionsForNavigation() {
  store.dispatch(setHasMultipleChoice(false));
}

export async function navigateToNextCMSPage(
  id: string,
  navigation: any,
  modalPresentation = false,
  additionalProps: any = {}
) {
  const isQuick = store.getState().currentResultReducer.isQuickHelp;
  if (!isQuick && !additionalProps?.additionalCaseData) {
    store.dispatch(clearAmount());
  }

  defaultActionsForNavigation();

  if (id === "$UNIT_CALCULATOR") {
    navigation.push("UnitCalculatorScreen");
    return;
  } else if (id === "$UNIT_CALCULATOR_SUBSTANCES") {
    navigation.push("UnitCalculatorScreenSubstances");
    return;
  } else if (id.includes("$PERFUSOR_CALC")) {
    navigation.push("PerfusorCalculatorScreen", {
      currentData: {
        ...additionalProps,
        title: additionalProps?.title,
      },
    });
    return;
  }

  const pageContent = getPageContent(id);

  if (!pageContent) return;
  navigationAdjustments(pageContent);

  let screenShotUri = "";
  // if (modalPresentation && Platform.OS === "web") {
  //   screenShotUri = await captureScreen({
  //     format: "jpg",
  //     quality: 0.0,
  //   });
  // }

  if (pageContent.type === "scorePage") {
    navigation.push("ScoreScreen", {
      pageContent: pageContent,
      ...additionalProps,
      modal: modalPresentation,
      screenShotUri,
    });
  } else if (pageContent.type === "nodePage" && id !== "final_multiple") {
    navigation.push("CMSNodePageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
  } else if (pageContent.type === "checklistPageType") {
    navigation.push("ChecklistScreen", {
      pageContent,
      modal: modalPresentation,
      ...additionalProps,
    });
  } else if (modalPresentation) {
    navigation.push("InfoResultPageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
  } else
    navigation.push("ResultPageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
}

function navigationAdjustments(pageContent) {
  if (pageContent.isQuickHelp) store.dispatch(setIsQuickHelp(true));
  else store.dispatch(setIsQuickHelp(false));
}

export function navigateFromSearch(
  item: any,
  navigation: any,
  assistantParams?: any
) {
  navigateToNextCMSPage(item._id, navigation, false, assistantParams);
}
