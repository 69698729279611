import { store } from "../../functions/store";

import { NodePage, ResultPage, ScorePage } from "./load-all/types";
import parsePageForNewSources from "./new-source-parser";

export default function getPageContent(id: string | undefined) {
  if (!id) return null;
  if (id === "final_multiple") {
    return createCustomResult();
  } else {
    return getContent(id);
  }
}

function getContent(id: string) {
  const allData = store.getState().dataReducer.allData;

  let _pageData = allData?.pages?.find(
    (page: ResultPage | NodePage | ScorePage) => page._id === id
  );
  if (!_pageData)
    _pageData = allData?.pages?.find(
      (page: any) => page.content?.legacy_id === id
    );

  if (!_pageData) return null;

  const pageData = JSON.parse(JSON.stringify(_pageData));

  const pageContent = {
    _id: pageData._id,
    ...pageData.content,
    internal_rating: pageData.internal_rating,
    type: pageData.content_type_id,
    created_at: pageData.created_at,
    last_modified_at: pageData.last_modified_at,
  };

  delete pageContent.quellen;

  if (pageContent?.newSources) {
    parsePageForNewSources(pageContent);
  }

  return pageContent;
}

function createCustomResult() {
  const resultContent = {
    pageId: "CUSTOM",
    fieldTitle: "Ergebnis der Auswahl",
    resultBoxes: [],
    sources: "",
    nextPage: "",
    filters: [],
    type: "resultPage",
    _id: "custom_result",
  };

  let results = getPagesFromMultipleFilters();
  results = [...new Set(results)];

  for (const r of results) {
    const b: any = getContent(r);

    resultContent.resultBoxes = resultContent.resultBoxes.concat(b.resultBoxes);
    if (!resultContent.sources.includes(b.sources)) {
      resultContent.sources = resultContent.sources + "<br>" + b.sources;
    }
  }
  resultContent.sources = resultContent.sources.replace(/<p><\/p>/g, "");
  let split = resultContent.sources.split("<a ");
  split = [...new Set(split)];

  resultContent.sources = split.join("<a ");
  return resultContent;
}

function getPagesFromMultipleFilters(): string[] {
  const filters = store.getState().currentResultReducer.currentFilters;
  const allPages = store.getState().dataReducer.allData?.pages;

  const ra = getPagesFromKnownMultipleFilters(filters, allPages);

  return ra;
}

function getPagesFromKnownMultipleFilters(filters: string[], allPages: any) {
  const ra: string[] = [];

  for (const filter of filters) {
    const res: any = allPages.find((page: any) => {
      if (page.content.filters !== undefined) {
        return page.content?.filters.includes(filter.trim());
      }
    });

    if (res !== undefined) {
      ra.push(res._id);
    }
  }
  return ra;
}
