export default {
  light: {
    text: "#000000",
    placeholder: "#c4c4c4",

    background: "#F2F2F7",
    foreground: "#ffffff",
    inputBackground: "#fafafa",

    accentLight: "#FFC371",
    accent: "#f4c430",
    accentDark: "#c9a227",
    selectedTint: "#FFF8DA",

    gray: "#c4c4c4",
    darkGray: "#A1A1A1",
    lightGray: "#e1e1e1e1",
    veryLightGray: "#eeeeee",

    blue: "#45b5d8",
    lightBlue: "#d1eef6", // New lighter blue for "Changed"
    green: "#12B347",
    red: "#FF4040",
    transparent: "transparent",
  },
  dark: {
    text: "#ffffff",
    placeholder: "#b8b8b8",

    background: "#0A0A0A",
    foreground: "#212121",
    inputBackground: "#3a3a3a",

    accentLight: "#FFC371",
    accent: "#f4c430",
    accentDark: "#c9a227",
    selectedTint: "#392900",

    gray: "#a9a9a9",
    darkGray: "#c4c4c4",
    lightGray: "#626262",
    veryLightGray: "#2f2f2f",

    blue: "#00d9ff",
    lightBlue: "#d1eef6", // Lighter version of blue for "Changed"
    green: "#12B347",
    red: "#FF4040",
    transparent: "transparent",
  },
};
