import { Feather } from "@expo/vector-icons";
import chroma from "chroma-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animated, Platform, TouchableOpacity, View } from "react-native";
import { Easing } from "react-native-reanimated";
import { useDispatch, useSelector } from "react-redux";
import { getSourceParsedText } from "../api/content/source-parser";
import staticColors from "../constants/static-colors";
import { pSBC } from "../functions/color-parser";
import {
  selectCurrentHighlightSection,
  selectResultPageHeaderContent,
  selectResultPageSearchQuery,
  selectShowSources,
  setCurrentHighlightSection,
  setResultPageHeaderContent,
} from "../functions/current-result/actions";
import getColorScheme from "../hooks/useColorScheme";

import { getSharedStylesContent } from "./boxes/SharedStyles";
import { BoxTextComponent } from "./custom-box-text/CustomBoxTextComponent";

import i18n from "../../localization/i18n";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { Sizes } from "../constants/static-sizes";
import { selectPatientData } from "../functions/calculator/actions";
import configureAnimations, {
  configureExpansionAnimation,
} from "../functions/configure-animations";
import AnimatedWrapper from "./AnimatedWrapper/AnimatedWrapper";
import BoxComponent from "./boxes/BoxSorter";

const isBaseTypeBox = (boxType) => {
  const baseTypes = [
    "warningBox",
    "hintBox",
    "infoBox",
    "textBox",
    "arrowBox",
    "searchBox",
    "expandableBox",
    "dosingRecommendationBox",
    "largeContentBox",
    "easyReadingInfoBox",
    "furtherActionBox",
    "actionRecommendationBox",
    "scoreFurtherActionBox",
    "checklistFurtherActionBox",
    "patientDataComponentBox",
    "formulaContentContainer",
    "newCalculatorBox",
  ];
  if (baseTypes.includes(boxType)) return true;
};

export default function ResultPageSkeleton({
  data,
  scrollY,
  remoteClose,
  setSubtitle,
  depth = 0,
  targetSections,
}: {
  readonly data: any;
  readonly scrollY: number;
  readonly remoteClose: boolean;
  readonly setSubtitle?: any;
  readonly depth?: number;
  readonly targetSections?: any;
}) {
  const dispatch = useDispatch();
  const [subviewsLayout, setSubviewsLayout] = useState([]);
  const [isFloatingBoxVisible, setIsFloatingBoxVisible] = useState<any>(true);
  const [viewTopY, setViewTopY] = useState(0);

  const resultPageHeaderContent = useSelector(selectResultPageHeaderContent);
  const resultPageSearchQuery = useSelector(selectResultPageSearchQuery);

  const [floatingCalculatorBoxData, setFloatingCalculatorBoxData] =
    useState<any>(null);

  const ref = useRef<any>();
  const calcBoxRef = useRef<any>();
  const colorScheme = getColorScheme();
  const currentHighlightedSection = useSelector(selectCurrentHighlightSection);

  const shouldShowSources = useSelector(selectShowSources);

  const NON_CONTENT_TAGS = ["typeId", "key", "title", "id", "color"];

  const generateDarkModeColor = (color, amount) => {
    if (colorScheme === "light") return color;
    // Darken the color by a certain amount. Adjust the ratio as needed.
    if (!color) return null;
    const darkenedColor = chroma(color).darken(amount).hex();

    return darkenedColor;
  };

  const handleLayout = (event, index, expanded, setExpanded, subtitle) => {
    const { y, height } = event.nativeEvent.layout;
    const updatedLayouts: any = [...subviewsLayout];
    updatedLayouts[index] = {
      y,
      height,
      expanded,
      setExpanded,
      subtitle: subtitle ?? "",
    };
    setSubviewsLayout(updatedLayouts);
  };

  const checkFloatingBoxVisibility = () => {
    // Example calculation, adjust based on your layout and measurements
    if (!floatingCalculatorBoxData?.y) return;

    const isBoxInView =
      scrollY <=
      floatingCalculatorBoxData?.y +
        floatingCalculatorBoxData?.height -
        (viewTopY + 80);

    if (isFloatingBoxVisible !== isBoxInView) {
      setIsFloatingBoxVisible(isBoxInView);

      // Add analytics tracking here for floating box visibility change
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "floating_calculator_box_visibility_changed",
        "ResultPageSkeleton",
        "Floating Calculator Box Visibility Changed",
        { isVisible: isBoxInView }
      );
    }
  };

  useEffect(() => {
    // Call this function whenever relevant values change
    checkFloatingBoxVisibility();
  }, [scrollY, viewTopY, floatingCalculatorBoxData, remoteClose]);

  const renderData = useMemo(() => {
    // Define the keys you want to check for
    const checkKeys = ["diagnostik"];
    // Check if both keys exist in the data object
    const bothKeysExist = checkKeys.every((key) =>
      Object.keys(data).includes(key)
    );

    let keysToProcess = Object.keys(data).filter(
      (key) =>
        !["detailPath", "path", "sources", "appendedSources"].includes(key)
    );

    // If both keys exist, prioritize them in the specified order
    if (bothKeysExist) {
      const priorityKeys = keysToProcess.filter((key) =>
        checkKeys.includes(key)
      );
      const otherKeys = keysToProcess.filter((key) => !checkKeys.includes(key));
      // Prioritize "diagnostik" while keeping their relative order unchanged
      keysToProcess = [...priorityKeys, ...otherKeys];
    }

    let items = keysToProcess
      .flatMap((key) => {
        if (Array.isArray(data[key]) && data[key].length < 2) {
          return { ...data[key][0], key };
        } else if (Array.isArray(data[key])) {
          return data[key].flatMap((item) => ({
            ...item,
            key: getItemTitle(item),
          }));
        }
      })
      .filter((item) => item)
      .filter(
        (item) =>
          Object.keys(item).filter((key) => !NON_CONTENT_TAGS.includes(key))
            .length > 0
      );

    // Filter items based on the resultPageSearchQuery if it's longer than 2 characters
    if (resultPageSearchQuery && resultPageSearchQuery.length > 2) {
      items = items.filter(
        (item) =>
          item.key === "floatingCalculatorBox" ||
          item.typeId === "calculatorBox" ||
          JSON.stringify(item)
            .toLowerCase()
            .includes(resultPageSearchQuery.toLowerCase())
      );
    }

    return items;
  }, [data, resultPageSearchQuery]); // Include resultPageSearchQuery in the dependency array

  useEffect(() => {
    if (depth !== 0) return;
    if (isFloatingBoxVisible) {
      if (resultPageHeaderContent && Platform.OS === "ios")
        configureAnimations();
      dispatch(setResultPageHeaderContent(null));
      return;
    }
    const calcBox = renderData.find(
      (item) =>
        item.key === "floatingCalculatorBox" || item.typeId === "calculatorBox"
    );
    if (!calcBox) return;
    if (Platform.OS === "ios") configureAnimations();
    dispatch(setResultPageHeaderContent(calcBox));
  }, [renderData, isFloatingBoxVisible]);

  useEffect(() => {
    if (depth !== 0 || subviewsLayout.length === 0) return;

    const visibleSubviewIndex: any = subviewsLayout
      //.filter((item) => item)
      .findIndex((item: any) => {
        if (!item) return;
        const viewBottomPosition = item.y + item.height;
        if (item.expanded)
          return (
            item.y <= scrollY - viewTopY &&
            viewBottomPosition >= scrollY - viewTopY
          );
        else return false;
      });

    if (currentHighlightedSection?.key === visibleSubviewIndex) {
      return;
    }

    if (visibleSubviewIndex !== -1) {
      const visibleSubview: any = renderData.filter(
        (item) =>
          item.key !== "floatingCalculatorBox" &&
          item.typeId !== "calculatorBox"
      )[visibleSubviewIndex];

      const visibleSubviewData: any = subviewsLayout[visibleSubviewIndex];

      const adjustedColor =
        generateDarkModeColor(
          pSBC(0.4, visibleSubview.colour ?? visibleSubview.color),
          3
        ) ?? staticColors[colorScheme].background;

      const _item = (
        <TouchableOpacity
          onPress={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "skeleton_item_interaction_header",
              "ResultPageSkeletonHeader",
              "User Interacted With Skeleton Item in Header",
              { key: visibleSubview.key, expanded: !visibleSubview.expanded }
            );
            visibleSubviewData?.setExpanded?.(false);
          }}
          key={visibleSubviewIndex}
          style={{
            backgroundColor: adjustedColor,
            paddingTop: 12,
            paddingBottom: 12,
            marginHorizontal: -Sizes.defaultContainerPadding,
            paddingHorizontal: Sizes.defaultContainerPadding,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <BoxTextComponent
              style={{
                body: {
                  color: staticColors[colorScheme].text,
                  fontWeight: "600",
                  fontSize: 15,
                  margin: 0,
                  padding: 0,
                },
                p: {
                  margin: 0,
                  padding: 0,
                },
              }}
              content={
                "<p>" +
                getSourceParsedText(
                  visibleSubview.title ??
                    visibleSubview.titel ??
                    reverseCamelCase(visibleSubview.key),
                  shouldShowSources
                ) +
                "</p>"
              }
            />
            {visibleSubviewData?.subtitle && (
              <BoxTextComponent
                style={{
                  body: {
                    margin: 0,
                    color: staticColors[colorScheme].text,
                    fontWeight: "400",
                    marginTop: 4,
                    fontSize: 14,

                    padding: 0,
                  },
                  p: {
                    margin: 0,
                    padding: 0,
                  },
                }}
                content={
                  "<p>" +
                  getSourceParsedText(
                    visibleSubviewData.subtitle,
                    shouldShowSources
                  ) +
                  "</p>"
                }
              />
            )}
          </View>
          <Feather
            name="chevron-up"
            size={24}
            color={staticColors[colorScheme].text}
          />
        </TouchableOpacity>
      );

      if (visibleSubviewIndex !== currentHighlightedSection?.key) {
        dispatch(setCurrentHighlightSection(_item));
      }
    } else if (currentHighlightedSection !== null) {
      if (currentHighlightedSection !== null)
        dispatch(setCurrentHighlightSection(null));
    }
  }, [scrollY, viewTopY]); // Ensure viewTopY is tracked/updated correctly in your component

  const patientData = useSelector(selectPatientData);

  const floatingCalculatorBox = !patientData && (
    <BoxComponent
      box={renderData.find(
        (item) =>
          item.key === "floatingCalculatorBox" ||
          item.typeId === "calculatorBox"
      )}
    />
  );

  const skeletonIsFirstItemAndNested =
    depth > 0 && !isBaseTypeBox(renderData?.[0]);

  return (
    <View
      ref={ref}
      onLayout={() => {
        if (depth !== 0) return;
        ref.current.measure((x, y, width, height, pageX, pageY) => {
          if (scrollY === 0)
            setViewTopY(pageY + Platform.OS === "web" ? 0 : 50);
        });
      }}
      style={{ marginTop: skeletonIsFirstItemAndNested ? 0 : 16 }}
    >
      {floatingCalculatorBox && (
        <View
          ref={calcBoxRef}
          onLayout={() => {
            if (depth !== 0) return;
            calcBoxRef.current.measure((x, y, width, height, pageX, pageY) => {
              if (!floatingCalculatorBoxData?.y)
                setFloatingCalculatorBoxData({ y: pageY, height });
            });
          }}
          // style={{ marginBottom: 12 }}
        >
          {floatingCalculatorBox}
        </View>
      )}
      {renderData
        .filter(
          (item) =>
            item.key !== "floatingCalculatorBox" &&
            item.typeId !== "calculatorBox"
        )
        .map((item, index) => (
          <SkeletonItem
            key={item.key + "-" + index}
            {...{
              item,
              depth,
              scrollY,
              handleLayout,
              index,
              remoteClose,
              targetSections,
              _setSubtitle: setSubtitle,
            }}
          />
        ))}
    </View>
  );
}

function getItemTitle(item) {
  let title = item.title ?? item.titel ?? reverseCamelCase(item.key);
  if (i18n.locale === "en") {
    title = getTranslatedItemTitle(title);
  }
  return title;
}

function getTranslatedItemTitle(title) {
  // Extract the main part of the title without the source numbers
  const mainTitle = title.split("$")[0].trim();

  // Translate the main title
  let translatedTitle;
  switch (mainTitle) {
    case "Symptomorientierte Anamnese":
      translatedTitle = "Symptom-oriented anamnesis";
      break;
    case "Prähospital":
      translatedTitle = "Prehospital";
      break;
    case "Intrahospital":
      translatedTitle = "In-hospital";
      break;
    case "Diagnostik":
      translatedTitle = "Diagnostic";
      break;
    default:
      translatedTitle = mainTitle;
  }

  // Reattach the source numbers to the translated title
  const sources = title.match(/\$source_\d+\$/g) || [];
  return translatedTitle + " " + sources.join(" ");
}

// Example usage

const SkeletonItem = ({
  item,
  depth,
  scrollY,
  handleLayout,
  index,
  remoteClose,
  _setSubtitle,
  targetSections,
}) => {
  const colorScheme = getColorScheme();
  const [expanded, setExpanded] = useState(false);

  const [subtitle, setSubtitle] = useState("");

  const indicatorLineWidth = useRef(new Animated.Value(0)).current;

  const shouldShowSources = useSelector(selectShowSources);

  const generateDarkModeColor = useCallback(
    (color, amount) => {
      if (colorScheme === "light") return color;
      // Darken the color by a certain amount. Adjust the ratio as needed.
      if (!color) return null;
      const darkenedColor = chroma(color).darken(amount).hex();

      return darkenedColor;
    },
    [colorScheme]
  );

  const adjustedColor =
    generateDarkModeColor(pSBC(0.75, item.colour ?? item.color), 3) ??
    staticColors[colorScheme].background;

  const adjustedColor_2 =
    generateDarkModeColor(pSBC(0.4, item.colour ?? item.color), 3) ??
    staticColors[colorScheme].background;

  useEffect(() => {
    setExpanded(false);
  }, [remoteClose]);

  useEffect(() => {
    configureExpansionAnimation();
    if (!expanded) hideIndicatorLine();
    else {
      setTimeout(() => {
        showIndicatorLine();
      }, 150);
    }
  }, [expanded]);

  useEffect(() => {
    if (depth >= 1 && expanded) {
      _setSubtitle?.(getItemTitle(item));
    } else {
      _setSubtitle?.(undefined);
    }
  }, [expanded, depth]);

  const showIndicatorLine = () => {
    Animated.timing(indicatorLineWidth, {
      toValue: Platform.OS === "web" ? 12 : 8,
      duration: 250,
      easing: Easing.in(Easing.quad),
      useNativeDriver: false,
    }).start();
  };

  const hideIndicatorLine = () => {
    Animated.timing(indicatorLineWidth, {
      toValue: 0,
      duration: 50,
      easing: Easing.in(Easing.ease),
      useNativeDriver: false,
    }).start();
  };

  const boxData = useMemo(() => {
    if (Array.isArray(item.content)) {
      return item.content.map((box) => {
        if (box.typeId === "multiContentSectionType") {
          return (
            <View
              key={box.id}
              style={{
                //marginTop: 8,
                marginBottom: 0,
                marginLeft: getMarginToDepth(depth),
              }}
            >
              <SkeletonItem
                key={box.id}
                _setSubtitle={_setSubtitle}
                targetSections={undefined}
                item={box}
                handleLayout={handleLayout}
                index={index}
                depth={depth + 1}
                scrollY={scrollY}
                remoteClose={remoteClose}
              />
            </View>
          );
        } else {
          return (
            <View
              key={box.id}
              style={{
                marginLeft: getMarginToDepth(depth),
              }}
            >
              <BoxComponent box={box} key={box.id} />
            </View>
          );
        }
      });
    } else if (isBaseTypeBox(item.typeId)) {
      return (
        <View
          style={{
            marginLeft: getMarginToDepth(depth),
          }}
        >
          <BoxComponent box={item} />
        </View>
      );
    }

    return [];
  }, [item.content]);

  useEffect(() => {
    if (!targetSections) return;
    setTimeout(() => {
      if (targetSections.find((section) => section.id === item.id)) {
        configureAnimations();
        setExpanded(true);
      }
    }, 250);
  }, [targetSections]);

  const skeletonItemRef = useRef<any>();

  if (isBaseTypeBox(item.typeId))
    return (
      <View
        style={{
          //marginBottom: 12,
          marginLeft: getMarginToDepth(depth),
        }}
      >
        {boxData}
      </View>
    );

  return (
    <View
      key={item.key}
      ref={skeletonItemRef}
      onLayout={(e) => {
        if (depth === 0)
          handleLayout(e, index, expanded, setExpanded, subtitle);
      }}
      style={{}}
    >
      <View
        style={{
          top: 24,
          bottom: 0,
          left: 0,
          right: 0,
          position: "absolute",
          zIndex: 0,
          backgroundColor: adjustedColor,
          opacity: expanded ? 1 : 0,
          marginHorizontal: -Sizes.defaultContainerPadding,

          borderBottomWidth: 4,
          borderBottomColor: adjustedColor_2,
          marginBottom: 0,
        }}
      />

      <View
        style={{
          //marginHorizontal: expanded ? -Sizes.defaultContainerPadding : 0,
          marginLeft: expanded && depth === 0 ? 7 : 0,
          borderWidth: expanded ? 0 : 2,
          borderRadius: 10,
          borderLeftWidth: !expanded ? 7 : 0,
          borderColor:
            generateDarkModeColor(item.colour ?? item.color, 1) ??
            staticColors[colorScheme].lightGray,
          marginVertical: Sizes.defaultBoxPadding,
          marginBottom: expanded ? 36 : 0,
          marginTop: Sizes.defaultBoxPadding + 12,
          flexDirection: "row",
          backgroundColor: expanded
            ? staticColors[colorScheme].transparent
            : adjustedColor,
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              flex: 1,
              //    paddingHorizontal: Sizes.defaultContainerPadding,
              padding: expanded ? 0 : Sizes.defaultBoxPadding,

              marginBottom: expanded ? 24 : 0,
              marginTop: expanded ? 12 : 0,
            }}
          >
            <AnimatedWrapper style={{ width: "100%" }}>
              <TouchableOpacity
                hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
                style={{
                  flexDirection: "row",
                  //paddingVertical: Sizes.defaultContainerPadding,
                  zIndex: 100,
                  gap: 8,
                  alignItems: "center",
                  paddingHorizontal: Sizes.defaultContainerPadding,
                  marginHorizontal: -Sizes.defaultContainerPadding,
                  overflow: "visible",
                  paddingVertical:
                    Platform.OS === "web" ? 15 : depth === 0 ? 12 : 8,

                  marginVertical: Platform.OS === "web" ? -15 : -12,
                  backgroundColor: !expanded
                    ? staticColors[colorScheme].transparent
                    : adjustedColor_2,
                }}
                onPress={() => {
                  configureExpansionAnimation();
                  setExpanded((e) => !e);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "section_toggle",
                    "SkeletonItem",
                    expanded
                      ? "User Collapsed Section"
                      : "User Expanded Section",
                    {
                      sectionKey: item.key,
                      sectionTitle: getItemTitle(item),
                    }
                  );
                }}
              >
                <View style={{ flex: 1 }}>
                  <TextTypeComponent
                    item={item}
                    shouldShowSources={shouldShowSources}
                    expanded={expanded}
                    depth={depth}
                  />
                </View>
                <Feather
                  key="arrow"
                  name={expanded ? "chevron-up" : "chevron-down"}
                  size={depth === 0 ? 28 : 20}
                  color={staticColors[colorScheme].text}
                />
              </TouchableOpacity>
            </AnimatedWrapper>
          </View>
          <AnimatedWrapper style={{ width: "100%" }}>
            {typeof item.content === "string" && expanded && (
              <InlineTextType item={item} />
            )}

            <View
              style={
                {
                  // paddingHorizontal: Sizes.defaultContainerPadding,
                  //   marginBottom: expanded ? 8 : 0,
                }
              }
            >
              {Array.isArray(item.content) && boxData.length !== 0 && (
                <View
                  style={{
                    overflow: expanded ? "visible" : "hidden",
                    height: expanded ? "auto" : 0,
                  }}
                >
                  {boxData}
                </View>
              )}

              {expanded && boxData.length === 0 && (
                <View
                  style={{
                    marginTop: -8,
                  }}
                >
                  <ResultPageSkeleton
                    data={item}
                    depth={depth + 1}
                    scrollY={scrollY}
                    remoteClose={remoteClose}
                    setSubtitle={setSubtitle}
                  />
                </View>
              )}
            </View>
          </AnimatedWrapper>
        </View>
      </View>
      <Animated.View
        style={{
          width: indicatorLineWidth,
          top: 24,
          bottom: 0,
          position: "absolute",
          left: -Sizes.defaultContainerPadding,
          backgroundColor: adjustedColor_2,
        }}
      />
    </View>
  );
};

const TextTypeComponent = ({ item, shouldShowSources, expanded, depth }) => {
  const colorScheme = getColorScheme();

  return useMemo(
    () => (
      <BoxTextComponent
        style={{
          body: {
            fontSize: depth === 0 ? Sizes.boxText + 3 : Sizes.boxText + 2,
            fontWeight: depth === 0 ? "700" : "600",
            flex: 1,
            color: expanded
              ? staticColors["light"].text
              : staticColors[colorScheme].text,
            margin: 0,
            padding: 0,
          },
          p: {
            margin: 0,
            padding: 0,
          },
        }}
        content={
          "<p>" +
          getSourceParsedText(getItemTitle(item), shouldShowSources) +
          "</p>"
        }
      />
    ),
    [item, shouldShowSources]
  );
};

const InlineTextType = ({ item }) => {
  const colorScheme = getColorScheme();

  return (
    <View style={{ marginTop: 8, marginHorizontal: -4, marginBottom: -20 }}>
      {useMemo(
        () => (
          <BoxTextComponent
            style={getSharedStylesContent("", colorScheme)}
            noBulletStyles
            content={item.content}
          />
        ),
        [item.content]
      )}
    </View>
  );
};

export function reverseCamelCase(s: string) {
  if (!s) return "";
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function getMarginToDepth(depth) {
  if (depth === 0) return 0;
  else return 7;
}
