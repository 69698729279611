import { createSlice } from "@reduxjs/toolkit";

const initialAmounts: any = {};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState: {
    amounts: initialAmounts,
    currentUnit: undefined,
    calculationVar: 0,
    gender: "m",
    formulaResult: null,
    resultingCalculation: null,
    scoreData: null,
    checklistData: null,
    checklistNote: "",
  },
  reducers: {
    setAmount(state, action) {
      Object.assign(state.amounts, action.payload);
    },
    setScoreData(state, action) {
      state.scoreData = { ...(state.scoreData ?? {}), ...action.payload };
    },
    setChecklistData(state, action) {
      state.checklistData = action.payload;
    },
    clearAmount(state) {
      state.amounts = {};
    },

    setCurrentUnit(state, action) {
      state.currentUnit = action.payload;
    },
    setCalculationVar(state, action) {
      state.calculationVar = action.payload;
    },
    setFormulaResult(state, action) {
      state.formulaResult = action.payload;
    },
    setResultingCalculation(state, action) {
      state.resultingCalculation = action.payload;
    },
    setChecklistNote(state, action) {
      state.checklistNote = action.payload;
    },
  },
});

export default calculatorSlice.reducer;
export const {
  setAmount,
  setCurrentUnit,
  setCalculationVar,
  clearAmount,
  setFormulaResult,
  setResultingCalculation,
  setScoreData,
  setChecklistData,
  setChecklistNote,
} = calculatorSlice.actions;
export const selectAllAmounts = (state: any) => state.calculatorReducer.amounts;
export const selectScoreData = (state: any) =>
  state.calculatorReducer.scoreData;
export const selectCurrentUnit = (state: any) =>
  state.calculatorReducer.currentUnit;
export const selectChecklistData = (state: any) =>
  state.calculatorReducer.checklistData;
export const selectCalculationVar = (state: any) =>
  state.calculatorReducer.calculationVar;
export const selectFormulaResult = (state: any) =>
  state.calculatorReducer.formulaResult;
export const selectResultingCalculation = (state: any) =>
  state.calculatorReducer.resultingCalculation;
export const selectChecklistNote = (state: any) =>
  state.calculatorReducer.checklistNote;
