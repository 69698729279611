interface Source {
  _id: string;
  attached_files: any[];
  author?: string;
  copyright?: string[];
  created_by: string;
  document_type: string;
  history: any[];
  item_type: string;
  links: any[];
  notes: string;
  publishing_year?: number;
  reminder_date?: string;
  source_language?: string;
  status: string;
  title: string;
  updated_by?: string;
  due_date?: Duedate;
  reminder?: any[];
  source_data?: Sourcedata;
}

interface Sourcedata {
  author_or_editor: string;
  band_and_volume: string;
  edition: string;
  editor: string;
  journals: string;
  location: string;
  publisher: string;
  publishing_date: string;
  source_language: string;
}

interface Duedate {
  due_date: string;
  is_sent: boolean;
}

// value: "Medizinisches Lehrbuch",
// value: "Medizinische Leitlinie",
// value: "Medizinische Fachinformation",
// value: "Journal-Artikel / Artikel einer Fachzeitschrift",
// value: "Internetquelle",

export default function getGeneratedSource(source: Source) {
  if (!source) return;

  let content = "";

  switch (source.item_type) {
    case "Medizinische Fachinformation":
      if (source.links?.length > 0) {
        // Handle link type
        content = `<li><a href="${source.links?.[0]?.link || "#"}">${
          source.source_data?.editor || ""
        }. ${source.title || ""}. ${getPublishingDate(
          source.source_data?.publishing_date
        )} [zitiert ${getCitationDate(source)}].</a></li>`;
      } else if (source.attached_files?.length > 0) {
        // Handle file type
        const fileLink = source.attached_files[0].file_ref || "#";

        content = `<li><a href="${fileLink}"> ${
          source.source_data?.editor || ""
        }. ${source.title || ""}. ${getPublishingDate(
          source.source_data?.publishing_date
        )} [zitiert ${getCitationDate(source)}].</a></li>`;
      } else {
        // Handle the case where neither links nor files are present
        content = `<li>${source.source_data?.editor || ""}. ${
          source.title || ""
        }. ${getPublishingDate(
          source.source_data?.publishing_date
        )} [zitiert ${getCitationDate(source)}].</li>`;
      }
      break;

    case "Medizinisches Lehrbuch":
      content = `${source.source_data?.author_or_editor || ""}. ${
        source.title || ""
      }. ${source.source_data?.edition || ""}. ${
        source.source_data?.location || ""
      }: ${source.source_data?.publisher || ""}, ${getPublishingDate(
        source.source_data?.publishing_date
      )}.`;
      if (source.links?.[0]?.link) {
        content = `<li><a href="${source.links[0].link}">${content}</a></li>`;
      } else {
        content = "<li>" + content + "</li>";
      }
      break;

    case "Medizinische Leitlinie":
      content = `<li><a href="${source.links?.[0]?.link || "#"}">${
        source.source_data?.author_or_editor || ""
      }. ${source.title || ""}. ${
        source.source_data?.editor || ""
      }. ${getPublishingDate(source.source_data?.publishing_date)}. ${
        source?.source_data?.edition || ""
      } [zitiert ${getCitationDate(source)}].</a></li>`;
      break;

    case "Journal-Artikel / Artikel einer Fachzeitschrift":
      content = `<li><a href="${source.links?.[0]?.link || "#"}">${
        source.source_data?.author_or_editor || ""
      }. ${source.title.trim() || ""}. ${
        source.source_data?.journals || ""
      }. ${getPublishingDate(source.source_data?.publishing_date)}; ${
        source.source_data?.band_and_volume || ""
      } [zitiert ${getCitationDate(source)}].</a></li>`;
      break;

    case "Internetquelle":
      content = `<li><a href="${source.links?.[0]?.link || "#"}">${
        source.source_data?.author_or_editor || ""
      }. ${source.title.trim() || ""}. ${
        source.source_data?.band_and_volume
          ? source.source_data.band_and_volume + "."
          : ""
      } ${getPublishingDate(
        source.source_data?.publishing_date
      )} [zitiert ${getCitationDate(source)}].</a></li>`;
      break;

    case "PDF":
      content = `<li><a href="${source.links?.[0]?.link || "#"}">${
        source.title || ""
      }</a></li>`;
      break;

    default:
      content = "<li>" + (source.title || "") + "</li>";
      break;
  }

  // Remove any instances of "null"
  content = content.replace(/null/g, "");

  // Ensure no ".." or ". ." appears
  content = content.replace(/\.{2,}/g, ".").replace(/\. \./g, ".");
  content = content.replace(/ : /g, "");

  return content;
}

function getPublishingDate(dateString) {
  if (!dateString) return "-";

  // Check if dateString is in ISO format (YYYY, YYYY-MM, YYYY-MM-DD, etc.)
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T00:00:00$/;
  if (isoDatePattern.test(dateString)) {
    const date = new Date(dateString);
    // Check if the date is valid
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString();
    }
  }

  // Return the original string if it's not a valid ISO date
  return dateString;
}

function getCitationDate(source: Source) {
  const date = source.history.find(
    (item) => item.change_type === "creation"
  )?.modified_at;
  if (!date) return "-";

  return new Date(date).toLocaleDateString();
}
