import { Feather } from "@expo/vector-icons";
import { Platform, TouchableOpacity } from "react-native";
import Colors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";
import isLargerDevice from "../../../hooks/isLargerDevice";
import { LargeHeaderBackButton } from "./LargeHeaderBackButton";

export const BackButton = ({ navigation, isModal = false }) => {
  const colorScheme = getColorScheme();
  if (isLargerDevice())
    return <LargeHeaderBackButton {...{ isModal, navigation }} />;
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.goBack();
      }}
      style={{
        ...Platform.select({ web: { alignSelf: "flex-end" } }),
        marginLeft: -6,
      }}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    >
      <Feather
        name={isModal ? "x" : "chevron-left"}
        color={Colors[colorScheme].text}
        size={28}
      />
    </TouchableOpacity>
  );
};
