import { Image, StyleSheet, Text, View } from "react-native";
import arrow_down from "../../../../../assets/images/icons/down-arrow.png";

export function ArrowBox({ title, color }) {
  return (
    <View
      style={{
        width: "100%",
        marginTop: 10,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: -10,
      }}
    >
      <View style={styles.container}>
        <Image
          source={arrow_down}
          style={{ width: 24, height: 24, tintColor: color }}
        />
        {title === undefined || title === "" ? null : (
          <Text style={[styles.content_text, { color: color, marginLeft: 12 }]}>
            {title}
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,

    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 75,
  },
  content_text: {
    fontSize: 12,
    lineHeight: 15,
    fontWeight: "700",
  },
});
