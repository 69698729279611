import { useHeaderHeight } from "@react-navigation/elements";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
} from "react-native";

import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import CreateNotesScreenHeader from "../components/header/CreateNotesScreenHeaderComponent";
import Colors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

function createContentStyle(theme) {
  // Can be selected for more situations (cssText or contentCSSText).
  const contentStyle = {
    backgroundColor: Colors[theme].foreground,
    color: "#fff",
    caretColor: "white", // initial valid// initial valid
    placeholderColor: "gray",
    // cssText: '#editor {background-color: #f3f3f3}', // initial valid
    contentCSSText: "font-size: 16px; min-height: 200px;", // initial valid
  };
  if (theme === "light") {
    contentStyle.backgroundColor = Colors[theme].foreground;
    contentStyle.caretColor = "black";
    contentStyle.color = "#000033";
    contentStyle.placeholderColor = "#a9a9a9";
  }
  return contentStyle;
}

export function CreateNoteScreen({ route, navigation }) {
  const { note, currentDisplayID } = route.params;
  const richText: any = useRef();
  const height = useHeaderHeight();
  // save on html

  const handleChange = useCallback((html: any) => {
    if (note?.note !== html) setShowCheck(true);
    else setShowCheck(false);
    setNoteText(html);
  }, []);

  const editorInitializedCallback = useCallback(() => {
    richText.current?.registerToolbar();
  }, []);

  const colorScheme = getColorScheme();

  const dark = colorScheme !== "light";

  const [showCheck, setShowCheck] = useState(false);

  const [noteText, setNoteText] = useState(note?.note);
  const [noteID, setNoteID] = useState(note?.id);

  useEffect(() => {
    if (noteID === undefined) {
      setNoteID(currentDisplayID);
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        paddingTop: 20,
        height: "100%",
        backgroundColor: Colors[colorScheme].foreground,
      }}
    >
      <CreateNotesScreenHeader
        note={{
          id: noteID,
          note: noteText,
          showInResult: true,
        }}
        navigation={navigation}
        showCheck={showCheck}
      />
      <RichToolbar
        style={[styles.richBar, dark && styles.richBarDark]}
        flatContainerStyle={styles.flatStyle}
        editor={richText}
        actions={[
          actions.keyboard,
          actions.setBold,
          actions.setItalic,
          actions.setStrikethrough,
          actions.insertBulletsList,
          actions.insertOrderedList,
          actions.removeFormat,
        ]}
        disabled={false}
        selectedIconTint={Colors[colorScheme].accentDark}
        disabledIconTint={"#bfbfbf"}
      />
      <ScrollView
        style={[
          styles.scroll,
          dark && {
            backgroundColor: Colors[colorScheme].background,
          },
        ]}
        keyboardShouldPersistTaps="handled"
      >
        <RichEditor
          initialFocus={true}
          editorStyle={createContentStyle(colorScheme)} // default light style
          ref={richText}
          style={styles.rich}
          initialHeight={Dimensions.get("screen").height - 250}
          // containerStyle={{borderRadius: 24}}
          placeholder={""}
          editorInitializedCallback={editorInitializedCallback}
          onChange={handleChange}
          initialContentHTML={note?.note ?? ""}
          pasteAsPlainText={true}
        />
      </ScrollView>

      <KeyboardAvoidingView
        behavior={"padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? height : 0}
        style={{ width: "100%" }}
      >
        <RichToolbar
          style={[styles.richBar, dark && styles.richBarDark]}
          flatContainerStyle={styles.flatStyle}
          editor={richText}
          // iconTint={color}
          selectedIconTint={Colors[colorScheme].accentDark}
          disabledIconTint={"#bfbfbf"}
          // iconSize={24}
          // iconGap={10}
          actions={[
            actions.undo,
            actions.redo,
            actions.alignLeft,
            actions.alignCenter,
            actions.alignRight,
          ]} // default defaultActions
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  rich: {
    minHeight: Dimensions.get("screen").height - 200,
    flex: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#e3e3e3",
  },
  richBar: {
    borderColor: "#efefef",
    width: "100%",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  richBarDark: {
    backgroundColor: "#191d20",
    borderColor: "#696969",
  },
  scroll: {
    backgroundColor: "#ffffff",
    width: "100%",
    flex: 1,
  },
  flatStyle: {
    paddingHorizontal: 12,
  },
});
