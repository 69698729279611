import { Platform, SafeAreaView, ScrollView, View } from "react-native";
import Pdf from "react-native-pdf";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getColorScheme from "../../../../hooks/useColorScheme";

const TOC_URI =
  "https://firebasestorage.googleapis.com/v0/b/medify-826de.appspot.com/o/Entwurf_Nutzungsbedingungen_MEDICEO_20230920_kostenlos.pdf?alt=media&token=3fddf906-b9bd-40c5-848d-c62e6953db51&_gl=1*184d4ua*_ga*MTIwNTQwOTI1OC4xNjczNzgzNzc3*_ga_CW55HF8NVT*MTY5OTM4NTA1OC4xNjcuMS4xNjk5Mzg1MTAzLjE1LjAuMA..";
export function TermsAndConditionsScreen() {
  const colorScheme = getColorScheme();

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            marginTop: 0,
            width: "100%",
            alignItems: "center",
            flex: 1,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "center",
              height: "100%",
              maxWidth: Sizes.containerWidth,
            }}
          >
            <TermsAndConditionsContent />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

export const TermsAndConditionsContent = () => {
  return (
    <ScrollView
      style={{ width: "100%", flex: 1 }}
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: "center",
        width: "100%",
      }}
      horizontal={false}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        {Platform.OS !== "web" ? (
          <Pdf
            trustAllCerts={false}
            source={{ uri: TOC_URI }}
            style={{ flex: 1 }}
          />
        ) : (
          <iframe src={TOC_URI} style={{ flex: 1 }} />
        )}
      </View>
    </ScrollView>
  );
};
